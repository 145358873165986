<template lang="html">
  <bi-control
    query-info-url="/RealEstate/Report/GetLandparcelPriceQueryInfo"
    tree-url="/RealEstate/Report/GetTenants"
    preview-url="/RealEstate/Report/LandparcelPriceBIResult"
    download-url="/RealEstate/Report/LandparcelPriceBIExcel"
  />
</template>

<script lang="js">
import biControl from 'src/vue/areas/real-estate/components/bi-controls/BIControl'

export default {
  components: {
    biControl
  }
}
</script>

<style lang="scss">
</style>
