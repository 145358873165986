{postJson, get} = require 'src/coffee/infrastructure/ajax'

{getModal} = require 'src/coffee/controls/modals'

{noop} = require 'src/coffee/areas/real-estate/utils/noop'
{Stati} = require 'src/coffee/areas/real-estate/utils/stati'
{Binder} = require 'src/coffee/areas/real-estate/utils/binder'
{parse, format} = require 'src/js/i18n/conversion'


{LandParcelSelectorController} = require './land-parcel-selector-controller'
{CreatePersonController} = require './create-person-controller'
{CreateBusinessObjectController} = require './create-business-object-controller'

class ConfirmationDialog
    constructor: (prompt) ->
        @confirmFunc = noop
        @dismissFunc = noop

        @$modal = $('#genericConfirmationDialog').modal(show: false)
        $('#genericConfirmationDialog').find('#dialog-text').empty().html(prompt)

        confirmButton = $('#genericConfirmationConfirmBtn')
        dismissButton = $('#genericConfirmationDismissBtn')

        disableEvents = ->
            dismissButton.off('click')
            confirmButton.off('click')

        confirmButton.off().on 'click', =>
            @$modal.modal('hide')
            disableEvents()
            @confirmFunc()
        dismissButton.off().on 'click', =>
            @$modal.modal('hide')
            disableEvents()
            @dismissFunc()

    onConfirm: (func) =>
        @confirmFunc = func
        return this

    onDismiss: (func) =>
        @dismissFunc = func
        return this

    show: =>
        @$modal.modal('show')
        return this


class PurchaseDetailController
    constructor: (options) ->
        @data = null
        @view = options.view
        @stati = new Stati(@view, 'common', 'landparcels', 'facilities', 'finances')
        @onChangeCallback = options.onChange
        @binder = new Binder(@view, @onChange)
        @entityId = null

        @formatter2d = (value) ->
            #inplace import because of problem with closure and live replacement of the binding
            {format} = require 'src/js/i18n/conversion'
            return format(value, 'f2')

        @formatter4d = (value) ->
            #inplace import because of problem with closure and live replacement of the binding
            {format} = require 'src/js/i18n/conversion'
            return format(value, 'f4')

        @setupControls()
        @setupAddLandParcel()

        @prompts = {
            priceDistribution: 'Es sind bereits Preise eingetragen. Diese werden beim Aufteilen überschrieben. Fortfahren?'
        }

    setupControls: ->
        partnerB = @view.find('#partnerB-input')

        @setupPartnerSelectionControl @view.find('#partnerB-container'), partnerB

        @partnerBTemplate = partnerB.attr('id', null).clone()

        @addpartnerBrButton =
          @view.find '#add-partnerB'
          .on 'click', @addpartnerB

        @bindData()

        @applyChangesBtn = @view.find '#applyChangesBtn'
                                .prop 'disabled', true
                                .on 'click', @save
        @resetChangesBtn = @view.find '#resetChangesBtn'
                                .prop 'disabled', true
                                .on 'click', @reset

        @landParcelGrid = @view.find('#landparcel-grid').staticgrid()[0]
        @distributePriceButton = @landParcelGrid.editControls
            .add
            .$button
            .parent()
            .append('<a class="btn btn-default buttons-create">Preis aufteilen</a>')
            .children()
            .last()
            .on 'click', @distributePriceToParcels

        @sumLandParcelBox = @view.find '#sum-land-parcel'

        @confirmationDialog =
            @view.find '#confirmationDialog'
            .modal
                show: false

        #confirmation dialog buttons
        @confirmButton =
            @view.find '#confirmBtn'
            .on 'click', @save
        @dismissButton =
            @view.find '#dismissBtn'
            .on 'click', => @callback() if @callback

        @disableTabs()

    addpartnerB: => @setupPartnerSelection '#partnerB-container', @partnerBTemplate, 'commonData.partnerB'

    setupPartnerSelection: (containerId, template, bindingPath) ->
      newId = @makeRandomIdentifier()

      container = @view.find containerId

      newInput = @makePartnerControl container, template, newId

      @setupPartnerSelectionControl container, newInput

      @binder.bindAjaxSelectize('#' + newId, "#{bindingPath}.#{newId}", @loadOtherContacts, @personoptions)

    makePartnerControl: (container, template, id) ->
      newInput = $(template.clone()).appendTo container
      newInput.find 'select'
      .attr 'id', id
      return newInput

    setupPartnerSelectionControl: (container, control) ->
      updateButtonHandler = @makeUpdateDeleteButtonHandler container
      updateButtonHandler()

      control.find '.delete-this'
      .on 'click', @makeDeleteHandler control, updateButtonHandler

    makeRandomIdentifier: () -> (Math.floor(Math.random() * Math.floor(Number.MAX_SAFE_INTEGER))).toString()

    makeUpdateDeleteButtonHandler: (container) ->
      () ->
        buttons = container.find '.delete-this'
        if container.children().length is 1
          buttons.disable()
        else
          buttons.enable()

    makeDeleteHandler: (control, updateDeleteButtons) =>
      () =>
        id = $(control).find('select').attr('id')
        control.off 'click'
        control.remove()
        updateDeleteButtons()
        @binder.remove '#' + id
        @onChange()

    createNewContract: (parentEntityId, contractTypeId, metaData) =>
        @ensureChangesAreConfirmedBefore( =>
            @data = {
                commonData:
                    uid: '00000000-0000-0000-0000-000000000000'
                    associatedEntities: [parentEntityId]
                    contractType: contractTypeId
                    partnerA:null
                    partnerB:[]
                landParcelData: []
                facilityData: []
                financeData:
                    rates: []
                    invoiceNumbers: []
                metaData: metaData
                permissions: {
                  PurchaseContractEditLandParcel: true
                  PurchaseContractEditFacilities: true
                  PurchaseContractEditFinances: true
                }
                }
            @updateContent parentEntityId, @data)
        @binder.bindings['#contract-number'].control.focus()

    bindData: ->
        parser = (value) ->
            #inplace import because of problem with closure and live replacement of the binding
            {parse} = require 'src/js/i18n/conversion'
            return parse(value)

        @binder.bindHiddenValue('##AssociatedEntities', 'commonData.associatedEntities')
        @binder.bindHiddenValue('##ContractUid', 'commonData.uid')
        @binder.bindHiddenValue('##ContractType', 'commonData.contractType')
        @binder.bindHiddenValue('##MetaData', 'metaData')

        @binder.bindInput('#contract-number', 'commonData.documentNumber')
        @binder.bindInput('#contract-price', 'financeData.price', false, @formatter2d, parser)
        @binder.bindInput('#dms', 'commonData.dmsEntry')
        @binder.bindInput('#contract-area', 'commonData.contractArea', false, @formatter4d, parser)
        @binder.bindInput('#remark', 'commonData.remark')

        @binder.bindCombobox('#price-currency', 'financeData.currency')

        @binder.bindDateControl('#contract-date', 'commonData.documentDate')
        @binder.bindDateControl('#contract-begin', 'commonData.contractStartDate')

        @binder.bindGrid('#landparcel-grid', 'landParcelData')
        @binder.bindGrid('#facility-grid', 'facilityData')
        financesGrid = @binder.bindGrid('#finances-grid', 'financeData.rates')
        financesGrid.editor
        .on 'open', =>
            ctrl = $('#DTE_Field_pricePaid')
            return if ctrl is undefined or ctrl[0] is undefined
            ctrl.on 'blur', => ctrl[0].value = @formatter2d(parser(ctrl[0].value))
            return true

        @binder.bindGrid('#invoice-grid', 'financeData.invoiceNumbers')

        @personoptions = {
            create: @createCreate()
            openOnFocus: false
            preload: false
            searchField: [
              'name'
              'nameAdditional'
            ]
            render:
                option: (data, escape) ->
                    "<div class='option'>
                        <span> #{escape(data.name)}</span>
                        <span> #{if data.nameAdditional then escape(data.nameAdditional) else ""}</span>
                        <small class='text-muted'> #{if data.city then escape(data.city) else ""}</small>
                    </div>"
                item: (data, escape) ->
                    "<div class='item'>
                        <span> #{escape(data.name)}</span>
                        <span> #{if data.nameAdditional then escape(data.nameAdditional) else ""}</span>
                        <small class='text-muted'> #{if data.city then escape(data.city) else ""}</small>
                    </div>"
                option_create: (data) ->
                    "<div class='option create'>
                        <span>F&uuml;ge </span>
                        <strong> #{data.input}</strong>
                        <span> hinzu</span>
                    </div>"
        }

        notarynoptions = {
            create: @createCreate('Notary')
            openOnFocus: false
            preload: false
            searchField: [
              'name'
              'nameAdditional'
            ]
            render:
                option: (data, escape) ->
                    "<div class='option'>
                        <span> #{escape(data.name)}</span>
                        <span> #{if data.nameAdditional then escape(data.nameAdditional) else ""}</span>
                        <small class='text-muted'> #{if data.city then escape(data.city) else ""}</small>
                    </div>"
                item: (data, escape) ->
                    "<div class='item'>
                        <span> #{escape(data.name)}</span>
                        <span> #{if data.nameAdditional then escape(data.nameAdditional) else ""}</span>
                        <small class='text-muted'> #{if data.city then escape(data.city) else ""}</small>
                    </div>"
                option_create: (data) ->
                    "<div class='option create'>
                        <span>F&uuml;ge </span>
                        <strong> #{data.input}</strong>
                        <span> hinzu</span>
                    </div>"
        }

        businessObjectOptions = {
            create: @createBusinessObject()
            openOnFocus: false
            preload: false
            valueField: 'uid'
            searchField: 'name'
            sortField: 'name'
            render:
                option: (data, escape) ->
                    "<div class='option'>
                        <span> #{escape(data.name)}</span>
                    </div>"
                item: (data, escape) ->
                    "<div class='item'>
                        <span> #{escape(data.name)}</span>
                    </div>"
        }

        @binder.bindAjaxSelectize('#notary', 'commonData.notary', @loadNotaries, notarynoptions)
        @binder.bindAjaxSelectize('#partnerA', 'commonData.partnerA', @loadOtherContacts, @personoptions)
        @binder.bindAjaxSelectize('#partnerB', 'commonData.partnerB.0', @loadOtherContacts, @personoptions)
        @binder.bindAjaxSelectize('#object', 'commonData.businessObject', @loadBusinessObjects, businessObjectOptions)

        @binder.disableControls()

    setupAddLandParcel: ->
        @landParcelGrid.editControls.add.$button.off()
        @landParcelGrid.editControls.add.$button.on 'click', (e) =>
            e.preventDefault()
            @addLandParcelModal()

    processAddedLandParcel: (newRow) =>
        newRow.DT_RowId = newRow.uid
        newRow.link = "/RealEstate/Company/#{@tenantId}/Landparcel/#{newRow.landParcelId}"
        @landParcelGrid.table.row.add(newRow).draw()
        @onChange()

    addLandParcelModal: ->
        getModal '/RealEstate/Contract/SelectLandParcel'
        .then ($modal) =>
            @landParcelModal = new LandParcelSelectorController $modal, @data.commonData.associatedEntities, @entityId
            @landParcelModal.onSubmit (result) =>
                @processAddedLandParcel(result.resultingRow)
                if result.reOpen
                    @addLandParcelModal()

    distributePriceToParcels: =>
        parcels = @binder.readControl('#landparcel-grid')
        facilities = @binder.readControl('#facility-grid')
        action = => (
            totalSize = (lp.sizeByContract for lp in parcels).reduce(((pre, cur) -> pre + (cur ? 0.0)), 0)
            totalPrice = @binder.readControl('#contract-price')
            facilityPriceTotal = (facility.price for facility in facilities).reduce(((pre, cur) -> pre + (cur ? 0.0)), 0)
            currency = @binder.readControl('#price-currency')

            for lp in parcels
                size = lp.sizeByContract ? 0.0
                lp.price = size * (totalPrice - facilityPriceTotal) / totalSize
                lp.currency = currency

            @landParcelGrid.table.rows().invalidate()
            @onChange()
        )

        if parcels.some((parcel) -> parcel.price isnt 0.0)
            @askForUserConfirmation('priceDistribution', action)
        else
            action()

    loadNotaries: (query, callback) =>
        tenantId = @tenantId
        get "/RealEstate/Contacts/PersonList?orgUnitId=#{tenantId}&query=#{query}&type=Notary"
        .done (result) -> callback result.personsWithAddress
    loadOtherContacts: (query, callback) =>
        tenantId = @tenantId
        get "/RealEstate/Contacts/PersonList?orgUnitId=#{tenantId}&query=#{query}&type=Other"
        .done (result) -> callback result.personsWithAddress

    loadBusinessObjects: (query, callback) =>
        tenantId = @tenantId
        get "/RealEstate/MasterDataBusinessObject/GetBusinessObjectsSelectize", {entityId: tenantId, query: query}
        .done (result) ->
            businessObjects = []

            for item of result
                businessObjects.push {uid: result[item].value, name: result[item].text }  #unfortunately necessary for mapping to BussinessObjectDbModel

            callback businessObjects


    createBusinessObject: =>
        controller = @

        return (input, callback) ->
            $('#modals').load '../MasterDataBusinessObject/CreateView', =>
                createBusinessObjectView = $ '#create-business-object-view'
                createBusinessObjectView.modal()
                createBusinessObjectView.one 'shown.bs.modal', ->
                    createBusinessObjectView.find('input').first().focus()

                ctrl = new CreateBusinessObjectController
                    view: createBusinessObjectView
                    name: input
                    tenantId: controller.tenantId
                    onCreated: (businessObject) =>
                        createBusinessObjectView.modal('hide')
                        @focus()
                        callback(businessObject)
                    onCancel: ->
                        createBusinessObjectView.modal('hide')
                        callback(false)
            return undefined


    createCreate: (type = 'Other') =>
        controller = @

        return (input, callback) ->
            $('#modals').load '/RealEstate/Person/Create', =>
                createNotaryView = $ '#create-notary-view'
                createNotaryView.modal()
                createNotaryView.one 'shown.bs.modal', ->
                    createNotaryView.find('input').first().focus()

                ctrl = new CreatePersonController #assignment necessary because of garbage collector
                    view: createNotaryView
                    name: input
                    tenantId: controller.tenantId
                    type: type
                    onCreated: (notary) =>
                        createNotaryView.modal('hide')
                        @focus()
                        callback(notary)
                    onCancel: ->
                        createNotaryView.modal('hide')
                        callback(false)
            return undefined

    onChange: =>
        activeTab = $('.tab-content>.active').prop('id')
        @stati.setTabStatus activeTab, 'changed'
        @applyChangesBtn.prop 'disabled', false
        @resetChangesBtn.prop 'disabled', false
        parcels = @binder.readControl('#landparcel-grid')
        totalSize = (lp.sizeByContract for lp in parcels).reduce(((pre, cur) -> pre + (cur ? 0.0)), 0)
        @sumLandParcelBox.val @formatter4d(totalSize)

    display: (treeItemUid, contractDetails) ->
        @ensureChangesAreConfirmedBefore( => @updateContent(treeItemUid, contractDetails))

    updateEntityId: (entityId) ->
        @entityId = entityId

    updateContent: (treeItemUid, contractData) ->
        @treeItemUid = treeItemUid
        get '/RealEstate/Tenants/GetCurrentTenant', {orgUnitId: treeItemUid}
        .done (result) =>
            @tenantId = result
        .fail =>
            @tenantId = null
        @data = contractData
        @id = @data.commonData.uid
        @callback = undefined
        @stati.changeStatus to: 'none'

        if @data.commonData.partnerB.length is 0
          @data.commonData.partnerB.push(undefined)

        for partner, idx in @data.commonData.partnerB
          continue if idx is 0
          container = @view.find '#partnerB-container'
          control = @makePartnerControl container, @partnerBTemplate, 'partnerB-' + idx
          @setupPartnerSelectionControl container, control, 'partnerB-' + idx
          @binder.bindAjaxSelectize('#partnerB-' + idx, "commonData.partnerB.#{idx}", @loadOtherContacts, @personoptions)

        @binder.updateControls(@data)
        @binder.enableControls()

        parcels = @binder.readControl('#landparcel-grid')
        totalSize = (lp.sizeByContract for lp in parcels).reduce(((pre, cur) -> pre + (cur ? 0.0)), 0)
        @sumLandParcelBox.val @formatter4d(totalSize)

        @applyChangesBtn.prop 'disabled', true
        @resetChangesBtn.prop 'disabled', true

        @enableTab 'common'
        @enableTab 'landparcels' if @data.permissions.PurchaseContractViewLandParcel or @data.permissions.PurchaseContractEditLandParcel
        @enableTab 'facilities' if @data.permissions.PurchaseContractViewFacilities or @data.permissions.PurchaseContractEditFacilities
        @enableTab 'finances' if @data.permissions.PurchaseContractViewFinances or @data.permissions.PurchaseContractEditFinances

    enableTab: (tabName) =>
        @view.find ".nav > li a[href=\"##{tabName}\"]"
        .enable()
        .parents()
        .enable()

    disableTabs: =>
        @view.find '.nav > li a'
        .on 'click', (event) -> event.preventDefault()
        .disable()
        .parents()
        .disable()

    clear: ->
        @ensureChangesAreConfirmedBefore(@clearContent)

    clearContent: =>
        @id = undefined
        @data = {}
        @callback = undefined
        @stati.changeStatus to: 'none'

        partnerBToRemove = @view.find('#partnerB-container').children(':not(:first)')

        firstid = @view.find('#partnerB-container').children(':first').find('select').attr('id')
        if firstid isnt 'partnerB'
          @binder.remove('#' + firstid)
        @view.find('#partnerB-container').children(':first').find('select').attr('id', 'partnerB')
        @binder.remove('#partnerB')
        @binder.bindAjaxSelectize('#partnerB', 'commonData.partnerB.0', @loadOtherContacts, @personoptions)

        # unbind
        partnerBToRemove.find('select').map((idx, ele) => ele.id).each((idx, id) => @binder.remove '#' + id)

        # remove from dom
        partnerBToRemove.remove()

        @binder.disableControls()
        @binder.clearControls()

        @applyChangesBtn.prop 'disabled', true
        @resetChangesBtn.prop 'disabled', true

        @disableTabs()

    askForUserConfirmation: (key, confirmAction, dismissAction = noop) =>
        (new ConfirmationDialog(@prompts[key]))
            .onConfirm(confirmAction)
            .onDismiss(dismissAction)
            .show()

    ensureChangesAreConfirmedBefore: (action) ->
        if @stati.hasAnyChanges()
            @callback = action
            @confirmationDialog.modal 'show'
        else
            action()

    save: =>
        @stati.changeStatus from: 'changed', to: 'working'
        # need validation here

        data = @binder.readControls()
        data.commonData.fkDataOwner = @entityId

        data.commonData.partnerB = for idx, val of data.commonData.partnerB when val
          val

        for rate in data.financeData.rates when rate.datePaid
            rate.datePaid = moment(rate.datePaid).utc().add(rate.datePaid.utcOffset(), 'm')

        postJson '/RealEstate/Contract/SaveContract', data
        .done (result) =>
            #set id to the return value of the save action so the reload
            #will load the correct contract even if it was a new one and
            #had an id assigned by the save process
            @data.commonData.uid = @id = result
            @binder.bindings['##ContractUid'].setData(result)
            @stati.changeStatus from: 'working', to: 'success'
            @applyChangesBtn.prop 'disabled', true
            @resetChangesBtn.prop 'disabled', true
            @onChangeCallback(result)
            if @callback
                @callback()
        .fail (result) => @stati.changeStatus from: 'working', to: 'error'

    reset: =>
        @binder.clearControls()
        @updateContent(@treeItemUid, @data)


module.exports = {PurchaseDetailController}

module.exports.__esModule = true
module.exports.default = PurchaseDetailController
