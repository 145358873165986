<template>
  <div class="form row">
    <div class="col-sm-12">
      <VueDateTimePicker v-model="validOnWrapper" :label="SR_ModelProperties.Date" iso />
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import VueDateTimePicker from 'src/vue/components/forms/VueDateTimePicker'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    VueDateTimePicker
  },
  mixins: [makeResourceMixin(resources)],
  props: ['value', 'name'],
  computed: {
    ...mapGetters('realEstateContractLease',
      [
        'currencies'
      ]),
    validOnWrapper: {
      get () {
        return this.value ? this.value.validOn : null
      },
      set (value) {
        this.$set(this.value, 'validOn', value)
      }
    },
    selectedAmendment: {
      get () {
        return [this.currentAmendment]
      }
    }
  },
  methods: {
    save () {
      this.$emit('input', this.value)
    }
  }
}
</script>
<style>
</style>
