{noop} = require 'src/coffee/areas/real-estate/utils/noop'
{LandParcelDetailController} = require './land-parcel-detail-controller'
{Confirmation} = require 'src/coffee/areas/real-estate/utils/confirmation'
treeUtility = require 'src/coffee/controls/tree/helpers'

class LandParcelController
    constructor: (@view, @mediator) ->
        @grid = @view.find '#landparcel-grid'
        .staticgrid()[0]
        @setupPayload()
        @setupLandParcelTable()
        @setupTree()
        @mediator.handle
            event: 'LandParcelChanged'
            with: @onDetailsChanged
            event: 'LandParcelCreated'
            with: @onLandParcelCreated
        @details = new LandParcelDetailController
            mediator: @mediator
        @details.bindTo @view
        @confirmation = new Confirmation @view, '#confirmationDialog'

        @executePreselection()

    executePreselection: ->
        div = $('#preselection')
        if div.length == 0
            return

        entityId = div.data('entity')
        itemId = div.data('item')

        @grid.table.one('draw.dt', => @grid.selectRow(itemId) )
        treeUtility.selectNode(entityId)

    setupPayload: ->
        @grid.options.ajax.payload = => {Id: @uid}

    setupLandParcelTable: ->
        @grid.table.on 'select', @onSelected
        .on 'deselect', @onDeselected
        .on 'draw.dt', @onDraw
        .on 'user-select.dt', @onUserSelect

    onUserSelect: (e, dt, type, cell, originalEvent) =>
        return unless type is 'row'
        if @details.hasChanges()
            e.preventDefault()
            @confirmation.show
                onAccept: =>
                    @details.saveChanges()
                    cell.row(cell.index().row).select()
                onDismiss: =>
                    @details.dismissChanges()
                    cell.row(cell.index().row).select()
                onCancel: noop

    onSelected: (e, dt, type, indexes) =>
        return unless type is 'rows' or type is 'row'
        dataItems = dt.rows(indexes).data().pluck('Uid')
        if dataItems.length > 0 and dataItems[0] isnt @details.landParcel?.uid
            @details.update dataItems[0], @uid

    onDeselected: (e, dt, type, indexes) =>
        return unless type is 'rows' or type is 'row'
        @details.clear()

    onDraw: =>
        @grid.table.rows( (idx, data, node) ->
          data.Active is 'Nein' or data.Active is 'No')
        .nodes()
        .to$()
        .find('td')
        .removeClass()
        .addClass('bg-warning')

        row = @grid.table.row('#' + @details.landParcel?.uid)
        if row is undefined or row.length is 0
            @details.clear()
        else
            row.select()

    setupTree: ->
        @tree = $ '.easytree'

        @tree.closest('#tree-container')
        .on 'click', '.easytree-node', @onNodeSelected

    onNodeSelected: (event) =>
        if @details.hasChanges()
            event.preventDefault()
            event.stopPropagation()
            @confirmation.show
                onAccept: =>
                    @details.saveChanges()
                    event.currentTarget.click()
                onDismiss: =>
                    @details.dismissChanges()
                    event.currentTarget.click()
                onCancel: =>
                    rootNodes = @tree.tree().getAllNodes()
                    allNodes = []
                    treeUtility.applyRecursive rootNodes, (n) -> allNodes.push(n)
                    nodes = allNodes.filter (n) => n.data.uid is @uid
                    return if nodes.length is 0
                    node = nodes[0]
                    @tree.tree().activateNode(node.id)
        else
            @uid = @tree.tree().getNode(event.currentTarget.id).data.uid
            @details.clear()
            @grid.table.ajax.reload()
            @grid.editControls.add.$button.enable().off().on 'click', (e) =>
                @details.create @uid
                @grid.table.rows().deselect()
                e.preventDefault()

    onDetailsChanged: =>
        @grid.table.ajax.reload(null, false)

    onLandParcelCreated: (params) =>
        @grid.selectRow(params.landParcelId)

module.exports = {
    LandParcelController
}
module.exports.__esModule = true
module.exports.default = LandParcelController
