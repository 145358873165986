<template lang="html">
  <div>
    <label class="control-label">Nachträge</label>
    <div class="list-group">
      <amendment-item
        v-for="amendment in sortedAmendments" :key="amendment.uid"
        :amendment="amendment"
        :selected="amendment === selectedAmendment" @click="onAmendmentClick(amendment)"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import moment from 'moment'

import AmendmentItem from './AmendmentItem.vue'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    AmendmentItem
  },
  mixins: [makeResourceMixin(resources)],
  props: {
    value: null
  },
  computed: {
    ...mapGetters('realEstateContractLease/amendments',
      [
        'isEditAmendmentModalOpen',
        'currentAmendment',
        'hasCurrentAmendment']),
    sortedAmendments: {
      get () {
        let clonedArray = [...this.value.amendments]
        clonedArray.sort((a, b) => {
          const aMoment = moment(a.validOn)
          const bMoment = moment(b.validOn)
          if (aMoment.isBefore(bMoment)) return 1
          if (aMoment.isAfter(bMoment)) return -1
          return 0
        })

        return clonedArray
      }
    },
    selectedAmendment: {
      get () {
        return this.currentAmendment
      }
    }
  },
  methods: {
    ...mapActions('realEstateContractLease/amendments', [
      'selectAmendment'
    ]),
    onAmendmentClick (amendment) {
      if (amendment.uid !== this.selectedAmendment.uid) {
        this.selectAmendment(amendment)
      }
    }
  }
}
</script>

<style lang="css" scoped>
.panel {
  margin-bottom: 5px;
}
</style>
