class ValidationGridIndicator
    constructor: (grid) ->
        @grid = grid
        @states = {}

    changeValidationState: (rowId, state) =>
        if not @states[rowId]
            @states[rowId] = {}

        if state is 'added'
            @states[rowId].rowAdded = true
        else if state is 'modified'
            if @states[rowId].rowAdded
                @states[rowId].state = 'added'
            else
                @states[rowId].state = 'modified'
        else
            @states[rowId].state = state


    hasAnyError: =>
        for rowId, val of @states
            return true if val.state is 'has-error'
        return false

    drawStateIndicator: (rowId, state) =>
        tr = @grid.table.row("##{rowId}").node()
        tr.classList.add state

    removeStateIndicator: (rowId) =>
        tr = @grid.table.row("##{rowId}").node()
        tr.classList.remove 'has-error'
        tr.classList.remove 'modified'
        tr.classList.remove 'added'

    drawStateIndicators: =>
        for rowId, val of @states
            @removeStateIndicator rowId
            @drawStateIndicator rowId, val.state


module.exports = {ValidationGridIndicator}

module.exports.__esModule = true
module.exports.default = ValidationGridIndicator
