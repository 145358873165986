<template lang="html">
  <div class="panel panel-default">
    <div class="panel-body">
      <div class="row">
        <div class="col-md-12">
          <h3>
            <IxRes :resource="SR_RealEstate">
              PaymentRecipients
            </IxRes>
          </h3>
          <grid
            v-if="paymentRecipientColumnsTranslationsAreLoaded" v-model="selectedPaymentRecipients"
            :data="paymentRecipients" :columns="paymentRecipientColumns"
            selection="single"
            minimal :add-button="!readOnly && currentPermissions.leaseContractCanEdit"
            :edit-button="hasCurrentPaymentRecipient && currentPermissions.leaseContractCanEdit" :delete-button="hasCurrentPaymentRecipient && currentPermissions.leaseContractCanEdit"
            @add="createRequest" @delete="deleteRequest"
            @edit="editRequest"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-offset-4 col-md-4">
          <button class="btn btn-default btn-block" :disabled="!(hasCurrentPaymentRecipient && currentPermissions.leaseContractCanEdit)" @click="calculatePayments">
            <IxRes :resource="SR_RealEstate">
              GeneratePayments
            </IxRes>
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <h3>
            <IxRes :resource="SR_RealEstate">
              Payments
            </IxRes>
          </h3>
          <grid
            v-if="paymentColumnsTranslationsAreLoaded" :data="payments"
            :columns="paymentColumns" selection="os"
            :value="selectedPayments" :add-button="!readOnly && currentPermissions.leaseContractCanEdit"
            :edit-button="selectedPayments.length === 1 && currentPermissions.leaseContractCanEdit" :delete-button="hasCurrentPayment && currentPermissions.leaseContractCanEdit"
            @input="selectPayments"
            @add="createPaymentRequest" @delete="deletePaymentRequest"
            @edit="editPaymentRequest"
          />
        </div>
      </div>
    </div>

    <bs-modal :title="SR_RealEstate.CreatePaymentRecipientTitle" :value="isEditModalOpen" blocking>
      <payment-recipient-form v-model="editedPaymentRecipient" @save="createOrEditPaymentRecipient" @cancel="createOrEditFailure" />
    </bs-modal>

    <bs-modal :title="SR_RealEstate.DeletePaymentRecipientTitle" :value="isDeleteModalOpen" blocking>
      <p>
        <IxRes :resource="SR_RealEstate">
          DeletePaymentRecipientQuestion
        </IxRes>
      </p>
      <button
        slot="footer" type="button"
        class="btn btn-success"
        @click="deleteFailure"
      >
        <IxRes :resource="SR_Common">
          No
        </IxRes>
      </button>
      <button
        slot="footer" type="button"
        class="btn btn-default"
        @click="deleteSuccess"
      >
        <IxRes :resource="SR_Common">
          Yes
        </IxRes>
      </button>
    </bs-modal>

    <bs-modal
      :title="SR_RealEstate.CreatePaymentTitle" :value="isEditPaymentModalOpen"
      blocking
      @hidden="createOrEditPaymentFailure"
    >
      <payment-form v-model="editPayment" />
      <button
        slot="footer" type="button"
        class="btn btn-success"
        :disabled="!requiredPaymentInputsFilled" @click="createOrEditPaymentSuccess"
      >
        <IxRes :resource="SR_Common">
          Save
        </IxRes>
      </button>
      <button
        slot="footer" type="button"
        class="btn btn-default"
        @click="createOrEditPaymentFailure"
      >
        <IxRes :resource="SR_Common">
          Cancel
        </IxRes>
      </button>
    </bs-modal>

    <bs-modal :title="SR_RealEstate.DeletePaymentTitle" :value="isDeletePaymentModalOpen" blocking>
      <p>
        <IxRes :resource="SR_RealEstate">
          DeletePaymentQuestion
        </IxRes>
      </p>
      <button
        slot="footer" type="button"
        class="btn btn-success"
        @click="deletePaymentFailure"
      >
        <IxRes :resource="SR_Common">
          No
        </IxRes>
      </button>
      <button
        slot="footer" type="button"
        class="btn btn-default"
        @click="deletePaymentSuccess"
      >
        <IxRes :resource="SR_Common">
          Yes
        </IxRes>
      </button>
    </bs-modal>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'

import Grid from 'src/vue/areas/real-estate/components/grid/RELegacyGrid.vue'
import BsModal from 'src/vue/components/bootstrap/BsModal'
import PaymentRecipientForm from './payment-recipient-form'
import PaymentForm from './payment-form'
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'
import {get} from 'src/coffee/infrastructure/ajax'
import moment from 'moment'
import newGuid from 'src/js/helpers/new-guid'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    Grid,
    BsModal,
    PaymentRecipientForm,
    PaymentForm
  },
  mixins: [makeResourceMixin(resources)],
  props: {
    value: null,
    readOnly: {
      type: Boolean,
      default: false
    },
    name: {
      Type: String,
      default: ''
    }
  },
  data () {
    return {
      paymentRecipientSaveButtonDisabled: true
    }
  },
  computed: {
    ...mapGetters('realEstateContractLease/contracts', [
      'currentContract',
      'newContract',
      'isCreateLeaseContract'
    ]),
    ...mapGetters('realEstateContractLease/finances', [
      'currentPaymentRecipient',
      'selectedPayments',
      'hasCurrentPaymentRecipient',
      'hasCurrentPayment',
      'editPaymentRecipient',
      'isEditModalOpen',
      'isDeleteModalOpen',
      'editPayment',
      'isEditPaymentModalOpen',
      'isDeletePaymentModalOpen'
    ]),
    ...mapGetters('realEstateContractLease/contracts', [
      'currentPermissions',
      'termFrom',
      'termTo'
    ]),
    ...mapGetters('realEstateContractLease/navigation', [
      'selectedOrgUnit'
    ]),

    paymentRecipients: {
      get () {
        return this.value.paymentRecipients ? this.value.paymentRecipients : []
      }
    },
    payments: {
      get () {
        return this.value.payments ? this.value.payments : []
      }
    },
    selectedPaymentRecipients: {
      get () {
        return [this.currentPaymentRecipient]
      },
      set (value) {
        const paymentRecipient = value && value.length > 0 ? value[0] : null
        this.selectPaymentRecipient(paymentRecipient)
      }
    },

    editedPaymentRecipient: {
      get () {
        return [this.editPaymentRecipient][0]
      },
      set (value) {
        this.updatePaymentRecipient(value)
      }
    },
    paymentModeName: {
      get () {
        return this.value.paymentMode ? this.value.paymentMode.text : 'No selection'
      }
    },
    paymentRecipientColumns () {
      return [
        {localizedTitle: this.getLocalizedTitleByResourceName('PaymentMode'), typeName: 'String', propertyName: 'paymentMode.text'},
        {localizedTitle: this.getLocalizedTitleByResourceName('Recipient'), typeName: 'String', propertyName: 'recipient.text'},
        {localizedTitle: this.getLocalizedTitleByResourceName('IBAN'), typeName: 'String', propertyName: 'iban'},
        {localizedTitle: this.getLocalizedTitleByResourceName('BIC'), typeName: 'String', propertyName: 'bic'},
        {localizedTitle: this.getLocalizedTitleByResourceName('Share'), typeName: 'Number', propertyName: 'share'},
        {localizedTitle: this.getLocalizedTitleByResourceName('FlatSum'), typeName: 'Float', propertyName: 'flatSum', minDecimalDigits: 2, maxDecimalDigits: 2},
        {localizedTitle: this.getLocalizedTitleByResourceName('Currency'), typeName: 'Number', propertyName: 'currency.symbol'},
        {localizedTitle: this.getLocalizedTitleByResourceName('TypeOfPayment'), typeName: 'String', propertyName: 'typeOfPayment'}
      ]
    },
    paymentRecipientColumnsTranslationsAreLoaded () {
      return this.paymentRecipientColumns.every(c => !!c.localizedTitle)
    },
    paymentColumns () {
      return [
        {localizedTitle: this.getLocalizedTitleByResourceName('Recipient'), typeName: 'String', propertyName: 'recipient.text'},
        {localizedTitle: this.getLocalizedTitleByResourceName('TransactionComment'), typeName: 'String', propertyName: 'transactionComment'},
        {localizedTitle: this.getLocalizedTitleByResourceName('DateDue'), typeName: 'DateTime', dateTimeFormat: 'L', propertyName: 'dateDue'},
        {localizedTitle: this.getLocalizedTitleByResourceName('DatePaid'), typeName: 'DateTime', dateTimeFormat: 'L', propertyName: 'datePaid'},
        {localizedTitle: this.getLocalizedTitleByResourceName('IBAN'), typeName: 'String', propertyName: 'lessorIBAN'},
        {localizedTitle: this.getLocalizedTitleByResourceName('BIC'), typeName: 'String', propertyName: 'lessorBIC'},
        {localizedTitle: this.getLocalizedTitleByResourceName('Amount'), typeName: 'Float', propertyName: 'amount', minDecimalDigits: 2, maxDecimalDigits: 2},
        {localizedTitle: this.getLocalizedTitleByResourceName('Currency'), typeName: 'Number', propertyName: 'currency.symbol'},
        {localizedTitle: this.getLocalizedTitleByResourceName('TypeOfPayment'), typeName: 'String', propertyName: 'typeOfPayment'},
        {localizedTitle: this.getLocalizedTitleByResourceName('Vetoed'), typeName: 'Boolean', propertyName: 'vetoed'}
      ]
    },
    paymentColumnsTranslationsAreLoaded () {
      return this.paymentColumns.every(c => !!c.localizedTitle)
    },
    requiredPaymentInputsFilled () {
      return this.editPayment.recipient && this.editPayment.dateDue && this.editPayment.amount && this.editPayment.currency &&
        this.editPayment.typeOfPayment
    }
  },
  methods: {
    ...mapActions('realEstateContractLease/finances', [
      'addPayments',
      'selectPaymentRecipient',
      'selectPayments',
      'createRequest',
      'editRequest',
      'createOrEditPaymentRecipientSuccess',
      'createOrEditFailure',
      'deleteRequest',
      'deleteSuccess',
      'deleteFailure',
      'createPaymentRequest',
      'editPaymentRequest',
      'createOrEditPaymentSuccess',
      'createOrEditPaymentFailure',
      'deletePaymentRequest',
      'deletePaymentSuccess',
      'deletePaymentFailure',
      'updatePaymentRecipient']),

    createOrEditPaymentRecipient () {
      this.createOrEditPaymentRecipientSuccess(this.selectedOrgUnit.id)
    },

    calculatePayments () {
      const recipients = this.value.paymentRecipients

      const totalShares = recipients.map(value => value.share).reduce((acc, value) => acc + Number.parseFloat(value))

      this.selectedPaymentRecipients.forEach((recipient) => {
        get('/RealEstate/PaymentMode/GetFullPaymentMode', {uid: recipient.paymentMode.value})
          .done((result) => {
            const payDates = []
            const startDate = moment(this.termFrom)
            const endDate = moment(this.termTo)

            for (let currentDate = startDate.clone(); !currentDate.isAfter(endDate); currentDate.add(1, 'month')) {
              const monthToPay = result.dates.find(date => date.month === (currentDate.month() + 1))
              if (monthToPay === undefined) {
                continue
              }
              const payDate = moment(currentDate).date(monthToPay.day)
              if (payDate.isBefore(startDate) || payDate.isAfter(endDate)) {
                continue
              }
              payDates.push(payDate)
            }

            const payments = []

            const contract = this.isCreateLeaseContract ? this.newContract : this.currentContract

            for (let term of contract.terms) {
              const yearsInTerm = Math.ceil(moment(term.end).diff(moment(term.start), 'months') / 12)
              const now = moment()
              const payDatesInTerm = payDates.filter(payDate => payDate.isSameOrAfter(term.start) && payDate.isSameOrBefore(term.end))
              const countPayDatesInTerm = payDatesInTerm.length

              const toPayByRecipient = term.leasePrice * yearsInTerm / totalShares * Number.parseFloat(recipient.share)
              const partPrice = toPayByRecipient / countPayDatesInTerm

              for (let idx = 0; idx < countPayDatesInTerm; idx++) {
                if (payDatesInTerm[idx].isBefore(now)) {
                  continue
                }

                const payment = {
                  uid: newGuid(),
                  recipient: recipient.recipient,
                  transactionComment: recipient.transactionComment,
                  amount: recipient.flatSum !== 0 ? recipient.flatSum : partPrice,
                  currency: {
                    uid: recipient.currency.uid,
                    symbol: recipient.currency.symbol
                  },
                  typeOfPayment: recipient.typeOfPayment,
                  lessorIBAN: recipient.iban,
                  lessorBIC: recipient.bic,
                  leaserIBAN: '',
                  leaserBIC: '',
                  dateDue: payDatesInTerm[idx].format(),
                  datePaid: null,
                  vetoed: false
                }
                payments.push(payment)
              }
            }

            this.addPayments(payments)
          })
      })
    },
    getLocalizedTitleByResourceName (resourceName) {
      return this.SR_ModelProperties[resourceName] ? this.SR_ModelProperties[resourceName] : `<<${resourceName}>>`
    }
  }
}
</script>

<style lang="css">
</style>
