<template>
  <div class="row">
    <div class="col-sm-6">
      <vue-selectize-ajax
        ref="focus" ajax-url="/RealEstate/MasterDataUsageType/FindUsageTypesForLandparcelModal"
        :allow-empty="true" :value="value.usage"
        required @input="handleChange($event, 'usage')"
      />
    </div>

    <div class="col-sm-3">
      <numeric-input
        integer :value="value.groundpoints"
        required :rule="validateGroundpoints"
        @input="handleChange($event, 'groundpoints')"
      />
    </div>

    <div class="col-sm-3">
      <numeric-input
        format="f4" addon="ha"
        :value="value.area"
        required @input="handleChange($event, 'area')"
      />
    </div>
  </div>
</template>

<script>

import NumericInput from 'src/vue/components/forms/NumericInput'
import vueSelectizeAjax from 'src/vue/areas/real-estate/components/forms/SelectizeAjaxWrapped'

export default {
  components: {
    NumericInput,
    vueSelectizeAjax
  },
  props: {
    value: {type: Object, required: true},
    usages: Array
  },
  methods: {
    handleChange (value, property) {
      const obj = {...this.value}
      obj[property] = value
      this.$emit('input', obj)
    },

    focus () {
      this.$refs.focus.focus()
    },

    validateGroundpoints (points) {
      return points >= 0 && points <= 200 ? true : 'Bodenpunkte müssen zwischen 0 und 200 liegen'
    }
  }
}
</script>

<style>

</style>
