<template>
  <div class="form row">
    <div class="col-sm-12">
      <local-district-picker
        v-model="currentLocalDistrict" :org-unit-id="selectedOrgUnit.id"
        autofocus
        :create="false"
      />
    </div>
    <div v-if="hasCurrentLocalDistrict" class="col-sm-12">
      <VueSelectize
        v-model="currentCadastralDistrict" :label="SR_RealEstate.CadastralDistictNumber"
        sort-field="order"
        :allow-empty="true" :fields="currentCadastralDistrictFields"
        :options="cadastralDistricts" :create="false"
      />
    </div>
    <div v-if="hasCurrentCadastralDistrict" class="col-sm-12">
      <VueSelectize
        v-model="currentLandParcelNumber" :label="SR_RealEstate.Number"
        sort-field="order"
        :allow-empty="true" :fields="currentLandParcelNumberFields"
        :options="landParcelNumbers" :create="false"
      />
    </div>
    <div v-if="hasSegments" class="col-sm-12">
      <grid
        v-model="selectedSegments" :data="segments"
        :columns="columns"
        selection="multiple" paging
        minimal
      />
    </div>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'

import LocalDistrictPicker from 'src/vue/areas/real-estate/components/object-picker/local-district-picker'
import VueSelectize from '@components/VueSelectize'
import Grid from 'src/vue/areas/real-estate/components/grid/RELegacyGrid.vue'
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    LocalDistrictPicker,
    Grid,
    VueSelectize
  },
  mixins: [makeResourceMixin(resources)],
  props: {
    value: {
      value: null,
      type: Object
    },
    name: {
      value: null,
      type: Object
    }
  },
  data () {
    return {
      currentCadastralDistrictFields: {
        text: 'number',
        value: 'uid'
      },
      currentLandParcelNumberFields: {
        text: 'number',
        value: 'uid'
      }
    }
  },
  computed: {
    ...mapGetters('realEstateContractLease/areas', {
      currentLocalDistrictX: 'currentLocalDistrict',
      hasCurrentLocalDistrict: 'hasCurrentLocalDistrict',
      localDistricts: 'localDistricts',
      currentCadastralDistrictX: 'currentCadastralDistrict',
      hasCurrentCadastralDistrict: 'hasCurrentCadastralDistrict',
      cadastralDistricts: 'cadastralDistricts',
      currentLandParcelNumberX: 'currentLandParcelNumber',
      landParcelNumbers: 'landParcelNumbers',
      segments: 'segments',
      selectedSegmentsX: 'selectedSegments'
    }),
    ...mapGetters('realEstateContractLease/navigation', {
      selectedOrgUnit: 'selectedOrgUnit'}),

    currentLocalDistrict: {
      get () {
        return this.currentLocalDistrictX
      },
      set (value) {
        this.selectedLocalDistrict(value)
      }
    },
    currentCadastralDistrict: {
      get () {
        return this.currentCadastralDistrictX
      },
      set (value) {
        this.selectedCadastralDistrict(value)
      }
    },
    currentLandParcelNumber: {
      get () {
        return this.currentLandParcelNumberX
      },
      set (value) {
        this.selectLandParcelNumber({cadastralDistrict: this.currentCadastralDistrict, landParcelNumber: value})
      }
    },
    selectedSegments: {
      get () {
        return this.selectedSegmentsX
      },
      set (value) {
        this.selectSegments(value)
      }
    },
    hasSegments: {
      get () {
        return this.segments.length > 0
      }
    },
    columns: {
      get () {
        return [
          {localizedTitle: this.getLocalizedTitleByResourceName('LocalDistrictName'), typeName: 'String', propertyName: 'localDistrictName'},
          {localizedTitle: this.getLocalizedTitleByResourceName('CadastralDistrictNumber'), typeName: 'String', propertyName: 'cadastralDistrictNumber'},
          {localizedTitle: this.getLocalizedTitleByResourceName('LandParcelNumber'), typeName: 'String', propertyName: 'landParcelNumber'},
          {localizedTitle: this.getLocalizedTitleByResourceName('UsageName'), typeName: 'String', propertyName: 'usageName'},
          {localizedTitle: this.getLocalizedTitleByResourceName('UsageShortcut'), typeName: 'String', propertyName: 'usageShortcut'},
          {localizedTitle: this.getLocalizedTitleByResourceName('Groundpoints'), typeName: 'Number', propertyName: 'groundpoints'},
          {localizedTitle: this.getLocalizedTitleByResourceName('SegmentSize'), typeName: 'Number', propertyName: 'segmentSize'}
        ]
      }
    }
  },
  methods: {
    ...mapActions('realEstateContractLease/areas', [
      'selectedLocalDistrict',
      'selectedCadastralDistrict',
      'selectLandParcelNumber',
      'selectSegments'
    ]),
    save () {
      this.$emit('input', this.value)
    },
    getLocalizedTitleByResourceName (resourceName) {
      return this.SR_ModelProperties[resourceName] || resourceName
    }
  }
}
</script>
<style>
</style>
