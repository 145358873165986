<template lang="html">
  <div :class="[widthClass]">
    <div class="form-group">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: ['width'],
  computed: {
    widthClass () {
      return `col-xs-${this.width || 4}`
    }
  }
}
</script>

<style lang="css">
</style>
