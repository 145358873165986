<template>
  <div class="form">
    <div class="row">
      <div class="col-sm-6">
        <bs-checkbox
          v-model="value.isRentOut" name="isRentOut"
          :disabled="!currentPermissions.leaseContractCanEdit"
          @input="onChange"
        >
          <IxRes :resource="SR_RealEstate">
            IsRentOut
          </IxRes>
        </bs-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <certificateNumberInput
          v-model="value.certificateNumber" :label="SR_ModelProperties.CertificateNumber"
          name="certificateNumber" required
          :placeholder="SR_RealEstate.ContractNumber" :disabled="!currentPermissions.leaseContractCanEdit"
          @input="onChange"
        />
      </div>
      <div class="col-sm-6">
        <date-time-picker
          v-model="value.contractDate" :label="SR_ModelProperties.ContractDate"
          name="contractDate" iso
          required
          :readonly="!currentPermissions.leaseContractCanEdit" @input="onChange"
        />
      </div>

      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <label class="control-label"><IxRes :resource="SR_ModelProperties">Lessor</IxRes></label>
            <div v-for="(lessor, idx) in currentAmendment.lessor" :key="idx">
              <div class="row">
                <div class="col-sm-10">
                  <div v-if="currentPermissions.leaseContractCanEdit">
                    <person-picker
                      v-model="currentAmendment.lessor[idx]" name="lessor"
                      type="Other" :org-unit-id="selectedOrgUnit.tenantId"
                      :disabled="!hasCurrentAmendment" :allow-empty="true"
                      :label="SR_ModelProperties.Lessor" :show-label="false"
                      @input="onChange"
                    />
                  </div>
                  <div v-else>
                    {{ currentAmendment.lessor[idx].text }}
                  </div>
                </div>
                <div v-if="currentPermissions.leaseContractCanEdit" class="col-sm-1">
                  <button class="btn btn-default delete-btn" :disabled="currentAmendment.lessor.length === 1" @click="removeLessor(idx)">
                    <span class="fa fa-trash-o" />
                  </button>
                </div>
              </div>
            </div>
            <a v-if="currentPermissions.leaseContractCanEdit" class="small" @click="addLessor">
              <IxRes :resource="SR_RealEstate">AddLessor</IxRes>
            </a>
          </div>
          <div class="col-sm-6">
            <label class="control-label"><IxRes :resource="SR_ModelProperties">Leaser</IxRes></label>
            <div v-for="(leaser, idx) in currentAmendment.leaser" :key="idx">
              <div class="row">
                <div class="col-sm-10">
                  <div v-if="currentPermissions.leaseContractCanEdit">
                    <person-picker
                      v-model="currentAmendment.leaser[idx]" name="leaser"
                      type="Other" :org-unit-id="selectedOrgUnit.tenantId"
                      :disabled="!hasCurrentAmendment" :allow-empty="true"
                      :label="SR_ModelProperties.Leaser" :show-label="false"
                      @input="onChange"
                    />
                  </div>
                  <div v-else>
                    {{ currentAmendment.leaser[idx].text }}
                  </div>
                </div>
                <div v-if="currentPermissions.leaseContractCanEdit" class="col-sm-1">
                  <button class="btn btn-default delete-btn" :disabled="currentAmendment.leaser.length === 1" @click="removeLeaser(idx)">
                    <span class="fa fa-trash-o" />
                  </button>
                </div>
              </div>
            </div>
            <a v-if="currentPermissions.leaseContractCanEdit" class="small" @click="addLeaser">
              <IxRes :resource="SR_RealEstate">AddLeaser</IxRes>
            </a>
          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <date-time-picker
          :label="SR_ModelProperties.TermFrom" name="termForm"
          iso
          :value="termFrom" readonly
          :tabindex="-1" @input="onChange"
        />
      </div>
      <div class="col-sm-6">
        <date-time-picker
          :label="SR_ModelProperties.TermTo" name="termTo"
          iso
          :value="termTo" readonly
          :tabindex="-1" @input="onChange"
        />
      </div>
      <div class="col-sm-6">
        <div v-if="currentPermissions.leaseContractCanEdit">
          <business-object-picker
            v-model="value.leaseObject" :label="SR_ModelProperties.LeaseObject"
            :org-unit-id="selectedOrgUnit.tenantId" name="leaseObject"
            :fields="{value: 'value', label: 'Name'}" @input="onChange"
          />
        </div>
        <div v-else>
          <b>{{ SR_ModelProperties.LeaseObject }}</b>
          <br>
          {{ value.leaseObject.text }}
        </div>
      </div>
      <div class="col-sm-6">
        <bs-checkbox
          v-model="currentAmendment.isExchangeAllowed" name="isExchangeAllowed"
          :disabled="!(hasCurrentAmendment && currentPermissions.leaseContractCanEdit)"
          @input="onChange"
        >
          <IxRes :resource="SR_ModelProperties">
            IsExchangeAllowed
          </IxRes>
        </bs-checkbox>
        <bs-checkbox
          v-model="currentAmendment.isWaterPaidByLeaser" name="isWaterPaidByLeaser"
          :disabled="!(hasCurrentAmendment && currentPermissions.leaseContractCanEdit)"
          @input="onChange"
        >
          <IxRes :resource="SR_ModelProperties">
            IsWaterPaidByLeaser
          </IxRes>
        </bs-checkbox>
        <bs-checkbox
          v-model="currentAmendment.isTaxPaidByLeaser" name="isTaxPaidByLeaser"
          :disabled="!(hasCurrentAmendment && currentPermissions.leaseContractCanEdit)"
          @input="onChange"
        >
          <IxRes :resource="SR_ModelProperties">
            IsTaxPaidByLeaser
          </IxRes>
        </bs-checkbox>
      </div>
      <div class="col-sm-6">
        <text-input
          v-model="value.comments" name="comments"
          :label="SR_ModelProperties.Comments"
          multiline :disabled="!currentPermissions.leaseContractCanEdit"
          @input="onChange"
        />
      </div>
      <div class="col-sm-10">
        <label class="control-label">
          <IxRes :resource="SR_RealEstate">AutoRenewalAndCancellation</IxRes>
        </label>
        <bs-checkbox
          v-model="value.autoRenew" name="autoRenew"
          :disabled="!currentPermissions.leaseContractCanEdit"
          @input="onChange"
        >
          <IxRes :resource="SR_ModelProperties">
            AutoRenew
          </IxRes>
        </bs-checkbox>
      </div>
      <div class="col-sm-4">
        <numeric-input
          v-model="value.autoRenewalPeriod" :label="SR_ModelProperties.AutoRenewalPeriod"
          integer name="autoRenewalPeriod"
          :addon="SR_Common.Months" :disabled="!currentPermissions.leaseContractCanEdit"
          @input="onChange"
        />
      </div>
      <div class="col-sm-4">
        <numeric-input
          v-model="value.cancellationPeriod" :label="SR_ModelProperties.CancellationPeriod"
          integer name="cancellationPeriod"
          :addon="SR_Common.Months" :disabled="!currentPermissions.leaseContractCanEdit"
          @input="onChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'

import PersonPicker from 'src/vue/areas/real-estate/components/object-picker/person-picker.vue'
import BusinessObjectPicker from 'src/vue/areas/real-estate/components/object-picker/business-object-picker'
import CertificateNumberInput from 'src/vue/areas/real-estate/components/CertificateNumberInput'

import TextInput from 'src/vue/components/forms/TextInput'
import NumericInput from 'src/vue/components/forms/NumericInput'
import DateTimePicker from 'src/vue/areas/real-estate/components/forms/DateTimePicker'
import BsCheckbox from 'src/vue/components/bootstrap/BsCheckbox'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

import ChangeDetectionMixin from 'src/vue/components/forms/ChangeDetectionMixin'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    PersonPicker,
    BusinessObjectPicker,
    CertificateNumberInput,
    TextInput,
    NumericInput,
    DateTimePicker,
    BsCheckbox
  },
  mixins: [ChangeDetectionMixin, makeResourceMixin(resources)],
  props: {
    value: null,
    readOnly: {
      type: Boolean,
      default: false
    },
    name: {
      Type: String,
      default: ''
    },
    certificateNumberValidationResult: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoaded: false
    }
  },
  computed: {
    ...mapGetters('realEstateContractLease/contracts', [
      'currentPermissions',
      'termFrom',
      'termTo'
    ]),
    ...mapGetters('realEstateContractLease/navigation', [
      'selectedOrgUnit'
    ]),
    ...mapGetters('realEstateContractLease/amendments',
      [
        'currentAmendment',
        'hasCurrentAmendment'
      ])
  },
  methods: {
    ...mapActions('realEstateContractLease/contracts', [
      'setContractAsChanged',
      'setCommonChanged'
    ]),
    ...mapActions('realEstateContractLease/amendments', [
      'addLeaser',
      'addLessor',
      'removeLeaser',
      'removeLessor'
    ]),
    save () {
      this.$emit('input', this.value)
    },
    onChange () {
      if (this.isLoaded) {
        this.setContractAsChanged()
        this.setCommonChanged()
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.isLoaded = true
    }, 1000)
  }
}
</script>
<style lang="scss" scoped>
   .delete-btn {
     margin-left: -15px;
   }
</style>
