<template lang="html">
  <div class="container">
    <div class="row">
      <div id="tree-container" class="col-md-3 col-xs-6">
        <slot name="tree" />
      </div>
      <div id="tree-content" class="col-md-9 col-xs-6">
        <div id="default-ajax-target" class="fade" :class="{in: !loading}">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['loading']
}
</script>

<style lang="scss" scoped>

</style>
