# CoffeeScript
{get} = require 'src/coffee/infrastructure/ajax'
{register} = require 'src/js/infrastructure/setup-registry'
{PurchaseDetailController} = require './purchase-detail-controller'
treeUtility = require 'src/coffee/controls/tree/helpers'

PurchaseViewSetup = ($ctx) ->
    view = $ctx.find '#purchase-view'
    $grid = $ctx.find '#contract-grid'
    grid = $grid.staticgrid()[0]
    metaData = {
        landParcelInOut: true
        contractUseSizeOut: false
    }
    #was ctrl = ...   in case of error examine this
    return new PurchaseController view, grid, 'cdfe1edc-faa0-49e8-a922-d4f637d49423', metaData #ContractTpyeId: Purchase

SaleViewSetup = ($ctx) ->
    view = $ctx.find '#sale-view'
    $grid = $ctx.find '#contract-grid'
    grid = $grid.staticgrid()[0]
    metaData = {
        landParcelInOut: false
        contractUseSizeOut: true
    }

    return new PurchaseController view, grid, '73a17169-f57d-41b3-aa4d-017c3d235b85', metaData #ContractTpyeId: Sale


class PurchaseController
    constructor: (@view, @grid, @contractTypeId, @metaData) ->
        @setupPayloads()
        @setupContractTable()
        @setupTree()
        @details = new PurchaseDetailController
            view: @view
            onChange: @onDetailsChanged

        @grid.editControls.add.$button.off()
        @grid.editControls.add.$button.on 'click', (e) =>
            e.preventDefault()
            @details.createNewContract(@treeItemUid, @contractTypeId, @metaData)
            @grid.table.rows().deselect()

        @executePreselection()

    executePreselection: ->
        div = $('#preselection')
        if div.length == 0
            return

        entityId = div.data('entity')
        itemId = div.data('item')

        @grid.table.one('draw.dt', => @grid.selectRow(itemId) )
        treeUtility.selectNode(entityId)


    setupPayloads: ->
        @grid.options.ajax.payload = => {
            Id: @treeItemUid
            ContractType: @contractTypeId
        }

    setupContractTable: ->
        @grid.table.on 'select', @onSelected
            .on 'deselect', @onDeselected
            .on 'draw.dt', @onDraw

    onSelected: (e, dt, type, indexes) =>
        return unless type is 'rows' or type is 'row'
        dataItems = dt.rows(indexes).data().pluck('Uid')
        if dataItems.length > 0 and dataItems[0] isnt @details.id
            get '/RealEstate/Contract/GetContractDetailData', {id: dataItems[0]}
            .done (contractDetails) =>
                contractDetails.metaData = @metaData
                @details.display @treeItemUid, contractDetails

    onDeselected: (e, dt, type, indexes) =>
        return unless type is 'rows' or type is 'row'
        dataItems = dt.rows(indexes).data().pluck('Uid')
        if dataItems.length > 0 and dataItems[0] is @details.id
            @details.clear()

    onDraw: =>
        row = @grid.table.row('#' + @details.id)
        if row is undefined or row.length is 0
            @details.clear()
        else
            row.select()

    setupTree: ->
        @tree = $ '.tree-wrapper'
        .on 'click', '.easytree-node', @onNodeSelected

    onNodeSelected: (event) =>
        @treeItemUid = $('.easytree').tree().getNode(event.currentTarget.id).data.uid
        if @treeItemUid isnt undefined
            @grid.editControls.add.$button.enable()
        else
            @grid.editControls.add.$button.disable()
        @details.clear()
        @details.updateEntityId(@treeItemUid)
        @grid.table.ajax.reload(null, false) #the false keeps pagination at the current page

    onDetailsChanged: (contractId) =>
        @grid.selectRow(contractId)
        @grid.table.ajax.reload(null, false) #the false keeps pagination at the current page

register('App.RealEstate.Contract.PurchaseViewSetup', PurchaseViewSetup)
register('App.RealEstate.Contract.SaleViewSetup', SaleViewSetup)
