<template lang="html">
  <div class="vue-tree-wrapper">
    <div class="easytree-addon-data" :data-expand-text="SR_Common.ExpandAll" :data-collapse-text="SR_Common.CollapseAll" />
    <div
      class="easytree fade" data-target="subcontent"
      :data-tree-id="mergedOptions.id"
      data-setup-control="tree" :data-keep-tree="mergedOptions.keep"
    />
  </div>
</template>

<script>
import setupTree from 'src/coffee/infrastructure/data-api/setup-functions/tree'
import {findNode, findParent} from 'src/coffee/controls/tree/helpers'
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

const defaultOptions = {
  labels: {
    expand: 'Expand',
    collapse: 'Collapse'
  },
  id: null,
  keep: true
}
const resources = {
  SR_Common: 'Common.SR_Common'
}

export default {
  mixins: [makeResourceMixin(resources)],
  props: ['options', 'nodes', 'value', 'useStateHandling'],
  data () {
    return {
      tree: null
    }
  },
  computed: {
    mergedOptions () {
      return Object.assign({}, defaultOptions, this.options)
    }
  },
  methods: {
    updateNodes (nodes) {
      this.tree.rebuildTree(nodes)
    },
    updateSelection () {
      const selectedNode = findNode(this.nodes, x => x.entity === this.value)
      if (!selectedNode) {
        this.tree.activateNode(null)
        return
      }

      this.expandTree(selectedNode.id)
      this.tree.activateNode(selectedNode.id)
    },
    onClick (event) {
      // this.$emit('input')
      if (event.target.tagName === 'A') {
        event.preventDefault()
      }

      let node = this.tree.getNode(event.currentTarget.id)

      this.$emit('input', node.entity)
    },
    deactivateAjax () {
      $(this.$el).find('.easytree a').attr('data-ajax', 'false')
    },
    expandTree (nodeId) {
      let parent = findParent(this.nodes, nodeId)

      if (parent) {
        let currentItem = parent

        while (currentItem) {
          const currentNode = this.tree.getNode(currentItem.id)
          currentNode.isExpanded = true

          currentItem = findParent(this.nodes, currentItem.id)
        }
      } else { // is 1st level
        const currentNode = this.tree.getNode(nodeId)

        if (currentNode) {
          currentNode.isExpandend = true
        }
      }

      this.tree.rebuildTree()
    }
  },
  watch: {
    value () {
      this.updateSelection()
    },
    nodes (value) {
      if (value instanceof Function) {
        value().then(this.updateNodes)
          .then(this.updateSelection)
      } else {
        this.updateNodes(value)
        this.updateSelection()
      }
    }
  },
  mounted () {
    const options = {
      data: this.nodes instanceof Function ? null : this.nodes,
      built: this.deactivateAjax,
      toggled: this.deactivateAjax
    }

    const parameters = {
      $element: $(this.$el.querySelector('.easytree')),
      $ctx: $(this.$el),
      options,
      useStateHandling: !!this.useStateHandling
    }
    setupTree(parameters)
      .then(tree => {
        this.tree = tree

        $(this.$el).on('click', '.easytree-node', this.onClick)

        if (this.nodes instanceof Function) {
          return this.nodes().then(this.updateNodes).then(this.updateSelection)
        } else {
          this.deactivateAjax()
          this.updateSelection()
        }

        this.$emit('initialized', tree)
      })
  }
}
</script>

<style lang="scss" scoped>
.fullheight.vue-tree-wrapper .tree-wrapper .easytree {
  height: 100% !important;
  overflow-y: hidden;
}
</style>
