<template>
  <div>
    <masterdata-ui
      :columns="columns" :grid-title="SR_RealEstate.Regions"
      :modal-title-add="SR_RealEstate.AddRegion" :modal-title-edit="SR_RealEstate.EditRegion"
      :load-items-action-url="loadItemsActionUrl" :update-item-action-url="updateItemActionUrl"
      :create-item-action-url="createItemActionUrl"
      :build-object-for-request="buildRegionObject" :setup-modal-content="setupModalContent"
      filter-params-key="country" :filter-params-value="countryUID"
    />
  </div>
</template>

<script lang="js">
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'
import newGuid from 'src/js/helpers/new-guid'
import masterdataUi from './masterdata-ui'

const resources = {
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_InfoMessages: 'Messages.Info.SR_InfoMessages'
}

export default {
  components: {
    masterdataUi
  },
  mixins: [makeResourceMixin(resources)],

  props: ['countryUID'],

  data () {
    return {
      createItemActionUrl: '/RealEstate/Region/Create',
      updateItemActionUrl: '/RealEstate/Region/Update',
      loadItemsActionUrl: '/RealEstate/Region'
    }
  },

  computed: {

    columns () {
      return [
        {
          localizedTitle: this.SR_RealEstate['Region'],
          typeName: 'String',
          propertyName: 'name'
        },
        {
          localizedTitle: this.SR_RealEstate['OfficialCode'],
          typeName: 'String',
          propertyName: 'officialCode'
        },
        {
          localizedTitle: 'UID',
          typeName: 'String',
          propertyName: 'uid',
          isIdColumn: true,
          invisible: true
        },
        {
          localizedTitle: 'fkCountry',
          typeName: 'String',
          propertyName: 'fkCountry',
          invisible: true
        }
      ]
    }
  },

  methods: {

    setupModalContent (data = {uid: newGuid(), name: '', officialCode: '', fkCountry: this.countryUID}) {
      return [
        {propertyName: 'uid', value: data.uid, label: '', required: false, editable: false},
        {propertyName: 'name', value: data.name, label: this.SR_RealEstate.Region, required: true, editable: true},
        {propertyName: 'officialCode', value: data.officialCode, label: this.SR_RealEstate.OfficialCode, required: true, editable: true, maxLength: '2'},
        {propertyName: 'fkCountry', value: data.fkCountry, label: '', required: false, editable: false}
      ]
    },

    buildRegionObject (data) {
      return {
        uid: data[0].value, // TODO: find better solution
        name: data[1].value,
        officialCode: data[2].value,
        fkCountry: data[3].value
      }
    }
  }
}
</script>

<style lang="scss">
</style>
