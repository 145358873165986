{register} = require 'src/js/infrastructure/setup-registry'﻿
{post, get} = require 'src/coffee/infrastructure/ajax'﻿
{makeReplaceHandler: replace} = require 'src/coffee/helpers/async/fragment-loading'

VisualizationSetup = ($ctx) ->
    $regionFilters = $ctx.find '#region-filters'
    return if $regionFilters.length is 0
    $mapWithLocalDistrictsContainer = $ctx.find '.map-with-local-districts-container'
    entityId = $ctx.find('#EntityId').val()
    entityName = $ctx.find('#EntityName').val()

    $printBtn = $ctx.find '#printBtn'
    .off()
    .on 'click', -> window.print()

    $regionFilters.on 'change', (event) ->
        selectize = $regionFilters[0].selectize
        
        if selectize.items.length == 0
            data = {
                entityId
                entityName
                reload: true
            }
            get "/RealEstate/Visualization/ShowLandParcels", data
            .then replace $mapWithLocalDistrictsContainer
            .then -> handleCheckboxes()
        else
            items = selectize.options[selectize.items[0]]
            regionFilterId = items.value
            regionFilterName = items.text

            selectedOwners = getCheckboxItems $mapWithLocalDistrictsContainer.find('.owner-checkboxes')
            deselectedOwners = getCheckboxItems $mapWithLocalDistrictsContainer.find('.owner-checkboxes'), false
            layerStyleItems = getLayerStyleItems $('.legend-container .layer-style-checkboxes')

            url = $mapWithLocalDistrictsContainer.data 'url'

            data =
            {
                entityId
                entityName
                regionFilterId
                regionFilterName
                selectedOwners: selectedOwners
                deselectedOwners: deselectedOwners
                layerStyleItems: layerStyleItems
            }

            post url, data
            .then replace $mapWithLocalDistrictsContainer
            .then -> handleCheckboxes()

    handleCheckboxes($ctx)

getLayerStyleItems = ($ctx) ->
    $checkboxes = $ctx.find 'input[type="checkbox"]'
    selectedItems = {}
    $checkboxes.each ->
        # we want 'this' as our checkbox
        item =
            identifier: $(this).attr('name')
            isSet: $(this).prop('checked')
            value: $(this).val()
        selectedItems[item.identifier] = item
    return selectedItems

getCheckboxItems = ($ctx, checked = true) ->
    $checkboxes = $ctx.find 'input[type="checkbox"]'
    selectedItems = []
    $checkboxes.each ->
        # we want 'this' as our checkbox
        if $(this).prop('checked') is checked
            #attention: all checkboxes are doubled due to modal legend. so there won't be a subset!
            selectedItems.push $(this).val()
    return selectedItems

getUpdatedUrl = (event) ->
    selectedOwners = getCheckboxItems $(event.delegateTarget).find('.owner-checkboxes')
    isWithoutOwner = selectedOwners.includes('null')
    if isWithoutOwner
        index = selectedOwners.indexOf('null')
        selectedOwners.splice(index, 1)

    newOwnersParam = encodeURI('ownerids:' + selectedOwners.join('\\,') + ';' + 'withoutowner:' + isWithoutOwner + ';')

    layerName = $('.map-container').data 'layer-name'
    mapcontrol = $('#land-parcels-map').mapcontrol()
    return if mapcontrol.length is 0
    layer = mapcontrol[0].layerManager.getLayersByName(layerName)[0]

    url = layer.getSource().getUrls()[0]

    getViewParams = url.match(/\?viewparams.*&/)[0]
    getViewParams = getViewParams.substring(0, getViewParams.length - 1)
    updatedViewParams = getViewParams
    if (updatedViewParams.indexOf('ownerids') is -1)
        updatedViewParams += newOwnersParam + '&'
    else
        updatedViewParams = updatedViewParams.replace(/withoutowner:.*?;/, '')
        updatedViewParams = updatedViewParams.replace(/ownerids:.*?;/, newOwnersParam) + '&'

    url = url.replace(/\?viewparams.*&/, updatedViewParams)

getStyledUrl = (event, url) ->
    layerStyleOptions = getLayerStyleItems $(event.delegateTarget).find('.layer-style-checkboxes')


    if event.currentTarget.name is 'Opacity'
        newOpacity = "opacityValue:#{layerStyleOptions.Opacity.value};"
        url = url.replace(/opacityValue:.*?;/, newOpacity)

    if event.currentTarget.name is 'Stroke'
        newStroke = "strokeWidth:#{layerStyleOptions.Stroke.value};"
        url = url.replace(/strokeWidth:.*?;/, newStroke)
    return url

handleCheckboxes = ($ctx = $('body')) ->
    mapcontrol = $('#land-parcels-map').mapcontrol()
    return if mapcontrol.length is 0

    $ctx.find('.legend-container, #land-parcels-map-modal')
    .on 'change', 'input[type="checkbox"]', (event) ->
        opacityDefaultValue = '0.01'
        strokeDefaultValue = '1'

        $currentTarget = $(event.currentTarget)
        if $currentTarget.attr('name') is 'Opacity'
            $currentTarget.val(if $currentTarget.prop('checked') then 0.2 else opacityDefaultValue)
        if $currentTarget.attr('name') is 'Stroke'
            $currentTarget.val(if $currentTarget.prop('checked') then 3 else strokeDefaultValue)

        if $(event.currentTarget).closest('.owner-checkboxes').length
            clickedItem = event.currentTarget.value
        else if $(event.currentTarget).closest('.layer-style-checkboxes').length
            clickedItem = event.currentTarget.name

        if $(event.currentTarget).closest('.legend-container').length
            $('#land-parcels-map-modal [value="' + clickedItem + '"],[name="' + clickedItem + '"]')
            .prop('checked', event.currentTarget.checked)
        else
            $('.legend-container [value="' + clickedItem + '"],[name="' + clickedItem + '"]')
            .prop('checked', event.currentTarget.checked)

        layerName = $('.map-container').data 'layer-name'
        mapcontrol = $('#land-parcels-map').mapcontrol()
        return if mapcontrol.length is 0
        layer = mapcontrol[0].layerManager.getLayersByName(layerName)[0]

        url = getStyledUrl(event, getUpdatedUrl(event))
        layer.getSource().setUrl(url)

register 'App.RealEstate.VisualizationSetup', VisualizationSetup
