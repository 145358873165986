<template lang="html">
  <div>
    <div class="row">
      <div :class="{'col-md-9': !createMode, 'col-md-12': createMode}">
        <grid
          v-if="columTranslationsAreLoaded && hasCurrentAmendment" v-model="selectedAreas"
          :data="areas" :columns="columns"
          selection="multi"
          minimal inline
          :add-button="currentPermissions.leaseContractCanEdit" i
          :delete-button="hasCurrentArea && currentPermissions.leaseContractCanEdit" @add="createAreaRequest"
          @delete="deleteAreaRequest" @edit="editAreaRequest"
        />
        <button
          v-if="currentPermissions.leaseContractCanEdit" type="button"
          class="btn btn-default"
          @click="showCreateModal"
        >
          <i class="fa fa-plus" aria-hidden="true" />
          <IxRes :resource="SR_RealEstate_Lease">
            AreaCreateNewLandparcel
          </IxRes>
        </button>
      </div>
      <div v-if="!createMode" class="col-md-3">
        <lease-amendments-selector v-model="value" />
      </div>
    </div>

    <lp-create-modal ref="lpCreateModal" :org-unit-id="selectedOrgUnit.id" />

    <bs-modal
      :title="SR_RealEstate.AddSegment" size="large"
      :value="isEditAreaModalOpen"
      blocking @hidden="createOrEditAreaFailure"
    >
      <area-form v-model="currentArea" />
      <button
        slot="footer" type="button"
        class="btn btn-primary"
        @click="createOrEditAreaSuccess"
      >
        <IxRes :resource="SR_Common">
          Confirm
        </IxRes>
      </button>
      <button
        slot="footer" type="button"
        class="btn btn-default"
        @click="createOrEditAreaFailure"
      >
        <IxRes :resource="SR_Common">
          Cancel
        </IxRes>
      </button>
    </bs-modal>
    <bs-modal :title="SR_RealEstate.DeleteAreaQuestion" :value="isDeleteAreaModalOpen" blocking>
      <p>
        <IxRes :resource="SR_RealEstate">
          DeleteAreaQuestion
        </IxRes>
      </p>
      <button
        slot="footer" type="button"
        class="btn btn-success"
        @click="deleteAreaFailure"
      >
        <IxRes :resource="SR_Common">
          No
        </IxRes>
      </button>
      <button
        slot="footer" type="button"
        class="btn btn-default"
        @click="deleteAreaSuccess"
      >
        <IxRes :resource="SR_Common">
          Yes
        </IxRes>
      </button>
    </bs-modal>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

import Grid from 'src/vue/areas/real-estate/components/grid/RELegacyGrid.vue'
import BsModal from 'src/vue/components/bootstrap/BsModal'
import AreaForm from './AreaForm'
import LeaseAmendmentsSelector from './LeaseAmendmentsSelector'

import lpCreateModal from 'src/vue/areas/real-estate/components/landparcel-create-modal'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_RealEstate_Lease: 'Areas.RealEstate.SR_RealEstate_Lease',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    Grid,
    BsModal,
    AreaForm,
    LeaseAmendmentsSelector,
    lpCreateModal
  },
  mixins: [makeResourceMixin(resources)],
  props: {
    value: null,
    createMode: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    name: {
      Type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('realEstateContractLease/navigation', {
      selectedOrgUnit: 'selectedOrgUnit'}),
    ...mapGetters('realEstateContractLease/areas',
      [
        'currentArea',
        'hasCurrentArea',
        'isEditAreaModalOpen',
        'isDeleteAreaModalOpen'
      ]),
    ...mapGetters('realEstateContractLease/contracts', [
      'currentPermissions']),
    ...mapGetters('realEstateContractLease/amendments',
      [
        'currentAmendment',
        'hasCurrentAmendment'
      ]),
    areas: {
      get () {
        return this.currentAmendment && this.currentAmendment.areas ? this.currentAmendment.areas : []
      }
    },
    selectedAreas: {
      get () {
        return this.$store.state.realEstateContractLease.areas.currentArea
          ? [this.$store.state.realEstateContractLease.areas.currentArea]
          : []
      },
      set (value) {
        const area = value && value.length > 0 ? value : null
        this.$store.dispatch('realEstateContractLease/areas/selectArea', area)
      }
    },
    columns () {
      return [
        {localizedTitle: 'ID', typeName: 'String', propertyName: 'uid', readonly: true, invisible: true},
        {localizedTitle: ' ', typeName: 'LinkUri', propertyName: 'link', readonly: true},
        {localizedTitle: this.getLocalizedTitleByResourceName('LandParcelNumber'), typeName: 'String', propertyName: 'landParcelIdentifier', defaultValue: ' ', readonly: true, create: false},
        {localizedTitle: this.getLocalizedTitleByResourceName('UsageName'), typeName: 'String', propertyName: 'usage.text', readonly: true},
        {localizedTitle: this.getLocalizedTitleByResourceName('Groundpoints'), typeName: 'Number', propertyName: 'groundpoints', defaultValue: ' ', readonly: true, create: false},
        {localizedTitle: this.SR_RealEstate['LeaseSegmentSize'], typeName: 'Float', propertyName: 'sizeActual', readonly: true, defaultValue: ' ', minDecimalDigits: 4, maxDecimalDigits: 4},
        {localizedTitle: this.SR_RealEstate['LeasedSize'], typeName: 'Float', propertyName: 'sizeContractual', readonly: !this.currentPermissions.leaseContractCanEdit, minDecimalDigits: 4, maxDecimalDigits: 4},
        {localizedTitle: 'Usage', typeName: 'Object', propertyName: 'usage', defaultValue: {text: ''}, invisible: true}
      ]
    },
    columTranslationsAreLoaded () {
      return this.columns.every(c => !!c.localizedTitle)
    }
  },
  methods: {
    ...mapActions('realEstateContractLease/areas', [
      'createAreaRequest',
      'editAreaRequest',
      'createOrEditAreaSuccess',
      'createOrEditAreaFailure',
      'deleteAreaRequest',
      'deleteAreaSuccess',
      'deleteAreaFailure',
      'addSegmentsFromCreateModalToLeaseContract']),

    getLocalizedTitleByResourceName (resourceName) {
      return this.SR_ModelProperties[resourceName] || resourceName
    },

    showCreateModal () {
      this.$refs.lpCreateModal.show()
        .then(this.addSegmentsFromCreateModalToLeaseContract, () => { /* Modal was cancelled */ })
    }
  }
}
</script>

<style lang="css">
</style>
