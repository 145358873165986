<template lang="html">
  <tree-layout :loading="treeLoading">
    <tree slot="tree" v-model="selectedOrgUnit" :nodes="nodes" />
    <div v-if="translationsLoaded">
      <div class="row">
        <div class="col-sm-12">
          <bs-panel :title="SR_RE_Reports['ChosenOrgUnit']">
            <input
              type="text" class="form-control"
              :value="selectedOrgUnit ? selectedOrgUnit.name : ''"
              disabled
            >
          </bs-panel>
        </div>
      </div>

      <div class="spacer" />

      <div v-if="selectedOrgUnit">
        <!-- Groupings -->
        <div class="row">
          <div class="col-sm-5">
            <bs-panel :title="SR_RE_Reports['SelectedGroupings']">
              <ul class="list-group" style="margin-bottom: 0;">
                <draggable v-model="selectedGroupings" :options="{group: 'groupings'}" style="min-height: 40px;">
                  <li v-for="(grouping, i) in selectedGroupings" :key="i" class="list-group-item">
                    {{ grouping.name }}
                    <span class="draghandle"><i class="fa fa-arrows" aria-hidden="true" /></span>
                  </li>
                  <div v-if="selectedGroupings.length == 0" class="text-center" style="color: lightgray">
                    <i class="fa fa-sign-in fa-4x fa-rotate-90" aria-hidden="true" />
                  </div>
                </draggable>
              </ul>
            </bs-panel>
          </div>

          <div class="col-sm-2 text-center">
            <i class="fa fa-exchange fa-3x text-muted" />
            <IxRes :resource="SR_RE_Reports">
              GroupingDragDropHelp
            </IxRes>
          </div>

          <div class="col-sm-5">
            <bs-panel :title="SR_RE_Reports['AvailableGroupings']">
              <ul class="list-group" style="margin-bottom: 0;">
                <draggable v-model="availableGroupings" :options="{group: 'groupings'}" style="min-height: 40px;">
                  <li v-for="(grouping, i) in availableGroupings" :key="i" class="list-group-item">
                    {{ grouping.name }}
                    <span class="draghandle"><i class="fa fa-arrows" aria-hidden="true" /></span>
                  </li>
                  <div v-if="availableGroupings.length == 0" class="text-center" style="color: lightgray">
                    <i class="fa fa-sign-in fa-4x fa-rotate-90" aria-hidden="true" />
                  </div>
                </draggable>
              </ul>
            </bs-panel>
          </div>
        </div>

        <!-- Filters -->
        <div class="row">
          <div class="col-sm-12">
            <bs-panel :title="SR_RE_Reports['ReportFilterTitle']">
              <div v-for="(filter, i) in filters" :key="i" class="row">
                <filter-control v-model="filter.value" :filter="filter" :selected-org-unit="selectedOrgUnit" />
              </div>
            </bs-panel>
          </div>
        </div>

        <div class="spacer" />

        <!-- Buttons -->
        <div class="row">
          <div class="col-sm-3">
            <input
              type="button" class="btn btn-default btn-block"
              :value="SR_RE_Reports['GeneratePreview']"
              :disabled="!selectedOrgUnit" @click="loadPreview"
            >
          </div>
          <div v-if="downloadUrl && dataLoaded && !dataLoading" class="col-sm-3">
            <input
              type="button" class="btn btn-success btn-block"
              :value="SR_RE_Reports['DownloadExcel']"
              @click="downloadExcel"
            >
          </div>
        </div>

        <div class="spacer" />

        <!-- Results -->
        <div class="row">
          <div class="col-sm-12">
            <div v-if="dataLoading">
              <div class="loading-indicator text-center">
                <i class="fa fa-cog fa-spin fa-5x" />
              </div>
            </div>
            <div v-else>
              <div v-if="dataLoaded">
                <grid
                  :data="gridData" :columns="columns"
                  paging
                  :add-button="false" :delete-button="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <IxRes :resource="SR_RE_Reports">
          SelectOrgUnitPrompt
        </IxRes>
      </div>
    </div>
  </tree-layout>
</template>

<script lang="js">
import draggable from 'vuedraggable'
import {uniq} from 'lodash'

import treeLayout from 'src/vue/legacy-compat/TreeLayout'
import tree from '@components/nav/DenseBindingTree'

import grid from 'src/vue/areas/real-estate/components/grid/RELegacyGrid'
import BsPanel from 'src/vue/components/bootstrap/BsPanel'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

import {get, postJson} from 'src/coffee/infrastructure/ajax'

import filterControl from 'src/vue/areas/real-estate/components/bi-controls/filter-control'

const resources = {
  SR_RE_Reports: 'Areas.RealEstate.SR_RealEstate_Reports'
}

export default {
  components: {
    treeLayout,
    tree,
    grid,
    draggable,
    BsPanel,
    filterControl
  },

  mixins: [
    makeResourceMixin(resources)
  ],

  props: {
    previewUrl: String,
    downloadUrl: String,
    queryInfoUrl: String,
    treeUrl: String
  },

  data () {
    return {
      translationsLoaded: false,
      selectedOrgUnit: null,
      treeLoading: true,
      dataLoading: false,
      dataLoaded: false,
      gridData: [],
      nodes: [],

      queryData: null,
      availableGroupings: [],
      selectedGroupings: [],

      availableMetrics: [],
      selectedMetrics: [],
      filters: []
    }
  },

  computed: {
    columns () {
      const groupColumns =
        this.selectedGroupings
          .map((group) => this.queryData.groupings[group.index])
          .map((grouping) => {
            return {
              localizedTitle: grouping.name,
              typeName: 'String',
              propertyName: grouping.sqlSelectors[0]
            }
          })

      const metricColumns =
        this.queryData.columns.map((col) => {
          return {
            localizedTitle: col.name,
            typeName: col.columnType,
            propertyName: col.sqlAlias,
            minDecimalDigits: col.decimals,
            maxDecimalDigits: col.decimals
          }
        })

      return groupColumns.concat(metricColumns)
    }
  },

  methods: {
    loadPreview () {
      this.dataLoading = true

      const payload = this.buildPayload()

      postJson(this.previewUrl, payload)
        .done((result) => {
          this.dataLoading = false
          this.dataLoaded = true
          this.gridData = result
        })
    },

    buildPayload () {
      const payload = {
        selectedGroupings: this.selectedGroupings.map((g) => g.index),
        filters: this.queryData.filters.map((filter) => filter.value ? filter.value : []),
        orgUnit: this.selectedOrgUnit.uid
      }

      return payload
    },

    downloadExcel () {
      const payload = this.buildPayload()

      postJson(this.downloadUrl, payload)
        .then((result) => {
          window.location = '/Download/Temp/' + escape(result)
        })
    },

    loadQueryInfo () {
      get(this.queryInfoUrl)
        .then((result) => {
          this.queryData = result

          if (!this.queryData) {
            this.availableGroupings = []
            this.selectedGroupings = []
            this.availableMetrics = []
            this.selectedMetrics = []
            this.filters = []
          } else {
            this.selectedGroupings = this.queryData.groupings.map((item, index) => {
              return {
                name: item.name,
                index: index
              }
            })

            this.filters = this.queryData.filters

            this.availableGroupings = []
            this.availableMetrics = []
          }
        })
        .fail((jqXHR) => alert(jqXHR))
    }
  },

  mounted () {
    get(this.treeUrl)
      .then((result) => {
        this.nodes = result
        this.treeLoading = false
      })
      .fail((jqXHR) => alert(jqXHR))

    this.loadQueryInfo()
  },
  created () {
    const resIdentifiers = uniq(Object.values(resources))

    this.$store.dispatch('i18n/preloadTranslations', resIdentifiers)
      .then(() => {
        this.translationsLoaded = true
      })
  }
}
</script>

<style lang="scss">
  .frame {
    border: 1px solid lightgrey;
    padding: 10px;
  }

  .spacer {
    height: 1em
  }

  .draghandle {
    float: right;
  }
</style>
