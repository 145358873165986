class Status
    icons =
        none: 'fa fa-fw'
        working: 'fa fa-fw fa-spinner fa-pulse'
        changed: 'fa fa-fw fa-asterisk'
        success: 'fa fa-fw fa-check'
        error: 'fa fa-fw fa-exclamation-triangle'
        invalid: 'fa fa-fw' #'fa fa-fw fa-times ix-fa-pulse' #for form validation

    constructor: (@control) ->
        @currentState = 'none'

    set: (state) ->
        if icons[state] is undefined
            throw new Error "#{state} is not a valid value!"
        @currentState = state
        @control.removeClass().addClass icons[state]

    is: (value) ->
        @currentState is value

module.exports = {
    Status
}
module.exports.__esModule = true
module.exports.default = Status
