class Mediator
    constructor: ->
        @commands = {}
        @events = {}

    register: (args) ->
        @commands[name] = handler for name, handler of args
        return this

    invoke: (params) ->
        @commands[params.command](params.args, this)
        return this

    handle: (params) ->
        @events[params.event] = [] if @events[params.event] is undefined
        @events[params.event].push params.with
        return this

    raise: (params) ->
        return if @events[params.event] is undefined
        handler params for handler in @events[params.event] when handler isnt undefined
        return this

module.exports = {
    Mediator
}
module.exports.__esModule = true
module.exports.default = Mediator
