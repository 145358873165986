{get} = require 'src/coffee/infrastructure/ajax'

class CommunityPicker
    constructor: (@view, @mediator, @binder) ->
        @entityId = null
        @setupControl()
        @loadCommunitiesAction =  $('#actions').data('communities-load-action')
    setupControl: ->
        @binder.bindAjaxSelectize('#community', 'taxNotice.community', @loadCommunities,
        {
            create: (input, callback) =>
                @createCommunity input, callback
                return undefined
            render:
                option: @renderCommunityWithDistrict
                item: @renderCommunityWithDistrict
            selectOnTab: false
            openOnFocus: true
        },
        (input, control) ->
            for option of control.options
                return false if control.options[option].name.toLowerCase() is input.toLowerCase()
            return true
        )

    createCommunity: (input, callback) ->
        @mediator
        .invoke
            command: 'CreateCommunity'
            args:
                Name: input,
                entityId: @entityId
        .handle
            event: 'CommunityCreated'
            with: (event) -> callback event.data
        .handle
            event: 'CreateCommunityCancelled'
            with: (event) -> callback()

    onChange: (@onChangeCallback) -> this
    enable: -> @selectize.enable()
    disable: -> @selectize.disable()
    clear: (notify) -> @selectize.clear notify
    focus: -> @selectize.focus()
    addAndPick: (community) ->
        @selectize.addOption community
        @selectize.addItem community.uid, true
    isPicked: -> @selectize.items.length is 1
    getId: -> @selectize.getValue()

    loadCommunities: (query, callback) =>
        get @loadCommunitiesAction, {query: query, entityId:@entityId}
        .done (result) -> callback(result)
        return undefined

    updateEntityId: (entityId) ->
        @entityId = entityId

    renderCommunityWithDistrict: (data, escape) =>
        stringToDisplay = @buildStringToDisplay(data)

        """
            <div>
                <span>#{escape(data.name)}</span>
                <small class='text-muted'>#{stringToDisplay}</small>
            </div>
        """

    buildStringToDisplay: (data) ->
        districtNameAndRegionName = []

        if data.districtName
            districtNameAndRegionName.push(data.districtName)
        if data.regionName
            districtNameAndRegionName.push(data.regionName)

        return districtNameAndRegionName.join(', ')

module.exports = {
    CommunityPicker
}
module.exports.__esModule = true
module.exports.default = CommunityPicker
