<template lang="html">
  <div class="real-estate">
    <masterdata-ui
      :include-tree="true" :columns="columns"
      :load-items-action-url="loadItemsActionUrl" :create-item-action-url="createItemActionUrl"
      :update-item-action-url="updateItemActionUrl" :grid-title="SR_RealEstate.TaxAuthority"
      :modal-title-add="SR_RealEstate.AddTaxAuthority"
      :modal-title-edit="SR_RealEstate.EditTaxAuthority" :setup-modal-content="setupModalContent"
      :build-object-for-request="buildTaxAuthorityObject"
      @entityChanged="updateEntity"
    />
  </div>
</template>

<script lang="js">
import masterdataUi from './masterdata-ui'
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'
import newGuid from 'src/js/helpers/new-guid'

const resources = {
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate'
}

export default {
  components: {
    masterdataUi
  },
  mixins: [makeResourceMixin(resources)],

  data () {
    return {
      loadItemsActionUrl: '/RealEstate/MasterDataTaxAuthority/GetTaxAuthorities',
      createItemActionUrl: '/RealEstate/MasterDataTaxAuthority/Create',
      updateItemActionUrl: '/RealEstate/MasterDataTaxAuthority/Update',
      entityId: null
    }
  },

  computed: {
    columns () {
      return [
        {
          localizedTitle: this.SR_RealEstate['TaxAuthority'],
          typeName: 'String',
          propertyName: 'name'
        },
        {
          localizedTitle: 'UID',
          typeName: 'String',
          propertyName: 'uid',
          isIdColumn: true,
          invisible: true
        }
      ]
    }

  },

  methods: {
    setupModalContent (data = {uid: newGuid(), name: ''}) {
      return [
        {propertyName: 'uid', value: data.uid, label: '', required: false, editable: false},
        {propertyName: 'name', value: data.name, label: this.SR_RealEstate.TaxAuthority, required: true, editable: true}
      ]
    },

    updateEntity (entityId) {
      this.entityId = entityId
    },

    buildTaxAuthorityObject (data) {
      return {
        uid: data[0].value, // TODO: find better solution
        name: data[1].value,
        fkDataOwner: this.entityId
      }
    }

  },

  created () {
    this.$store.dispatch('i18n/preloadTranslations', [
      'Areas.RealEstate.SR_RealEstate'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
