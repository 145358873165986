<template lang="html">
  <div class="panel" :class="panelClass">
    <div v-if="title" class="panel-heading">
      <h3 class="panel-title">
        {{ title }}
      </h3>
    </div>
    <div class="panel-body">
      <slot />
    </div>
    <div v-if="$slots.footer" class="panel-footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['type', 'title'],
  computed: {
    panelClass () {
      if (!this.type || this.type === 'default') {
        return 'panel-default'
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="css">
</style>
