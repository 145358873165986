import shp from 'shpjs'
import Datum from 'proj4/lib/constants/Datum'

import {geoJsonFormat} from '@helpers/openlayers/features'
import {flatten} from '@helpers/reducers'

import {readAsText} from '@helpers/files'

Datum.deutsches_hauptdreiecksnetz = {
  datumName: 'Deutsches_Hauptdreiecksnetz',
  ellipse: 'bessel',
  towgs84: '598.1,73.7,418.2,0.202,0.045,-2.455,6.7'
}

const looksLikeShapeFile = fileGrouping => {
  // TODO check contents of zip for validity
  return (fileGrouping.type.toLowerCase() === 'zip') ||
    (fileGrouping.type.toLowerCase() === 'separate' && includesMandatoryShapeExtensions(fileGrouping))
}

export const canConvert = files => {
  if (files instanceof FileList) {
    files = Array.from(files)
  }

  const groupedFiles = groupFilesByName(files)

  return groupedFiles.length && groupedFiles.every(looksLikeShapeFile)
}

const groupFilesByName = files => {
  const separateFileLookup = {}
  const fileGroupings = []
  for (const file of files) {
    if (file.name.endsWith('.zip')) {
      fileGroupings.push({
        type: 'zip',
        name: file.name,
        file
      })
    } else {
      const nameParts = file.name.split('.')

      const nameWithoutExtension = nameParts.slice(0, nameParts.length - 1).join('.')
      const extension = nameParts[nameParts.length - 1]
      if (!(nameWithoutExtension in separateFileLookup)) {
        separateFileLookup[nameWithoutExtension] = []
      }
      separateFileLookup[nameWithoutExtension].push({
        extension,
        file
      })
    }
  }

  for (const name in separateFileLookup) {
    fileGroupings.push({
      type: 'separate',
      name,
      files: separateFileLookup[name]
    })
  }

  return fileGroupings
}

const readAsBuffer = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve({name: file.name, buffer: reader.result})
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}

const includesMandatoryShapeExtensions = fileGrouping => {
  const extensions = fileGrouping.files.map(x => x.extension)
  return extensions.filter(x => x.toLowerCase() === 'shp').length === 1 &&
    extensions.filter(x => x.toLowerCase() === 'dbf').length === 1
}

const loadShapes = shapeDescriptors => {
  return Promise.all(shapeDescriptors.map(fileGrouping => {
    switch (fileGrouping.type) {
    // returns FeatureCollection or Array<FeatureCollection>
    case 'zip': {
      // TODO check contents of zip for validity
      return readAsBuffer(fileGrouping.file).then(shp.parseZip)
    }
    // returns FeatureCollection
    case 'separate': {
      if (!includesMandatoryShapeExtensions(fileGrouping)) {
        return Promise.reject(new Error(`incorrect files for shape '${fileGrouping.name}'`))
      }
      const shpFile = fileGrouping.files.find(x => x.extension.toLowerCase() === 'shp').file
      const dbfFile = fileGrouping.files.find(x => x.extension.toLowerCase() === 'dbf').file
      const prj = fileGrouping.files.find(x => x.extension.toLowerCase() === 'prj')
      return Promise.all([
        Promise.all([
          readAsBuffer(shpFile),
          prj ? readAsText(prj.file) : Promise.resolve()
        ]).then(([shpBuffer, prj]) => shp.parseShp(shpBuffer, prj)),
        readAsBuffer(dbfFile).then(shp.parseDbf)
      ]).then(shp.combine)
    }
    }
  }))
}

const convertFeatures = geojson => {
  const readFeatures = x => geoJsonFormat.readFeatures(x, {dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857'})

  return Array.isArray(geojson)
    ? geojson.map(readFeatures).reduce(flatten, [])
    : readFeatures(geojson)
}

export default files => {
  if (files instanceof FileList) {
    files = Array.from(files)
  }

  const groupedFiles = groupFilesByName(files)

  return loadShapes(groupedFiles)
    .then(shapesAsGeoJson => shapesAsGeoJson.map(convertFeatures).reduce(flatten, []))
}
