{get, postJson} = require 'src/coffee/infrastructure/ajax'
{Binder} = require 'src/coffee/areas/real-estate/utils/binder'
newGuid = (require 'src/js/helpers/new-guid').default


class CommunityCreateController
    constructor: (@$view, @mediator) ->
        @loadDistrictsAction =  @$view.find('#actions').data('districts-load-action')
        @loadCreatedAction = @$view.find('#actions').data('load-created-action')
        @binder = new Binder(@$view)
        @setupControls()
        @entityId = null

    setupControls: ->
        @createBtn = @$view.find '#createBtn'
        .on 'click', => @create()
        @cancelBtn = @$view.find '#cancelBtn'
        .on 'click', => @cancel()

        @$isUrbanCommuneCheckbox = @$view.find '#is-district-free-city'
        @$isUrbanCommuneCheckbox.on 'change', (event) =>
            if event.currentTarget.checked
                $('#district-only-distric-free-cities-select').closest('.form-group').show()

                @binder.bindings['#district-without-distric-free-cities-select'].clearData()
                $('#district-without-distric-free-cities-select').closest('.form-group').hide()
            else
                @binder.bindings['#district-only-distric-free-cities-select'].clearData()
                $('#district-only-distric-free-cities-select').closest('.form-group').hide()
                $('#district-without-distric-free-cities-select').closest('.form-group').show()


        @binder.bindInput('#community-name', 'name')
        @binder.bindInput('#official-code', 'officialCode')

        @binder.bindAjaxSelectize('#district-only-distric-free-cities-select',
        'districtFreeCity',
        @loadDistrictsOnlyDistrictFreeCities,
        {
            searchField: ['regionName']
            allowEmptyOption: false
            render:
                option: @renderRegion
                item: @renderRegion
        })
        @binder.bindAjaxSelectize('#district-without-distric-free-cities-select',
        'district',
        @loadDistrictsWithoutDistrictFreeCities
        {
            allowEmptyOption: false
            render:
                option: @renderDistrictWithRegion
                item: @renderDistrictWithRegion
        })
        $('#district-only-distric-free-cities-select').closest('.form-group').hide()

    loadDistrictsWithoutDistrictFreeCities: (query, callback) =>
        get @loadDistrictsAction, {query: query; isDistrictFreeCity: false, orgUnitId:@entityId}
        .done (result) -> callback(result)
        return undefined

    loadDistrictsOnlyDistrictFreeCities: (query, callback) =>
        get @loadDistrictsAction, {query: query; isDistrictFreeCity: true, orgUnitId:@entityId }
        .done (result) -> callback(result)
        return undefined

    setName: (name) ->
        data =
            name: name
            district: null
        @binder.updateControls(data)

    setEntityId: (entityId) ->
        @entityId = entityId

    create: ->
        $modal = $('#create-community-view')
        unless $modal.find('form#create-community-form').valid()
            return
        @createBtn.off()
        @cancelBtn.off()

        data = @binder.readControls()
        if @$view.find('#is-district-free-city').prop('checked')
            fkDistrict = data.districtFreeCity.uid
        else
            fkDistrict = data.district.uid

        community =
            uid: newGuid()
            Name: data.name
            officialCode: data.officialCode
            FKDistrict: fkDistrict
            FKDataOwner: @entityId

        postJson 'Community/Create', community
        .done (createdCommunityId) =>
            get @loadCreatedAction, communityId: createdCommunityId
            .done (createdCommunity) => @mediator.raise event: 'CommunityCreated', data: createdCommunity
        $modal.modal('hide')

    cancel: ->
        @createBtn.off()
        @cancelBtn.off()
        @mediator.raise event: 'CreateCommunityCancelled'

    renderDistrictWithRegion: (data, escape) ->
        """
            <div>
                <span>#{escape(data.name)}</span>
                <small class='text-muted'>#{escape(data.regionName)}</small>
            </div>
        """

    renderRegion: (data, escape) ->
        """
            <div>
                <span>#{escape(data.regionName)}</span>
            </div>
        """

module.exports = {
    CommunityCreateController
}
module.exports.__esModule = true
module.exports.default = CommunityCreateController
