{register} = require 'src/js/infrastructure/setup-registry'
{Mediator} = require '../utils/mediator'
{commands} = require './commands'
{LandParcelController} = require './land-parcel-controller'

ViewSetup = (ctx) ->
    mediator = new Mediator()
    mediator.register commands
    view = ctx.find '#landparcel-view'
    ctrl = new LandParcelController view, mediator
    return ctrl

register('App.RealEstate.LandParcel.ViewSetup', ViewSetup)
