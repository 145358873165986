<template>
  <div v-if="ui.translationsLoaded">
    <BsModal
      v-model="ui.modalVisible" blocking
      :title="SR_RE_LandParcelCreateModal.CreateLandparcel" size="large"
      @hidden="hiddenHandler" @shown="shownHandler"
    >
      <div class="form" :disabled="ui.internalDisabled">
        <bs-panel :title="SR_RealEstate.General">
          <div class="row">
            <div class="col-sm-6">
              <vue-selectize-ajax
                ref="firstInput" v-model="lp.localDistrict"
                :label="SR_RealEstate.LocalDistrict" :disabled="ui.internalDisabled"
                ajax-url="/RealEstate/LocalDistricts" :additional-query-params="{entityId: orgUnitId}"
                :allow-empty="true" required
              />
            </div>
            <div class="col-sm-3">
              <text-input
                ref="t1" v-model="lp.cadastralDistrict"
                :label="SR_RealEstate.CadastralDistrict"
                required
              />
            </div>
            <div class="col-sm-3">
              <text-input
                ref="t2" v-model="lp.number"
                :label="SR_RealEstate.Number"
                required
              />
            </div>
          </div>

          <div class="row">
            <div class="col-sm-3">
              <numeric-input
                ref="n1" v-model="lp.size"
                :label="SR_RE_LandParcelCreateModal.Size"
                format="f4" addon="ha"
                required
              />
            </div>

            <div class="col-sm-6">
              <vue-selectize-ajax
                v-model="lp.lrCommunity" :label="SR_RE_LandParcelCreateModal.LandregisterCommunity"
                :disabled="ui.internalDisabled" :allow-empty="true"
                ajax-url="/RealEstate/Community" :additional-query-params="{entityId: orgUnitId, localDistrictId: lp.localDistrict ? lp.localDistrict.value : null}"
              />
            </div>

            <div class="col-sm-3">
              <text-input ref="t3" v-model="lp.lrPage" :label="SR_RE_LandParcelCreateModal.LandregisterPage" />
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <vue-selectize-ajax
                v-model="lp.owner" :label="SR_RealEstate.Owner"
                :disabled="ui.internalDisabled" :allow-empty="true"
                ajax-url="/RealEstate/Contacts/Selectize" :additional-query-params="{orgUnitId: orgUnitId, type: 'Other'}"
              />
            </div>

            <div class="col-sm-6">
              <text-input ref="t4" v-model="lp.remark" :label="SR_RealEstate.Remarks" />
            </div>
          </div>
        </bs-panel>

        <bs-panel :title="SR_RE_LandParcelCreateModal.Segments">
          <div class="row">
            <div class="col-sm-12">
              <dynamic-list
                v-model="lp.segments" :selector="() => segmentRow"
                :add-link-label="SR_RE_LandParcelCreateModal.AddNewSegment"
                :new-item-builder="makeNewSegment"
              >
                <div slot="header" class="row">
                  <div class="col-sm-6">
                    <label><IxRes :resource="SR_RealEstate">Usage</IxRes></label>
                  </div>

                  <div class="col-sm-3">
                    <label><IxRes :resource="SR_RealEstate">Groundpoints</IxRes></label>
                  </div>

                  <div class="col-sm-3">
                    <label><IxRes :resource="SR_RealEstate">Size</IxRes></label>
                  </div>
                </div>
              </dynamic-list>
            </div>
          </div>
        </bs-panel>

        <transition name="fade">
          <bs-panel v-if="validationErrors.length > 0" :title="SR_RE_LandParcelCreateModal.Errors">
            <transition-group tag="ul">
              <li v-for="message in validationErrors" :key="message" class="text-danger">
                {{ message }}
              </li>
            </transition-group>
          </bs-panel>
        </transition>
      </div>

      <button
        slot="footer" type="button"
        class="btn btn-success"
        :disabled="!isValid" @click="submit"
      >
        <IxRes :resource="SR_RE_LandParcelCreateModal">
          CreateLandparcel
        </IxRes>
      </button>
      <button
        slot="footer" type="button"
        class="btn btn-default"
        @click="cancel"
      >
        <IxRes :resource="SR_Common">
          Cancel
        </IxRes>
      </button>
    </BsModal>
  </div>
  <!-- <IxRes :resource="SR_RealEstate_Lease">AreaCreateNewLandparcel</IxRes> -->
</template>

<script>

import {get, postJson} from 'src/coffee/infrastructure/ajax'

import BsModal from 'src/vue/components/bootstrap/BsModal.vue'
import BsPanel from 'src/vue/components/bootstrap/BsPanel.vue'

import TextInput from 'src/vue/components/forms/TextInput'
import NumericInput from 'src/vue/components/forms/NumericInput'
import vueSelectizeAjax from 'src/vue/areas/real-estate/components/forms/SelectizeAjaxWrapped.vue'
import dynamicList from './dynamic-list'
import segmentRow from './segment-row'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_RE_LandParcelCreateModal: 'Areas.RealEstate.SR_RE_LandParcelCreateModal'
}

let counter = 0
let listcounter = 0

const newSegment = () => {
  return {
    listkey: listcounter++,
    usage: null,
    groundpoints: null,
    area: null,
    selectedInResult: true
  }
}

const newLandParcel = () => {
  return {
    localDistrict: null,
    cadastralDistrict: null,
    number: null,
    size: null,
    owner: null,
    lrCommunity: null,
    lrPage: null,
    remark: '',
    segments: [newSegment()]
  }
}

const initialValidationState = () => {
  return {
    lpDuplicationStatus: 'none'
  }
}

export default {
  components: {
    BsModal,
    TextInput,
    NumericInput,
    vueSelectizeAjax,
    dynamicList,
    BsPanel
  },
  mixins: [
    makeResourceMixin(resources)
  ],
  props: {
    orgUnitId: {
      type: String,
      required: true
    },
    allowSelectionForResult: Boolean
  },
  data () {
    return {
      lp: newLandParcel(),
      validation: initialValidationState(),
      ui: {
        id: `lp-create-modal-${counter++}`,
        modalVisible: false,
        internalDisabled: false,
        translationsLoaded: false
      }
    }
  },
  computed: {

    segmentRow () {
      return segmentRow
    },

    validationErrors () {
      return [
        this.lpDuplicationMessage,
        this.hasDuplicatedSegments ? this.SR_RE_LandParcelCreateModal.SegmentDuplicationMessage : null,
        this.unassignedLpSize ? this.SR_RE_LandParcelCreateModal.SegmentSumSizeErrorMessage + ' ' + this.$i18n.format(this.unassignedLpSize, 'f4') + ' ha.' : null
      ].filter(x => !!x)
    },

    lpDuplicationMessage () {
      if (this.validation.lpDuplicationStatus === 'error') {
        const lpIdentifier = `${this.lp.localDistrict.text}, ${this.lp.cadastralDistrict}, ${this.lp.number}`
        return this.SR_RE_LandParcelCreateModal.LandparcelDuplicationMessage.replace('/s1', lpIdentifier)
      } else {
        return null
      }
    },

    isValid () {
      return this.validationErrors.length === 0 && this.lp.localDistrict && this.lp.cadastralDistrict && this.lp.number && this.lp.size
      // return this.lp.localDistrict && this.lp.localDistrict.value
      // && this.lp.cadastralDistrict && this.lp.cadastralDistrict.value
      // && this.lp.number && this.lp.number != ''
      // && this.lp.size && this.lp.size > 0
      // && (this.lp.owner == null || this.lp.owner.value)
      // && ((this.lp.lrCommunity == null && this.lp.lrPage == null) || (this.lp.lrCommunity.value && this.lp.lrPage))
      // && this.segmentSizeSum === this.lp.size
      // && !this.hasDuplicatedSegments
      // && this.segments.every(
      //   s=> s.area > 0
      //   && s.groundpoints >= 0 && s.groundpoints <= 200
      //   && s.usage && s.usage.value)
    },

    segmentSizeSum () {
      return this.lp.segments.map(s => s.area).reduce((acc, val) => acc + val)
    },

    unassignedLpSize () {
      return this.lp.size - this.segmentSizeSum
    },

    hasDuplicatedSegments () {
      let hasDuplicates = false

      this.lp.segments.filter(s => s.usage && s.usage.value && s.groundpoints >= 0 && s.groundpoints <= 200)
        .reduce((acc, s) => {
          const propName = `${s.usage.value}_${s.groundpoints}`
          if (acc.hasOwnProperty(propName)) {
            acc[propName] += 1
            hasDuplicates = true
          } else {
            acc[`${s.usage.value}_${s.groundpoints}`] = 1
          }
          return acc
        }, {})

      return hasDuplicates
    }
  },

  methods: {
    reset () {
      this.lp = newLandParcel()
      this.validation = initialValidationState()
      this.ui.internalDisabled = false

      for (const ref in this.$refs) {
        if (this.$refs[ref].dirty) {
          this.$refs[ref].dirty = false
        }
      }
    },

    // call this from external to show the modal
    // returns a promise that resolves when the user is finished with
    // the modal and any potential server actions have finished
    show () {
      this.reset()
      return new Promise(function (resolve, reject) {
        this.resolve = resolve
        this.reject = reject
        this.ui.modalVisible = true
      }.bind(this))
    },

    submit () {
      this.ui.internalDisabled = true

      const lp = {
        ...this.lp,
        orgUnitId: this.orgUnitId
      }

      postJson('/RealEstate/LandParcel/CreateFromModal', lp)
        .then(
          this.handleSaved,
          this.handleError)
    },

    handleSaved (result) {
      this.ui.modalVisible = false
      this.resolve(result)
    },

    handleError () {
      this.ui.internalDisabled = false
    },

    shownHandler () {
      this.$refs.firstInput.focus()
    },

    hiddenHandler () {
      this.reject()
    },

    cancel () {
      this.ui.modalVisible = false
      this.reject()
    },

    validateLandparcelId () {
      if (this.lp.localDistrict && this.lp.localDistrict.value && this.lp.cadastralDistrict && this.lp.number) {
        this.validation.lpDuplicationStatus = 'working'

        get('/RealEstate/LandParcel/CheckForExistingLandparcel', {
          localDistrictId: this.lp.localDistrict.value,
          cadastralDistrict: this.lp.cadastralDistrict,
          number: this.lp.number,
          entityId: this.orgUnitId
        })
          .done((result) => {
            this.validation.lpDuplicationStatus = result ? 'error' : 'success'
          })
      } else {
        this.validation.lpDuplicationStatus = 'none'
      }
    },

    makeNewSegment () {
      const seg = newSegment()
      seg.area = this.unassignedLpSize
      return seg
    }
  },

  watch: {
    'lp.number': function () {
      this.validateLandparcelId()
    },
    'lp.cadastralDistrict': function () {
      this.validateLandparcelId()
    },
    'lp.localDistrict': function () {
      this.validateLandparcelId()
    }
  },

  created () {
    this.$store.dispatch('i18n/preloadTranslations', [
      'Common.SR_Common',
      'Areas.RealEstate.SR_RealEstate',
      'Areas.RealEstate.SR_RE_LandParcelCreateModal'
    ])
      .then(() => {
        this.ui.translationsLoaded = true
      })
  }
}
</script>

<style scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity .25s
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

</style>
