<template lang="html">
  <ObjectPicker
    :value="value"
    load-url="/RealEstate/Community"
    :label="label||'Gemeinde'"
    modal-title="Gemeinde erfassen"
    :open-function="populateModal"
    :confirm-function="createOnServer"
    :additional-query-params="{localDistrictId: localDistrictId, entityId: entityId}"
    @input="onInputCommunity"
  >
    <VueRow>
      <VueElement width="6">
        <VueCheckBox v-model="showFreeCityDistricts" label="Kreisfreie Stadt" @input="resetSelectedDistrict" />
      </VueElement>
    </VueRow>
    <VueRow>
      <VueElement width="6">
        <template v-if="!showFreeCityDistricts">
          <div>
            <label class="control-label">Landkreis</label>
            <VueSelectize
              ref="focusDistrict" v-model="selectedDistrict"
              :options="districts"
              :allow-empty="true" @type="onTypeDistricts"
            />
          </div>
        </template>
        <template v-else>
          <div>
            <label class="control-label">Bundesland</label>
            <VueSelectize
              ref="focusDistrict" v-model="selectedDistrict"
              :options="freeCities"
              :allow-empty="true"
            />
          </div>
        </template>
      </VueElement>
    </VueRow>
    <VueRow>
      <VueElement width="6">
        <VueInput ref="focusName" v-model="communityName" label="Name" />
        <VueInput ref="focusOfficialCode" v-model="officialCode" label="Gemeindeschlüssel" />
      </VueElement>
    </VueRow>
  </ObjectPicker>
</template>

<script>

import {postJson, get} from 'src/coffee/infrastructure/ajax'
import newGuid from 'src/js/helpers/new-guid'
import VueSelectize from 'src/vue/components/VueSelectize'
import VueInput from 'src/vue/areas/real-estate/components/forms/vue-input'
import VueRow from 'src/vue/areas/real-estate/components/forms/vue-row'
import VueElement from 'src/vue/areas/real-estate/components/forms/vue-element'
import VueCheckBox from 'src/vue/areas/real-estate/components/forms/vue-checkbox'
import ObjectPicker from 'src/vue/areas/real-estate/components/object-picker'

export default {
  components: {
    VueRow,
    VueElement,
    VueInput,
    VueSelectize,
    VueCheckBox,
    ObjectPicker
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    localDistrictId: String,
    label: String,
    entityId: String
  },
  data () {
    return {
      selectedDistrict: null,
      communityName: '',
      officialCode: '',
      showFreeCityDistricts: false,
      checkboxId: newGuid(),
      freeCities: [],
      districts: []
    }
  },
  methods: {
    onInputCommunity (value) {
      this.$emit('input', value)
    },
    onTypeDistricts (str) {
      get('/RealEstate/District/GetDistrictsForPicker', {query: str, entityId: this.entityId})
        .done((result) => {
          this.districts = result
        })
    },
    resetSelectedDistrict () {
      this.selectedDistrict = null
    },
    populateModal (input) {
      this.selectedDistrict = null
      this.officialCode = ''
      this.communityName = input
      this.showFreeCityDistricts = false

      return this.$refs.focusDistrict
      // TODO:implement handling below and remove this return here

      // if(this.localDistrictId)
      // {
      //   //TODO: do the loading
      //   return this.$refs.focusName
      // }
      // else {
      //   return this.$refs.focusDistrict
      // }
    },
    createOnServer () {
      return new Promise((resolve) => {
        postJson('Community/Create', {
          uid: newGuid(),
          Name: this.communityName,
          officialCode: this.officialCode,
          FKDistrict: this.selectedDistrict.value,
          FKDataOwner: this.entityId
        })
          .then((createdCommunityId) => {
            let getUrl = 'Community/GetCommunityWithDistrict'
            get(getUrl, {communityId: createdCommunityId})
              .then((c) => {
                resolve({
                  text: c.name,
                  label: `${c.districtName}, ${c.regionName}`,
                  value: c.uid
                })
              })
          })
      })
    }
  },
  mounted: function () {
    get('/RealEstate/District/GetFreeCitiesForPicker', {entityId: this.entityId})
      .done((result) => {
        this.freeCities = result
      })
  }
}
</script>

<style lang="css">
</style>
