<template>
  <div class="form row">
    <div class="col-sm-12">
      <selectize
        v-model="value.bank" :label="SR_ModelProperties.Bank"
        :options="banks"
        :allow-empty="true"
        name="bank"
      />
    </div>
    <div class="col-sm-12">
      <text-input v-model="value.iban" :label="SR_ModelProperties.IBAN" />
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import TextInput from 'src/vue/components/forms/TextInput'
import Selectize from 'src/vue/areas/real-estate/components/forms/Selectize'
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

const resources = {
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    TextInput,
    Selectize
  },
  mixins: [makeResourceMixin(resources)],
  props: ['value'],
  computed: {
    ...mapGetters('realEstateMasterDataContacts/bankingAccounts',
      [
        'banks'
      ])
  }
}
</script>
<style>
</style>
