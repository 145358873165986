require './contracts/purchase'
require './visualization/visualization'
require './land-parcel/land-parcel-startup'
require './property/property-startup'
require './facility/facility-startup'
require './masterdata/masterdata-setups'
require './report/report-setups'

{register} = require 'src/js/infrastructure/setup-registry'
{mount} = require 'src/js/helpers/vue'

store = require('src/js/store').default

store.dispatch('i18n/preloadTranslations', [
                'Messages.Info.SR_InfoMessages', 
                'Common.SR_Common'])

contractLeaseSetup = ($ctx) ->
    el = $ctx.find('#mount')[0]
    leaseComponent = require('src/vue/areas/real-estate/contract/lease').default

    store =  require('src/js/store').default
    orgUnitId = el.attributes['data-orgunit-id'].value
    contractId = el.attributes['data-contract-id'].value

    mount el, leaseComponent

    if contractId and orgUnitId
        store.dispatch('realEstateContractLease/initRequest', {orgUnitId, contractId})

register('real-estate/contract/lease', contractLeaseSetup)

contractLeaseAppointmentSetup = ($ctx) ->
    el = $ctx.find('#mount')[0]
    leaseAppointmentComponent = require('src/vue/areas/real-estate/contract/appointment').default

    entityId = $ctx.find('#entityId')[0]?.innerHTML
    startOfPeriod = $ctx.find('#startOfPeriod')[0]?.innerHTML
    endOfPeriod = $ctx.find('#endOfPeriod')[0]?.innerHTML

    if(entityId && startOfPeriod && endOfPeriod)
        leaseAppointmentComponent.propsData = {dataFromNotification: true, initialEntityId: entityId, initialStart: startOfPeriod, initialEnd: endOfPeriod}

    vm = mount(el, leaseAppointmentComponent)


contractLeasePaymentsSetup = ($ctx) ->
    el = $ctx.find('#mount')[0]
    paymentComponent = require('src/vue/areas/real-estate/contract/payments').default
    mount el, paymentComponent

register('real-estate/contract/lease-appointment', contractLeaseAppointmentSetup)
register('real-estate/contract/lease-payments', contractLeasePaymentsSetup)

leaseAppointmentNotification = ($ctx) ->
    el = $ctx.find('#mount')[0]
    notificationComponent = require('src/vue/areas/real-estate/LeaseAppointmentNotification').default
    mount el, notificationComponent

register('real-estate/lease-appointment-notification', leaseAppointmentNotification)