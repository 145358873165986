{Status} = require './status'

class Stati
    constructor: (view, idList...) ->
        @trackers = {}
        for id in idList
            @trackers[id] = new Status(view.find('a[href="#' + id + '"]>span[class~="fa"]'))

    changeStatus: (args) ->
        (status.set args.to if args.from is undefined or status.is args.from) for tab, status of @trackers

    hasAnyChanges: ->
        (tab for tab, status of @trackers when status.is 'changed').length > 0

    hasAnyInvalidValidations: ->
        (tab for tab, status of @trackers when status.is 'invalid').length > 0

    setTabStatus: (tab, status) ->
        @trackers[tab].set(status)
    tab: (tabName) ->
        @trackers[tabName]

module.exports = {
    Stati
}

module.exports.__esModule = true
module.exports.default = Stati
