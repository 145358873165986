{register} = require 'src/js/infrastructure/setup-registry'
{mount} = require 'src/js/helpers/vue'

makeAndRegisterSetup = (setupSpec, component) ->
    setup = ($ctx) ->
        el = $ctx.find('#mount')[0]
        mount el, component
    register setupSpec, setup


makeAndRegisterSetup('real-estate/masterdata/bank',
  require('src/vue/areas/real-estate/masterdata/bank').default)

makeAndRegisterSetup('real-estate/masterdata/beneficiary',
  require('src/vue/areas/real-estate/masterdata/beneficiary').default)

makeAndRegisterSetup('real-estate/masterdata/building-usage',
  require('src/vue/areas/real-estate/masterdata/building-usage').default)

makeAndRegisterSetup('real-estate/masterdata/business-object',
  require('src/vue/areas/real-estate/masterdata/business-object').default)

makeAndRegisterSetup('real-estate/masterdata/community',
  require('src/vue/areas/real-estate/masterdata/community').default)

makeAndRegisterSetup('real-estate/masterdata/contacts',
  require('src/vue/areas/real-estate/masterdata/contact/contacts').default)

makeAndRegisterSetup('real-estate/masterdata/masterdata-country',
  require('src/vue/areas/real-estate/masterdata/country').default)

makeAndRegisterSetup('real-estate/masterdata/currency',
  require('src/vue/areas/real-estate/masterdata/currency').default)

makeAndRegisterSetup('real-estate/masterdata/district',
  require('src/vue/areas/real-estate/masterdata/district').default)

makeAndRegisterSetup('real-estate/masterdata/easement-type',
  require('src/vue/areas/real-estate/masterdata/easement-type').default)

makeAndRegisterSetup('real-estate/masterdata/facility-part-type',
  require('src/vue/areas/real-estate/masterdata/facility-part-type').default)

makeAndRegisterSetup('real-estate/masterdata/functional-zoning-category',
  require('src/vue/areas/real-estate/masterdata/functional-zoning-category').default)

makeAndRegisterSetup('real-estate/masterdata/loan-number',
  require('src/vue/areas/real-estate/masterdata/loan-number').default)

makeAndRegisterSetup('real-estate/masterdata/dms-reference-type',
  require('src/vue/areas/real-estate/masterdata/dms-reference-type').default)

makeAndRegisterSetup('real-estate/masterdata/payment-modes',
  require('src/vue/areas/real-estate/masterdata/payment-modes').default)

makeAndRegisterSetup('real-estate/masterdata/property-type',
  require('src/vue/areas/real-estate/masterdata/property-type').default)

makeAndRegisterSetup('real-estate/masterdata/region-filter',
  require('src/vue/areas/real-estate/masterdata/region-filter').default)

makeAndRegisterSetup('real-estate/masterdata/tax-authority',
  require('src/vue/areas/real-estate/masterdata/tax-authority').default)

makeAndRegisterSetup('real-estate/masterdata/usage-type',
  require('src/vue/areas/real-estate/masterdata/usage-type').default)

makeAndRegisterSetup('real-estate/masterdata/local-district',
  require('src/vue/areas/real-estate/masterdata/local-district').default)

makeAndRegisterSetup('real-estate/masterdata/cadastral-district',
  require('src/vue/areas/real-estate/masterdata/cadastral-district').default)
