<template lang="html">
  <treeLayout>
    <tree
      slot="tree" :nodes="treeNodes"
      :value="selectedEntity"
      @input="updateEntityData"
    />
    <div v-if="translationsLoaded">
      <grid
        ref="regionFilterGrid" v-model="selectedRegionFilter"
        :data="regionFilterGridData" :columns="regionFilterColumns"
        selection="single" :title="SR_RealEstate.RegionFilter"
        paging :add-button="isOrgUnitSelected"
        :edit-button="isRegionFilterSelected" :delete-button="isRegionFilterSelected"
        @add="openRegionFilterModal(true)" @edit="openRegionFilterModal(false)"
        @delete="prepareAndShowConfirmationModal(true);"
      />
      <div v-if="isRegionFilterSelected">
        <grid
          ref="localDistrictGrid" v-model="selectedLocalDistrict"
          :data="localDistrictsForRegionFilter" :columns="localDistrictColumns"
          selection="single"
          :title="SR_RealEstate.LocalDistrictsForRegionFilter" paging
          :add-button="isRegionFilterSelected" :delete-button="isLocalDistrictSelected"
          @add="showLocalDistrictModal=true" @delete="prepareAndShowConfirmationModal(false);"
        />
      </div>
    </div>

    <modal :title="regionFilterModalTitle" :value="showRegionFilterModal" blocking>
      <div class="form-horizontal">
        <div class="row">
          <label for="input" class="col-md-3 control-label">
            <p>{{ SR_RealEstate.RegionFilterName }}</p>
          </label>
          <div class="col-md-8">
            <div class="form-group">
              <input
                ref="regionFilterNameInput" v-model="regionFilterName"
                type="text"
                class="form-control"
              >
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="col-md-offset-6 col-md-3">
            <input
              type="button" class="btn btn-success btn-block"
              :value="SR_Common.Apply"
              :disabled="regionFilterInputEmpty" @click="applyRegionFilterModal"
            >
          </div>
          <div class="col-md-3">
            <input
              type="button" class="btn btn-danger btn-block"
              :value="SR_Common.Cancel"
              @click="showRegionFilterModal=false"
            >
          </div>
        </div>
      </div>
    </modal>

    <modal :title="SR_RealEstate.AddLocalDistrict" :value="showLocalDistrictModal" blocking>
      <div class="form-horizontal">
        <div class="row">
          <label for="input" class="col-md-3 control-label">
            <p>{{ SR_RealEstate.LocalDistrictName }}</p>
          </label>
          <vue-selectize
            id="input" ref="localDistrictSelectize"
            v-model="localDistrictModalSelection"
            type="text" :options="allLocalDistricts"
            class="col-md-9" :allow-empty="true"
          />
        </div>

        <div class="form-group">
          <div class="col-md-offset-6 col-md-3">
            <input
              type="button" class="btn btn-success btn-block"
              :value="SR_Common.Apply"
              :disabled="localDistrictInputEmpty" @click="applyLocalDistrictModal"
            >
          </div>
          <div class="col-md-3">
            <input
              type="button" class="btn btn-danger btn-block"
              :value="SR_Common.Cancel"
              @click="showLocalDistrictModal=false"
            >
          </div>
        </div>
      </div>
    </modal>

    <div class="bigbuttons">
      <ConfirmationModal
        :title="SR_RealEstate.RegionFilterRemoveTitle" :value="showConfirmationModal"
        @confirm="deleteSelectedElement"
        @cancel="showConfirmationModal=false"
      >
        <p> {{ SR_RealEstate.RegionFilterRemoveText }} </p>
      </ConfirmationModal>
    </div>
  </treeLayout>
</template>

<script lang="js">
import treeLayout from 'src/vue/legacy-compat/TreeLayout'
import tree from '@components/nav/Tree'
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'
import grid from 'src/vue/areas/real-estate/components/grid/RELegacyGrid'
import {get, postJson} from 'src/coffee/infrastructure/ajax'
import VueSelectize from 'src/vue/components/VueSelectize'
import ConfirmationModal from '@components/modals/ConfirmationModal'
import modal from 'src/vue/components/bootstrap/BsModal'
import {success, error} from 'src/coffee/infrastructure/ixmap/notifications'
import Vue from 'vue'
import newGuid from 'src/js/helpers/new-guid'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_InfoMessages: 'Messages.Info.SR_InfoMessages',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate'
}

export default {
  components: {
    treeLayout,
    grid,
    tree,
    VueSelectize,
    ConfirmationModal,
    modal
  },
  mixins: [makeResourceMixin(resources)],

  data () {
    return {
      translationsLoaded: false,
      regionFilterGridData: [],
      localDistrictsForRegionFilter: [],
      selectedRegionFilter: null,
      selectedLocalDistrict: null,
      modalTitle: null,
      showRegionFilterModal: false,
      showConfirmationModal: false,
      showLocalDistrictModal: false,
      currentSelectedFilterUid: null,
      treeNodes: [],
      selectedEntity: null,
      fkDataOwner: null,
      allLocalDistricts: [],
      regionFilterModalTitle: null,
      regionFilterName: null,
      regionFilterModalEditMode: false,
      localDistrictModalSelection: null,
      deleteElementRequestUrl: null,
      objectForRequest: null,
      reloadGridDataFunction: null
    }
  },

  computed: {
    regionFilterColumns () {
      return [
        {
          localizedTitle: this.SR_RealEstate['RegionFilter'],
          typeName: 'String',
          propertyName: 'regionFilterName'
        },

        {
          localizedTitle: 'UID',
          propertyName: 'regionFilterId',
          isIdColumn: true,
          invisible: true
        }
      ]
    },

    localDistrictColumns () {
      return [
        {
          localizedTitle: this.SR_RealEstate['LocalDistrictsForRegionFilter'],
          typeName: 'String',
          propertyName: 'name'
        },
        {
          localizedTitle: 'UID',
          propertyName: 'uid',
          isIdColumn: true,
          invisible: true
        }
      ]
    },

    isRegionFilterSelected () {
      return this.selectedRegionFilter && this.selectedRegionFilter.length > 0
    },

    isLocalDistrictSelected () {
      return this.selectedLocalDistrict && this.selectedLocalDistrict.length > 0
    },

    isOrgUnitSelected () {
      return !!this.selectedEntity
    },

    regionFilterInputEmpty () {
      return !(this.regionFilterName && this.regionFilterName.length > 0)
    },

    localDistrictInputEmpty () {
      return !(this.localDistrictModalSelection && this.localDistrictModalSelection.text.length > 0)
    }
  },

  methods: {
    loadEntities () {
      get('/RealEstate/Entity/GetTenants')
        .then((result) => {
          this.treeNodes = result
        })
    },

    loadRegionFilters () {
      get('/RealEstate/MasterDataRegionFilter/GetRegionFilters', {entityId: this.selectedEntity.uid})
        .then((result) => {
          this.regionFilterGridData = result

          if (this.currentSelectedFilterUid) { // reselect currently selected value
            Vue.nextTick(() => {
              this.$refs.regionFilterGrid.selectRow(this.currentSelectedFilterUid)
            })
          }
        })
    },

    loadLocalDistricts () {
      get('/RealEstate/MasterDataRegionFilter/GetLocalDistrictsForFilter', {entityId: this.selectedEntity.uid, regionFilterUid: this.currentSelectedFilterUid})
        .then((result) => {
          this.localDistrictsForRegionFilter = result
        })
    },

    updateEntityData (entity) {
      this.regionFilterGridData = []
      this.localDistrictsForRegionFilter = []
      this.selectedEntity = entity
      this.loadRegionFilters()
      this.loadLocalDistrictsForEntity()
    },

    loadLocalDistrictsForEntity () {
      get('/RealEstate/MasterDataRegionFilter/GetAllLocalDistricts', {entityId: this.selectedEntity.uid})
        .then((result) => {
          this.allLocalDistricts = result
        })
    },

    openRegionFilterModal (add) {
      if (add) {
        this.regionFilterName = ''
        this.regionFilterModalTitle = this.SR_RealEstate.AddRegionFilter
        this.regionFilterModalEditMode = false
      } else {
        this.regionFilterModalTitle = this.SR_RealEstate.EditRegionFilter
        this.regionFilterModalEditMode = true
      }
      this.showRegionFilterModal = true
    },

    applyRegionFilterModal () {
      this.showRegionFilterModal = false
      if (this.regionFilterModalEditMode) {
        const regionFilter = {regionFilterId: this.currentSelectedFilterUid, regionFilterName: this.regionFilterName, fkDataOwner: this.selectedEntity.uid}
        this.saveRegionFilter('/RealEstate/MasterDataRegionFilter/Update', regionFilter)
      } else {
        const filterUid = newGuid()
        const regionFilter = {regionFilterId: filterUid, regionFilterName: this.regionFilterName, fkDataOwner: this.selectedEntity.uid}
        this.saveRegionFilter('/RealEstate/MasterDataRegionFilter/Create', regionFilter)
      }
    },

    saveRegionFilter (url, regionFilterObject) {
      postJson(url, regionFilterObject)
        .then(() => {
          success(this.SR_InfoMessages.SaveSuccessful)
          this.currentSelectedFilterUid = regionFilterObject.regionFilterId
          this.loadRegionFilters()
        })
        .fail((jqXHR) => {
          error(jqXHR.statusText)
        })
    },

    applyLocalDistrictModal () {
      this.showLocalDistrictModal = false
      const regionFilterXLocalDistrict = {fKRegionFilter: this.currentSelectedFilterUid, fkLocalDistrict: this.localDistrictModalSelection.value}

      postJson('/RealEstate/MasterDataRegionFilter/AddLocalDistrictToRegionFilter', regionFilterXLocalDistrict)
        .then(() => {
          success(this.SR_InfoMessages.SaveSuccessful)
          this.localDistrictModalSelection = null
          this.loadLocalDistricts()
        })
        .fail((jqXHR) => {
          error(jqXHR.statusText)
        })
    },

    prepareAndShowConfirmationModal (deleteRegionFilter) {
      if (deleteRegionFilter) {
        this.deleteElementRequestUrl = '/RealEstate/MasterDataRegionFilter/Delete'
        this.objectForRequest = this.currentSelectedFilterUid
        this.reloadGridDataFunction = this.loadRegionFilters
      } else { // remove LocalDistrict
        this.deleteElementRequestUrl = '/RealEstate/MasterDataRegionFilter/RemoveLocalDistrictFromRegionFilter'
        this.objectForRequest = {fKRegionFilter: this.currentSelectedFilterUid, fkLocalDistrict: this.selectedLocalDistrict[0].uid}
        this.reloadGridDataFunction = this.loadLocalDistricts
      }
      this.showConfirmationModal = true
    },

    deleteSelectedElement () {
      postJson(this.deleteElementRequestUrl, this.objectForRequest)
        .then(() => {
          success(this.SR_InfoMessages.DeleteSuccess)
          if (this.currentSelectedFilterUid === this.objectForRequest) {
            this.selectedRegionFilter = null
          }
          this.selectedLocalDistrict = null
          this.reloadGridDataFunction.call()
        })
        .fail((jqXHR) => {
          error(jqXHR.statusText)
        })
      this.showConfirmationModal = false
    }
  },

  watch: {
    selectedRegionFilter () {
      if (this.selectedRegionFilter && this.selectedRegionFilter[0]) {
        this.selectedLocalDistrict = null
        this.currentSelectedFilterUid = this.selectedRegionFilter[0].regionFilterId
        this.regionFilterName = this.selectedRegionFilter[0].regionFilterName
        this.loadLocalDistricts()
      }
    },

    selectedEntity () {
      this.selectedLocalDistrict = null
      this.selectedRegionFilter = null
    }
  },

  mounted () {
    this.loadEntities()
  },

  created () {
    this.$store.dispatch('i18n/preloadTranslations', [
      'Areas.RealEstate.SR_RealEstate',
      'Messages.Info.SR_InfoMessages',
      'Common.SR_Common'
    ])
      .then(() => {
        this.translationsLoaded = true
      })
  }
}
</script>

<style>
    .bigbuttons .footer{
        display: block;
    }
    .bigbuttons .btn {
        display: block;
        width: 25%;
        float: left;
        font-size: 14px;
        padding: 6px 12px;
    }
    .bigbuttons .btn:first-child {
        background-color: green;
        color: white;
        margin-left: 275px;
    }
    .bigbuttons .btn:not(:first-child) {
        background-color: red;
        color: white;
    }
</style>
