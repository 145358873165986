class Confirmation
    constructor: (@view, @dialogId) ->
        @setup()

    show: (options) ->
        @confirmationDialog.modal('show')
        @confirmButton.off().on 'click', (event) -> options.onAccept()
        @dismissButton.off().on 'click', (event) -> options.onDismiss()
        @cancelButton.off().on 'click', (event) -> options.onCancel()

    setup: ->
        dialog = @view.find @dialogId
        @confirmationDialog = dialog.modal show: false
        @confirmButton = dialog.find '.confirmBtn'
        @dismissButton = dialog.find '.dismissBtn'
        @cancelButton = dialog.find '.cancelBtn'

module.exports = {
    Confirmation
}
