<template>
  <bs-modal
    :title="SR_RealEstate.CreateAmendmentTitle" :value="isEditAmendmentModalOpen"
    blocking
    @hidden="createOrEditAmendmentFailure"
  >
    <amendments-form id="amendments-form" v-model="editAmendment" />
    <button
      slot="footer" type="button"
      class="btn btn-success"
      @click="createOrEditAmendmentSuccess"
    >
      <IxRes :resource="SR_Common">
        Save
      </IxRes>
    </button>
    <button
      slot="footer" type="button"
      class="btn btn-default"
      @click="createOrEditAmendmentFailure"
    >
      <IxRes :resource="SR_Common">
        Cancel
      </IxRes>
    </button>
  </bs-modal>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import BsModal from 'src/vue/components/bootstrap/BsModal'
import AmendmentsForm from './lease-amendments-form'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    BsModal,
    AmendmentsForm
  },
  mixins: [makeResourceMixin(resources)],
  props: {
    value: null,
    name: {
      Type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('realEstateContractLease/amendments', [
      'isEditAmendmentModalOpen',
      'isDeleteAmendmentModalOpen',
      'editAmendment',
      'hasCurrentAmendment'])
  },
  methods: {
    ...mapActions('realEstateContractLease/amendments', [
      'createOrEditAmendmentSuccess',
      'createOrEditAmendmentFailure'])
  }
}
</script>
<style lang="scss" scoped>
</style>
