<template>
  <!-- <button type="button" class="list-group-item">
    {{ formatDate(amendment.start) }} - {{ formatDate(amendment.end)}}
  </button> -->
  <li class="list-group-item" :class="{active: selected}" @click="$emit('click')">
    {{ formatDate(amendment.validOn) }}
  </li>
</template>
<script>
import moment from 'moment'

export default {
  props: {
    amendment: null,
    selected: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {

    }
  },
  methods: {
    formatDate (value) {
      if (value) {
        return moment(value).format('LL')
      }
    }
  }
}
</script>
<style lang="scss">
</style>
