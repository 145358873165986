{Binder} = require 'src/coffee/areas/real-estate/utils/binder'

class AddressEditController
    constructor: ($modal, @addressModel) ->
        @$modal = $modal
        @submitHandlers = []

        @setupModal $modal

    setupModal: ($modal) =>
        @submitButton = $modal.find '#submit'
        @submitButton.on 'click', @handleSubmit

        @setupFields()

        @street.focus()

    setupFields: =>
        @modalBinder = new Binder(@$modal)

        @street = @modalBinder.bindInput('#street', 'street')
        @modalBinder.bindInput('#postal-code', 'postalCode')
        @modalBinder.bindInput('#city', 'city')

        @modalBinder.updateControls(@addressModel)

    onSubmit: (func) =>
        @submitHandlers.push(func)

    handleSubmit: =>
        newOrChangedAddress = @modalBinder.readControls()

        @submit newOrChangedAddress
        @$modal.modal('hide')

    submit: (result) =>
        for handler in @submitHandlers
            handler result

module.exports = {AddressEditController}

module.exports.__esModule = true
module.exports.default = AddressEditController
