<template lang="html">
  <div class="panel panel-default">
    <div class="panel-body">
      <div class="row">
        <div class="col-md-12">
          <h3>
            <IxRes :resource="SR_RealEstate">
              BankingAccounts
            </IxRes>
          </h3>
          <grid
            v-if="bankingAccountsColumnsTranslationsAreLoaded" v-model="selectedBankingAccounts"
            :data="bankingAccounts" :columns="bankingAccountsColumns"
            selection="single"
            paging minimal
            :add-button="!readOnly" :edit-button="hasCurrentBankingAccount"
            :delete-button="hasCurrentBankingAccount"
            @add="createRequest" @delete="deleteRequest"
            @edit="editRequest"
          />
        </div>
      </div>
    </div>

    <bs-modal :title="SR_RealEstate.CreateBankingAccountTitle" :value="isEditModalOpen">
      <banking-account-form v-model="editBankingAccount" />
      <button
        slot="footer" type="button"
        class="btn btn-success"
        :disabled="!bankingAccountInputsFilledOut" @click="createOrEditSuccess"
      >
        <IxRes :resource="SR_Common">
          Save
        </IxRes>
      </button>
      <button
        slot="footer" type="button"
        class="btn btn-default"
        @click="createOrEditFailure"
      >
        <IxRes :resource="SR_Common">
          Cancel
        </IxRes>
      </button>
    </bs-modal>

    <bs-modal :title="SR_RealEstate.DeleteBankingAccountTitle" :value="isDeleteModalOpen">
      <p>
        <IxRes :resource="SR_RealEstate">
          DeleteBankingAccount
        </IxRes>
      </p>
      <button
        slot="footer" type="button"
        class="btn btn-success"
        @click="deleteFailure"
      >
        <IxRes :resource="SR_Common">
          No
        </IxRes>
      </button>
      <button
        slot="footer" type="button"
        class="btn btn-default"
        @click="deleteSuccess"
      >
        <IxRes :resource="SR_Common">
          Yes
        </IxRes>
      </button>
    </bs-modal>
  </div>
</template>
<script>

import {mapGetters, mapActions} from 'vuex'
import Grid from 'src/vue/areas/real-estate/components/grid/RELegacyGrid.vue'
import BsModal from 'src/vue/components/bootstrap/BsModal'
import BankingAccountForm from './banking-account-form'
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    Grid,
    BsModal,
    BankingAccountForm
  },
  mixins: [makeResourceMixin(resources)],
  props: {
    value: null,
    readOnly: {
      type: Boolean,
      default: false
    },
    name: {
      Type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('realEstateMasterDataContacts/bankingAccounts', [
      'currentBankingAccount',
      'hasCurrentBankingAccount',
      'editBankingAccount',
      'isEditModalOpen',
      'isDeleteModalOpen'
    ]),
    bankingAccounts: {
      get () {
        return this.value && this.value.bankingAccounts ? this.value.bankingAccounts : []
      }
    },
    selectedBankingAccounts: {
      get () {
        return [this.currentBankingAccount]
      },
      set (value) {
        const bankingAccount = value && value.length > 0 ? value[0] : null
        this.selectBankingAccount(bankingAccount)
      }
    },
    bankingAccountsColumns () {
      return [
        {localizedTitle: this.getLocalizedTitleByResourceName('Bank'), typeName: 'String', propertyName: 'bank.text'},
        {localizedTitle: this.getLocalizedTitleByResourceName('IBAN'), typeName: 'String', propertyName: 'iban'}
      ]
    },
    bankingAccountsColumnsTranslationsAreLoaded () {
      return this.bankingAccountsColumns.every(c => !!c.localizedTitle)
    },
    bankingAccountInputsFilledOut () {
      return this.editBankingAccount.bank ? this.editBankingAccount.bank.text !== '' && this.editBankingAccount.iban !== '' : false
    }
  },
  methods: {
    ...mapActions('realEstateMasterDataContacts/bankingAccounts', [
      'selectBankingAccount',
      'createRequest',
      'editRequest',
      'createOrEditSuccess',
      'createOrEditFailure',
      'deleteRequest',
      'deleteSuccess',
      'deleteFailure']),
    getLocalizedTitleByResourceName (resourceName) {
      return this.SR_ModelProperties[resourceName] ? this.SR_ModelProperties[resourceName] : `<<${resourceName}>>`
    }
  }
}
</script>

<style lang="css">
</style>
