<template>
  <div class="col-sm-12">
    <vue-selectize
      v-if="filter.type == 'SelectedSet' && filter.options"
      multiple
      :options="filter.options"
      :label="filter.name"
      :value="value ? value : []"
      :allow-empty="true"
      @input="$emit('input', $event)"
    />

    <selectize-ajax
      v-if="filter.type == 'SelectedSet' && filter.optionsUrl"
      multiple
      :label="filter.name"
      :ajax-url="filter.optionsUrl"
      :additional-query-params="buildFilterPayload(filter)"
      :value="value ? value : []"
      :allow-empty="true"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>

import vueSelectize from 'src/vue/components/VueSelectize'
import SelectizeAjax from 'src/vue/areas/real-estate/components/forms/SelectizeAjaxWrapped'

export default {
  components: {
    vueSelectize,
    SelectizeAjax
  },

  props: {
    filter: Object,
    value: [Object, Array],
    selectedOrgUnit: Object
  },

  methods: {
    buildFilterPayload (filter) {
      const payload = {
        ...filter.additionalQueryParams
      }

      if (filter.orgUnitParameterName) {
        payload[filter.orgUnitParameterName] = this.selectedOrgUnit.uid
      }

      return payload
    }
  }

}
</script>

<style scoped>

</style>
