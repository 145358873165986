<template lang="html">
  <div>
    <label v-if="hasLabel" :for="identifier" class="control-label">{{ label }}</label>
    <textarea
      :id="identifier" class="form-control"
      :value="value"
      :rows="sanitizedRows" @input="onInput"
    />
  </div>
</template>

<script>

import newGuid from 'src/js/helpers/new-guid'

export default {
  props: ['label', 'value', 'rows'],
  data () {
    return {
      identifier: newGuid()
    }
  },
  computed: {
    hasLabel () {
      return !!this.label
    },
    sanitizedRows () {
      return this.rows || 4
    }
  },
  methods: {
    onInput ($event) {
      this.$emit('input', $event.target.value)
    }
  }
}
</script>

<style lang="css">
</style>
