{notifications} = require 'src/js/infrastructure'
{post} = require 'src/coffee/infrastructure/ajax'
{makeErrorHandler} = require 'src/coffee/helpers/async/notifications'

treeUtility = require 'src/coffee/controls/tree/helpers'

{FacilityDetailController} = require './facility-detail-controller'
{noop} = require 'src/coffee/areas/real-estate/utils'


class Confirmation
    constructor: (@view) ->
        @setup()

    show: (options) ->
        @confirmationDialog.modal('show')
        @confirmButton.off().on 'click', (event) -> options.onAccept()
        @dismissButton.off().on 'click', (event) -> options.onDismiss()
        @cancelButton.off().on 'click', (event) -> options.onCancel()

    setup: ->
        @confirmationDialog = @view.find '#confirmationDialog'
                                .modal show: false
        @confirmButton = @view.find '#confirmBtn'
        @dismissButton = @view.find '#dismissBtn'
        @cancelButton = @view.find '#cancelBtn'



class FacilityController
    constructor: (@view) ->
        @grid = @view.find '#facility-grid'
            .staticgrid()[0]
        @grid.editControls.add.$button.on 'click', (event) =>
            @details.create @uid
            @grid.table.rows().deselect()
            event.preventDefault()


        @selectedRowId = ''
        @setupPayload()
        @setupFacilityTable()
        @setupTree()
        @details = new FacilityDetailController(@view, @onDetailsChanged)
        @confirmation = new Confirmation @view
        @setupGridDelete()

        @executePreselection()
 
    executePreselection: ->
        div = $('#preselection')
        if div.length == 0
            return
 
        entityId = div.data('entity')
        itemId = div.data('item')
 
        @grid.table.one('draw.dt', => @grid.selectRow(itemId))
        treeUtility.selectNode(entityId)

    setupPayload: ->
        @grid.options.ajax.payload = => {Id: @uid}


    setupFacilityTable: ->
        @grid.table.on 'select', @onSelected
                    .on 'deselect', @onDeselected
                    .on 'draw.dt', @onDraw
                    .on 'user-select.dt', @onUserSelect


    onUserSelect: (e, dt, type, cell, originalEvent) =>
        return unless type is 'row'

        if @details.hasChanges() and not @details.hasAnyInvalidValidations()
            e.preventDefault()
            @confirmation.show
                onAccept: =>
                    @details.saveChanges()
                    cell.row(cell.index().row).select()
                onDismiss: =>
                    @details.dismissChanges()
                    cell.row(cell.index().row).select()
                onCancel: noop

        @view.find('#dms-reference-grid').staticgrid()[0].validator.errors = {}


    onSelected: (e, dt, type, indexes) =>
        return unless type is 'rows' or type is 'row'
        dataItems = dt.rows(indexes).data().pluck('Uid')
        @selectedRowId = dataItems[0]
        if dataItems.length > 0 and dataItems[0] isnt @details.data?.uid
            @details.display dataItems[0]


    onDeselected: (e, dt, type, indexes) =>
        return unless type is 'rows' or type is 'row'
        dataItems = dt.rows(indexes).data().pluck('Uid')
        if  (@details.data and dataItems.length > 0 and dataItems[0] is @details.data?.uid)
            @details.clear()


    onDraw: =>
        row = @grid.table.row('#' + @details.data?.uid)
        if row is undefined or row.length is 0
            @details.clear()
        else
            row.select()


    setupTree: ->
        @tree = $ '.easytree'

        @tree.closest('#tree-container')
        .on 'click', '.easytree-node', @onNodeSelected


    onNodeSelected: (event) =>
        if @details.hasChanges()
            event.preventDefault()
            event.stopPropagation()
            @confirmation.show
                onAccept: =>
                    @details.saveChanges()
                    event.currentTarget.click()
                onDismiss: =>
                    @details.dismissChanges()
                    event.currentTarget.click()
                onCancel: =>
                    rootNodes = @tree.tree().getAllNodes()

                    allNodes = []
                    treeUtility.applyRecursive rootNodes, (node) -> allNodes.push(node)

                    nodes = allNodes.filter((node) => node.data.uid is @uid)
                    return if nodes.length is 0
                    node = nodes[0]
                    @tree.tree().activateNode(node.id)
        else
            @uid = @tree.tree().getNode(event.currentTarget.id).data.uid
            @details.clear()
            @details.updateEntityId(@uid)
            @grid.table.ajax.reload()
            @grid.editControls.add.$button.enable()


    onDetailsChanged: (selection) =>
        @grid.table.ajax.reload()
        @grid.selectRow(selection)


    onGridChanged: =>
        @grid.state.reset()
        @grid.table.ajax.reload()


    setupGridDelete: =>
        deleteModalData = @view.find('#deleteModalData')

        deleteButtonTitle = deleteModalData.data 'buttonTitle'
        deleteSuccessMessage = deleteModalData.data 'delete-success-message'
        deleteErrorMessage = deleteModalData.data 'delete-error-message'

        @grid.editor.on 'preOpen', =>
            @grid.editor.buttons(deleteButtonTitle)
        @grid.editor.on 'postRemove', =>
            url = deleteModalData.data 'url'

            toDelete = @grid.state.changes()
            data =
                properties: toDelete.deleted

            post url, data, false
            .then @onGridChanged
            .done ->
                if $('#errorPage').length is 1
                    notifications.error deleteErrorMessage
                else
                    notifications.success deleteSuccessMessage
            .fail makeErrorHandler deleteErrorMessage


module.exports = {FacilityController}

module.exports.__esModule = true
module.exports.default = FacilityController
