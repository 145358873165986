<template lang="html">
  <div class="real-estate">
    <tree-layout :loading="treeLoading">
      <tree slot="tree" v-model="selectedOrgUnit" :nodes="nodes" />
      <div v-if="translationsLoaded">
        <div class="row">
          <div class="col-sm-6">
            <label>{{ SR_RE_Reports['ChosenOrgUnit'] }}</label>
            <input
              type="text" class="form-control"
              :value="selectedOrgUnit ? selectedOrgUnit.name : ''"
              disabled
            >
          </div>
        </div>

        <div class="spacer" />

        <div class="row">
          <div class="col-sm-3">
            <input
              type="button" class="btn btn-default btn-block"
              :value="SR_RE_Reports['GeneratePreview']"
              :disabled="!selectedOrgUnit" @click="loadPreview"
            >
          </div>
          <div v-if="dataLoaded && !dataLoading" class="col-sm-3">
            <input
              type="button" class="btn btn-success btn-block"
              :value="SR_RE_Reports['DownloadExcel']"
              @click="downloadExcel"
            >
          </div>
        </div>

        <div class="spacer" />

        <div class="row">
          <div class="col-sm-12">
            <div v-if="dataLoading">
              <div class="loading-indicator text-center">
                <i class="fa fa-cog fa-spin fa-5x" />
              </div>
            </div>
            <div v-else>
              <div v-if="dataLoaded">
                <grid
                  :data="gridData" :columns="columns"
                  paging
                  :add-button="false" :delete-button="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </tree-layout>
  </div>
</template>

<script lang="js">
import treeLayout from 'src/vue/legacy-compat/TreeLayout'
import tree from '@components/nav/DenseBindingTree'
import grid from 'src/vue/areas/real-estate/components/grid/RELegacyGrid'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'
// import makeTree from './makeTree'
import {get} from 'src/coffee/infrastructure/ajax'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties',
  SR_RE_Reports: 'Areas.RealEstate.SR_RealEstate_Reports'
}

export default {
  components: {
    treeLayout,
    tree,
    grid
  },
  mixins: [
    makeResourceMixin(resources)
  ],
  data () {
    return {
      translationsLoaded: false,
      selectedOrgUnit: null,
      treeLoading: true,
      dataLoading: false,
      dataLoaded: false,
      gridData: [],
      nodes: []
    }
  },
  computed: {
    columns () {
      return [
        {
          localizedTitle: this.SR_ModelProperties['LocalDistrict'],
          typeName: 'String',
          propertyName: 'localDistrict'
        },
        {
          localizedTitle: this.SR_ModelProperties['CadastralDistrict'],
          typeName: 'String',
          propertyName: 'cadastralDistrict'
        },
        {
          localizedTitle: this.SR_ModelProperties['Number'],
          typeName: 'String',
          propertyName: 'landParcelNumber'
        },
        {
          localizedTitle: this.SR_ModelProperties['Size'],
          typeName: 'Float',
          propertyName: 'landParcelSize',
          minDecimalDigits: 4,
          maxDecimalDigits: 4
        },
        {
          localizedTitle: this.SR_ModelProperties['SumSegmentSize'],
          typeName: 'Float',
          propertyName: 'segmentSizeSum',
          minDecimalDigits: 4,
          maxDecimalDigits: 4
        },
        {
          localizedTitle: this.SR_ModelProperties['Owner'],
          typeName: 'String',
          propertyName: 'owner'
        },
        {
          localizedTitle: this.SR_ModelProperties['BusinessObject'],
          typeName: 'String',
          propertyName: 'businessObject'
        },
        {
          localizedTitle: this.SR_ModelProperties['Community'],
          typeName: 'String',
          propertyName: 'landRegisterCommunity'
        },
        {
          localizedTitle: this.SR_ModelProperties['LandRegisterPage'],
          typeName: 'String',
          propertyName: 'landRegisterPage'
        }
      ]
    }
  },
  methods: {
    loadPreview () {
      this.dataLoading = true
      get('/RealEstate/Report/MismatchedLandparcelSegmentSizePreview',
        {
          orgunit: this.selectedOrgUnit.uid
        })
        .done((result) => {
          this.dataLoading = false
          this.dataLoaded = true
          this.gridData = result
        })
    },
    downloadExcel () {
      get('/RealEstate/Report/MismatchedLandparcelSegmentSizeDownload',
        {
          orgunit: this.selectedOrgUnit.uid
        })
        .then((result) => {
          window.location = '/Download/Temp/' + escape(result)
        })
    }
  },
  mounted () {
    get('/RealEstate/Report/GetTenants')
      .then((result) => {
        //   debugger
        this.nodes = result
        this.treeLoading = false
      })
      .fail((jqXHR) => alert(jqXHR))
  },
  created () {
    this.$store.dispatch('i18n/preloadTranslations', [
      'Common.SR_Common',
      'Areas.RealEstate.SR_RealEstate',
      'ModelProperties.SR_ModelProperties',
      'Areas.RealEstate.SR_RealEstate_Reports'
    ])
      .then(() => {
        this.translationsLoaded = true
      })
  }
}
</script>

<style lang="scss">
  .frame {
    border: 1px solid lightgrey;
    padding: 10px;
  }
  .spacer {
    height: 1em
  }
</style>
