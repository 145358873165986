class SelectionGridExtension
    constructor: (@grid, childController) ->
        @reset()

        @grid.selectRow = @selectRow

        @updateFunctions = @createUpdateFunctions(childController)
        @attachToDatatablesEvents()


    reset: =>
        @selection = []
        @rowState = {}
        @setRowMarkers()


    createUpdateFunctions: (childController) ->
        functions = {}
        if typeof(childController?.clear) is 'function'
            functions.clear = -> childController.clear()
        else
            functions.clear = -> null

        if typeof(childController?.update) is 'function'
            functions.update = (id) -> childController.update(id)
        else
            functions.update = (id) -> null

        return functions


    attachToDatatablesEvents: ->
        @grid.table.on 'select.dt.DT', @onSelected
                    .on 'deselect.dt.DT', @onDeselected
                    .on 'draw.dt', @onDraw


    rowsToIds: (rows) =>
        data = rows.data()

        if data.toArray
            data = data.toArray()
        else
            data = [data]

        return data.map((x) => x[@grid.idColumn])


    onSelected: (e, dt, type, indexes) =>
        return unless type is 'rows' or type is 'row'
        selected = @rowsToIds(dt).filter (x) => x not in @selection
        @selection = @selection.concat selected

        if selected.length is 1
            @updateFunctions.update(selected[0])

        for rowId in selected
            @setMarkerForRow(rowId, @rowState[rowId])


    onDeselected: (e, dt, type, indexes) =>
        return unless type is 'rows' or type is 'row'
        deselected = @rowsToIds(dt)
        @selection = @selection.filter (x) -> x not in deselected

        @updateFunctions.clear()


    onDraw: =>
        hashIds = @selection.map (x) -> '#' + x
        @grid.table.rows(hashIds).select()
        @setRowMarkers()


    setRowMarkers: =>
        for rowId in @grid.table.rows().data().map((row) -> row.DT_RowId)
            @setMarkerForRow(rowId)


    setMarkerForRow: (rowId) =>
        $domElement = @grid.table.rows('#' + rowId).nodes().to$()
        $domElement.removeClass('added modified has-error')
        for c of @getRowState(rowId)
            $domElement.addClass(c)


    getRowState: (rowId) =>
        if not @rowState[rowId]
            @rowState[rowId] = {}

        return @rowState[rowId]


    selectRow: (id) =>
        @grid.table.rows().deselect()
        @grid.table.rows('#' + id).select()


    markRowNew: (rowId) =>
        @getRowState(rowId).added = true
        @setMarkerForRow(rowId)


    markRowModified: (rowId) =>
        state = @getRowState(rowId)
        if not state.added
            state.modified = true
        @setMarkerForRow(rowId)


    markRowValidationState: (rowId, isValid) =>
        state = @getRowState(rowId)
        if isValid
            delete state['has-error']
        else
            state['has-error'] = true
        @setMarkerForRow(rowId)



makeGridAParent = (grid, childController) ->
    grid.selectionExtension = new SelectionGridExtension(grid, childController)


module.exports = {
    makeGridAParent
}

module.exports.__esModule = true
module.exports.default = makeGridAParent
