<template>
  <modal
    :title="modalTitle" :value="showModal"
    blocking
    @shown="focusFirstInput"
  >
    <div class="form-horizontal">
      <div v-for="(item,i) in content" :key="item.uid">
        <div v-if="item.editable">
          <div v-if="item.dataType === 'selectize'" class="row">
            <label class="col-md-3 control-label">
              <p>{{ item.label }}</p>
            </label>
            <div class="col-md-8">
              <vue-selectize
                :ref="'input-'+i" v-model="item.value"
                :options="item.selectizeOptions"
                :allow-empty="true"
              />
            </div>
          </div>
          <div v-else-if="item.dataType === 'checkbox'" class="form-group">
            <label for="input" class="col-md-3 control-label">
              <p>{{ item.label }}</p>
            </label>
            <div class="col-md-8">
              <div class="checkbox">
                <label>
                  <input
                    id="input" :ref="'input-'+i"
                    v-model="item.value"
                    type="checkbox"
                  >
                </label>
              </div>
            </div>
          </div>
          <div v-else class="form-group">
            <label for="input" class="col-md-3 control-label">
              <p>{{ item.label }}</p>
            </label>
            <div class="col-md-8">
              <input
                id="input" :ref="'input-'+i"
                v-model.trim="item.value"
                :type="item.dataType ? item.dataType : 'text'" class="form-control"
                :maxlength="item.maxLength ? item.maxLength : null"
              >
            </div>
          </div>
        </div>
      </div>
      <div v-if="globalAndTenantItems && content" class="row">
        <label class="col-md-3 control-label" for="isSystem">
          <IxRes :resource="SR_RealEstate">IsSystem</IxRes>
        </label>
        <div class="col-md-8">
          <div class="form-group">
            <div class="checkbox">
              <label>
                <input
                  id="isSystem" v-model="isSystemChecked"
                  class="form-check-input"
                  type="checkbox" :disabled="!permissionToEditVisibility"
                >
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="col-md-offset-6 col-md-3">
          <input
            type="button" class="btn btn-success btn-block"
            :value="SR_Common.Apply"
            :disabled="inputsEmpty || contentNotChanged" @click="applyModal"
          >
        </div>
        <div class="col-md-3">
          <input
            type="button" class="btn btn-danger btn-block"
            :value="SR_Common.Cancel"
            @click="cancelModal"
          >
        </div>
      </div>
    </div>
  </modal>
</template>

<script lang="js">
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'
import modal from 'src/vue/components/bootstrap/BsModal'
import {isEqual} from 'lodash'
import vueSelectize from 'src/vue/components/VueSelectize'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate'
}

export default {
  components: {
    modal,
    vueSelectize
  },
  mixins: [makeResourceMixin(resources)],

  props: ['modalTitle', 'showModal', 'content', 'globalAndTenantItems'],

  data () {
    return {
      originalContent: null,
      isSystemInitial: null,
      isSystemChecked: null,
      permissionToEditVisibility: true // TODO: only temporarily in data; implement method to check if user has permissions to edit visibility of entry
    }
  },

  computed: {
    inputsEmpty () {
      return this.content && this.content.some(element => element.required && (!element.value))
    },
    contentNotChanged () {
      if (this.globalAndTenantItems && this.content) {
        return isEqual(this.originalContent, this.content) && (this.isSystemChecked === this.isSystemInitial)
      }
      return isEqual(this.originalContent, this.content)
    }
  },

  methods: {

    applyModal () {
      if (this.globalAndTenantItems) {
        this.content.isSystem = this.isSystemChecked
      }
      this.$emit('apply', this.content)
    },

    cancelModal () {
      this.$emit('cancel')
    },

    focusFirstInput () {
      for (let i in this.content) {
        if (this.$refs['input-' + i] !== undefined) {
          this.$refs['input-' + i][0].focus()
          break
        }
      }
    }
  },

  watch: {
    content () {
      this.originalContent = JSON.parse(JSON.stringify(this.content))
      if (this.globalAndTenantItems) {
        this.isSystemInitial = this.content.isSystem
        this.isSystemChecked = this.content.isSystem
      }
    }
  }
}
</script>
