<template lang="html">
  <span class="fa fa-fw status-icon" :class="mode[status]" :tooltip="message" />
</template>

<script>

const mode = {
  none: 'none',
  working: 'fa-spinner fa-pulse',
  changed: 'fa-asterisk',
  success: 'fa-check success',
  error: 'fa-exclamation-triangle error'
}

export default {
  props: {
    status: {
      type: String,
      required: true,
      default: 'none',
      validator: function (value) {
        return !!mode[value]
      }
    },
    message: {
      type: String
    }
  },
  data () {
    return {
      mode
    }
  }
}
</script>

<style lang="scss" scoped>
.status-icon {
  color: dimgray;

  &.error {
    color: red;
  }
  &.success {
    color: green;
  }
}
</style>
