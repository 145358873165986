{get, postJson} = require 'src/coffee/infrastructure/ajax'

class LocalDistrictCreateController
    constructor: (@view, @mediator) ->
        @setupControls()

    setupControls: ->
        @createBtn = @view.find '#createBtn'
        .on 'click', => @create()
        @cancelBtn = @view.find '#cancelBtn'
        .on 'click', => @cancel()
        @localDistrictName = @view.find '#local-district-name'
        @localDistrictName.focus()
        districtSelectOptions =
            valueField: 'UID'
            searchField: 'Name'
            labelField: 'Name'
            selectOnTab: true
        @districts = @view.find('#district-select').selectize(districtSelectOptions)[0].selectize
        regionSelectOptions =
            valueField: 'uid'
            searchField: 'region'
            labelField: 'region'
            load: (query, callback) ->
                if query in ['', null, undefined]
                    callback()
                else
                    get 'Region/CountriesWithRegions', {query: query}
                    .then (result) -> callback result
            onChange: (value) =>
                return if value is undefined
                @districts.load (callback) ->
                    get 'District', {region: value}
                    .then (result) -> callback result
            selectOnTab: true
        @regions = @view.find('#region-select').selectize(regionSelectOptions)[0].selectize

    setName: (name) ->
        @localDistrictName.val name

    create: ->
        @createBtn.off()
        @cancelBtn.off()
        localDistrict =
            Name: @localDistrictName.val()
            FKDistrict: @districts.getValue()

        postJson 'LocalDistricts/Create', localDistrict
        .done (createdLocalDistrictId) =>
            get 'LocalDistricts/Detail', {id: createdLocalDistrictId}
            .done (createdLocalDistrict) => @mediator.raise event: 'LocalDistrictCreated', data: createdLocalDistrict

    cancel: ->
        @createBtn.off()
        @cancelBtn.off()
        @mediator.raise event: 'CreateLocalDistrictCancelled'

module.exports = {
    LocalDistrictCreateController
}
module.exports.__esModule = true
module.exports.default = LocalDistrictCreateController
