{get, postJson} = require 'src/coffee/infrastructure/ajax'

{Binder} = require 'src/coffee/areas/real-estate/utils/binder'
{parse, format} = require 'src/js/i18n/conversion'

class LandParcelCreatorController
    constructor: (@$modal, @associatedEntities, @entityId) ->
        @submitHandlers = []
        @binder = new Binder(@$modal, @inputChanged)
        @setupModal()

    onSubmit: (func) =>
        @submitHandlers.push(func)

    submit: (result) =>
        for handler in @submitHandlers
            handler result

    populate: (data) ->
        @binder.updateControls(data)

    setupModal: ->
        parser = (value) ->
            #inplace import because of problem with closure and live replacement of the binding
            {parse} = require 'src/js/i18n/conversion'
            return parse(value)

        formatter4d = (value) ->
            #inplace import because of problem with closure and live replacement of the binding
            {format} = require 'src/js/i18n/conversion'
            return format(value, 'f4')

        @binder.bindInput('#localDistrict', 'localDistrictName', true)
        @binder.bindInput('#cadastralDistrict', 'cadastralDistrictNumber')
        @binder.bindInput('#landParcelNumber', 'number')
        @binder.bindHiddenValue('##ldId', 'localDistrictUid')
        @binder.bindHiddenValue('##AssociatedEntities', 'associatedEntities')
        @binder.bindInput('#size', 'size', false, formatter4d, parser)
        @binder.bindInput('#contractualSize', 'contractualSize', false, formatter4d, parser)
        @binder.bindInput('#remark', 'landParcelRemark')

        @binder.connect('#size', '#contractualSize')
        @binder.connect('#size', '#size')
        @binder.connect('#contractualSize', '#contractualSize')

        @binder.disableReadonlyControls()
        @binder.bindings['#size'].control.focus()

        @createAction = @$modal.find('#land-parcel-create-actions').data('create-action')
        @loadCommunitiesAction = @$modal.find('#land-parcel-create-actions')
            .data('load-land-register-communities-action')

        @binder.bindAjaxSelectize('#land-register', 'landRegisterCommunity', @loadCommunities,
                                    {
                                        create: (input) -> {
                                            name: input
                                            uid: '00000000-0000-0000-0000-000000000000'
                                        }
                                    },
                                    (input, control) ->
                                        for name, option of control.options
                                            return false if option.name.toLowerCase().startsWith(input)
                                        return true
                                    )

        @binder.bindInput('#land-register-page', 'landRegisterPage')

        $errorMessages = @$modal.find '#error-messages'
        @noNumberMessage = $errorMessages.data 'land-parcel-no-number'
        @noSizeMessage = $errorMessages.data 'land-parcel-no-size'
        @noContractualSizeMessage = $errorMessages.data 'land-parcel-no-contractual-size'

        @submitButton = @$modal.find('#submit')
        @submitButton.on('click', @handleSubmit)
        @submitButton.disable()     

    loadCommunities: (query, callback) =>
        get(@loadCommunitiesAction, {query:query, localDistrictId:@binder.readControl('##ldId'), entityId:@entityId})
            .done (result) -> callback(result)

    inputChanged: => 
        if not @submitButton
            return
        data = @binder.readControls() 
        if not data.cadastralDistrictNumber || not data.number || not data.size || not data.contractualSize  
            @submitButton.disable() 
        else 
            @submitButton.enable() 

    handleSubmit: =>
        data = @binder.readControls()
        data.fkDataOwner = @entityId
        return if not @validate(data)

        postJson(@createAction, data)
            .then (result) =>
                if result.HasError
                    alert(result.ErrorMessage)
                else
                    @submit(@buildResultObject(result.newUid))
                    @$modal.modal('hide')


    buildResultObject: (lpId) =>
        data = @binder.readControls()

        if not data.landRegisterCommunity 
            data.landRegisterCommunity = {name: '', uid: ''} 

        return {
            resultingRow:
                landParcelId: lpId
                contractualSize: data.contractualSize
                currentSize: data.size
                community: data.landRegisterCommunity.name
                page: data.landRegisterPage
                localDistrict: data.localDistrictName
                cadastralDistrict: data.cadastralDistrictNumber
                number: data.number
                remark: ''
                price: 0.0
                currency: '346c8459-bfb3-4054-ad9c-e08cd17792fe' #Id for €
                uid: '00000000-0000-0000-0000-000000000000'
            reOpen: false
        }

    validate: (data) =>
        if not data.number
            alert(@noNumberMessage)
            return false

        if not data.size
            alert(@noSizeMessage)
            return false

        if not data.contractualSize
            alert(@noContractualSizeMessage)
            return false

        return true


module.exports = {LandParcelCreatorController}

module.exports.__esModule = true
module.exports.default = LandParcelCreatorController
