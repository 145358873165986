<template lang="html">
  <div>
    <label v-if="!!label" :for="identifier" class="control-label">{{ label }}</label>
    <div v-if="!!addOnText" class="input-group">
      <input
        :id="identifier" ref="input"
        class="form-control"
        :value="value" :readonly="readOnly"
        @input="onInput"
      >
      <div class="input-group-addon">
        {{ addOnText }}
      </div>
    </div>
    <input
      v-else :id="identifier"
      ref="input"
      class="form-control" :value="value"
      :readonly="readOnly" @input="onInput"
    >
  </div>
</template>

<script>

import newGuid from 'src/js/helpers/new-guid'

export default {
  props: ['label', 'value', 'addOnText', 'readOnly'],
  data () {
    return {
      identifier: newGuid()
    }
  },
  computed: {

  },
  methods: {
    onInput ($event) {
      this.$emit('input', $event.target.value)
    },
    focus () {
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="css">
</style>
