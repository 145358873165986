<template lang="html">
  <div class="panel panel-default">
    <div class="panel-body">
      <div class="row">
        <div :class="{'col-md-9': !createMode, 'col-md-12': createMode}">
          <lease-form v-model="value" />
          <div class="row">
            <div class="col-sm-12">
              <h3>
                <IxRes :resource="SR_RealEstate">
                  DmsReferences
                </IxRes>
              </h3>
              <grid
                v-model="selectedDmsReferences" :data="dmsReferences"
                :columns="columns" selection="single"
                paging minimal
                :add-button="!readOnly && permissions.leaseContractCanEdit" :edit-button="hasCurrentDmsReference && permissions.leaseContractCanEdit"
                :delete-button="hasCurrentDmsReference && permissions.leaseContractCanEdit"
                @add="createDmsReferenceRequest" @delete="deleteDmsReferenceRequest"
                @edit="editDmsReferenceRequest"
              />
            </div>
          </div>
        </div>
        <div v-if="!createMode" class="col-md-3">
          <lease-amendments-selector v-model="value" />
        </div>
      </div>
    </div>
    <bs-modal
      :title="SR_RealEstate.CreateDmsReferenceTitle" :value="isEditDmsReferenceModalOpen"
      blocking
      @hidden="createOrEditDmsReferenceFailure"
    >
      <dms-reference-form id="dms-reference-form" v-model="editDmsReference" />
      <button
        slot="footer" type="button"
        class="btn btn-success"
        @click="createOrEditDmsReferenceSuccess"
      >
        <IxRes :resource="SR_Common">
          Save
        </IxRes>
      </button>
      <button
        slot="footer" type="button"
        class="btn btn-default"
        @click="createOrEditDmsReferenceFailure"
      >
        <IxRes :resource="SR_Common">
          Cancel
        </IxRes>
      </button>
    </bs-modal>
    <bs-modal :title="SR_RealEstate.DeleteDmsReferenceTitle" :value="isDeleteDmsReferenceModalOpen" blocking>
      <p>
        <IxRes :resource="SR_RealEstate">
          DeleteDmsReferenceQuestion
        </IxRes>
      </p>
      <button
        slot="footer" type="button"
        class="btn btn-success"
        @click="deleteDmsReferenceFailure"
      >
        <IxRes :resource="SR_Common">
          No
        </IxRes>
      </button>
      <button
        slot="footer" type="button"
        class="btn btn-default"
        @click="deleteDmsReferenceSuccess"
      >
        <IxRes :resource="SR_Common">
          Yes
        </IxRes>
      </button>
    </bs-modal>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Grid from 'src/vue/areas/real-estate/components/grid/RELegacyGrid.vue'
import BsModal from 'src/vue/components/bootstrap/BsModal'

import LeaseAmendmentsSelector from './LeaseAmendmentsSelector'

import LeaseForm from './LeaseForm'

import DmsReferenceForm from './DmsReferenceForm'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    LeaseForm,
    Grid,
    BsModal,
    DmsReferenceForm,
    LeaseAmendmentsSelector
  },
  mixins: [makeResourceMixin(resources)],
  props: {
    value: null,
    createMode: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    name: String
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('realEstateContractLease/contracts', {
      permissions: 'currentPermissions'
    }),
    ...mapGetters('realEstateContractLease/dmsReferences', [
      'editDmsReference',
      'isEditDmsReferenceModalOpen',
      'isDeleteDmsReferenceModalOpen',
      'currentDmsReference',
      'hasCurrentDmsReference']),
    dmsReferences: {
      get () {
        return this.value.dmsReferences ? this.value.dmsReferences : []
      }
    },
    selectedDmsReferences: {
      get () {
        return [this.currentDmsReference]
      },
      set (value) {
        const dmsReference = value && value.length > 0 ? value[0] : null
        this.selectDmsReference(dmsReference)
      }
    },
    columns () {
      return [
        {localizedTitle: this.getLocalizedTitleByResourceName('Type'), typeName: 'String', propertyName: 'type.text'},
        {localizedTitle: this.getLocalizedTitleByResourceName('Reference'), typeName: 'String', propertyName: 'name'},
        {localizedTitle: this.getLocalizedTitleByResourceName('ReferenceLink'), typeName: 'String', propertyName: 'reference'}
      ]
    }
  },
  methods: {
    ...mapActions('realEstateContractLease/dmsReferences', [
      'createDmsReferenceRequest',
      'editDmsReferenceRequest',
      'createOrEditDmsReferenceSuccess',
      'createOrEditDmsReferenceFailure',
      'deleteDmsReferenceRequest',
      'deleteDmsReferenceSuccess',
      'deleteDmsReferenceFailure',
      'selectDmsReference']),
    getLocalizedTitleByResourceName (resourceName) {
      return this.SR_ModelProperties[resourceName]
    }
  }
}
</script>

<style lang="css">
</style>
