{postJson} = require 'src/coffee/infrastructure/ajax'

newGuid = (require 'src/js/helpers/new-guid').default

class CreateBusinessObjectController
    constructor: (@options) ->
        @nameCtrl = @options.view.find '#business-object-name'
        @nameCtrl.val(@options.name)
        @nameCtrl.focus()

        @doneBtn = @options.view.find '#createBtn'
        @doneBtn.on 'click', @create

        @cancelBtn = @options.view.find '#cancelBtn'
        @cancelBtn.on 'click', @cancel

    create: =>
        postJson '/RealEstate/MasterDataBusinessObject/Create',
            uid: newGuid()
            fkDataOwner: @options.tenantId
            name: @nameCtrl.val()
        .done @raiseCreated
    
    raiseCreated: (id) =>
        @options.onCreated
            uid: id
            name: @nameCtrl.val()
            fkDataOwner: @options.tenantId

    cancel: =>
        @options.onCancel()


module.exports = {CreateBusinessObjectController}

module.exports.__esModule = true
module.exports.default = CreateBusinessObjectController