<template lang="html">
  <TreeLayout>
    <Tree
      v-if="includeTree" slot="tree"
      :nodes="treeNodes"
      :value="currentEntity" @input="loadEntityData"
    />
    <div v-if="translationsLoaded">
      <Grid
        ref="grid" v-model="selectedItem"
        :data="gridData" :columns="columns"
        selection="single" :title="gridTitle"
        paging :add-button="isOrgUnitSelected"
        :edit-button="isItemSelectedAndEditable" @add="addNewItem"
        @edit="editItem" @input="updateSelectedItem"
      />
      <MasterdataModal
        :modal-title="modalTitle" :show-modal="showModal"
        :content="modalContent"
        :global-and-tenant-items="globalAndTenantItems" @apply="applyModalAction"
        @cancel="showModal=false"
      />
    </div>
  </TreeLayout>
</template>

<script lang="js">
import Vue from 'vue'

import {mapResources} from '@helpers/vuex'

import {get, postJson} from 'src/coffee/infrastructure/ajax'
import {success, error} from 'src/coffee/infrastructure/ixmap/notifications'

import TreeLayout from 'src/vue/legacy-compat/TreeLayout'
import Tree from '@components/nav/Tree'
import Grid from 'src/vue/areas/real-estate/components/grid/RELegacyGrid'

import MasterdataModal from './masterdata-modal'

export default {
  components: {
    TreeLayout,
    Grid,
    MasterdataModal,
    Tree
  },
  props: {
    columns: Array,
    gridTitle: String,
    modalTitleAdd: String,
    modalTitleEdit: String,
    loadItemsActionUrl: String,
    createItemActionUrl: String,
    updateItemActionUrl: String,
    filterParamsKey: String,
    filterParamsValue: String,
    buildObjectForRequest: Function,
    setupModalContent: Function,
    includeTree: Boolean,
    globalAndTenantItems: Boolean
  },
  data () {
    return {
      translationsLoaded: false,
      gridData: [],
      selectedItem: null,
      modalTitle: null,
      showModal: false,
      applyModalAction: Function,
      modalContent: null,
      currentSelectedUID: null,
      treeNodes: [],
      currentEntity: null,
      fkDataOwner: null,
      isSystem: false
    }
  },
  computed: {
    isItemSelectedAndEditable () {
      if (this.globalAndTenantItems) {
        return this.selectedItem && this.selectedItem.length > 0 && (this.selectedItem[0].fkDataOwner != null)
      } else {
        return this.selectedItem && this.selectedItem.length > 0
      }
    },
    isOrgUnitSelected () {
      if (!this.includeTree) {
        return true
      } else {
        return !!this.currentEntity
      }
    },
    ...mapResources([
      'Messages.Info.SR_InfoMessages'
    ])
  },
  methods: {
    addNewItem () {
      this.selectedItem = null
      this._openModal(this.modalTitleAdd, this.saveNewItem, this.setupModalContent())
    },
    editItem () {
      this._openModal(this.modalTitleEdit, this.saveExistingItem, this.setupModalContent(this.selectedItem[0]))
    },
    _openModal (title, action, item) {
      this.showModal = true
      this.modalTitle = title
      this.applyModalAction = action
      this.modalContent = item
      if (this.globalAndTenantItems && this.selectedItem) {
        this.modalContent.isSystem = this.selectedItem[0].isSystem
      }
    },
    saveNewItem (result) {
      this.processResult(result, this.createItemActionUrl, this.SR_InfoMessages.SaveSuccessful)
    },
    saveExistingItem (result) {
      this.processResult(result, this.updateItemActionUrl, this.SR_InfoMessages.ChangesSaved)
    },
    processResult (result, route, infoMessage) {
      if (result[0].propertyName === 'uid') {
        this.currentSelectedUID = result[0].value // TODO: find better solution
      }
      this.setDataOwnerProperty(result)

      postJson(route, this.buildObjectForRequest(result))
        .then(() => {
          success(infoMessage)
          this.loadItems()
        })
        .fail((jqXHR) => {
          error(jqXHR.statusText)
        })
      this.showModal = false
    },
    loadEntityData (entity) {
      this.currentEntity = entity
      this.loadItems()
    },
    setDataOwnerProperty (item) {
      if (this.globalAndTenantItems) {
        if (item.isSystem) {
          item.fkDataOwner = null
        } else {
          item.fkDataOwner = this.currentEntity.uid
        }
      }
    },
    loadItems () {
      get(this.loadItemsActionUrl, this.buildQueryStringParameters())
        .then((result) => {
          if (this.globalAndTenantItems) {
            result.forEach(item => {
              item.isSystem = item.fkDataOwner === null
            })
          }
          this.gridData = result

          // reselect currently selected value
          if (this.currentSelectedUID) {
            Vue.nextTick(() => {
              this.$refs.grid.selectRow(this.currentSelectedUID)
            })
          }
        })
    },
    buildQueryStringParameters () {
      var queryStringParameters = {}

      if (this.currentEntity) {
        queryStringParameters.entityId = this.currentEntity.uid
      }
      if (this.filterParamsKey && this.filterParamsValue) {
        queryStringParameters[this.filterParamsKey] = this.filterParamsValue
      }
      return queryStringParameters
    },
    loadEntities () {
      get('/RealEstate/Entity/GetTenants')
        .then((result) => {
          this.treeNodes = result
        })
    },
    updateSelectedItem () {
      this.$emit('input', this.selectedItem[0])
    }
  },
  watch: {
    filterParamsValue () {
      this.loadItems()
    },
    currentEntity () {
      this.selectedItem = null
      this.$emit('entityChanged', this.currentEntity.uid)
    }
  },
  mounted () {
    if (this.includeTree) {
      this.loadEntities()
    } else {
      this.loadItems()
    }
  },
  created () {
    this.$store.dispatch('i18n/preloadTranslations', [
      'Messages.Info.SR_InfoMessages'
    ])
      .then(() => {
        this.translationsLoaded = true
      })
  }
}
</script>
