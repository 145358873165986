{get} = require 'src/coffee/infrastructure/ajax'

{Binder} = require 'src/coffee/areas/real-estate/utils/binder'

class SegmentsSelectorController

    constructor: ($modal, @propertyId, @entityId) ->
        @$modal = $modal
        @submitHandlers = []

        @selectedLocalDistrict = undefined
        @selectedCadastralDistrict = undefined
        @selectedLandParcel = undefined
        @selectedSegments = undefined

        @setupModal $modal

    setupModal: ($modal) =>
        $actions = $modal.find '#segment-add-actions'

        @localDistrictAction = $actions.data 'local-district-action'  
        @cadastralDistrictAction = $actions.data 'cadastral-district-action'
        @landParcelAction = $actions.data 'land-parcel-action'
        @segmentsAction = $actions.data 'segments-action'

        @localDistrictBox = @setupLocalDistrictSelector $modal
        @cadastralDistrictBox = @setupCadastralDistrictSelector $modal
        @landParcelBox = @setupLandParcelSelector $modal

        @submitButton = $modal.find '#submit'
        @submitButton.on 'click', @handleSubmit
        @submitButton.disable()

        @setupSegmentsGrid()

        @localDistrictBox.focus()

    setupLocalDistrictSelector: ($modal) =>
        loader = (query, callback) =>
            get(@localDistrictAction, {query:query, entityId: @entityId})
            .done (result) -> callback result
            return undefined

        renderLocalDistrict = (data, escape) ->
            """
                <div>
                    <span>#{escape(data.text)}</span>
                    <small class='text-muted'>#{escape(data.label)}</small>
                </div>
            """
        select = $modal.find '#localDistrict'
        control = select
        .selectize
            valueField: 'value'
            searchField: 'text'
            selectOnTab: true
            closeAfterSelect: true
            load: loader
            onChange: @localDistrictChanged
            render:
                option: renderLocalDistrict
                item: renderLocalDistrict
        return control[0].selectize

    setupCadastralDistrictSelector: ($modal) =>
        select = $modal.find '#cadastralDistrict'
        control = select
        .selectize
            valueField: 'uid'
            labelField: 'number'
            searchField: 'number'
            selectOnTab: true
            closeAfterSelect: true
            onChange: @cadastralDistrictChanged
        c = control[0].selectize
        c.disable()
        return c

    setupLandParcelSelector: ($modal) =>
        select = $modal.find '#landParcelNumber'
        control = select
        .selectize
            valueField: 'uid'
            labelField: 'number'
            searchField: 'number'
            selectOnTab: true
            closeAfterSelect: true
            onChange: @landParcelChanged
            create: @switchToCreateModal
            createFilter: @createAllowed
        c = control[0].selectize
        c.disable()
        return c


    setupSegmentsGrid: =>
        footerPanel = @$modal.find('#segments-grid_wrapper .panel-footer')
        footerPanel.addClass('hide')

        @gridBinder = new Binder(@$modal)

        gridId = '#segments-grid'
        @gridBinder.bindGrid(gridId, 'segments', true)

        grid = @$modal.find(gridId).staticgrid()[0]
        grid.table.on 'select', @onGridSelected
        grid.table.on 'deselect', @onGridSelected

    onGridSelected: (e, dt, type, indexes) =>
        selectedItemsCount = dt.rows('.selected').data().length

        if selectedItemsCount > 0
            @submitButton.enable()
        else
            @submitButton.disable()

    localDistrictChanged: (value) =>
        @selectedLocalDistrict = value
        @updateBox @selectedLocalDistrict, @cadastralDistrictAction, @cadastralDistrictBox

    cadastralDistrictChanged: (value) =>
        @selectedCadastralDistrict = value
        @updateBox @selectedCadastralDistrict, @landParcelAction, @landParcelBox
        @updateGrid(@selectedCadastralDistrict, null)

    landParcelChanged: (value) =>
        @selectedLandParcel = value
        @updateGrid(@selectedCadastralDistrict, @selectedLandParcel)

    updateBox: (parentId, action, box) =>
        box.clear()
        box.clearOptions()
        if parentId is undefined or parentId is ''
            box.disable()
        else
            @loadBoxContents parentId, action, box

    updateGrid: (cadastralDistrictId, landParcelId) =>
        data =
            cadastralDistrictId: cadastralDistrictId
            landParcelId: landParcelId

        get(@segmentsAction, data, false)
            .done (result) =>
                viewModel =
                    segments: result

                @gridBinder.updateControls(viewModel)

    loadBoxContents: (parentId, action, box) ->
        fillBox = (contents) ->
            box.addOption(contents)
            box.refreshOptions(true)

        $.get(action, {parentId:parentId, entityId:@entityId})
        .done (result) -> fillBox result
        .then box.enable()

    onSubmit: (func) =>
        @submitHandlers.push(func)

    handleSubmit: =>
        gridId = '#segments-grid'
        dataTable = @$modal.find(gridId).DataTable()

        returnItem =
            resultingRows: dataTable.rows('.selected').data().toArray()
            reOpen: false

        @submit returnItem
        @$modal.modal('hide')

    submit: (result) =>
        for handler in @submitHandlers
            handler result


module.exports = {SegmentsSelectorController}

module.exports.__esModule = true
module.exports.default = SegmentsSelectorController
