<template lang="html">
  <div class="real-estate">
    <tree-layout>
      <tree
        slot="tree" :nodes="nodes"
        :value="currentEntity"
        @input="loadEntity"
      />
      <div v-if="translationsLoaded">
        <grid
          ref="paymentModeGrid" v-model="selectedPaymentModes"
          :data="paymentModes" :columns="columns"
          selection="single" :title="SR_ModelProperties.PaymentMode"
          paging :add-button="isOrgUnitSelected"
          :delete-button="isSelected && !isReadonly" @add="addPaymentMode"
          @delete="deletePaymentMode"
        />
        <div v-if="isSelected" ref="details">
          <div class="form-horizontal frame" :class="{readonly: isReadonly}">
            <div class="form-group">
              <label for="name" class="col-sm-1 control-label">
                <IxRes :resource="SR_Common">Name</IxRes>
              </label>
              <div class="col-sm-4">
                <input
                  id="name" ref="name"
                  v-model="currentToEdit.name"
                  type="text" class="form-control"
                  :readonly="isReadonly" @input="onChange"
                >
              </div>
              <div class="col-sm-offset-6 col-sm-1">
                <status :status="status" :message="statusMessage" />
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-12">
                <grid
                  v-model="selectedDates" :data="currentToEdit.dates"
                  :columns="dateColumns"
                  :title="SR_RealEstate.PayDates" :add-button="!isReadonly"
                  :delete-button="dateIsSelected && !isReadonly" :edit-button="dateIsSelected && !isReadonly"
                  selection="single" paging
                  @add="addPaymentDate" @edit="editPaymentDate"
                  @delete="deletePaymentDate"
                />
                <modal :title="SR_RealEstate.EditPaydate" :value="isDateModalOpen" @hidden="cancelDateModal">
                  <div class="form-horizontal">
                    <div class="form-group has-feedback" :class="{'has-error': dayHasError}">
                      <label for="day" class="col-sm-2 control-label">
                        <IxRes :resource="SR_RealEstate">Day</IxRes>
                      </label>
                      <div class="col-sm-3">
                        <input
                          id="day" v-model="day"
                          type="number"
                          class="form-control" min="1"
                          max="31"
                        >
                        <span v-if="dayHasError" class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true" />
                      </div>
                    </div>
                    <div class="form-group has-feedback" :class="{'has-error': monthHasError}">
                      <label for="month" class="col-sm-2 control-label">
                        <IxRes :resource="SR_RealEstate">Month</IxRes>
                      </label>
                      <div class="col-sm-3">
                        <input
                          id="month" v-model="month"
                          type="number"
                          class="form-control" min="1"
                          max="12"
                        >
                        <span v-if="monthHasError" class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true" />
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="comment" class="col-sm-2 control-label">
                        <IxRes :resource="SR_RealEstate">Comment</IxRes>
                      </label>
                      <div class="col-sm-10">
                        <input
                          id="comment" v-model="comment"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="col-md-offset-6 col-md-3">
                        <input
                          type="button" class="btn btn-success btn-block"
                          :value="SR_Common.Apply"
                          :disabled="!dateIsValid" @click="applyDateModal"
                        >
                      </div>
                      <div class="col-md-3">
                        <input
                          type="button" class="btn btn-danger btn-block"
                          :value="SR_Common.Cancel"
                          @click="cancelDateModal"
                        >
                      </div>
                    </div>
                  </div>
                </modal>
              </div>
            </div>
            <div v-if="!isReadonly" class="form-group">
              <div class="col-md-offset-8 col-md-2">
                <input
                  type="button" class="btn btn-success btn-block"
                  :value="SR_Common.Save"
                  :disabled="!hasChange" @click="createOrUpdate"
                >
              </div>
              <div class="col-md-2">
                <input
                  type="button" class="btn btn-danger btn-block"
                  :value="SR_Common.Cancel"
                  :disabled="!hasChange" @click="cancelCreateOrUpdate"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </tree-layout>
  </div>
</template>

<script lang="js">
import treeLayout from 'src/vue/legacy-compat/TreeLayout'
import tree from '@components/nav/Tree'
import modal from 'src/vue/components/bootstrap/BsModal'
import grid from 'src/vue/areas/real-estate/components/grid/RELegacyGrid'
import status from 'src/vue/areas/real-estate/components/status'
import moment from 'moment'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'
import newGuid from 'src/js/helpers/new-guid'
import {warning, success, error} from 'src/coffee/infrastructure/ixmap/notifications'
import {get, postJson} from 'src/coffee/infrastructure/ajax'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties',
  SR_InfoMessages: 'Messages.Info.SR_InfoMessages'
}

export default {
  components: {
    treeLayout,
    tree,
    grid,
    modal,
    status
  },
  mixins: [
    makeResourceMixin(resources)
  ],
  data () {
    return {
      selectedPaymentModes: [],
      paymentModes: [],
      translationsLoaded: false,
      selectedDates: [],
      isDateModalOpen: false,
      nodes: [],
      currentEntity: null,
      currentToEdit: null,
      hasChange: false,
      status: 'none',
      statusMessage: '',
      currentDateId: null,
      day: '',
      month: '',
      comment: ''
    }
  },
  computed: {
    columns () {
      return [{
        localizedTitle: this.SR_ModelProperties['PaymentMode'],
        typeName: 'String',
        propertyName: 'name'
      },
      {
        localizedTitle: this.SR_ModelProperties['IsSystem'],
        typeName: 'Boolean',
        propertyName: 'isSystem'
      }]
    },
    dateColumns () {
      return [
        {
          localizedTitle: this.SR_RealEstate['Day'],
          typeName: 'Integer',
          propertyName: 'day'
        },
        {
          localizedTitle: this.SR_RealEstate['Month'],
          typeName: 'Integer',
          propertyName: 'month'
        },
        {
          localizedTitle: this.SR_RealEstate['Comment'],
          typeName: 'String',
          propertyName: 'comment'
        }
      ]
    },
    isSelected () {
      return !!this.currentToEdit
    },
    isOrgUnitSelected () {
      return !!this.currentEntity
    },
    dateIsSelected () {
      return this.isSelected && this.selectedDates.length === 1
    },
    isReadonly () {
      return this.currentToEdit.ownerId == null
    },
    dateIsValid () {
      return !(this.dayHasError || this.monthHasError)
    },
    dayHasError () {
      const dayAsInt = parseInt(this.day)
      const monthAsInt = parseInt(this.month)
      const daysInMonth = moment().year(2012).month(monthAsInt - 1).daysInMonth()
      return isNaN(dayAsInt) || dayAsInt < 1 || dayAsInt > daysInMonth
    },
    monthHasError () {
      const monthAsInt = parseInt(this.month)
      return isNaN(monthAsInt) || monthAsInt < 1 || monthAsInt > 12
    }
  },
  methods: {
    $_resetState () {
      this.currentToEdit = null
      this.currentDateId = null
      this.selectedDates = []
      this.day = ''
      this.month = ''
      this.comment = ''
    },
    loadEntity (entity) {
      this.currentEntity = entity

      this.$_resetState()
      this.paymentModes = []
      this.selectedPaymentModes = []

      get('/RealEstate/PaymentMode/GetPaymentModes', {entityId: entity.uid, full: true})
        .then((result) => {
          result.forEach(mode => {
            mode.isSystem = mode.ownerId === null
          })
          this.paymentModes = result
        })
        .fail((jqXHR) => alert(jqXHR))
    },
    addPaymentMode () {
      this.$_resetState()

      this.currentToEdit = {
        uid: '00000000-0000-0000-0000-000000000000',
        ownerId: this.currentEntity.uid,
        name: '',
        dates: []
      }

      if (this.$refs.details && this.$refs.name) {
        this.$refs.details.scrollIntoView()
        this.$refs.name.focus()
      }
    },
    deletePaymentMode () {
      warning("{Can't delete this payment mode!}")
    },
    createOrUpdate () {
      this.status = 'working'
      postJson('/RealEstate/PaymentMode/Update', this.currentToEdit)
        .then((result) => {
          success(this.SR_InfoMessages.SaveSuccessful)
          this.status = 'success'
          setTimeout(() => {
            this.status = 'none'
          }, 300)
          this.hasChange = false
          get(result)
            .then((row) => {
              row.isSystem = row.ownerId == null
              this.paymentModes = this.paymentModes.filter(r => {
                return r.uid !== row.uid
              }).concat(row)
              this.$nextTick(() => this.$refs.paymentModeGrid.selectRow(row.uid))
            })
        })
        .fail((jqXHR) => {
          error(jqXHR.responseText)
          this.status = 'error'
          this.statusMessage = jqXHR.responseText
        })
    },
    cancelCreateOrUpdate () {
      this.hasChange = false
      this.status = 'none'
      const currentPaymentMode = this.paymentModes.find(mode => {
        return this.selectedPaymentModes[0].uid === mode.uid
      })
      this.currentToEdit.dates = currentPaymentMode.dates
    },
    addPaymentDate () {
      this.openDateModal(newGuid(), '', '', '')
    },
    editPaymentDate () {
      const currentDate = this.selectedDates[0]
      this.openDateModal(currentDate.uid, currentDate.day, currentDate.month, currentDate.comment)
    },
    deletePaymentDate () {
      const dateToDelete = this.selectedDates[0]
      this.currentToEdit.dates = this.currentToEdit.dates.filter(date => {
        return !this.$_compareDates(date, dateToDelete)
      })
      this.onChange()
    },

    openDateModal (dateId, day, month, comment) {
      this.currentDateId = dateId
      this.day = day
      this.month = month
      this.comment = comment
      this.isDateModalOpen = true
    },
    applyDateModal () {
      const datetoChange = this.currentToEdit.dates.find(date => {
        return this.currentDateId === date.uid
      })
      if (datetoChange === undefined) {
        this.currentToEdit.dates.push({uid: this.currentDateId, day: this.day, month: this.month, comment: this.comment})
      } else {
        datetoChange.day = this.day
        this.selectedDates[0].day = this.day
        datetoChange.month = this.month
        this.selectedDates[0].month = this.month
        datetoChange.comment = this.comment
        this.selectedDates[0].comment = this.comment
      }
      this.closeDateModal()
      this.onChange()
    },
    closeDateModal () {
      this.currentDateId = null
      this.day = null
      this.month = null
      this.comment = null
      this.isDateModalOpen = false
    },
    cancelDateModal () {
      if (!this.isDateModalOpen) {
        return
      }
      this.closeDateModal()
    },
    $_compareDates (left, right) {
      return left.uid === right.uid
    },
    onChange () {
      this.hasChange = true
      this.status = 'changed'
    }
  },
  watch: {
    selectedPaymentModes (value) {
      this.$_resetState()
      if (value.length === 1) {
        this.currentToEdit = value[0]
      } else {
        this.currentToEdit = null
      }
    }
  },
  mounted () {
    get('/RealEstate/PaymentMode/GetTenants')
      .then((result) => {
        this.nodes = result
      })
  },
  created () {
    this.$store.dispatch('i18n/preloadTranslations', [
      'Common.SR_Common',
      'Areas.RealEstate.SR_RealEstate',
      'ModelProperties.SR_ModelProperties'
    ])
      .then(() => {
        this.translationsLoaded = true
      })
  }
}
</script>

<style lang="scss" scoped>
  .frame {
    border: 1px solid lightgrey;
    padding: 10px;
    &.readonly {
      background-color: lightgray;
    }
  }
</style>
