{makeValidationRule, Operators, BaseRules} = require './rule-builder'



Required = makeValidationRule(BaseRules.HasValue(), 'A value is required')
RequiredSelectizeGrid = makeValidationRule(BaseRules.HasValueSelectizeGrid(), 'A value is required')
LinkOptional = makeValidationRule(Operators
    .Any(BaseRules.IsEmpty(),
         BaseRules.IsLink()
        ), 'field must either be empty or a valid http link')
IsNumeric = makeValidationRule(BaseRules.IsNumeric(), 'A number is required')


module.exports = {
    ValidationRules: {
        Required,
        RequiredSelectizeGrid,
        LinkOptional,
        IsNumeric
    }
}

module.exports.__esModule = true
module.exports.default = module.exports.ValidationRules
