<template>
  <div>
    <div v-if="$slots.header" class="row">
      <div class="col-sm-10">
        <slot name="header" />
      </div>
    </div>
    <transition-group name="list-complete" tag="div">
      <div v-for="(item, i) in items" :key="item.listkey" class="list-complete-item">
        <div class="row">
          <div class="col-sm-10">
            <component
              :is="selector()" :ref="'element-' + i"
              :value="item"
              :ordinal="i + 1" v-bind="additionalProps"
              @input="changeElement(i, $event)"
            />
          </div>
          <div class="col-sm-1">
            <i class="fa fa-heart invisible">
              <!-- HACK
                      little dirty buggfix to fix the layout
                      problem is that the input boxes have validation enable and get a little icon above them
                      to align the button correctly it helps to have hidden icon above the button
                   -->
            </i>
            <button
              type="button" class="btn btn-default"
              :disabled="!canRemove"
              @click="removeElement(i)"
            >
              <span class="fa fa-trash-o" />
            </button>
          </div>
        </div>
      </div>
    </transition-group>

    <button type="button" class="btn btn-default" @click="addElement">
      <i class="fa fa-plus" aria-hidden="true" />
      {{ addLinkLabel }}
    </button>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  model: {
    prop: 'items'
  },
  props: {
    items: {type: Array, required: true},
    selector: {type: Function, required: true},
    newItemBuilder: {type: Function, default: () => {}},
    addLinkLabel: {type: String, default: 'Add Element'},
    additionalProps: Object
  },
  data () {
    return {
      focusOnNextChange: false
    }
  },

  computed: {
    canRemove () {
      return this.items.length > 1
    }
  },
  methods: {
    removeElement (i) {
      const array = [...this.items]
      array.splice(i, 1)
      this.$emit('input', array)
    },
    addElement () {
      this.focusOnNextChange = true
      this.$emit('input', [...this.items, this.newItemBuilder()])
    },
    changeElement (i, element) {
      const array = [...this.items]
      array[i] = element
      this.$emit('input', array)
    }
  },

  watch: {
    items () {
      if (this.focusOnNextChange) {
        this.focusOnNextChange = false
        Vue.nextTick().then(() => {
          const i = this.items.length - 1
          if (i < 0) {
            return
          }

          const ref = this.$refs['element-' + i][0]
          if (ref && ref.focus && typeof (ref.focus) === 'function') {
            ref.focus()
          }
        })
      }
    }
  }
}
</script>

<style>
a:hover {
  text-decoration-line: underline
}

.list-complete-item {
  transition: all .3s;
}
.list-complete-enter, .list-complete-leave-to {
  opacity: 0;
  transform: translateX(100px);
}

.list-complete-leave-active {
  position: absolute;

}

</style>
