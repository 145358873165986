<template lang="html">
  <div class="form-group">
    <label v-if="label" class="control-label" :for="id">
      {{ label }}
      <span v-if="!allowEmpty"> *</span>
      <a
        v-if="hasDescription" ref="info"
        :data-content="actualInfoTitle"
        data-placement="left" data-trigger="hover"
        class="info text-info"
      ><i class="fa fa-question" aria-hidden="true" /></a>
    </label>
    <vue-selectize
      :options="options" :groups="groups"
      :multiple="multiple"
      :fields="fields" :max-items="maxItems"
      :create="create" :placeholder="placeholder"
      :allow-empty="allowEmpty" :value="value"
      :name="name"
      @input="onInput"
    />
  </div>
</template>

<script>
import VueSelectize from 'src/vue/components/VueSelectize'

let counter = 0

export default {
  components: {
    VueSelectize
  },
  props: ['options', 'groups', 'value', 'multiple', 'fields', 'maxItems', 'create', 'placeholder', 'allowEmpty', 'label', 'name'],
  data () {
    return {
      internalUpdate: false,
      id: `selectize-${counter++}`
    }
  },
  computed: {
    hasDescription () {
      return !this.allowEmpty || this.ruleDescription
    },
    actualInfoTitle () {
      if (!this.allowEmpty) {
        return 'Dies ist ein Pflichtfeld.'
      } else if (this.ruleDescription) {
        return this.ruleDescription
      }
      return ''
    }
  },
  methods: {
    onInput (event) {
      this.$emit('input', event)
    }
  },
  // selectize needs to be updated non-reactively
  watch: {

  }
}
</script>

<style lang="css">
</style>
