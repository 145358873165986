<template lang="html">
  <div class="grid-wrapper">
    <div class="loading-indicator text-center">
      <i class="fa fa-cog fa-spin fa-5x" />
    </div>
    <div class="grid-container fade">
      <script class="data" type="application/json">
        {{ JSON.stringify(data) }}
      </script>
      <script class="columnoptions" type="application/json">
        {{ JSON.stringify(columns) }}
      </script>
      <table
        ref="table" :data-title="title"
        :data-subtitle="subtitle" class="table table-condensed table-striped display"
        :data-selection="selection" :data-minimal="minimal"
        :data-paging="paging"
        :data-ajax-url="ajaxUrl" :data-excel-ajax-url="excelAjaxUrl"
        :data-use-column-filtering="columnFiltering"
      >
        <tbody />
      </table>
      <div
        class="dt-buttons btn-group edit-button-group" :data-add-mode="inline ? 'None' : 'None'"
        :data-edit-mode="inline ? 'Inline' : 'None'"
        :data-delete-mode="inline ? 'None' : 'None'"
      />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {type: Array, default: () => []},
    data: {type: Array},
    ajaxUrl: String,
    ajaxPayload: Object,
    excelAjaxUrl: String,
    columnFiltering: Boolean,
    columns: {type: Array, required: true},
    // header
    title: {type: String, default: ''},
    subtitle: String,
    // options
    minimal: Boolean,
    paging: Boolean,
    selection: String, // 'single', 'multiple', 'os',
    addButton: Boolean,
    editButton: Boolean,
    deleteButton: Boolean,
    inline: {type: Boolean, default: false}
  },

  data () {
    return {
      grid: null,
      isUpdating: 0,
      isSelecting: false,
      buttonHandlers: { },
      needsRefresh: false
    }
  },

  methods: {
    setup () {
      require('src/coffee/controls/grid')

      const deferred = $.Deferred()

      const options = {
        retrieve: true,
        selection: this.selection,
        paging: this.paging,
        minimal: this.minimal,
        promises: {addedToDom: deferred.promise()}
      }

      this.grid = $(this.$refs.table).staticgrid(options)[0]

      // tab column width fix: search for a tab pane in the parent element chain
      // and add an eventhandler for the shown event
      const findParentWithClass = function (child, cssClass) {
        let parent = child.parentNode
        while (parent && !(parent.classList && parent.classList.contains(cssClass))) {
          parent = parent.parentNode
        }
        return parent
      }
      const parent = findParentWithClass(this.$el, 'tab-pane')
      if (parent) {
        parent.addEventListener('shown', () => {
          this.$nextTick(() => this.grid.table.columns.adjust())
        })
      }

      // selection handling
      this.grid.table.on('change.selection', event => {
        if (!this.isSelecting && this.isUpdating === 0 && event.rows) {
          this.$emit('input', event.rows)
        }
      })

      // persist selection through draws
      this.grid.$table.on('draw.dt', () => {
        if (this.isSelecting) return

        this.reselectValuesAlt()
      })

      // show/hide buttons
      for (const name of ['add', 'edit', 'delete']) {
        if (this[`${name}Button`]) {
          this.enableButton(name)
        } else {
          this.hideButton(name)
        }
      }

      // bind button events for inline
      if (this.inline) {
        // NOTE inline editing does not work properly, would need refactoring of core grid code
        this.grid.editor.on('submitSuccess', () => {
          this.$emit('change', this.grid.state.rows())
        })
        // workaround for lack of editor use on row addition
        this.grid.$table.on('postCreate', () => {
          this.$emit('add')
        })
        this.grid.editor.on('postEdit', (e, json, data) => {
          this.$emit('edit', data)
        })
        this.grid.editor.on('postRemove', () => {
          this.$emit('delete')
        })
      }

      // set payloadfunc if ajaxmode
      if (this.ajaxUrl) {
        this.grid.options.ajax.payload = this.payloadFunc
      }

      deferred.resolve()
    },

    enableButton (name) {
      this.grid.editControls[name].$button.show()
      this.grid.editControls[name].$button.enable()
      this.grid.editControls[name].$button.get(0).addEventListener('click', this.createButtonHandler(name))
    },

    createButtonHandler (name) {
      const handler = event => {
        this.$emit(name)
        event.preventDefault()
      }
      this.buttonHandlers[name] = handler
      return handler
    },

    hideButton (name) {
      this.grid.editControls[name].$button.hide()
      this.grid.editControls[name].$button.get(0).removeEventListener('click', this.buttonHandlers[name])
    },

    updateButton (name, state) {
      if (state) {
        this.enableButton(name)
      } else {
        this.hideButton(name)
      }
    },

    setRowIds (rows) {
      rows.forEach((x, i) => {
        x.DT_RowId = x.DT_RowId || `${x.uid ? x.uid : i}`
      })
    },

    directDataUpdateHandler () {
      this.grid.table.rows().remove()
      if (this.data) {
        const processedRows = this.data.map(this.grid.converter.processJSObject)

        this.setRowIds(processedRows)

        this.grid.table.rows.add(processedRows)
        if (this.value && this.value.map) {
          this.grid.table.rows((idx, data) => this.value.map((ele) => ele.DT_RowId).includes(data.DT_RowId)).select()
        }
      }
      this.grid.table.draw(false)
    },

    payloadFunc () {
      return this.ajaxPayload
    },

    // call this to refresh the view in ajax mode
    reloadAjax () {
      if (this.grid) {
        this.isUpdating += 1
        this.grid.table.ajax.reload(null, false)

        this.reselectValues()
        this.needsRefresh = false
        this.isUpdating -= 1
      } else {
        this.needsRefresh = true
      }
    },
    isRowIdSelected (rowId) {
      const selectedItemIds = this.grid.table.rows('.selected').data().toArray().map(x => x.uid)
      return selectedItemIds.includes(rowId)
    },
    selectRow (rowId) {
      if (this.isSelecting || this.isRowIdSelected(rowId)) return

      this.isSelecting = true
      this.grid.selectRow(rowId)

      this.isSelecting = false
    },

    reselectValues () {
      this.isUpdating += 1

      const firstItemId = this.value && this.value.length > 0 ? this.value[0].uid : null

      if (firstItemId) {
        this.selectRow(firstItemId)
      }
      this.isUpdating -= 1
    },
    reselectValuesAlt () {
      this.isUpdating += 1

      if (this.value && this.value.forEach) {
        this.value.forEach((x) => {
          if (x && x.uid) {
            this.grid.table.row('#' + x.uid).select()
          }
        })
      }

      this.isUpdating -= 1
    }
  },

  watch: {
    value () {
      if (this.ajaxUrl) {
        if (!this.isSelecting) {
          this.reselectValues()
        }
      } else {
        this.grid.table.draw(false)
      }
    },

    data: {
      handler: function () {
        if (!this.grid) return
        this.directDataUpdateHandler()
      },
      deep: true
    },

    addButton (val) {
      this.updateButton('add', val)
    },

    editButton (val) {
      this.updateButton('edit', val)
    },

    deleteButton (val) {
      this.updateButton('delete', val)
    },

    ajaxPayload () {
      if (this.ajaxUrl) {
        this.reloadAjax()
      }
    }
  },

  mounted () {
    require.ensure('src/coffee/controls/grid', this.setup)
    if (this.needsRefresh) {
      this.reloadAjax()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
