<template lang="html">
  <div>
    <input
      :id="domId" type="checkbox"
      :checked="value"
      @change="onChange"
    >
    <label v-if="label" :for="domId">{{ label }}</label>
  </div>
</template>

<script>
import newGuid from 'src/js/helpers/new-guid'

export default {
  props: ['value', 'label'],
  data () {
    return {
      domId: newGuid()
    }
  },
  methods: {
    onChange (event) {
      this.$emit('input', event.target.checked)
    }
  }

}
</script>

<style lang="css">
</style>
