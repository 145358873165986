<template lang="html">
  <div class="real-estate">
    <TreeLayout :loading="loading">
      <Tree slot="tree" v-model="selectedTreeNode" :nodes="nodes" />
      <div v-if="translationsLoaded">
        <div class="row">
          <div class="col-md-10">
            <div class="btn-group togglebar">
              <ToggleButton v-model="filterMode" value="CurrentMonth" required>
                <IxRes>Common.SR_Common.CurrentMonth</IxRes>
              </ToggleButton>
              <ToggleButton v-model="filterMode" value="NextMonth" required>
                <IxRes>Common.SR_Common.NextMonth</IxRes>
              </ToggleButton>
              <ToggleButton v-model="filterMode" value="CurrentQuarter" required>
                <IxRes>Common.SR_Common.CurrentQuarter</IxRes>
              </ToggleButton>
              <ToggleButton v-model="filterMode" value="NextQuarter" required>
                <IxRes>Common.SR_Common.NextQuarter</IxRes>
              </ToggleButton>
              <ToggleButton v-model="filterMode" value="CustomTimeRange" required>
                <IxRes>Common.SR_Common.CustomTimeRange</IxRes>
              </ToggleButton>
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
            <DateRangePicker v-model="dateTimeRange" iso :readonly="dateRangePickerReadonly" />
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
            <Grid
              v-model="selectedAppointments" :data="appointmentList"
              :columns="columns"
              selection="os" :title="SR_RealEstate.AppointmentsTitle"
              paging column-filtering
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <button class="btn btn-default btn-block" :disabled="!(canExecuteCommands && canExecuteCancelContract)" @click="showCancelContractsModal">
              <IxRes>Areas.RealEstate.SR_RealEstate.MarkAsCancelled</IxRes>
            </button>
          </div>
          <div class="col-md-3">
            <button class="btn btn-default btn-block" :disabled="!(canExecuteCommands && canExecuteExtendContracts)" @click="showExtendContractsModal">
              <IxRes>Areas.RealEstate.SR_RealEstate.Extend</IxRes>
            </button>
          </div>
        </div>
      </div>
      <BsModal :value="IsCancelContractsModalOpen" blocking>
        <template #title>
          <IxRes>Areas.RealEstate.SR_RealEstate.CancelTheseContracts</IxRes>
        </template>
        <p>
          <IxRes>Areas.RealEstate.SR_RealEstate.TheseContractsAreGoingToBeCanceled</IxRes>:
        </p>
        <ul>
          <li v-for="(contract, i) in selectedCancelableContracts" :key="i">
            {{ contract.leaseContract.text }}
          </li>
        </ul>
        <p>
          <IxRes>Areas.RealEstate.SR_RealEstate.DoYouWantToContinue</IxRes>?
        </p>
        <button
          slot="footer" type="button"
          class="btn btn-default"
          @click="closeCancelContractModal"
        >
          <IxRes>Common.SR_Common.No</IxRes>
        </button>
        <button
          slot="footer" type="button"
          class="btn btn-primary"
          @click="markAsCancelled"
        >
          <IxRes>Common.SR_Common.Yes</IxRes>
        </button>
      </BsModal>
      <BsModal :value="IsExtendContractsModalOpen" blocking>
        <template #title>
          <IxRes>Areas.RealEstate.SR_RealEstate.ExtendTheseContracts</IxRes>
        </template>
        <p>
          <IxRes>Areas.RealEstate.SR_RealEstate.TheseContractsAreGoingToBeExtended</IxRes>:
        </p>
        <ul>
          <li v-for="(contract, i) in selectedExtendableContracts" :key="i">
            {{ contract.leaseContract.text }}
          </li>
        </ul>
        <p>
          <IxRes>Areas.RealEstate.SR_RealEstate.DoYouWantToContinue</IxRes>?
        </p>
        <button
          slot="footer" type="button"
          class="btn btn-default"
          @click="closeExtendContractModal"
        >
          <IxRes>Common.SR_Common.No</IxRes>
        </button>
        <button
          slot="footer" type="button"
          class="btn btn-primary"
          @click="extend"
        >
          <IxRes>Common.SR_Common.Yes</IxRes>
        </button>
      </BsModal>
    </TreeLayout>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapState, mapMutations} from 'vuex'
import moment from 'moment'

import {mapResources} from '@helpers/vuex'
import {findNode} from 'src/coffee/controls/tree/helpers'

import TreeLayout from 'src/vue/legacy-compat/TreeLayout'
import Tree from '@components/nav/Tree'

import BsModal from 'src/vue/components/bootstrap/BsModal'
import ToggleButton from '@components/ToggleButton'
import DateRangePicker from '@components/VueDateTimeRangePicker'

import Grid from 'src/vue/areas/real-estate/components/grid/RELegacyGrid.vue'

import makeTree from './makeTree'

export default {
  components: {
    Tree,
    TreeLayout,
    BsModal,
    Grid,
    ToggleButton,
    DateRangePicker
  },

  props: ['value', 'dataFromNotification', 'initialEntityId', 'initialStart', 'initialEnd'],

  data () {
    return {
      translationsLoaded: false,
      filterMode: '',
      loading: false,
      dateRangePickerReadonly: true,
      dateTimeRange: {
        start: '',
        end: ''
      },
      urlDataSetSet: false,
      selectedOrganisationUnit: null,
      IsCancelContractsModalOpen: false,
      IsExtendContractsModalOpen: false
    }
  },

  computed: {
    ...mapState('realEstateContractLeaseAppointment/appointments', {
      currentAppointments: state => state.currentAppointments
    }),
    ...mapGetters('realEstateContractLeaseAppointment/appointments', {
      appointments: 'appointments',
      canEditAppointments: 'canEditAppointments'
    }),
    ...mapGetters('realEstateContractLeaseAppointment/navigation', {
      hasCurrentOrgUnit: 'hasCurrentOrgUnit',
      orgUnits: 'orgUnits',
      selectedOrgUnit: 'selectedOrgUnit'
    }),
    selectedTreeNode: {
      get () {
        let value = this.selectedOrgUnit

        if (value && !value.name && !value.template) { // value is not a orgUnit from tree
          const foundNode = findNode(this.nodes, x => x.id === value.uid)

          if (foundNode && foundNode.entity) {
            value = foundNode.entity
          }
        }

        return value
      },
      set (value) {
        this.setSelectedOrgUnit(value)
      }
    },
    appointmentList () {
      return this.appointments.map(a => {
        return {
          ...a,
          nextPossibleTerm: a.nextPossibleTermStart != null && a.nextPossibleTermEnd != null ? `${this.$i18n.format(a.nextPossibleTermStart, 'date')} - ${this.$i18n.format(a.nextPossibleTermEnd, 'date')}` : '',
          totalDurationOfTheContractSoFar: `${this.$i18n.format(a.totalDurationOfTheContractSoFarStart, 'date')} - ${this.$i18n.format(a.totalDurationOfTheContractSoFarEnd, 'date')}`
        }
      })
    },
    selectedAppointments: {
      get () {
        return this.currentAppointments
      },
      set (value) {
        const appointments = value && value.length > 0 ? value : []

        if (appointments !== this.currentAppointments) {
          this.selectAppointments(appointments)
        }
      }
    },
    selectedExtendableContracts () {
      return this.selectedAppointments.filter(appointment => appointment.nextPossibleTerm.length > 0)
    },
    selectedCancelableContracts () {
      return this.selectedAppointments.filter(val => val.type.value === 3)
    },
    canExecuteCommands () {
      return this.selectedAppointments.length > 0 && this.canEditAppointments
    },
    canExecuteCancelContract () {
      return this.selectedCancelableContracts && this.selectedCancelableContracts !== 0
    },
    canExecuteExtendContracts () {
      return this.selectedExtendableContracts && this.selectedExtendableContracts !== 0
    },
    start () {
      return this.dateTimeRange.start
    },
    end () {
      return this.dateTimeRange.end
    },
    nodes () {
      return this.orgUnits.map(makeTree)
    },

    columns () {
      return [
        {localizedTitle: '', name: 'Link', typeName: 'LinkUri', propertyName: 'url', orderable: false, searchable: false},
        {localizedTitle: this.SR_ModelProperties['LeaseContract'], typeName: 'String', propertyName: 'leaseContract.text', searchable: false, maxLength: 20},
        {localizedTitle: this.SR_ModelProperties['Partner'], typeName: 'String', propertyName: 'partner', searchable: false, maxLength: 20},
        {localizedTitle: this.SR_ModelProperties['Type'], typeName: 'String', propertyName: 'type.displayName'},
        {localizedTitle: this.SR_ModelProperties['Date'], typeName: 'DateTime', propertyName: 'date', dateTimeFormat: 'L', orderData: 0, searchable: false},
        {localizedTitle: this.SR_ModelProperties['TotalDurationOfTheContractSoFar'], typeName: 'String', propertyName: 'totalDurationOfTheContractSoFar', orderable: false, searchable: false},
        {localizedTitle: this.SR_ModelProperties['NextPossibleTerm'], typeName: 'String', propertyName: 'nextPossibleTerm', orderable: false, searchable: false}
      ]
    },
    ...mapResources([
      'Areas.RealEstate.SR_RealEstate',
      'ModelProperties.SR_ModelProperties'
    ])
  },

  methods: {
    ...mapActions('realEstateContractLeaseAppointment', [
      'initRequest',
      'getFilteredAppointments',
      'cancelContracts',
      'extendContracts']),
    ...mapActions('realEstateContractLeaseAppointment/navigation', [
      'selectOrgUnit']),
    ...mapMutations('realEstateContractLeaseAppointment/appointments', [
      'selectAppointments'
    ]),
    setSelectedOrgUnit (orgUnit) {
      this.selectOrgUnit({orgUnit: orgUnit, start: this.start, end: this.end})
    },
    setDateRangePickerReadonly (readonly) {
      this.dateRangePickerReadonly = readonly
    },
    markAsCancelled () {
      this.cancelContracts({orgUnit: this.selectedOrgUnit, contractIds: this.selectedCancelableContracts.map(v => v.leaseContract.value), start: this.start, end: this.end})
      this.closeCancelContractModal()
    },
    extend () {
      this.extendContracts({orgUnit: this.selectedOrgUnit, start: this.start, end: this.end})
      this.closeExtendContractModal()
    },
    showExtendContractsModal () {
      this.IsExtendContractsModalOpen = true
    },
    showCancelContractsModal () {
      this.IsCancelContractsModalOpen = true
    },
    closeCancelContractModal () {
      this.IsCancelContractsModalOpen = false
    },
    closeExtendContractModal () {
      this.IsExtendContractsModalOpen = false
    }

  },
  watch: {
    start (value) {
      if (this.selectedOrgUnit && value && this.end && this.urlDataSet) {
        this.getFilteredAppointments({orgUnit: this.selectedOrgUnit, start: value, end: this.end})
      }
    },
    end (value) {
      if (this.selectedOrgUnit && this.start && value && this.urlDataSetSet) {
        this.getFilteredAppointments({orgUnit: this.selectedOrgUnit, start: this.start, end: value})
      }
    },
    filterMode (value) {
      const nextMonth = moment().add(1, 'month')
      const quarter = moment().quarter()
      const nextQuarter = moment().add(1, 'quarter')

      switch (value) {
      case 'CurrentMonth':
        this.dateTimeRange.start = moment().startOf('month').toISOString()
        this.dateTimeRange.end = moment().endOf('month').toISOString()
        this.setDateRangePickerReadonly(true)
        break
      case 'NextMonth':
        this.dateTimeRange.start = nextMonth.startOf('month').toISOString()
        this.dateTimeRange.end = nextMonth.endOf('month').toISOString()
        this.setDateRangePickerReadonly(true)
        break
      case 'CurrentQuarter':
        this.dateTimeRange.start = moment().quarter(quarter).startOf('quarter').toISOString()
        this.dateTimeRange.end = moment().quarter(quarter).endOf('quarter').toISOString()
        this.setDateRangePickerReadonly(true)
        break
      case 'NextQuarter':
        this.dateTimeRange.start = nextQuarter.startOf('quarter').toISOString()
        this.dateTimeRange.end = nextQuarter.endOf('quarter').toISOString()
        this.setDateRangePickerReadonly(true)
        break
      case 'CustomTimeRange':
        this.setDateRangePickerReadonly(false)
        break
      }
    }
  },

  mounted () {
    if (this.dataFromNotification) {
      this.filterMode = 'CustomTimeRange'
      this.dateTimeRange = {}
      this.dateTimeRange.start = moment(this.initialStart)
      this.dateTimeRange.end = moment(this.initialEnd)
      this.setSelectedOrgUnit({uid: this.initialEntityId})
    } else {
      this.filterMode = 'CurrentMonth'
    }
    this.urlDataSet = true
    this.initRequest()
  },

  created () {
    this.$store.dispatch('i18n/preloadTranslations', [
      'Common.SR_Common',
      'Areas.RealEstate.SR_RealEstate',
      'ModelProperties.SR_ModelProperties'
    ])
      .then(() => {
        this.translationsLoaded = true
      })
  }
}

</script>
<style lang="css">
  .togglebar{
    margin-top: 25px
  }

</style>
