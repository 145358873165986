<template lang="html">
  <div class="real-estate">
    <tree-layout :loading="loading">
      <tree slot="tree" v-model="selectedOrgUnit" :nodes="nodes" />
      <div v-if="translationsLoaded">
        <grid
          v-if="!isCreateContact" ref="grid"
          v-model="selectedContacts" :columns="columns"
          selection="single"
          :title="SR_RealEstate.Contacts" paging
          :add-button="hasCurrentOrgUnit" :edit-button="hasCurrentContact"
          :create-button="hasCurrentContact"
          ajax-url="/RealEstate/Contacts/LoadContacts" :ajax-payload="ajaxPayload"
          column-filtering @add="addContact"
          @edit="editContactRequest" @delete="deleteContact"
        />
        <div class="detail-body">
          <div v-if="hasCurrentContact && !isCreateContact">
            <bs-tabs v-model="activeTab" :tabs="allTabs">
              <contact-common slot="0" v-model="currentContact" />
              <contact-communications slot="1" v-model="currentContact" />
              <contact-banking-accounts slot="2" v-model="currentContact" />
            </bs-tabs>
            <div class="row">
              <div class="col-sm-offset-8 col-sm-2">
                <button
                  slot="footer" type="button"
                  class="btn btn-success btn-block" :disabled="!isEditChanged"
                  @click="editContactSuccessWithNotification"
                >
                  <IxRes :resource="SR_Common">
                    Save
                  </IxRes>
                </button>
              </div>
              <div class="col-sm-2">
                <button
                  slot="footer" type="button"
                  class="btn btn-default btn-block"
                  :disabled="!isEditChanged" @click="editContactFailure"
                >
                  <IxRes :resource="SR_Common">
                    Cancel
                  </IxRes>
                </button>
              </div>
            </div>
          </div>

          <div v-if="isCreateContact">
            <h1>
              <IxRes :resource="SR_RealEstate">
                CreateNewContact
              </IxRes>
            </h1>
            <bs-tabs v-model="activeCreateTab" :tabs="allCreateTabs">
              <contact-common slot="0" v-model="newContact" create-mode />
              <contact-communications slot="1" v-model="newContact" />
              <contact-banking-accounts slot="2" v-model="newContact" />
            </bs-tabs>
            <div class="row">
              <div class="col-sm-offset-8 col-sm-2">
                <button
                  slot="footer" type="button"
                  class="btn btn-success btn-block" :disabled="!isEditChanged"
                  @click="createNewContactSuccessWithNotification"
                >
                  <IxRes :resource="SR_Common">
                    Create
                  </IxRes>
                </button>
              </div>
              <div class="col-sm-2">
                <button
                  slot="footer" type="button"
                  class="btn btn-default btn-block"
                  @click="createNewContactFailure"
                >
                  <IxRes :resource="SR_Common">
                    Cancel
                  </IxRes>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </tree-layout>
    <save-or-discard-modal
      :value="isSaveChangesModalOpen" @cancel="cancel"
      @no="discardAndNavigate"
      @yes="saveAndNavigate"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters, mapActions} from 'vuex'

import BsTabs from 'src/vue/components/bootstrap/BsTabs'

import TreeLayout from 'src/vue/legacy-compat/TreeLayout'
import Tree from '@components/nav/Tree'
import Grid from 'src/vue/areas/real-estate/components/grid/RELegacyGrid.vue'

import ContactCommon from './contact-common'
import ContactBankingAccounts from './contact-banking-accounts'
import ContactCommunications from './contact-communications'
import SaveOrDiscardModal from './save-or-discard-modal'

import makeTree from './makeTree'

import {notifications} from 'src/js/infrastructure'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    BsTabs,
    Tree,
    TreeLayout,
    Grid,
    ContactCommon,
    ContactCommunications,
    ContactBankingAccounts,
    SaveOrDiscardModal
  },
  mixins: [makeResourceMixin(resources)],
  data () {
    return {
      translationsLoaded: false,
      loading: false,
      selectedOrganisationUnit: null,
      isModalOpen: false,
      activeTab: 0,
      activeCreateTab: 0,
      potentialNavigationTarget: null,
      potentialNavigationTargetType: null
    }
  },
  computed: {
    ...mapGetters('realEstateMasterDataContacts/contacts', {
      contacts: 'contacts',
      currentContact: 'currentContact',
      newContact: 'newContact',
      isCreateContact: 'isCreateContact',
      isEditContact: 'isEditContact',
      isEditChanged: 'isEditChanged',
      hasCurrentContact: 'hasCurrentContact'}),
    ...mapGetters('realEstateMasterDataContacts/navigation', {
      hasCurrentOrgUnit: 'hasCurrentOrgUnit',
      orgUnits: 'orgUnits',
      selectedOrgUnitX: 'selectedOrgUnit'}),
    ...mapGetters('realEstateMasterDataContacts/save', {
      isSaveChangesModalOpen: 'isSaveChangesModalOpen'
    }),
    selectedOrgUnit: {
      get () {
        return this.selectedOrgUnitX
      },
      set (value) {
        this.maybeNavigateToOrgUnit(value)
      }
    },
    selectedContacts: {
      get () {
        return [this.currentContact]
      },
      set (value) {
        const contact = value && value.length > 0 ? value[0] : null

        if (contact !== this.currentContact) {
          this.maybeNavigateToContact(contact ? contact.uid : null)
        }
      }
    },
    nodes () {
      return this.orgUnits.map(makeTree)
    },

    columns () {
      return [
        {typeName: 'String', propertyName: 'uid', invisible: true, isIdColumn: true},
        {localizedTitle: this.SR_ModelProperties['Name'], typeName: 'String', propertyName: 'name'},
        {localizedTitle: this.SR_ModelProperties['NameAdditional'], typeName: 'String', propertyName: 'nameAdditional'},
        {localizedTitle: this.SR_ModelProperties['Street'], typeName: 'String', propertyName: 'street'},
        {localizedTitle: this.SR_ModelProperties['ZipCity'], typeName: 'String', propertyName: 'postalCodeCity'},
        {localizedTitle: this.SR_ModelProperties['Info'], typeName: 'String', propertyName: 'info'}
      ]
    },

    allTabs () {
      return [
        this.getLocalizedTitleByResourceName('Common'),
        this.getLocalizedTitleByResourceName('Communicatons'),
        this.getLocalizedTitleByResourceName('BankingAccounts')
      ]
    },

    allCreateTabs () {
      return [
        this.getLocalizedTitleByResourceName('Common'),
        this.getLocalizedTitleByResourceName('Communicatons'),
        this.getLocalizedTitleByResourceName('BankingAccounts')
      ]
    },

    ajaxPayload () {
      return {
        Id: this.selectedOrgUnit ? this.selectedOrgUnit.id : null
      }
    }
  },
  methods: {
    ...mapActions('realEstateMasterDataContacts', [
      'initRequest']),
    ...mapActions('realEstateMasterDataContacts/contacts', [
      'selectContact',
      'deleteContact',
      'createNewContactRequest',
      'createNewContactSuccess',
      'createNewContactFailure',
      'editContactRequest',
      'editContactFailure',
      'editContactSuccess']),
    ...mapActions('realEstateMasterDataContacts/navigation', [
      'selectOrgUnit']),
    ...mapActions('realEstateMasterDataContacts/save', [

      'reseContactChanges',
      'saveChangesRequest',
      'saveChangesCancel',
      'saveChangesFailure',
      'saveChangesSuccess']),

    getLocalizedTitleByResourceName (resourceName) {
      return this.SR_RealEstate[resourceName]
    },

    maybeNavigateToContact (target) {
      if (this.isEditChanged) {
        this.potentialNavigationTarget = target
        this.potentialNavigationTargetType = 'contact'
        this.saveChangesRequest()
      } else {
        this.selectContact(target)
      }
    },
    createNewContactSuccessWithNotification () {
      this.createNewContactSuccess()
        .then(() => {
          notifications.success(this.SR_RealEstate.SaveContactSuccessMessage)
          Vue.nextTick().then(this.refreshGrid)
          Vue.nextTick().then(this.$refs.grid.selectRow(this.selectedContacts[0].uid))
        })
    },
    editContactSuccessWithNotification () {
      this.editContactSuccess()
        .then(() => {
          notifications.success(this.SR_RealEstate.SaveContactSuccessMessage)
          this.refreshGrid()
        })
    },

    refreshGrid () {
      this.$refs.grid.reloadAjax()
    },

    saveAndNavigate () {
      if (this.isCreateContact) {
        this.createNewContactSuccessWithNotification()
          .then(this.navigateAway)
      } else {
        this.saveChangesSuccess()
          .then(this.navigateAway)
          .then(this.refreshGrid)
      }
    },

    navigateAway () {
      if (this.potentialNavigationTargetType === 'contact') {
        this.selectContact(this.potentialNavigationTarget)
      } else if (this.potentialNavigationTargetType === 'newContact') {
        this.createNewContactRequest()
      } else if (this.potentialNavigationTargetType === 'orgUnit') {
        this.selectOrgUnit(this.potentialNavigationTarget)
        this.selectContact(null)
      }
    },

    discardAndNavigate () {
      if (this.isCreateContact) {
        this.createNewContactFailure()
          .then(this.saveChangesFailure)
          .then(this.navigateAway)
      } else {
        this.saveChangesFailure()
          .then(this.navigateAway)
      }
    },

    maybeNavigateToOrgUnit (target) {
      if (this.isEditChanged) {
        this.potentialNavigationTarget = target
        this.potentialNavigationTargetType = 'orgUnit'
        this.saveChangesRequest()
      } else {
        if (this.isCreateContact) {
          this.createNewContactFailure()
        }
        this.selectContact(null)
        this.selectOrgUnit(target)
      }
    },

    cancel () {
      this.saveChangesCancel()
    },

    addContact () {
      if (!this.isEditChanged) {
        this.createNewContactRequest()
      } else {
        this.potentialNavigationTarget = null
        this.potentialNavigationTargetType = 'newContact'
        this.saveChangesRequest()
      }
    }
  },

  mounted () {
    this.initRequest()
    this.activeTab = 0
    this.activeCreateTab = 0
  },

  created () {
    this.$store.dispatch('i18n/preloadTranslations', [
      'Common.SR_Common',
      'Areas.RealEstate.SR_RealEstate',
      'ModelProperties.SR_ModelProperties'
    ])
      .then(() => {
        this.translationsLoaded = true
      })
  }
}

</script>
<style lang="css">

</style>
