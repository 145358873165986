<template lang="html">
  <div class="real-estate">
    <masterdata-ui
      :include-tree="true" :columns="columns"
      :load-items-action-url="loadItemsActionUrl" :create-item-action-url="createItemActionUrl"
      :update-item-action-url="updateItemActionUrl" :grid-title="SR_RealEstate.District"
      :modal-title-add="SR_RealEstate.AddDistrict"
      :modal-title-edit="SR_RealEstate.EditDistrict" :setup-modal-content="setupModalContent"
      :build-object-for-request="buildDistrictObject"
      @entityChanged="updateEntity"
    />
  </div>
</template>

<script lang="js">
import masterdataUi from './masterdata-ui'
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'
import newGuid from 'src/js/helpers/new-guid'
import {get} from 'src/coffee/infrastructure/ajax'

const resources = {
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate'
}

export default {
  components: {
    masterdataUi
  },
  mixins: [makeResourceMixin(resources)],

  data () {
    return {
      loadItemsActionUrl: '/RealEstate/MasterDataDistrict/GetDistricts',
      createItemActionUrl: '/RealEstate/MasterDataDistrict/Create',
      updateItemActionUrl: '/RealEstate/MasterDataDistrict/Update',
      entityId: null,
      regions: []
    }
  },

  computed: {
    columns () {
      return [
        {
          localizedTitle: this.SR_RealEstate['District'],
          typeName: 'String',
          propertyName: 'name'
        },
        {
          localizedTitle: this.SR_RealEstate['Region'],
          typeName: 'String',
          propertyName: 'regionName'
        },
        {
          localizedTitle: 'IsDistrictFreeCity',
          typeName: 'Boolean',
          propertyName: 'isDistrictFreeCity',
          invisible: true
        },
        {
          localizedTitle: 'UID',
          typeName: 'String',
          propertyName: 'uid',
          isIdColumn: true,
          invisible: true
        }
      ]
    }
  },

  methods: {
    setupModalContent (data = {uid: newGuid(), name: '', regionName: ''}) {
      return [
        {propertyName: 'uid', value: data.uid, label: '', required: false, editable: false},
        {propertyName: 'name', value: data.name, label: this.SR_RealEstate.District, required: true, editable: true},
        {propertyName: 'region', value: data.regionId ? {value: data.regionId, text: data.regionName} : null, label: this.SR_RealEstate.Region, required: true, editable: true, dataType: 'selectize', selectizeOptions: this.regions},
        {propertyName: 'isDistrictFreeCity', value: data.isDistrictFreeCity, label: this.SR_RealEstate.DistrictFreeCity, required: false, editable: true, dataType: 'checkbox'}
      ]
    },

    updateEntity (entityId) {
      this.entityId = entityId
    },

    buildDistrictObject (data) {
      return {
        uid: data[0].value, // TODO: find better solution
        name: data[1].value,
        regionId: data[2].value.value,
        regionName: data[2].value.text,
        isDistrictFreeCity: data[3].value,
        fkDataOwner: this.entityId
      }
    }

  },

  created () {
    this.$store.dispatch('i18n/preloadTranslations', [
      'Areas.RealEstate.SR_RealEstate'
    ])
    get('/RealEstate/Region/CountriesWithRegions')
      .then((result) => {
        for (let countryId in result) {
          this.regions = this.regions.concat(result[countryId])
        }
      })
  }
}
</script>

<style lang="scss" scoped>
</style>
