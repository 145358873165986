{notifications} = require 'src/js/infrastructure'
{get, postJson} = require 'src/coffee/infrastructure/ajax'

{makeGridAParent} = require 'src/coffee/controls/grid/selection-grid-extension'

{Validation} = require 'src/coffee/helpers/validation'
{ValidationRules} = require 'src/coffee/controls/grid/validation-rules'

{Binder, Stati, GridExtender} = require 'src/coffee/areas/real-estate/utils'
{FacilityPartDetailController} = require './facility-part-detail-controller'

class FacilityDetailController
    constructor: (@$view, @onSaveCallback) ->
        @data = null
        @entityId = null

        @formatter2d = (value) ->
            #inplace import because of problem with closure and live replacement of the binding
            {format} = require 'src/js/i18n/conversion'
            return format(value, 'f2')

        @binder = new Binder(@$view, @onChange, Validation.invalidatedHandler)

        @stati = new Stati(@$view, 'common', 'facility-parts', 'insurance')

        @partDetails = new FacilityPartDetailController(@$view, @onPartDetailsChanged)

        @partDetails.onFacilityPartTypeChanged (result) =>
            bindingsItem = @binder.bindings['#facility-part-grid']

            gridData = bindingsItem.getData()
            resultRow = gridData.find((x) -> x.uid is result.facilityPartUid)

            newPartType = result.facilityPartType

            if newPartType
                resultRow.type = newPartType
                resultRow.typeName = newPartType.name
            else
                resultRow.type = ''
                resultRow.typeName = ''

            bindingsItem.setData(gridData)

        @partDetails.onSizeChanged (result) =>
            bindingsItem = @binder.bindings['#facility-part-grid']

            gridData = bindingsItem.getData()
            resultRow = gridData.find((x) -> x.uid is result.facilityPartUid)
            resultRow.dimensionsOuterSurface = result.dimensionOuterSurfaceSize

            resultRow.dimensionsOuterSurfaceToDisplay =
                @partDetails.getDimensionsOuterSurfaceToDisplayFromFacilityPart(result)
            bindingsItem.setData(gridData)

        @partDetails.onDeductiblesChanged (result) =>
            @stati.setTabStatus 'facility-parts', 'changed'

            @applyChangesButton.prop 'disabled', false
            @resetChangesButton.prop 'disabled', false

        @setupControls()
        @setupAddFacilityPart()
        @setupDMSReferenceTable()

        @validationState = {
            commonTabState: {}
            facilityPartsTabState: {}
            insuranceTabState: {}
        }

        @commonTab = $('.tab-content > #common')
        @facilityPartTab = $('.tab-content > #facility-parts')
        @insuranceTab = $('.tab-content > #insurance')
        @registerEvents()

    setupDMSReferenceTable: ->
        referenceGrid = @$view.find('#dms-reference-grid').staticgrid()[0]
        reference = referenceGrid.columnLookup['reference']
        referenceLink = referenceGrid.columnLookup['referenceLink']
        reference.dataValidation = ValidationRules.Required
        referenceLink.dataValidation = ValidationRules.LinkOptional

    update: (id) ->
        url =  $('#actions').data('get-facility-details')
        get url, {id}
            .done (result) =>
                @updateContent(result)

    updateContent: (facility) ->
        @data = facility
        @id = @data.uid
        @stati.changeStatus to: 'none'

        if @data.property
            @data.propertyLink = "/RealEstate/Company/#{@data.property.assignedOrgUnitId}/Property/#{@data.property.uid}"

        @resetSelectizeOptions()
        @binder.updateControls(@data)
        @binder.enableControls()

        $('#common-form').clearValidation()
        $('#insurance-form').clearValidation()
        Validation.resetState(@validationState)
        Validation.invalidateControls(
            @binder.getIdentifiers((binding) -> binding.type isnt 'hidden' and not binding.isReadOnly),
            true)

        @applyChangesButton.prop 'disabled', true
        @resetChangesButton.prop 'disabled', true

        @facilityPartsGrid.editControls.add.$button.enable()
        @data.parts.map((part) -> part.uid).reverse().forEach((id) => @facilityPartsGrid.selectRow(id))

    updateEntityId:(entityId) ->
        @entityId = entityId
        @partDetails.updateEntityId(entityId)

    moveToFirstTabAndFirstInput: =>
        $('.nav-tabs a:first').tab('show')
        @binder.getControl('#facility-name').focus()

    setupControls: ->
        parser = (value) ->
            #inplace import because of problem with closure and live replacement of the binding
            {parse} = require 'src/js/i18n/conversion'
            return parse(value)

        @saveOrUpdateAction = $('#actions').data('save-or-update-action')
        @loadSeDetailsAction = $('#actions').data('get-facility-part-details-action')
        @removeFacilityPartModalText = $('#actions').data('remove-facility-part-modal-text')
        @editAddressModalAction = $('#actions').data('edit-address-modal-action')
        @loadRegions = $('#actions').data('region-load-action')
        @loadFacilityTypesAction = $('#actions').data('facility-type-load-action')
        @loadPhotovoltaicSystemStatesAction = $('#actions').data('photovoltaic-system-states-action')
        @loadPotentialForUseAction = $('#actions').data('potential-for-use-load-action')
        @loadPropertiesAction = $('#actions').data('properties-load-action')
        @saveFacilityErrorMessage =  $('#actions').data('save-facility-error-message')
        @saveFacilitySuccessMessage =  $('#actions').data('save-facility-success-message')
        @validateFacilityErrorMessage = $('#actions').data('validate-facility-error-message')

        @binder.bindHiddenValue('##UID', 'uid')
        @binder.bindHiddenValue('##AssignedEntityId', 'assignedEntityId')
        @binder.bindHiddenValue('##FKPropertyId', 'fkPropertyId')

        @binder.bindLink('#property-link', 'propertyLink')

        @binder.bindInput('#facility-name', 'name')

        hasOptionCaseInsensitive = (input, control) ->
            for name, option of control.options
                return false if option.name.toLowerCase() is input.toLowerCase()
            return true

        @binder.bindAjaxSelectize('#property', 'property',
                            @loadPropertiesWithAssignedOrgunit,
                            {
                                render:
                                    option: @renderPropertyWithOrgUnit
                                    item: @renderPropertyWithOrgUnit

                            },
                            hasOptionCaseInsensitive)

        $property = @binder.bindings['#property'].control
        $property.on 'change', => #Load Regionname on selected property change
            url = @loadRegions
            data = @binder.bindings['#property'].getData()
            return unless data
            propertyId = data.uid

            get url, {propertyId}
                .done (result) =>
                    @binder.bindings['#region-name'].setData(result)

        @binder.bindAjaxSelectize('#facility-type', 'type', @loadFacilityTypes,
                            {
                                create: (input) ->
                                    $('#facility-type')[0].selectize
                                    .removeOption('00000000-0000-0000-0000-000000000000')

                                    return {
                                        name: input
                                        uid: '00000000-0000-0000-0000-000000000000'
                                    }
                            },
                            hasOptionCaseInsensitive)

        @binder.bindInput('#region-name', 'region', true)
        @binder.bindGrid('#dms-reference-grid', 'dmsReferences')

        @binder.bindInput('#remark', 'remark')
        @binder.bindInput('#insurance-related-data', 'insuranceRelatedData')
        @binder.bindInput('#coverageAmount-allocated-proportionally', 'coverageAmountAllocatedProportionally', false, @formatter2d, parser)

        @binder.bindAjaxSelectize('#potential-for-use', 'potentialForUse', @loadPotentialForUseTypes,
                    {
                        create: (input) ->
                            $('#potential-for-use')[0].selectize.removeOption('00000000-0000-0000-0000-000000000000')

                            return {
                                name: input
                                uid: '00000000-0000-0000-0000-000000000000'
                            }
                    },
                    hasOptionCaseInsensitive)

        options =  {
            valueField: 'value'
            labelField: 'displayName'
            searchField: 'displayName'
            sortField: 'displayName'
            create: null
        }

        @binder.bindAjaxSelectize('#photovoltaic-system-state',
            'photovoltaicSystemState',
            @loadPhotovoltaicSystemStates,
            options,
            hasOptionCaseInsensitive
            )

        @applyChangesButton = @$view.find('#applyChangesBtn')
                                .prop 'disabled', true
                                .on 'click', @saveOrUpdate


        @resetChangesButton = @$view.find('#resetChangesBtn')
                                .prop 'disabled', true
                                .on 'click', @reset

        @facilityPartsGrid = @binder.bindGrid('#facility-part-grid', 'parts')
        gridExtender = new GridExtender(@facilityPartsGrid)
        gridExtender.addExtendedRemoveConfirmationDialog(@removeFacilityPartModalText)

        @facilityPartsGrid.editControls.add.$button.disable()

        miniController = {
            clear: => @partDetails.clear()
            update: (id) =>
                parts = @binder.readControl('#facility-part-grid')
                @partDetails.updateContent(parts.find((part) -> part.uid is id))
        }

        makeGridAParent(@facilityPartsGrid, miniController)

        @setupConfirmationDialog()

    resetSelectizeOptions: =>
        @binder.bindings['#property'].control.clearOptions()
        @binder.bindings['#property'].control.refreshOptions()
        @binder.bindings['#facility-type'].control.clearOptions()
        @binder.bindings['#facility-type'].control.refreshOptions()
        @binder.bindings['#potential-for-use'].control.clearOptions()
        @binder.bindings['#potential-for-use'].control.refreshOptions()
        @binder.bindings['#photovoltaic-system-state'].control.clearOptions()
        @binder.bindings['#photovoltaic-system-state'].control.refreshOptions()

    setupConfirmationDialog: ->
        @confirmationDialog = @$view.find('#confirmationDialog').modal show: false
        @confirmButton = @$view.find '#confirmBtn'
        @dismissButton = @$view.find '#dismissBtn'
        @cancelButton = @$view.find '#cancelBtn'

    loadFacilityTypes: (query, callback) =>
        get(@loadFacilityTypesAction, {query: query, entityId: @entityId})
            .done (result) -> callback(result)

    loadPotentialForUseTypes: (query, callback) =>
        get("#{@loadPotentialForUseAction}?query=#{query}")
            .done (result) -> callback(result)

    loadPhotovoltaicSystemStates: (query, callback) =>
        get("#{@loadPhotovoltaicSystemStatesAction}")
            .done (result) -> callback(result)

    loadPropertiesWithAssignedOrgunit: (query, callback) =>
        url = @loadPropertiesAction
        data =
            entityId: @binder.readControl('##AssignedEntityId')
            query: query
        get url, data
            .done (result) -> callback(result)

    createNewFacility: (entityId) ->
        firstPartPromise = @partDetails.createNewFacilityPart('00000000-0000-0000-0000-000000000000')
        firstPartPromise.then (firstPart) ->
            newFacility =
                uid: '00000000-0000-0000-0000-000000000000'
                type: undefined
                assignedEntityId: entityId
                dmsReferences: [],
                parts: [firstPart],

            return newFacility

    create: (entityId) ->
        @ensureChangesAreConfirmedBefore =>
            @createNewFacility(entityId)
            .then (data) =>
                @updateContent(data)

        @moveToFirstTabAndFirstInput()

    saveOrUpdate: =>
        if not Validation.isStateValid(@validationState)
            for key, value of @stati.trackers
                if value.currentState is 'invalid'
                    translatedTabName = $(".nav.nav-tabs [href=##{key}]>span").text().trim()
                    notifications.error("#{@validateFacilityErrorMessage} #{translatedTabName}")
            for tabName, tabState of @validationState
                for propertyName, isOk of tabState when not isOk
                    if(propertyName.indexOf('grid_wrapper') isnt -1)
                        errorMessage = $('#' + propertyName).parents('[data-validation-error]').data('validation-error')
                    else
                        errorMessage = $('#' + propertyName).data('validation-error')
                notifications.error(errorMessage)
            return

        @stati.changeStatus from: 'changed', to: 'working'

        @data = @binder.readControls()

        for tabName in ['common', 'facility-parts', 'insurance']
            $('.tab-content #' + tabName + ' form').clearValidation()


        url = @saveOrUpdateAction
        postJson(url, @data)
            .done (result) =>
                @stati.changeStatus from: 'working', to: 'success'
                @applyChangesButton.prop 'disabled', true
                @resetChangesButton.prop 'disabled', true
                notifications.success @saveFacilitySuccessMessage
                @onSaveCallback(result)
            .fail (result) =>
                @stati.changeStatus from: 'working', to: 'error'
                notifications.error  @saveFacilityErrorMessage


    reset: =>
        @binder.clearControls()
        @updateContent(@data)

    hasChanges: ->
        @hasAnyChanges()

    hasAnyChanges: ->
        return @stati.hasAnyChanges()

    hasAnyInvalidValidations: ->
        return @stati.hasAnyInvalidValidations()

    dismissChanges: ->
        @reset()

    saveChanges: ->
        @saveOrUpdate()

    onChange: =>
        activeTab = $('.tab-content>.active').prop('id')
        @applyChangesButton.prop 'disabled', false
        @resetChangesButton.prop 'disabled', false
        return if @stati.tab(activeTab).is('invalid')
        @stati.setTabStatus activeTab, 'changed'

    clearContent: =>
        @data = {}
        @stati.changeStatus to: 'none'

        @binder.disableControls()
        @binder.clearControls()
        @partDetails.clear()

        $('#common-form').clearValidation()
        $('#insurance-form').clearValidation()
        $('#facility-part-form').clearValidation()
        Validation.triggerValidationClearEvent(@commonTab, @facilityPartTab, @insuranceTab)

        @applyChangesButton.prop 'disabled', true
        @resetChangesButton.prop 'disabled', true

        @facilityPartsGrid.editControls.add.$button.disable()


    clear: ->
        @ensureChangesAreConfirmedBefore(@clearContent)

    display: (facilityId) ->
        @ensureChangesAreConfirmedBefore( => @update(facilityId))


    ensureChangesAreConfirmedBefore: (action) ->
        if @stati.hasAnyChanges()
            @callback = action
            @confirmationDialog.modal 'show'
        else
            action()

    renderPropertyWithOrgUnit: (data, escape) ->
        """
            <div>
                <span>#{escape(data.name)}</span><br/>
                <small class='text-muted'>#{data.assignedOrgUnitName}</small>
            </div>
        """


    setupAddFacilityPart: ->
        @facilityPartsGrid.editControls.add.$button.off()
        @facilityPartsGrid.editControls.add.$button.on 'click', (e) =>
            e.preventDefault()
            @addNewFacilityPart()

    addNewFacilityPart: =>
        @partDetails.createNewFacilityPart(@id)
        .then (newFacilityPart) =>
            @facilityPartsGrid.add(newFacilityPart)
            @facilityPartsGrid.selectRow(newFacilityPart.uid)
            @facilityPartsGrid.selectionExtension.markRowNew(newFacilityPart.uid)


    onPartDetailsChanged: =>
        @onChange()

        binding = @binder.bindings['#facility-part-grid']
        currentFacilityPart = @partDetails.data

        if not currentFacilityPart.typeName
            currentFacilityPart.typeName = if currentFacilityPart.type then currentFacilityPart.type.name else ''

        gridData = binding.getData()
        currentRow = gridData.find((x) -> x.uid is currentFacilityPart.uid)
        indexOfCurrentRow = gridData.indexOf(currentRow)

        if indexOfCurrentRow > -1
            gridData[indexOfCurrentRow] = currentFacilityPart

        binding.setData(gridData)

        @facilityPartsGrid.selectionExtension.markRowModified(currentFacilityPart.uid)

    registerEvents: =>
        Validation.catchEvents(@commonTab, @validationState.commonTabState,
            $('.nav.nav-tabs > li > a[href="#common"]'))
        Validation.catchEvents(@facilityPartTab, @validationState.facilityPartsTabState,
            $('.nav.nav-tabs > li > a[href="#facility-parts"]'))
        Validation.catchEvents(@insuranceTab, @validationState.insuranceTabState,
            $('.nav.nav-tabs > li > a[href="#insurance"]'))

        Validation.attachSelectionExtension(@facilityPartsGrid, $('#facility-part-form'))


    getNewGuid: (callback) ->
        url = '/RealEstate/Facility/GetNewGuid'
        get url
            .done (guid) ->
                callback guid


module.exports = {FacilityDetailController}

module.exports.__esModule = true
module.exports.default = FacilityDetailController
