{get} = require 'src/coffee/infrastructure/ajax'

{Validation} = require 'src/coffee/helpers/validation'
{ValidationRules} = require 'src/coffee/controls/grid/validation-rules'

{round} = require 'lodash'

{Binder, GridExtender} = require 'src/coffee/areas/real-estate/utils'
{format} = require 'src/js/i18n/conversion'

class FacilityPartDetailController
    constructor: (@$view, @onChangeCallback) ->
        @data = null
        @facilityPartTypeChangedHandlers = []
        @sizeChangedHandlers = []
        @deductiblesChangedHandlers = []
        @entityId = null

        @formatter2d = (value) ->
            #inplace import because of problem with closure and live replacement of the binding
            {format} = require 'src/js/i18n/conversion'
            return format(value, 'f2')

        @binder = new Binder(@$view,
            @onChange,
            Validation.invalidatedHandler)

        @hasChange = false

        @setupControls()
        @setupDeductibleTable()


    updateContent: (facilityPart) ->
        length = facilityPart.dimensionsOuterSurface.length
        width = facilityPart.dimensionsOuterSurface.width
        height = facilityPart.dimensionsOuterSurface.height1
        enclosedSpace = facilityPart.dimensionsOuterSurface.enclosedSpace
        facilityPart.dimensionsOuterSurface.area = @getSizeArea(length, width)

        @resetSelectizeOptions()
        @data = facilityPart
        @id = @data.uid

        @binder.bindings['#deductible-grid'].control.validator.errors = {}
        @binder.updateControls(@data)

        $('#facility-part-form').clearValidation()

        Validation.invalidateControls(
            @binder.getIdentifiers((binding) -> binding.type isnt 'hidden' and not binding.isReadOnly),
            true)

        @binder.bindings['#deductible-grid'].control.validator.validate()
        @binder.enableControls()


    moveToFirstTextInput: =>
        @binder.bindings['#facility-part-name'].control.focus()


    setupControls: ->
        parser = (value) ->
            #inplace import because of problem with closure and live replacement of the binding
            {parse} = require 'src/js/i18n/conversion'
            return parse(value)

        @applyChangesButton = @$view.find('#applyChangesBtn')
        @resetChangesButton = @$view.find('#resetChangesBtn')

        @loadFacilityPartTypesAction = $('#actions').data('facility-part-type-load-action')

        @binder.bindInput('#facility-part-name', 'name')
        $facilityPart = @binder.bindAjaxSelectize('#facility-part-type', 'type', @loadFacilityPartTypes,
                            {
                                create: (input) ->
                                    $('#facility-part-type')[0].selectize
                                    .removeOption('00000000-0000-0000-0000-000000000000')

                                    return {
                                        name: input
                                        uid: '00000000-0000-0000-0000-000000000000'
                                    }
                            },
                            (input, control) ->
                                for name, option of control.options
                                    return false if option.name.toLowerCase() is input.toLowerCase()
                                return true
                            )
        @binder.bindHiddenValue('##facility-part-type-name', 'typeName')

        $facilityPart.on 'change', =>
            @handleFacilityPartTypeChanged()

        @binder.bindHiddenValue('##FacilityPartUID', 'uid')
        @binder.bindHiddenValue('##FkFacilityId', 'fkFacilityId')
        @binder.bindHiddenValue('##dimensions-outer-surface-to-display', 'dimensionsOuterSurfaceToDisplay')

        formatYearfunction = ((val) -> val.year())

        parseYearfunction = ((val) ->
            if val? and val isnt ''
                return moment(new Date(val, 0, 1)).utcOffset(0, true)
            else
                return null)

        @binder.bindInput('#year-of-construction', 'yearOfConstruction', false, formatYearfunction, parseYearfunction)
        @binder.bindInput('#start-of-usage', 'startOfUsage', false, formatYearfunction, parseYearfunction)

        @binder.bindInput('#reconstruction-year', 'reconstructionYear', false, formatYearfunction, parseYearfunction)
        @binder.bindInput('#price-per-cubic-metre-dm', 'pricePerCubicMetreDM', false,  @formatter2d, parser)
        @binder.bindInput('#evaluation-of-facility-dm', 'evaluationOfFacilityDM', false,  @formatter2d, parser)
        $dimensionOuterSurfaceSizeLength = @binder.bindInput('#dimension-outer-surface-size-length', 'dimensionsOuterSurface.length', false, @formatter2d, parser)
        $dimensionOuterSurfaceSizeLength.on 'input', =>
            @handleSizeChanged()
        $dimensionOuterSurfaceSizeWidth = @binder.bindInput('#dimension-outer-surface-size-width', 'dimensionsOuterSurface.width', false, @formatter2d, parser)
        $dimensionOuterSurfaceSizeWidth.on 'input', =>
            @handleSizeChanged()
        $dimensionOuterSurfaceSizeHeight1 = @binder.bindInput('#dimension-outer-surface-size-height', 'dimensionsOuterSurface.height1', false, @formatter2d, parser)
        $dimensionOuterSurfaceSizeHeight1.on 'input', =>
            @handleSizeChanged()
        @binder.bindInput('#dimension-outer-surface-size-area', 'dimensionsOuterSurface.area', true, @formatter2d, parser)
        @dimensionOuterSurfaceEnclosedSpace = @binder.bindInput('#dimension-outer-surface-enclosed-space', 'dimensionsOuterSurface.enclosedSpace', false, @formatter2d, parser)
        @dimensionOuterSurfaceEnclosedSpace.on 'input', =>
            @handleSizeChanged()
        @binder.bindGrid('#deductible-grid', 'deductibles')

        @facilityPartsGrid = @$view.find '#facility-part-grid'
                                .staticgrid()[0]
        gridExtender = new GridExtender(@facilityPartsGrid)
        gridExtender.addExtendedRemoveConfirmationDialog(@removeFacilityPartModalText)

        @facilityPartsGrid.editControls.add.$button.disable()

    setupDeductibleTable: ->
        $table = @$view.find('#deductible-grid')
        deductibleGrid = $table.staticgrid()[0]
        deductible = deductibleGrid.columnLookup['deductibleValue']
        deductible.dataValidation = ValidationRules.IsNumeric


    createNewFacilityPart: (facilityId) ->
        return @getNewGuidPromise().then (guid) ->
            newFacilityPart =
                uid: guid
                name: null
                fkFacilityId: facilityId
                type: null
                typeName: ''
                yearOfConstruction: null
                dimensionsOuterSurface:
                    width: null
                    length: null
                    height1: null
                    height2: null
                    dimensionOuterSurfaceEnclosedSpace: null
                startOfUsage: null
                reconstructionYear: null
                pricePerCubicMetreDM: null
                evaluationOfFacilityDM: null
                deductibles: []
                dimensionsOuterSurfaceToDisplay: null
            return newFacilityPart


    onChange: =>
        @data = @binder.readControls()

        @hasChange = true
        @onChangeCallback()

    clearContent: =>
        @data = {}

        @binder.disableControls()
        @binder.clearControls()

        $('#facility-part-form').clearValidation()

    clear: ->
        @clearContent()

    updateEntityId:(entityId) ->
        @entityId = entityId

    resetSelectizeOptions: =>
        @binder.bindings['#facility-part-type'].control.clearOptions()
        @binder.bindings['#facility-part-type'].control.refreshOptions()

    loadFacilityPartTypes: (query, callback) =>
        get(@loadFacilityPartTypesAction, {query:@query, entityId: @entityId })
            .done (result) -> callback(result)

    getDimensionsOuterSurfaceToDisplayFromFacilityPart: (facilityPart) ->
        length = facilityPart.dimensionOuterSurfaceSize.length
        if length is ''
            length = 0

        width = facilityPart.dimensionOuterSurfaceSize.width
        if width is ''
            width = 0

        height1 = facilityPart.dimensionOuterSurfaceSize.height1
        if height1 is ''
            height1 = 0

        return  @getDimensionsOuterSurfaceToDisplay(length, width, height1)

    getDimensionsOuterSurfaceToDisplay: (length, width, height1) ->
        if length is ''
            length = 0

        if width is ''
            width = 0

        if height1 is ''
            height1 = 0

        return "#{length} x #{width} x #{height1}"

    getSizeArea: (length, width) ->
        return length * width

    #partyType event
    onFacilityPartTypeChanged: (func) =>
        @facilityPartTypeChangedHandlers.push(func)

    facilityPartTypeChanged: (result) =>
        for handler in @facilityPartTypeChangedHandlers
            handler result

    handleFacilityPartTypeChanged: =>
        facilityPartType = @binder.bindings['#facility-part-type'].getData()
        facilityPartTypeName = undefined

        if facilityPartType
            facilityPartTypeName = facilityPartType.name

        @binder.bindings['##facility-part-type-name'].setData(facilityPartTypeName)

        result =
            facilityPartUid: @id
            facilityPartType: facilityPartType
        @facilityPartTypeChanged result


    #size event
    onSizeChanged: (func) =>
        @sizeChangedHandlers.push(func)

    sizeChanged: (result) =>
        for handler in @sizeChangedHandlers
            handler result

    handleSizeChanged: =>
        length = round @binder.bindings['#dimension-outer-surface-size-length'].getData(), 2
        width =  round @binder.bindings['#dimension-outer-surface-size-width'].getData(), 2
        height = round @binder.bindings['#dimension-outer-surface-size-height'].getData(), 2
        dimensionOuterSurfaceEnclosedSpace = round @binder.bindings['#dimension-outer-surface-enclosed-space'].getData(), 2

        dimensionOuterSurfaceSize =
            length: length
            width: width
            height1: height
            enclosedSpace: dimensionOuterSurfaceEnclosedSpace

        sizeToDisplay = @getDimensionsOuterSurfaceToDisplay(length, width, height)
        @binder.bindings['##dimensions-outer-surface-to-display'].setData(sizeToDisplay)

        sizeArea = @getSizeArea(length, width)
        @binder.bindings['#dimension-outer-surface-size-area'].setData(sizeArea)

        result =
            facilityPartUid: @id
            dimensionOuterSurfaceSize: dimensionOuterSurfaceSize
        @sizeChanged result

    #DeductiblesChanged event
    onDeductiblesChanged: (func) =>
        @deductiblesChangedHandlers.push(func)

    deductiblesChanged: (result) =>
        for handler in @deductiblesChangedHandlers
            handler result


    getNewGuid: (callback) ->
        url = '/RealEstate/Facility/GetNewGuid'
        get url
            .done (guid) ->
                callback guid

    getNewGuidPromise: ->
        url = '/RealEstate/Facility/GetNewGuid'
        return get url


module.exports = {FacilityPartDetailController}

module.exports.__esModule = true
module.exports.default = FacilityPartDetailController
