{postJson, get} = require 'src/coffee/infrastructure/ajax'

class BusinessObjectCreateController
    constructor: (@view, @mediator) ->
        @entityId = null
        @setupControls()

    setupControls: ->
        @createBtn = @view.find '#createBtn'
        .on 'click', => @create()
        @cancelBtn = @view.find '#cancelBtn'
        .on 'click', => @cancel()
        @businessObjectName = @view.find '#business-object-name'
        @businessObjectName.focus()

    setName: (name) ->
        @businessObjectName.val name

    setEntityId: (entityId) ->
        @entityId entityId

    create: ->
        @createBtn.off()
        @cancelBtn.off()
        businessObject =
            Name: @businessObjectName.val()
            fkDataOwner: @entityId


        postJson 'MasterDataBusinessObject/Create', businessObject
        .done (createdBusinessObjectId) =>
            get "MasterDataBusinessObject/GetBusinessObjectById", {objectId: createdBusinessObjectId, entityId: @entityId}
            .done (createdBusinessObject) => @mediator.raise event: 'BusinessObjectCreated', data: createdBusinessObject

    cancel: ->
        @createBtn.off()
        @cancelBtn.off()
        @mediator.raise event: 'CreateBusinessObjectCancelled'

module.exports = {
    BusinessObjectCreateController
}
module.exports.__esModule = true
module.exports.default = BusinessObjectCreateController
