<template>
  <div class="form row">
    <div class="col-sm-12">
      <selectize
        v-model="value.type" :label="SR_ModelProperties.Type"
        :fields="dmsReferenceTypeSelectionFields"
        :options="dmsReferenceTypes" required
        :allow-empty="true"
        name="dmsReference"
        />
    </div>
    <div class="col-sm-12">
      <text-input v-model="value.name" :label="SR_ModelProperties.Reference" required />
    </div>
    <div class="col-sm-12">
      <text-input v-model="value.reference" :label="SR_ModelProperties.ReferenceLink" required />
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'

import TextInput from 'src/vue/components/forms/TextInput'
import Selectize from 'src/vue/areas/real-estate/components/forms/Selectize'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'
import ChangeDetectionMixin from 'src/vue/components/forms/ChangeDetectionMixin'

const resources = {
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    TextInput,
    Selectize
  },
  mixins: [ChangeDetectionMixin, makeResourceMixin(resources)],
  props: ['value', 'text'],
  data () {
    return {
      dmsReferenceTypeSelectionFields: {
        'text': 'text',
        'value': 'value'
      }
    }
  },
  computed: {
    ...mapGetters('realEstateContractLease/dmsReferences',
      [
        'dmsReferenceTypes'
      ])
  },
  methods: {
    save () {
      this.$emit('input', this.value)
    }
  }
}
</script>
<style>
</style>
