<template lang="html">
  <div class="row">
    <div class="col-md-3">
      <label><IxRes :resource="SR_Common">From</IxRes></label>
      <VueDateTimePicker
        :value="value.start" :max="value.endDate"
        :iso="iso"
        :readonly="readonly" :name="'RangePickerStart'+idNumber"
        @input="emitStart"
      />
    </div>
    <div class="col-md-3">
      <label><IxRes :resource="SR_Common">To</IxRes></label>
      <VueDateTimePicker
        :value="value.end" :min="value.startDate"
        :iso="iso"
        :readonly="readonly" :name="'RangePickerEnd'+idNumber"
        @input="emitEnd"
      />
    </div>
  </div>
</template>

<script>
import VueDateTimePicker from 'src/vue/components/forms/VueDateTimePicker'
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

const resources = {
  SR_Common: 'Common.SR_Common'
}
let counter = 1
export default {
  components: {
    VueDateTimePicker
  },
  mixins: [makeResourceMixin(resources)],
  props: {
    value: {},
    iso: Boolean,
    readonly: Boolean
  },
  data () {
    return {
      idNumber: `${counter++}`
    }
  },
  methods: {
    emitStart (start) {
      this.$emit('input', {start, end: this.value.end})
    },
    emitEnd (end) {
      this.$emit('input', {start: this.value.start, end})
    }
  }
}

</script>

<style lang="css">

</style>
