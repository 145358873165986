<template>
  <bs-modal :title="SR_Common.Save" :value="value" blocking>
    <p>{{ SR_Common.SaveChanges }}</p>
    <!-- <button type="button" slot="footer" class="btn btn-default" @click="cancel">
      <IxRes :resource="SR_Common">Cancel</IxRes>
    </button> -->
    <button
      slot="footer" type="button"
      class="btn btn-default"
      @click="no"
    >
      <IxRes :resource="SR_Common">
        No
      </IxRes>
    </button>
    <button
      slot="footer" type="button"
      class="btn btn-primary"
      @click="yes"
    >
      <IxRes :resource="SR_Common">
        Yes
      </IxRes>
    </button>
  </bs-modal>
</template>
<script>

import BsModal from 'src/vue/components/bootstrap/BsModal'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    BsModal
  },
  mixins: [makeResourceMixin(resources)],
  props: ['value'],
  data () {
    return {}
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    no () {
      this.$emit('no')
    },
    yes () {
      this.$emit('yes')
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
