{Confirmation} = require '../utils/confirmation'
{PropertyDetailController} = require './property-detail-controller'
{noop} = require 'src/coffee/areas/real-estate/utils/noop'
treeUtility = require 'src/coffee/controls/tree/helpers'
{post} = require 'src/coffee/infrastructure/ajax'
{notifications} = require 'src/js/infrastructure'

class PropertyController
    constructor: (@view, @mediator) ->
        @grid = @view.find '#property-grid'
        .staticgrid()[0]
        @setupPayload()
        @setupPropertyTable()
        @setupTree()
        @details = new PropertyDetailController(@view, @onDetailsChanged, @mediator)
        @confirmation = new Confirmation @view, '#confirmationDialog'
        @setupGridDelete()

        @executePreselection()
 
    executePreselection: ->
        div = $('#preselection')
        if div.length == 0
            return
 
        entityId = div.data('entity')
        itemId = div.data('item')
 
        @grid.table.one('draw.dt', => @grid.selectRow(itemId))
        treeUtility.selectNode(entityId)

    setupPayload: ->
        @grid.options.ajax.payload = => {Id: @uid}

    setupPropertyTable: ->
        @grid.table.on 'select', @onSelected
        .on 'deselect', @onDeselected
        .on 'draw.dt', @onDraw
        .on 'user-select.dt', @onUserSelect

    onUserSelect: (e, dt, type, cell, originalEvent) =>
        return unless type is 'row'
        if @details.hasChanges()
            e.preventDefault()
            @confirmation.show
                onAccept: =>
                    @details.saveChanges()
                    cell.row(cell.index().row).select()
                onDismiss: =>
                    @details.dismissChanges()
                    cell.row(cell.index().row).select()
                onCancel: noop

    onSelected: (e, dt, type, indexes) =>
        return unless type is 'rows' or type is 'row'
        dataItems = dt.rows(indexes).data().pluck('Uid')
        if dataItems.length > 0 and dataItems[0] isnt @details.data?.uid
            @details.display dataItems[0]

    onDeselected: (e, dt, type, indexes) =>
        return unless type is 'rows' or type is 'row'
        dataItems = dt.rows(indexes).data().pluck('Uid')
        if  (@details.data and dataItems.length > 0 and dataItems[0] is @details.data?.uid)
            @details.clear()

    onDraw: =>
        row = @grid.table.row('#' + @details.data?.uid)
        if row is undefined or row.length is 0
            @details.clear()
        else
            row.select()

    setupTree: ->
        @tree = $ '.easytree'
        
        @tree.closest('#tree-container')
        .on 'click', '.easytree-node', @onNodeSelected

    onNodeSelected: (event) =>
        if @details.hasChanges()
            event.preventDefault()
            event.stopPropagation()
            @confirmation.show
                onAccept: =>
                    @details.saveChanges()
                    event.currentTarget.click()
                onDismiss: =>
                    @details.dismissChanges()
                    event.currentTarget.click()
                onCancel: =>
                    rootNodes = @tree.tree().getAllNodes()

                    allNodes = []
                    treeUtility.applyRecursive rootNodes, (n) -> allNodes.push(n)

                    nodes = allNodes.filter((n) => n.data.uid is @uid)
                    return if nodes.length is 0
                    node = nodes[0]
                    @tree.tree().activateNode(node.id)
        else
            @uid = @tree.tree().getNode(event.currentTarget.id).data.uid
            @details.clear()
            @details.updateEntityId(@uid)
            @grid.table.ajax.reload()
            @grid.editControls.add.$button.enable().on 'click', (e) =>
                @details.create @uid
                @grid.table.rows().deselect()
                e.preventDefault()

    onDetailsChanged: (selection) =>
        @grid.table.ajax.reload()
        @grid.selectRow(selection)

    onGridChanged: =>
        @grid.state.reset()
        @grid.table.ajax.reload()

    setupGridDelete: =>
        deleteModalData = @view.find('#deleteModalData')

        deleteButtonTitle = deleteModalData.data 'buttonTitle'
        deleteSuccessMessage = deleteModalData.data 'delete-success-message'
        deleteErrorMessage = deleteModalData.data 'delete-error-message'

        @grid.editor.on 'preOpen', =>
            @grid.editor.buttons(deleteButtonTitle)
        @grid.editor.on 'postRemove', =>
            url = deleteModalData.data 'url'

            toDelete = @grid.state.changes()
            data =
                properties: toDelete.deleted

            post url, data, false
            .then @onGridChanged
            .done ->
                if $('#errorPage').length is 1
                    notifications.error deleteErrorMessage
                else
                    notifications.success deleteSuccessMessage
            .fail notifications.criticalError deleteErrorMessage

module.exports = {
    PropertyController
}
module.exports.__esModule = true
module.exports.default = PropertyController
