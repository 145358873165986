<template>
  <div class="form">
    <div class="row">
      <div class="col-sm-6">
        <person-picker
          v-model="value.recipient" :org-unit-id="selectedOrgUnit.id"
          name="recipient"
          type="Other" :label="SR_ModelProperties.Recipient"
          :allow-empty="true"
        />
      </div>
      <div class="col-sm-6">
        <text-input v-model="value.transactionComment" :label="SR_ModelProperties.TransactionComment" name="transactionComment" />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <date-time-picker
          v-model="value.dateDue" :label="SR_ModelProperties.DateDue"
          name="dateDue"
          iso
        />
      </div>
      <div class="col-sm-6">
        <date-time-picker
          v-model="value.datePaid" :label="SR_ModelProperties.DatePaid"
          name="datePaid"
          iso
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <text-input v-model="value.lessorIBAN" :label="SR_ModelProperties.IBAN" />
      </div>
      <div class="col-sm-6">
        <text-input v-model="value.lessorBIC" :label="SR_ModelProperties.BIC" />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <numeric-input v-model="value.amount" :label="SR_ModelProperties.Amount" />
      </div>
      <div class="col-sm-6">
        <selectize
          v-model="value.currency" :label="SR_ModelProperties.Currency"
          :fields="{value: 'uid', text: 'symbol', label: 'name'}"
          :options="currencies" required
          :allow-empty="true"
          name="currency"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <bs-checkbox v-model="value.vetoed" name="vetoed">
          <IxRes :resource="SR_ModelProperties">
            Vetoed
          </IxRes>
        </bs-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <text-input v-model="value.typeOfPayment" :label="SR_ModelProperties.TypeOfPayment" />
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'

import TextInput from 'src/vue/components/forms/TextInput'
import NumericInput from 'src/vue/components/forms/NumericInput'
import Selectize from 'src/vue/areas/real-estate/components/forms/Selectize'
import PersonPicker from 'src/vue/areas/real-estate/components/object-picker/person-picker.vue'
import DateTimePicker from 'src/vue/areas/real-estate/components/forms/DateTimePicker'
import BsCheckbox from 'src/vue/components/bootstrap/BsCheckbox'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

const resources = {
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    TextInput,
    NumericInput,
    Selectize,
    PersonPicker,
    DateTimePicker,
    BsCheckbox
  },
  mixins: [makeResourceMixin(resources)],
  props: {
    value: {
      default: {currency: null}
    },
    name: String,
    readOnly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters('realEstateContractLease', [
      'currencies'
    ]),
    ...mapGetters('realEstateContractLease/navigation', [
      'selectedOrgUnit'
    ])
  }
}
</script>
