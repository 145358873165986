<template lang="html">
  <div class="real-estate">
    <tree-layout :loading="loading">
      <tree slot="tree" v-model="selectedOrgUnit" :nodes="nodes" />
      <div v-if="translationsLoaded">
        <div class="row">
          <div class="col-md-12">
            <div class="btn-group togglebar">
              <toggle-button v-model="filterMode" value="CurrentMonth" required>
                <IxRes :resource="SR_Common">
                  CurrentMonth
                </IxRes>
              </toggle-button>
              <toggle-button v-model="filterMode" value="NextMonth" required>
                <IxRes :resource="SR_Common">
                  NextMonth
                </IxRes>
              </toggle-button>
              <toggle-button v-model="filterMode" value="CurrentQuarter" required>
                <IxRes :resource="SR_Common">
                  CurrentQuarter
                </IxRes>
              </toggle-button>
              <toggle-button v-model="filterMode" value="NextQuarter" required>
                <IxRes :resource="SR_Common">
                  NextQuarter
                </IxRes>
              </toggle-button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <date-range-picker v-model="dateTimeRange" iso />
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="checkbox">
              <label for="onlyUnpaid">
                <input
                  id="onlyUnpaid" v-model="onlyUnpaid"
                  type="checkbox"
                  value="true" name="onlyUnpaid"
                >
                <IxRes :resource="SR_RealEstate">OnlyUnpaid</IxRes>
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <grid
              v-model="currentSelection" :data="payments"
              :columns="columns"
              selection="os" :title="SR_RealEstate.Payments"
              paging
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <input
              type="button" name="exportSepa"
              :value="SR_RealEstate.ExportAsSepa"
              class="btn btn-default btn-block" :disabled="currentSelection.length == 0"
              @click="exportSepa"
            >
          </div>
          <div class="col-md-3">
            <input
              type="button" name="exportSepa"
              :value="SR_RealEstate.MakeUnpaid"
              class="btn btn-default btn-block" :disabled="!selectionIsPaid"
              @click="markAsUnPaid"
            >
          </div>
          <div class="col-md-3">
            <input
              type="button" name="exportSepa"
              :value="SR_RealEstate.MakePaid"
              class="btn btn-default btn-block" :disabled="!selectionIsUnPaid"
              @click="markAsPaid"
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="checkbox">
              <label for="markExportedAsPaid">
                <input
                  id="markExportedAsPaid" v-model="markExportedAsPaid"
                  type="checkbox"
                  value="true" name="markExportedAsPaid"
                >
                <IxRes :resource="SR_RealEstate">MarkExportedAsPaid</IxRes>
              </label>
            </div>
          </div>
        </div>
      </div>
    </tree-layout>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

import TreeLayout from 'src/vue/legacy-compat/TreeLayout'
import Tree from '@components/nav/Tree'
import Grid from 'src/vue/areas/real-estate/components/grid/RELegacyGrid.vue'
import ToggleButton from 'src/vue/components/ToggleButton'
import DateRangePicker from 'src/vue/components/VueDateTimeRangePicker'

import makeTree from './makeTree'
import moment from 'moment'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'
import {success, error} from 'src/coffee/infrastructure/ixmap/notifications'

import {postJson} from 'src/coffee/infrastructure/ajax'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    Tree,
    TreeLayout,
    Grid,
    ToggleButton,
    DateRangePicker
  },
  mixins: [makeResourceMixin(resources)],
  data () {
    return {
      currentSelection: [],
      markExportedAsPaid: true,
      onlyUnpaid: false,
      translationsLoaded: false,
      filterMode: '',
      loading: false,
      dateTimeRange: {
        start: '',
        end: ''
      }
    }
  },
  computed: {
    ...mapGetters('realEstateContractLeasePayments', {
      payments: 'payments'
    }),
    ...mapGetters('realEstateContractLeasePayments/navigation', {
      orgUnits: 'orgUnits',
      selectedOrgUnitX: 'selectedOrgUnit'}),
    selectedOrgUnit: {
      get () {
        return this.selectedOrgUnitX
      },
      set (value) {
        this.setSelectedOrgUnit(value)
      }
    },
    start () {
      return this.dateTimeRange.start
    },
    end () {
      return this.dateTimeRange.end
    },
    nodes () {
      return this.orgUnits.map(makeTree)
    },

    columns () {
      return [
        {localizedTitle: this.SR_ModelProperties['IsPaid'], typeName: 'Boolean', propertyName: 'isPaid'},
        {localizedTitle: this.SR_ModelProperties['DocumentNumber'], typeName: 'String', propertyName: 'contract'},
        {localizedTitle: this.SR_ModelProperties['TransactionComment'], typeName: 'String', propertyName: 'information'},
        {localizedTitle: this.SR_ModelProperties['Partner'], typeName: 'String', propertyName: 'lessor', maxLength: 20},
        {localizedTitle: this.SR_ModelProperties['IBAN'], typeName: 'String', propertyName: 'lessorIBAN'},
        {localizedTitle: this.SR_ModelProperties['Amount'], typeName: 'Float', propertyName: 'amount', minDecimalDigits: 2, maxDecimalDigits: 2},
        {localizedTitle: this.SR_ModelProperties['Currency'], typeName: 'String', propertyName: 'currency'},
        {localizedTitle: this.SR_ModelProperties['DueDate'], typeName: 'DateTime', propertyName: 'dueDate', dateTimeFormat: 'L', orderData: 0}
      ]
    },
    selectionIsPaid () {
      return this.currentSelection.length !== 0 && this.currentSelection.some((ele) => ele.isPaid)
    },
    selectionIsUnPaid () {
      return this.currentSelection.length !== 0 && this.currentSelection.some((ele) => !ele.isPaid)
    }
  },
  methods: {
    ...mapActions('realEstateContractLeasePayments', [
      'initRequest',
      'getPayments'
    ]),
    ...mapActions('realEstateContractLeasePayments/navigation', [
      'selectOrgUnit'
    ]),
    exportSepa () {
      const paymentIds = this.currentSelection.map((ele) => ele.id)
      const url = '/RealEstate/Payments/GetSepaFile?paymentIdstring=' + paymentIds.join() + '&markAsPaid=' + this.markExportedAsPaid
      window.open(url)
      if (this.markExportedAsPaid) {
        setTimeout(() => this.getPayments({orgUnit: this.selectedOrgUnit, start: this.start, end: this.end, onlyUnpaid: this.onlyUnpaid}), 1000)
      }
    },
    setSelectedOrgUnit (orgUnit) {
      this.selectOrgUnit({orgUnit: orgUnit, start: this.start, end: this.end, onlyUnpaid: this.onlyUnpaid})
    },
    markAsUnPaid () {
      const paymentIds = this.currentSelection.map((ele) => ele.id)
      this.$_toggleIsPaid(paymentIds, false)
    },
    markAsPaid () {
      const paymentIds = this.currentSelection.map((ele) => ele.id)
      this.$_toggleIsPaid(paymentIds, true)
    },
    $_toggleIsPaid (paymentIds, isPaid) {
      postJson('/RealEstate/Payments/SetIsPaid', {paymentIds, isPaid})
        .then((result) => {
          success(result)
          this.getPayments({orgUnit: this.selectedOrgUnit, start: this.start, end: this.end, onlyUnpaid: this.onlyUnpaid})
        })
        .fail((jqXhr) => error(jqXhr.responseText))
    }
  },
  watch: {
    onlyUnpaid (value) {
      if (this.selectedOrgUnit) {
        this.getPayments({orgUnit: this.selectedOrgUnit, start: this.start, end: this.end, onlyUnpaid: value})
      }
    },
    start (value) {
      if (this.selectedOrgUnit && value && this.end) {
        this.getPayments({orgUnit: this.selectedOrgUnit, start: value, end: this.end, onlyUnpaid: this.onlyUnpaid})
      }
    },
    end (value) {
      if (this.selectedOrgUnit && this.start && value) {
        this.getPayments({orgUnit: this.selectedOrgUnit, start: this.start, end: value, onlyUnpaid: this.onlyUnpaid})
      }
    },
    filterMode (value) {
      const nextMonth = moment().add(1, 'month')
      const quarter = moment().quarter()
      const nextQuarter = moment().add(1, 'quarter')

      switch (value) {
      case 'CurrentMonth':
        this.dateTimeRange.start = moment().startOf('month').toISOString()
        this.dateTimeRange.end = moment().endOf('month').toISOString()
        break
      case 'NextMonth':
        this.dateTimeRange.start = nextMonth.startOf('month').toISOString()
        this.dateTimeRange.end = nextMonth.endOf('month').toISOString()
        break
      case 'CurrentQuarter':
        this.dateTimeRange.start = moment().quarter(quarter).startOf('quarter').toISOString()
        this.dateTimeRange.end = moment().quarter(quarter).endOf('quarter').toISOString()
        break
      case 'NextQuarter':
        this.dateTimeRange.start = nextQuarter.startOf('quarter').toISOString()
        this.dateTimeRange.end = nextQuarter.endOf('quarter').toISOString()
        break
      }
    },
    selectedOrgUnit () {
      this.currentSelection = []
    }
  },
  mounted () {
    this.initRequest()
    this.filterMode = 'CurrentMonth'
  },
  created () {
    this.$store.dispatch('i18n/preloadTranslations', [
      'Common.SR_Common',
      'Areas.RealEstate.SR_RealEstate',
      'ModelProperties.SR_ModelProperties'
    ])
      .then(() => {
        this.translationsLoaded = true
      })
  }
}

</script>
<style lang="css">
  .togglebar{
    margin-top: 25px
  }

</style>
