{Binder} = require './binder'
{Confirmation} = require './confirmation'
{GridExtender} = require './grid-extender'
{Mediator} = require './mediator'
{noop} = require './noop'
{Stati} = require './stati'
{Status} = require './status'
{ValidationGridIndicator} = require './validation-grid-indicator'

Vue = require('vue').default

cloneArray = (array) ->
    (clone obj for obj in array)

clone = (obj) ->
    if not obj?
        return obj

    if Array.isArray(obj)
        return cloneArray obj

    if typeof obj isnt 'object'
        return obj

    if obj instanceof Date
        return new Date(obj.getTime())

    if obj instanceof RegExp
        flags = ''
        flags += 'g' if obj.global?
        flags += 'i' if obj.ignoreCase?
        flags += 'm' if obj.multiline?
        flags += 'y' if obj.sticky?
        return new RegExp(obj.source, flags)

    newInstance = new obj.constructor()

    for key of obj
        newInstance[key] = clone obj[key]

    return newInstance

cloneObjectInto = (source, target) ->
    for key of target
        if not source.hasOwnProperty(key)
            Vue.delete(target, key)

    for key, value of clone(source)
        Vue.set(target, key, value)

class Memento
    constructor: (data) ->
        @Events = new EventSystem(this)
        @Events.createEvent('reset')
        @Events.createEvent('apply')
        @Events.createEvent('setNewData')

        @data = data
        @initialData = clone(data)

    resetChanges: (silent) =>
        cloneObjectInto(@initialData, @data)
        if not silent
            @Events.raiseEvent('reset')

    applyChanges: (silent) =>
        @initialData = clone(@data)
        if not silent
            @Events.raiseEvent('apply')

    setNewData: (data, silent) =>
        @initialData = clone(data)
        cloneObjectInto(@initialData, @data)
        if not silent
            @Events.raiseEvent('setNewData')

class Event
    constructor: ->
        @handlers = []

    registerHandler: (handler) =>
        @handlers.push(handler)

    deregisterHandler: (handler) =>
        if handler
            @handlers = @handlers.filter((x) -> x isnt handler)
        else
            @handlers = []

    raise: (args...) =>
        for handler in @handlers
            handler(args...)

class EventSystem
    constructor: (obj) ->
        @events = new Map()

        obj.on = @on
        obj.off = @off

    createEvent: (eventDescriptor) =>
        if @events.has(eventDescriptor)
            console.log("warning: Multiple creation of event '#{eventDescriptor}' attempted.")
            return

        @events.set(eventDescriptor, new Event())

    raiseEvent: (eventDescriptor, args...) =>
        @getEvent(eventDescriptor).raise(args...)

    on: (eventDescriptor, handler) =>
        @getEvent(eventDescriptor).registerHandler(handler)

    off: (eventDescriptor, handler) =>
        @getEvent(eventDescriptor).deregisterHandler(handler)

    getEvent: (eventDescriptor) =>
        if @events.has(eventDescriptor)
            return @events.get(eventDescriptor)

        console.log("Warning: Unknown eventDescriptor '#{eventDescriptor}' accessed.")
        return undefined


module.exports = {
    Binder,
    clone,
    Confirmation,
    EventSystem,
    Event,
    GridExtender,
    Mediator,
    Memento,
    noop,
    Stati,
    Status,
    ValidationGridIndicator
}
