<template>
  <div class="form">
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-12">
            <selectize
              v-model="value.type" :label="SR_ModelProperties.ContactType"
              :allow-empty="true"
              :fields="contactTypeFields" :options="contactTypes"
              @input="onChange"
              name="addressType"
            />
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <text-input
                v-model="value.name" :label="SR_ModelProperties.Name"
                name="name" required
                :placeholder="SR_ModelProperties.Name" @input="onChange"
              />
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <text-input
                v-model="value.nameAdditional" :label="SR_ModelProperties.NameAdditional"
                name="name"
                :placeholder="SR_ModelProperties.NameAdditional" @input="onChange"
              />
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <text-input
                v-model="value.info" name="info"
                :label="SR_ModelProperties.Info"
                multiline @input="onChange"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <text-input
                v-model="value.address.street" :label="SR_ModelProperties.Street"
                name="street"
                :placeholder="SR_ModelProperties.Street" @input="onChange"
              />
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <text-input
                v-model="value.address.postOfficeBox" :label="SR_ModelProperties.PostOfficeBox"
                name="postOfficeBox"
                :placeholder="SR_ModelProperties.PostOfficeBox" @input="onChange"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <selectize
              v-model="value.address.country" :label="SR_ModelProperties.Country"
              :allow-empty="true"
              :fields="countriesFields" :options="countries"
              @input="onChangeCountry"
              name="country"
            />
          </div>
          <div class="col-sm-6">
            <selectize
              v-model="value.address.region" :label="SR_ModelProperties.Region"
              :allow-empty="true"
              :options="countriesWithRegions" @input="onChange"
              name="region"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <text-input
                v-model="value.address.postalCode" :label="SR_ModelProperties.PostalCode"
                name="postalCode"
                :placeholder="SR_ModelProperties.PostalCode" @input="onChange"
              />
            </div>
          </div>
          <div class="col-sm-9">
            <div class="form-group">
              <text-input
                v-model="value.address.city" :label="SR_ModelProperties.City"
                name="city"
                :placeholder="SR_ModelProperties.City" @input="onChange"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3" />
          <div class="col-sm-9">
            <div class="form-group">
              <text-input
                v-model="value.address.localCentre" :label="SR_ModelProperties.LocalCentre"
                name="localCentre"
                :placeholder="SR_ModelProperties.LocalCentre" @input="onChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'

import Selectize from 'src/vue/areas/real-estate/components/forms/Selectize'
import TextInput from 'src/vue/components/forms/TextInput'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'
import ChangeDetectionMixin from 'src/vue/components/forms/ChangeDetectionMixin'

const resources = {
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    Selectize,
    TextInput
  },
  mixins: [ChangeDetectionMixin, makeResourceMixin(resources)],
  props: {
    value: null,
    readOnly: {
      type: Boolean,
      default: false
    },
    name: {
      Type: String,
      default: ''
    }
  },
  data () {
    return {
      contactTypeFields: {
        'text': 'displayName',
        'value': 'value'
      },
      countriesFields: {
        'text': 'text',
        'value': 'value'
      },
      isLoaded: false
    }
  },
  computed: {
    ...mapGetters('realEstateMasterDataContacts',
      [
        'contactTypes',
        'countries',
        'countriesWithRegions'
      ])
  },
  methods: {
    ...mapActions('realEstateMasterDataContacts/contacts', [
      'setContactAsChanged'
    ]),
    ...mapActions('realEstateMasterDataContacts', [
      'updateCurrentSelectedCountryUID'
    ]),

    save () {
      this.$emit('input', this.value)
    },
    onChange () {
      if (this.isLoaded) {
        this.setContactAsChanged()
      }
    },
    onChangeCountry () {
      this.onChange()
      if (this.value && this.value.address && this.value.address.country && this.value.address.country.value) {
        this.updateCurrentSelectedCountryUID(this.value.address.country.value)
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.isLoaded = true
    }, 1000)
    if (this.value && this.value.address && this.value.address.country && this.value.address.country.value) {
      this.updateCurrentSelectedCountryUID(this.value.address.country.value)
    }
  }
}
</script>
<style>
</style>
