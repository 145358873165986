<template lang="html">
  <textInput
    :label="label" :value="value"
    required :multiline="multiline"
    :rows="rows"
    :list="list" :list-id="listId"
    :info-description="infoDescription" :input-description="inputDescription"
    :placeholder="placeholder"
    :disabled="disabled"
    :addon="addon"
    :rule="startValidateCertificateNumber"
    :messages="{warning: 'Es ist bereits ein Vertrag mit gleicher Vertragsnummer vorhanden'}"
    @input="onInput"
  />
</template>
<script>
import TextInput from 'src/vue/components/forms/TextInput'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

import queryData from 'src/js/store/modules/real-estate/contract/lease/api/queryData'

const resources = {
  SR_InfoMessages: 'Messages.Info.SR_InfoMessages'
}
let counter = 0

export default {
  components: {
    TextInput
  },
  mixins: [makeResourceMixin(resources)],
  props: {
    value: String,
    label: String,
    placeholder: String, // placeholder has higher priority than default property of messages object
    addon: {},
    multiline: Boolean,
    rows: Number,
    list: Boolean,
    listId: String,
    infoDescription: String,
    inputDescription: Boolean,
    disabled: Boolean
  },
  data () {
    return {
      id: `certificate-number-${counter++}`,
      // dirty: false,
      validationResult: null
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', value)
      this.validateCertificateNumber(value)
    },
    startValidateCertificateNumber () {
      return this.validationResult === true ? 'warning' : 'success'
    },
    validateCertificateNumber (value) {
      if (!(value === null || value === '')) {
        this.validationResult = null

        const self = this

        queryData.checkCertificateNumberDublicate(value)
          .then((isDublicate) => {
            self.validationResult = isDublicate
          })
          .catch((err) => {
            console.error(err)
            self.validationResult = null
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
