<template lang="html">
  <div class="row">
    <slot />
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>
