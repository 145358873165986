const convertNode = (orgUnit) => {
  return {
    text: orgUnit.name,
    icon: {
      iconName: 'ix-tree-orgunit',
      style: '',
      tooltip: 'Organisationseinheit'
    },
    isExpanded: false,
    extraIcons: [],
    liClass: '',
    isActive: false,
    data: {
      template: 'OrgUnit',
      uid: ''
    },
    href: '#',
    id: orgUnit.uid,
    children: (orgUnit.children !== undefined && orgUnit.children.filter !== undefined) ? orgUnit.children.filter(x => x.children).map(convertNode) : [],
    entity: orgUnit
  }
}

export default convertNode
