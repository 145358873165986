{get, postJson} = require 'src/coffee/infrastructure/ajax'
{notifications} = require 'src/js/infrastructure'
{getModal} = require 'src/coffee/controls/modals'
{noop} = require 'src/coffee/areas/real-estate/utils/noop'

{Confirmation} = require 'src/coffee/areas/real-estate/utils/confirmation'

{Validation} = require 'src/coffee/helpers/validation'
{ValidationRules} = require 'src/coffee/controls/grid/validation-rules'

WKT = require('ol/format/WKT').default
{Status, clone, Memento} = require '../utils'
{RegisterLoadEventOnTab, ClearCurrentHistoryDisplay} = require './history-display-manager'

{LandParcelSelectorController} = require '../contracts/purchase/land-parcel-selector-controller'

convertShapes = require('src/vue/areas/field-record-system/components/geometry-editing/convert-shape-files').default

Vue = require('vue').default
store = require('src/js/store').default
commonTabVue = require('src/vue/areas/real-estate/land-parcel/common-tab').default

class LandParcelDetailController
    constructor: (options) ->
        @mediator = options.mediator #for raising the LandParcelChanged event in postJson...
        @data = {}
        @dataMemento = new Memento(@data)
        @dataMemento.on 'reset', @updateControls
        @dataMemento.on 'setNewData', @updateControls
        @tenantId = null
        @entityId = null

    bindTo: (@view) =>
        @setupControls()
        @setupValidation()
        @setupVue()
        @status =
            common: new Status @view.find('a[href="#common"]>span[class~="fa"]')
            segments: new Status @view.find('a[href="#segments"]>span[class~="fa"]')
            mortgage: new Status @view.find('a[href="#mortgage"]>span[class~="fa"]')
            easement: new Status @view.find('a[href="#easement"]>span[class~="fa"]')
            geodata: new Status @view.find('a[href="#geodata"]>span[class~="fa"]')
            history: new Status @view.find('a[href="#history"]>span[class~="fa"]')
            contract: new Status @view.find('a[href="#contract"]>span[class~="fa"]')
            predecessorSuccessor: new Status @view.find('a[href="#predecessorSuccessor"]>span[class~="fa"]')

        $('#common').on 'change', @onChange

        @confirmation = new Confirmation @view, '#size-different-dialog'

    setupVue: =>
        vueSetup = {
            el: @view.find('#vuemountpoint').get(0),
            store: store,
        }

        for prop, val of commonTabVue
            vueSetup[prop] = val

        @vue = new Vue(vueSetup)
        @vue.$data.lp = @data



    update: (id, entityId) =>
        @entityId = entityId
        @disableControls()
        get '/RealEstate/Tenants/GetCurrentTenant', {orgUnitId: entityId}
        .done (result) =>
            @tenantId = result
            @vue.setOrgUnitId(@tenantId)
            @mortgages.setSelectizeQueryData('loanNumber',{entityId:entityId})
            @mortgages.setSelectizeQueryData('bank',{entityId:entityId})
            @easements.setSelectizeQueryData('easementType',{entityId:entityId})
            @easements.setSelectizeQueryData('beneficiary',{entityId:entityId})
        .fail =>
            @tenantId = null
            @vue.setOrgUnitId(@tenantId)

        get '/RealEstate/LandParcel/Details/', {id}
        .done (result) =>
            @calculateSegmentSize(result)
            @setNewData(result)
            @enableControls()
        .fail (jqXHR, statusText) =>
            @clear()

    create: (entityId) =>
        @entityId = entityId
        @disableControls()
        get '/RealEstate/Tenants/GetCurrentTenant', {orgUnitId: entityId}
        .done (result) =>
            @tenantId = result
            @vue.setOrgUnitId(@tenantId)
            @mortgages.setSelectizeQueryData('loanNumber',{entityId:entityId})
            @mortgages.setSelectizeQueryData('bank',{entityId:entityId})
            @easements.setSelectizeQueryData('easementType',{entityId:entityId})
            @easements.setSelectizeQueryData('beneficiary',{entityId:entityId})
        .fail =>
            @vue.setOrgUnitId(@tenantId)
            @vue.setOrgUnitId(@tenantId)

        get '/RealEstate/LandParcel/CreateModel/', {entityId}
        .then (result) =>
            @calculateSegmentSize(result)
            @setNewData(result)
            @scrollIntoView()
            @enableControls()
            @view.find('#common').tab('show')
            @vue.focusFirstInput()

    scrollIntoView: ->
        offset = $('#common').offset()
        $('html, body').animate
            scrollTop: offset.top
            scrollLeft: offset.left

    clear: =>
        @setNewData({})
        @tenantId = null
        @vue.setOrgUnitId(@tenantId)
        #return if @commonTab is undefined
        Validation.triggerValidationClearEvent(@commonTab, @segmentsTab, @mortgageTab, @easementTab, @geodataTab)

    saveToServer: =>
        if not Validation.isStateValid(@validationState)
            notifications.error('Es liegen Validierungsfehler vor. Bitte korrigieren Sie diese vor dem Speichern.')
            return

        doThat = =>
          if @data.isNewlyCreated
              @putNewlyCreate()
          else
              @postChanges()

        if(@data.segmentSize isnt @data.size)
          @confirmation.show
                onAccept: doThat
                onDismiss: noop
                onCancel: noop
        else
          doThat()

    setNewData: (data) =>
        @dataMemento.setNewData(data)

    restoreDataFromResetCache: =>
        @dataMemento.resetChanges()
        @changeStatus to: 'none'
        if @data.isNewlyCreated
            @disableControls

    hasChanges: =>
        @hasAnyChanges()

    saveChanges: =>
        @applyButton.click()

    dismissChanges: =>
        @resetButton.click()

    setupControls: =>
        @view.find('a[role="tab"]').click (e) ->
            e.preventDefault()
        @uploadModal = @view.find '#uploadGeometryDialog'
        @setupInputControls()
        @setupButtons()
        @disableControls()


    setupInputControls: =>
        @segmentSizeTab = @setupInput '#segment-size-tab'
        @segments = @view.find('#segment-grid').staticgrid()[0]
        @easements = @view.find('#easements-grid').staticgrid()[0]
        @mortgages = @view.find('#mortgages-grid').staticgrid()[0]
        @contracts = @view.find('#contracts-grid').staticgrid()[0]
        @predecessors = @view.find('#predecessors-grid').staticgrid()[0]
        @predecessorsTable = @view.find('#predecessors-grid')
        @successors = @view.find('#successors-grid').staticgrid()[0]
        @successorsTable = @view.find('#successors-grid')

        @map = @view.find('#map').mapcontrol()[0]
        landParcelSource = @map.layerManager.getLayersByName('LandParcel')[0].getSource()
        landParcelSource.on 'addfeature', @onChange
        landParcelSource.on 'removefeature', @onChange
        landParcelSource.on 'changefeature', @onChange

        @segments.editor
        .on 'preSubmit', => if @data.permissions.LandParcelsEditSegments then @onChange() else false
        .on 'preEdit', (event, json, data) =>
            if data.area is undefined or isNaN(data.area)
              data.area = 0
        .on 'edit', (event, json, data) =>
            segmentSum = @segments.table.rows().data().pluck('area').reduce(((a, b) -> a + b), 0)
            @data.segmentSize = segmentSum
            @segmentSizeTab.val Globalize.formatNumber segmentSum, {minimumFractionDigits: 4}
            @onChange()
        .on 'create', (event, json, data) =>
            segmentSum = @segments.table.rows().data().pluck('area').reduce(((a, b) -> a + b), 0)
            @data.segmentSize = segmentSum
            @segmentSizeTab.val Globalize.formatNumber segmentSum, {minimumFractionDigits: 4}
            @onChange()
        .on 'initRemove', => @data.permissions.LandParcelsEditSegments
        .on 'remove', (event) => @onChange()
        .on 'preOpen', => @data.permissions.LandParcelsEditSegments

        @easements.editor
        .on 'edit', (event) => @onChange()
        .on 'remove', (event) => @onChange()
        .on 'preOpen', => @data.permissions.LandParcelsEditEasements
        .on 'preSubmit', => @onChange()

        ctrl.$button.hide() for name, ctrl of @contracts.editControls

        @predecessors.editControls.edit.$button.hide()
        @predecessors.editControls.add.$button.off()
        @predecessors.editControls.add.$button.on 'click', (e) =>
            e.preventDefault()
            @addLandParcelModal(@predecessors)

        @predecessors.editControls.delete.$button.off()
        @predecessors.editControls.delete.$button.on 'click', (e) =>
            e.preventDefault()
            @removeLandParcel(@predecessors)

        @successors.editControls.edit.$button.hide()
        @successors.editControls.add.$button.off()
        @successors.editControls.add.$button.on 'click', (e) =>
            e.preventDefault()
            @addLandParcelModal(@successors)

        @successors.editControls.delete.$button.off()
        @successors.editControls.delete.$button.on 'click', (e) =>
            e.preventDefault()
            @removeLandParcel(@successors)
        @setupMortgagesGrid()

        @geodataMissingBox = @view.find('#geodata-missing-box').hide()

    processAddedLandParcel: (newRow, targetgrid) =>
        newRow.DT_RowId = newRow.uid
        targetgrid.table.row.add(newRow).draw()
        @onChange()

    addLandParcelModal: (targetgrid) ->
        getModal '/RealEstate/Contract/SelectLandParcel'
        .then ($modal) =>
            @landParcelModal = new LandParcelSelectorController $modal, @data.assignedEntities, @entityId
            @landParcelModal.onSubmit (result) =>
                @processAddedLandParcel(result.resultingRow, targetgrid)
                if result.reOpen
                    @addLandParcelModal()

    removeLandParcel: (targetgrid) ->
        targetgrid.table.row('.selected').remove().draw()
        @onChange()

    setupMortgagesGrid: ->
        @mortgages.editor
        .on 'edit', (event) => @onChange()
        .on 'remove', (event) => @onChange()
        .on 'preOpen', => @data.permissions.LandParcelsEditEasements
        .on 'preSubmit', => @onChange()

        currencyColumn = @mortgages.columnLookup['currency']
        currencyColumn.dataValidation = ValidationRules.Required

    setupValidation: =>
        @validationState = {
            commonTabState: {}
            segmentsTabState: {}
            mortgageTabState: {}
            easementTabState: {}
            geodataTabState: {}
        }
        @commonTab = @view.find('.tab-content > #common')
        @segmentsTab = @view.find('.tab-content > #segments')
        @mortgageTab = @view.find('.tab-content > #mortgage')
        @easementTab = @view.find('.tab-content > #easement')
        @geodataTab = @view.find('.tab-content > #geodata')
        @registerValidationEvents()


    registerValidationEvents: =>
        Validation.catchEvents(@commonTab, @validationState.commonTabState,
            $('.nav.nav-tabs > li > a[href="#common"]'))
        Validation.catchEvents(@segmentsTab, @validationState.segmentsTabState,
            $('.nav.nav-tabs > li > a[href="#segments"]'))
        Validation.catchEvents(@mortgageTab, @validationState.mortgageTabState,
            $('.nav.nav-tabs > li > a[href="#mortgage"]'))
        Validation.catchEvents(@easementTab, @validationState.easementTabState,
            $('.nav.nav-tabs > li > a[href="#easement"]'))
        Validation.catchEvents(@geodataTab, @validationState.geodataTabState,
            $('.nav.nav-tabs > li > a[href="#geodata"]'))

    calculateSegmentSize: (lp) ->
        if lp.segmentations is null
            segmentSum = 0
        else if lp.segmentations.length is 0
            segmentSum = 0
        else if lp.segmentations[0].segments is null
            segmentSum = 0
        else
            segmentSum = lp.segmentations[0].segments.map((x) -> x.area).reduce(((a, b) -> a + b), 0)
        lp.segmentSize = segmentSum


    setupInput: (selector) =>
        @view.find selector
        .on 'input', @onChange
        .disable()


    setupButtons: =>
        @applyButton = @view.find '#applyChangesBtn'
                            .prop 'disabled', true
        @resetButton = @view.find '#resetChangesBtn'
                            .prop 'disabled', true
        @printButton = @view.find '#printBtn'
                            .prop 'disabled', true
        @uploadButton = @view.find '#open-upload-dialog'
                             .prop 'disbaled', true

        @applyButton.off().on 'click', @saveToServer
        @resetButton.off().on 'click', @restoreDataFromResetCache
        @printButton.off().on 'click', -> window.print()
        @uploadButton.off().on 'click', @showUploadModal

    enableControls: =>
        @segmentSizeTab.enable()

        @enableGridButtons @segments
        @enableGridButtons @easements
        @enableGridButtons @mortgages
        @enableGridButtons @contracts
        @enableGridButtons @successors
        @enableGridButtons @predecessors

        @enableTab 'common'
        @enableTab 'history' if @data.permissions.LandParcelsViewHistory
        @enableTab 'segments' if @data.permissions.LandParcelsViewSegments or @data.permissions.LandParcelsEditSegments
        @enableTab 'easement' if @data.permissions.LandParcelsViewEasements or @data.permissions.LandParcelsEditEasements
        @enableTab 'predecessorSuccessor'
        @enableTab 'contract'
        @enableTab 'mortgage' if @data.permissions.LandParcelsViewMortgages or @data.permissions.LandParcelsEditMortgages
        @enableTab 'geodata'  if @data.permissions.LandParcelsViewGeodata or @data.permissions.LandParcelsEditGeodata

        @printButton.enable()

    enableTab: (tabName) =>
        @view.find ".nav > li a[href=\"##{tabName}\"]"
        .on 'click', (event) -> event.preventDefault()
        .enable()
        .parents()
        .enable()

    disableControls: =>
        @segmentSizeTab.disable()

        @disableGridButtons @segments
        @disableGridButtons @easements
        @disableGridButtons @mortgages
        @disableGridButtons @contracts
        @disableGridButtons @successors
        @disableGridButtons @predecessors

        #disable all tabs
        @view.find '.nav > li a'
        .disable()
        .parents()
        .disable()

        @printButton.disable()

    enableGridButtons: (grid) =>
        rowsSelected = grid.table.rows('.selected').data().count()
        for key, config of grid.editControls
            if (key =='edit' || key == 'delete') && rowsSelected < 1
                continue
            config.$button.enable()

    disableGridButtons: (grid) =>
        for key, config of grid.editControls
            config.$button.disable()

    updateControls: =>
        @updating = true

        Validation.resetState(@validationState)

        @segmentSizeTab.val Globalize.formatNumber @data?.segmentSize ? 0, {minimumFractionDigits: 4}
        @segments.table.clear()
        @easements.table.clear()
        @mortgages.table.clear()
        @contracts.table.clear()
        @successors.table.clear()
        @predecessors.table.clear()

        @map.webgis.clearSelection()
        @map.layerManager.getLayersByName('LandParcel')[0]
        .getSource()
        .clear()

        ClearCurrentHistoryDisplay()

        if @data isnt undefined and Object.keys(@data).length > 0 #object not empty

            RegisterLoadEventOnTab(@view.find('a[data-toggle="tab"][href="#history"]'), @data.uid)
            @enableControls()

            if @data.permissions.LandParcelsViewSegments or @data.permissions.LandParcelsEditSegments
                segments = @data?.segmentations[0]?.segments ? []
                segment.DT_RowId = segment.uid for segment in segments
                @segments.table.rows.add(clone segments)
            if not @data.permissions.LandParcelsEditSegments
                for key, config of @segments.editControls
                    config.$button.disable()
            else
                for key, config of @segments.editControls
                    config.$button.enable()

            if @data.permissions.LandParcelsViewEasements or @data.permissions.LandParcelsEditEasements
                easement.DT_RowId = easement.uid for easement in @data.easements
                @easements.table.rows.add(clone @data.easements)
            if not @data.permissions.LandParcelsEditEasements
                for key, config of @easements.editControls
                    config.$button.disable()
            else
                for key, config of @easements.editControls
                    config.$button.enable()

            if @data.permissions.LandParcelsViewMortgages or @data.permissions.LandParcelsEditMortgages
                mortgage.DT_RowId = mortgage.uid for mortgage in @data.mortgages
                @mortgages.table.rows.add(clone @data.mortgages)
            if not @data.permissions.LandParcelsEditMortgages
                for key, config of @mortgages.editControls
                    config.$button.disable()
            else
                for key, config of @mortgages.editControls
                    config.$button.enable()

            if @data.permissions.LandParcelsEditGeodata
              @map.webgis.switchMode 0
              @map.webgis.menu.$element.show()
            else
              @map.webgis.switchMode -1
              @map.webgis.menu.$element.hide()

            if(@data.contracts)
              contract.DT_RowId = contract.uid for contract in @data.contracts
              contracts = clone @data.contracts
              for contract in contracts
                contract.DT_RowId = contract.uid
                contract.contractDate = moment contract.contractDate if contract.contractDate
                contract.begin = moment contract.begin if contract.begin
                contract.end = moment contract.end if contract.end
              @contracts.table.rows.add(contracts)
#new
            # if @data.permissions.LandParcelsViewMortgages or @data.permissions.LandParcelsEditMortgages
            successor.DT_RowId = successor.uid for successor in @data.landParcelSuccessors
            @successors.table.rows.add(clone @data.landParcelSuccessors)
            @successors.editControls.add.$button.enable()

            @successorsTable.on 'change.selection', => @toggleDeleteButton(@successors)
            # if not @data.permissions.LandParcelsEditMortgages
            #     for key, config of @successors.editControls
            #         config.$button.disable()
            # else
            #     for key, config of @mortgages.editControls
            #         config.$button.enable()

            # if @data.permissions.LandParcelsViewMortgages or @data.permissions.LandParcelsEditMortgages
            predecessor.DT_RowId = predecessor.uid for predecessor in @data.landParcelPredecessors
            @predecessors.table.rows.add(clone @data.landParcelPredecessors)
            @predecessors.editControls.add.$button.enable()

            @predecessorsTable.on 'change.selection', => @toggleDeleteButton(@predecessors)
            # if not @data.permissions.LandParcelsEditMortgages
            #     for key, config of @mortgages.editControls
            #         config.$button.disable()
            # else
            #     for key, config of @mortgages.editControls
            #         config.$button.enable()

            @updateMap()
        else
            @disableControls()

        @segments.table.draw()
        @easements.table.draw()
        @mortgages.table.draw()
        @contracts.table.draw()
        @successors.table.draw()
        @predecessors.table.draw()

        @changeStatus to: 'none'
        @resetButton.disable()
        @applyButton.disable()

        @updating = false

    toggleDeleteButton: (grid) ->
        rowsSelected = grid.table.rows('.selected').data().count()
        if rowsSelected > 0
            grid.editControls.delete.$button.enable()
        else
            grid.editControls.delete.$button.disable()

    updateMap: =>
        wktReader = new WKT()
        options =
            dataProjection: 'EPSG:4326'
            featureProjection: @map.map.getView().getProjection().getCode()

        if not @data.geometry or @data.geometry.length is 0
          @geodataMissingBox.show()
          geomWkt = @data.cadastralDistrictGeometry ? @data.localDistrictGeometry
          if geomWkt
            feature = wktReader.readFeature geomWkt, options
            extent = feature.getGeometry().getExtent()
          else
            extent = @map.map.getView().getProjection().getExtent()
        else
          @geodataMissingBox.hide()
          for geom in @data.geometry
            feature = wktReader.readFeature geom.geometry, options
            feature.setId geom.uid
            extent = feature.getGeometry().getExtent()
            @map.layerManager.getLayersByName('LandParcel')[0].getSource().addFeature feature

        @map.changeDefaultExtent extent
        @map.map.getView().fit extent

    putNewlyCreate: =>
        @applyChanges()
        @changeStatus to: 'working', from: 'changed'
        delete @data.history
        postJson '/RealEstate/LandParcel/Save', @data
        .done (landParcelId) =>
            @changeStatus to: 'success', from: 'working'
            @update landParcelId, @entityId
            setTimeout (=> @changeStatus to: 'none', from: 'success'), 1000
            @resetButton.disable()
            @applyButton.disable()
            @mediator.raise event: 'LandParcelCreated', landParcelId: landParcelId
        .fail =>
            @changeStatus to: 'error', from: 'working'

    postChanges: =>
        @applyChanges()
        @changeStatus to: 'working', from: 'changed'
        landParcel = @data
        delete landParcel.history
        postJson '/RealEstate/LandParcel/Save', landParcel
        .done =>
            @changeStatus to: 'success', from: 'working'
            @update landParcel.uid, @entityId
            setTimeout (=> @changeStatus to: 'none', from: 'success'), 1000
            @resetButton.disable()
            @applyButton.disable()
            @mediator.raise event: 'LandParcelChanged', args: landParcel.uid
        .fail =>
            @changeStatus to: 'error', from: 'working'

    onChange: =>
        if @updating
            return
        activeTab = $('.tab-content>.active').prop('id')
        @status[activeTab].set 'changed'
        @resetButton.enable()
        if @canApplyChanges() then @applyButton.enable() else @applyButton.disable()

    canApplyChanges: =>
        @data.number isnt '' and
        @data.size? and @data.size isnt '' and
        @data.cadastralDistrict isnt '' and
        @data.localDistrict isnt '' and
        @data.segmentSize > 0

    applyChanges: =>
        if @data.segmentations is undefined
            @data.segmentations = [{
                uid: '00000000-0000-0000-0000-000000000000'
                segments: clone @segments.state.rows()
            }]
        else if @data.segmentations[0] isnt undefined
            @data.segmentations[0].segments = clone @segments.state.rows()


        @data.easements = clone @easements.state.rows()
        @data.mortgages = clone @mortgages.state.rows()
        @data.landParcelPredecessors = clone @predecessors.state.rows()
        @data.landParcelSuccessors = clone @successors.state.rows()
        @data.fkDataOwner = @tenantId

        wktConverter = new WKT()
        landParcelSource = @map.layerManager.getLayersByName('LandParcel')[0].getSource()
        @data.geometry = []
        features = landParcelSource.getFeatures()

        isUuid = (str) ->
          regExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
          regExp.test str

        for feature in features
          geometry = feature.getGeometry().clone()
          geometry.transform(@map.map.getView().getProjection(), 'EPSG:4326')

          geom =
            geometry: wktConverter.writeGeometry geometry
            uid: if isUuid feature.getId() then feature.getId() else '00000000-0000-0000-0000-000000000000'

          @data.geometry.push geom

    changeStatus: (args) =>
        (status.set args.to if args.from is undefined or status.is args.from) for tab, status of @status

    hasAnyChanges: =>
        (tab for tab, status of @status when status.is 'changed').length > 0

    showUploadModal: =>
        @filepicker = @uploadModal.find '#filepicker'
        .show()

        @selector = @uploadModal.find '#selector'
        .hide()

        @featureBox = @uploadModal.find('#featureBox').selectize({})

        @uploadContinueBtn = @uploadModal.find '.continueUploadBtn'
        .show().off().on 'click', @loadShapeFile

        cancelBtn = @uploadModal.find '.cancelUploadBtn'

        @uploadConfirmBtn = @uploadModal.find '.confirmUploadBtn'
        .hide()

        @uploadModal.modal('show')

    loadShapeFile: =>
        files = @uploadModal.find('input[type=file]')[0].files
        convertShapes files
        .then (features) =>
            if features.length is 1
              feature = features[0]
              extent = feature.getGeometry().getExtent()
              @map.layerManager.getLayersByName('LandParcel')[0].getSource().addFeature feature
              @map.changeDefaultExtent extent
              @map.map.getView().fit extent
              @uploadModal.modal('hide')
            else
              @filepicker.hide()
              @selector.show()
              @uploadConfirmBtn.show()
              .off().on 'click', =>
                return if @featureBox[0].selectize.items.length is 0
                selectedItem = @featureBox[0].selectize.items[0]
                feature = @featureBox[0].selectize.options[selectedItem].feature
                extent = feature.getGeometry().getExtent()
                @map.layerManager.getLayersByName('LandParcel')[0].getSource().clear()
                @map.layerManager.getLayersByName('LandParcel')[0].getSource().addFeature feature
                @map.changeDefaultExtent extent
                @map.map.getView().fit extent
                @uploadModal.modal('hide')

              @uploadContinueBtn.hide()

              makeName = (feature) ->
                names = for key, value of feature.getProperties() when key isnt 'geometry' and key isnt 'id'
                  "#{key}: #{value}"
                names.join(', ') + ';'

              makeOption = (feature, idx) ->
                value: idx,
                text: makeName feature
                feature: feature

              @featureBox[0].selectize.clearOptions()
              @featureBox[0].selectize.addOption makeOption feature, idx for feature, idx in features
              @featureBox[0].selectize.refreshOptions(true)

module.exports = {
    LandParcelDetailController
}
module.exports.default = LandParcelDetailController
module.exports.__esModule = true
