<template>
  <BsModal
    v-if="translationsLoaded"
    :value="showNotification"
    use-static-backdrop blocking
  >
    <template #title>
      <IxRes>Areas.RealEstate.SR_RealEstate.PendingLeaseAppointments</IxRes>
    </template>
    <div>
      <p>{{ leaseNotificationText }}</p>
      <div v-for="entity in entitiesWithPendingAppointments" :key="entity.uid">
        <a @click="gotoAppointmentsView(entity.crosslink)">
          <span class="glyphicon glyphicon-circle-arrow-right" aria-hidden="true" />
        </a>
        <span> {{ entity.name }} ({{ entity.numberOfAppointments }} Termine) </span>
      </div>
    </div>
    <button
      slot="footer" type="button"
      class="btn btn-default"
      @click="showModal=false"
    >
      <IxRes>Areas.RealEstate.SR_RealEstate.LeaseNotificationClose</IxRes>
    </button>
  </BsModal>
</template>

<script>
import moment from 'moment'
import {get, postJson} from 'src/coffee/infrastructure/ajax'
import BsModal from 'src/vue/components/bootstrap/BsModal'

export default {

  components: {
    BsModal
  },
  data () {
    return {
      userHasNotificationPermission: false,
      entitiesWithPendingAppointments: [],
      entitiesWithLeaseNotificationPermission: [],
      userSettings: {
        lastTimeNotified: null,
        checkingPeriod: {
          timeUnit: null,
          value: null
        },
        checkingInterval: {
          timeUnit: null,
          value: null
        }
      },
      showModal: false,
      translationsLoaded: false
    }
  },

  computed: {

    leaseNotificationText () {
      return this.$i18n.translate('Areas.RealEstate.SR_RealEstate.LeaseNotificationText', {
        0: moment().format('l'),
        1: moment(this.endOfPeriod).format('l')
      })
    },

    userHasPendingAppointments () {
      return this.entitiesWithPendingAppointments.length > 0
    },

    showNotification () {
      return this.userHasNotificationPermission && this.userHasPendingAppointments && this.showModal
    },

    startOfPeriod () {
      return moment().format('YYYY-MM-DD')
    },

    endOfPeriod () {
      return moment().add({[this.userSettings.checkingPeriod.timeUnit]: this.userSettings.checkingPeriod.value}).format('YYYY-MM-DD')
    }
  },

  methods: {
    linkToAppointmentsView (entityId) {
      return `/RealEstate/Appointment/AppointmentFromNotification?entityId=${entityId}&startOfPeriod=${this.startOfPeriod}&endOfPeriod=${this.endOfPeriod}`
    },

    gotoAppointmentsView (crosslink) {
      if (this.entitiesWithPendingAppointments.length > 1) {
        window.open(crosslink, '_blank')
      } else {
        window.open(crosslink, '_parent')
      }
    },

    fetchData () {
      this.entitiesWithPendingAppointments = []
      this.entitiesWithLeaseNotificationPermission.forEach((entity) => {
        get('/RealEstate/Appointment/GetAppointmentsForEntity', {orgUnitId: entity.uid, start: this.startOfPeriod, end: this.endOfPeriod})
          .then((result) => {
            if (result && result.length > 0) {
              this.entitiesWithPendingAppointments.push({uid: entity.uid, name: entity.name, numberOfAppointments: result.length, crosslink: this.linkToAppointmentsView(entity.uid)})
            }
          })
        this.showModal = true
      })
    }
  },

  mounted () {
    get('/RealEstate/Home/GetEntitiesWithNotificationPermissionForUser')
      .then((result) => {
        if (result.length > 0) {
          this.userHasNotificationPermission = true
          this.entitiesWithLeaseNotificationPermission = result
          get('/RealEstate/Home/GetNotificationSettings')
            .then((result) => {
              this.userSettings = result
              const untilNextNotification = {[this.userSettings.checkingInterval.timeUnit]: this.userSettings.checkingInterval.value}
              const lastTimeDate = moment(this.userSettings.lastTimeNotified)
              const currentTime = moment()
              if (lastTimeDate.add(untilNextNotification).isSameOrBefore(currentTime, 'day')) {
                this.fetchData()
                postJson('/RealEstate/Home/SetLastNotificationTime', {date: currentTime.toISOString()})
              }
            })
        }
      })
  },

  created () {
    this.$store.dispatch('i18n/preloadTranslations', [
      'Areas.RealEstate.SR_RealEstate'
    ])
      .then(() => {
        this.translationsLoaded = true
      })
  }
}
</script>

<style>
</style>
