{register} = require 'src/js/infrastructure/setup-registry'
{mount} = require 'src/js/helpers/vue'

makeAndRegisterSetup = (setupSpec, component) ->
    setup = ($ctx) ->
        el = $ctx.find('#mount')[0]
        mount el, component
    register setupSpec, setup


makeAndRegisterSetup('real-estate/report/mortgage',
  require('src/vue/areas/real-estate/report/MortgageReport').default)

makeAndRegisterSetup('real-estate/report/noMortgage',
  require('src/vue/areas/real-estate/report/NoMortgageReport').default)

makeAndRegisterSetup('real-estate/report/mismatchedLandparcelSegmentSizeSetup',
  require('src/vue/areas/real-estate/report/MismatchedLandparcelSegmentSizeReport').default)

makeAndRegisterSetup('real-estate/report/landparcelBISetup',
  require('src/vue/areas/real-estate/report/LandparcelBI').default)

makeAndRegisterSetup('real-estate/report/landparcelPriceBISetup',
  require('src/vue/areas/real-estate/report/LandparcelPriceBI').default)

makeAndRegisterSetup('real-estate/report/forest',
  require('src/vue/areas/real-estate/report/ForestReport').default)
