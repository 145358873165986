# CoffeeScript
{get, postJson} = require 'src/coffee/infrastructure/ajax'
{notifications} = require 'src/js/infrastructure'

newGuid = (require 'src/js/helpers/new-guid').default

{getModal} = require 'src/coffee/controls/modals'

{Binder} = require 'src/coffee/areas/real-estate/utils/binder'
{Stati} = require 'src/coffee/areas/real-estate/utils/stati'
{format} = require 'src/js/i18n/conversion'
{GridExtender} = require 'src/coffee/areas/real-estate/utils/grid-extender'
{CommunityPicker} = require 'src/coffee/areas/real-estate/community/community-picker'

{SegmentsSelectorController} = require './segments-selector-controller'
{AddressEditController} = require './address-edit-controller'

{Validation} = require 'src/coffee/helpers/validation'
{ValidationRules} = require 'src/coffee/controls/grid/validation-rules'

class PropertyDetailController
    constructor: (@$view, @onSaveCallback, @mediator) ->
        @data = null
        @entityId = null
        @binder = new Binder(@$view, @onChange, Validation.invalidatedHandler)

        @stati = new Stati(@$view, 'common', 'segments', 'site-development', 'tax-notices', 'facilities', 'infrastructure')

        @setupControls()
        @setupAddSegment()

        @validationState = {
            commonTabState: {}
            segmentsTabState: {}
            siteDevelopmentTabState: {}
            taxNoticeTabState: {}
            facilitiesTabState: {}
            infrastructure: {}
        }

    update: (id) ->
        url =  $('#actions').data('get-property-details')
        get url, {id}
            .done (result) =>
                @updateContent(result)

    updateContent: (property) ->
        @data = property
        @id = @data.uid

        @resetSelectizeOptions()

        @stati.changeStatus to: 'none'

        @data.size = @accumulateSegmentSizes(@data.segments)
        @data.buildingValueSum = @calculateBuildingValueSum(@data)

        @binder.updateControls(@data)
        @binder.enableControls()

        @editAddressButton.prop 'disabled', false

        $('#common-form').clearValidation()
        $('#tax-notice-form').clearValidation()
        $('#infrastructure-form').clearValidation()
        Validation.resetState(@validationState)
        Validation.invalidateControls(
            @binder.getIdentifiers((binding) -> binding.type isnt 'hidden' and not binding.isReadOnly),
            true)

        @applyChangesButton.prop 'disabled', true
        @resetChangesButton.prop 'disabled', true

        @segmentGrid.editControls.add.$button.enable()

    updateEntityId: (entityId) ->
        @entityId = entityId
        @community.updateEntityId(entityId)


    calculateBuildingValueSum: (data) ->
        buildingValueSum = 0.00
        for item in data.facilities
            buildingValueSum+= item.evaluation

        return buildingValueSum


    moveToFirstTabAndFirstInput: =>
        $('.nav-tabs a:first').tab('show')
        @binder.bindings['#property-number'].control.focus()

    accumulateSegmentSizes: (segments) ->
        totalSize = 0

        if segments
            for segment in segments
                totalSize += segment.size

        return totalSize

    setupControls: ->
        @saveOrUpdateAction = $('#actions').data('save-or-update-action')
        @selectSegmentsModalAction = $('#actions').data('select-segments-modal-action')
        @removeSegmentsModalText = $('#actions').data('remove-segments-modal-text')
        @editAddressModalAction = $('#actions').data('edit-address-modal-action')
        @loadTaxAuthoritiesAction =  $('#actions').data('tax-authorities-action')
        @loadCommunitiesAction =  $('#actions').data('communities-load-action')
        @savePropertyErrorMessage =  $('#actions').data('save-property-error-message')
        @savePropertySuccessMessage =  $('#actions').data('save-property-success-message')

        formatter4d = (value) ->
            {format} = require 'src/js/i18n/conversion'
            return format(value, 'f4')
        formatter2d = (value) ->
            {format} = require 'src/js/i18n/conversion'
            return format(value, 'f2')
        formatter1d = (value) ->
            {format} = require 'src/js/i18n/conversion'
            return format(value, 'f1')
        formatter0dg = (value) ->
            {format} = require 'src/js/i18n/conversion'
            return format(value, 'f0g')
        parser = (value) ->
            {parse} = require 'src/js/i18n/conversion'
            return parse(value)

        @binder.bindHiddenValue('##UID', 'uid')
        @binder.bindHiddenValue('##AssignedEntityId', 'assignedEntityId')
        @binder.bindInput('#property-number', 'number')
        @binder.bindInput('#property-name', 'name')
        @binder.bindAjaxSelectize('#property-type', 'propertyType', @loadPropertyTypes,
                    {
                        create: (input) ->
                            $('#property-type')[0].selectize.removeOption('00000000-0000-0000-0000-000000000000')

                            return {
                                name: input
                                uid: '00000000-0000-0000-0000-000000000000'
                           }
                        selectOnTab: false
                        openOnFocus: true
                    },
                    (input, control) ->
                        for name, option of control.options
                            return false if option.name.toLowerCase() is input.toLowerCase()
                        return true
                    )
        @binder.bindInput('#size', 'size', true, formatter4d)
        @binder.bindInput('#segment-size-tab', 'size', true, formatter4d)
        @addressStreet =  @binder.bindHiddenValue('#address-street', 'address.street')
        @addressPostalCode = @binder.bindHiddenValue('#address-postal-code', 'address.postalCode')
        @addressCity = @binder.bindHiddenValue('#address-city', 'address.city')
        @binder.bindInput('#address-as-string', 'address.addressAsString', true)

        @setupEditAddress()

        @binder.bindInput('#remark', 'remark')
        @binder.bindAjaxSelectize('#functional-zoning-category', 'functionalZoningCategory',
                    @loadFunctionalZoningCategories,
                    {
                        create: (input) ->
                            $('#functional-zoning-category')[0].selectize
                            .removeOption('00000000-0000-0000-0000-000000000000')

                            return {
                                name: input
                                uid: '00000000-0000-0000-0000-000000000000'
                            }
                        selectOnTab: false
                        openOnFocus: true
                    },
                    (input, control) ->
                        for name, option of control.options
                            return false if option.name.toLowerCase() is input.toLowerCase()
                        return true
                    )
        @binder.bindDateControl('#date-unit-value-from-tax-notice', 'taxNotice.dateUnitValueFromTaxNotice')
        @binder.bindInput('#building-license-restrictions', 'buildingLicenseRestrictions')

        @binder.bindGrid('#segment-grid', 'segments')

        @binder.bindAjaxSelectize('#tax-authority', 'taxNotice.taxAuthority', @loadTaxAuthorities,
            {
                create: (input) ->
                    $('#tax-authority')[0].selectize.removeOption('00000000-0000-0000-0000-000000000000')

                    return {
                        name: input
                        uid: '00000000-0000-0000-0000-000000000000'
                    }
                selectOnTab: false
                openOnFocus: true
            }
            (input, control) ->
                for name, option of control.options
                    return false if option.name.toLowerCase() is input.toLowerCase()
                return true
            )

        @community = new CommunityPicker @view, @mediator, @binder

        @binder.bindDateControl('#notification-date', 'taxNotice.notificationDate')
        @binder.bindInput('#reference-number', 'taxNotice.referenceNumber')
        @binder.bindInput('#cash-reference-number', 'taxNotice.cashReferenceNumber')
        @binder.bindInput('#annual-property-tax', 'taxNotice.annualPropertyTax', false, formatter2d, parser)
        @binder.bindInput('#property-tax-q1', 'taxNotice.propertyTaxQ1', false, formatter2d, parser)
        @binder.bindInput('#property-tax-q2', 'taxNotice.propertyTaxQ2', false, formatter2d, parser)
        @binder.bindInput('#property-tax-q3', 'taxNotice.propertyTaxQ3', false, formatter2d, parser)
        @binder.bindInput('#property-tax-q4', 'taxNotice.propertyTaxQ4', false, formatter2d, parser)
        @binder.bindInput('#land-value-dm', 'taxNotice.landValueDM', false, formatter2d, parser)
        @binder.bindInput('#unit-value-dm', 'taxNotice.unitValueDM', false, formatter2d, parser)
        @binder.bindInput('#unit-value-eur', 'taxNotice.unitValueEUR', false, formatter2d, parser)
        @binder.bindInput('#assessed-rate', 'taxNotice.assessedRate')
        @binder.bindInput('#basic-ammount-EUR', 'taxNotice.basicAmmount', false, formatter2d, parser)
        @binder.bindInput('#outdoor-facilities-value-dm', 'taxNotice.outdoorFacilitiesValue', false, formatter0dg, parser)
        @binder.bindInput('#property-value-dm', 'taxNotice.propertyValue', false, formatter0dg, parser)

        @binder.bindGrid('#facilities-grid', 'facilities')
        facilityGrid = @$view.find('#facilities-grid').staticgrid()[0]
        facilityGrid.editControls.add.$button.hide()
        facilityGrid.editControls.edit.$button.hide()
        facilityGrid.editControls.delete.$button.hide()

        @binder.bindInput('#sum-building-value-tab', 'buildingValueSum', true, formatter2d)

        @binder.bindInput('#internet-provider', 'infrastructure.internetProvider')
        @binder.bindInput('#contract-number', 'infrastructure.contractNumber')
        @binder.bindInput('#router-name', 'infrastructure.routerName')
        @binder.bindInput('#phone-number', 'infrastructure.phoneNumber')
        @binder.bindInput('#upload-speed', 'infrastructure.uploadSpeed', false, formatter1d, parser)
        @binder.bindInput('#download-speed', 'infrastructure.downloadSpeed', false, formatter1d, parser)
        @binder.bindInput('#device-screen', 'infrastructure.screenSize', false, formatter1d, parser)

        @binder.bindCombobox('#energy-meter-cellular-area', 'infrastructure.energyMeterCellularArea')
        @binder.bindCombobox('#smartphone-usage', 'infrastructure.smartphoneUsage')

        @binder.bindCheckbox('#device-computer', 'infrastructure.computer')
        @binder.bindCheckbox('#device-laptop', 'infrastructure.laptop')
        @binder.bindCheckbox('#device-tablet', 'infrastructure.tablet')
        @binder.bindCheckbox('#device-fax', 'infrastructure.fax')
        @binder.bindCheckbox('#router-lan', 'infrastructure.lan')
        @binder.bindCheckbox('#router-wlan', 'infrastructure.wlan')
        @binder.bindCheckbox('#device-phone', 'infrastructure.phone')

        @binder.bindInput('#infrastructure-remark', 'infrastructure.remarks')

        @moveToFirstTabAndFirstInput()

        @loadPropertyTypesAction = $('#actions').data('property-type-load-action')  #warum so spät?
        @loadFunctionalZoningCategoriesAction = $('#actions').data('functional-zoning-category-load-action')
        @loadTaxAuthorityAction = $('#actions').data('tax-authority-load-action')

        @applyChangesButton = @$view.find('#applyChangesBtn')
                                .prop 'disabled', true
                                .on 'click', @saveOrUpdate
        @resetChangesButton = @$view.find('#resetChangesBtn')
                                .prop 'disabled', true
                                .on 'click', @reset

        @segmentGrid = @$view.find '#segment-grid'
                            .staticgrid()[0]
        gridExtender = new GridExtender(@segmentGrid)
        gridExtender.addExtendedRemoveConfirmationDialog(@removeSegmentsModalText)

        @segmentGrid.editor.on 'remove', (event) =>
            @recalculateSizeField()

        @segmentGrid.editControls.add.$button.disable()

        @setupConfirmationDialog()

    resetSelectizeOptions: =>
        @binder.bindings['#property-type'].control.clearOptions()
        @binder.bindings['#property-type'].control.refreshOptions()
        @binder.bindings['#functional-zoning-category'].control.clearOptions()
        @binder.bindings['#functional-zoning-category'].control.refreshOptions()
        @binder.bindings['#tax-authority'].control.clearOptions()
        @binder.bindings['#tax-authority'].control.refreshOptions()

    recalculateSizeField: ->
        segments = @binder.readControl('#segment-grid')
        completeSize = @accumulateSegmentSizes(segments)
        @binder.bindings['#size'].setData(completeSize)
        @binder.bindings['#segment-size-tab'].setData(completeSize)

    setupConfirmationDialog: ->
        @confirmationDialog = @$view.find('#confirmationDialog').modal show: false
        @confirmButton = @$view.find '#confirmBtn'
        @dismissButton = @$view.find '#dismissBtn'
        @cancelButton = @$view.find '#cancelBtn'


    loadPropertyTypes: (query, callback) =>
        get(@loadPropertyTypesAction, {query: query, entityId: @entityId})
            .done (result) -> callback(result)

    loadFunctionalZoningCategories: (query, callback) =>
        get(@loadFunctionalZoningCategoriesAction, {query:query, entityId: @entityId})
            .done (result) -> callback(result)

    loadTaxAuthorities: (query, callback) =>
        get(@loadTaxAuthorityAction, {query:query, entityId: @entityId})
        .done (result) -> callback(result)


    createNewProperty: (entityId) ->
        newProperty =
            uid: '00000000-0000-0000-0000-000000000000'
            assignedEntityId: entityId
            name: ''
            number: ''
            remark: ''
            taxNotice:
                uid: '00000000-0000-0000-0000-000000000000',
                dateUnitValueFromTaxNotice: null
                notificationDate: null

            segments: [],
            facilities: [],
            address:
                street: null
                postalCode: null
                city: null
                addressAsString: null

        return newProperty

    setupAddSegment: ->
        @segmentGrid.editControls.add.$button.off()
        @segmentGrid.editControls.add.$button.on 'click', (e) =>
            e.preventDefault()
            @addSegmentModal()

    addSegmentModal: =>
        getModal @selectSegmentsModalAction
            .then ($modal) =>
                @segmentsModal = new SegmentsSelectorController $modal, @id, @entityId
                @segmentsModal.onSubmit (result) =>
                    @processAddedSegments(result.resultingRows)

    processAddedSegments: (segments) =>
        processedSegments = segments.map (seg) => {
            uid: '00000000-0000-0000-0000-000000000000'
            cadastralDistrictNumber: seg.cadastralDistrictNumber
            groundpoints: seg.groundpoints
            landParcelId: seg.landParcelId
            landParcelNumber: seg.landParcelNumber
            localDistrictName: seg.localDistrictName
            size: seg.segmentSize
            DT_RowId: newGuid()
            usage: "#{seg.usageName} (#{seg.usageShortcut})"
            usageId: seg.usageId
            owner: seg.owner
            link: "/RealEstate/Company/#{@data.assignedEntityId}/Landparcel/#{seg.landParcelId}"
        }

        @segmentGrid.table.rows.add(processedSegments)
        @segmentGrid.table.draw()
        @recalculateSizeField()
        @onChange()

    setupEditAddress: =>
        @editAddressButton = @$view.find '#editAddressButton'

        @editAddressButton.on 'click', (event) =>  @addEditAddressModal()

        @editAddressButton.prop 'disabled', true

    addEditAddressModal: =>
        getModal @editAddressModalAction
            .then ($modal) =>
                temp = @binder.readControls()
                @segmentsModal = new AddressEditController $modal, temp.address
                @segmentsModal.onSubmit (result) =>
                    @processAddressChanged(result)


    loadCommunities: (query, callback) =>
        get(@loadCommunitiesAction, {query:@query, entityId:@entityId})
            .done (result) -> callback(result)
        return undefined

    processAddressChanged: (address) =>
        @addressStreet.set(address.street)
        @addressPostalCode.set(address.postalCode)
        @addressCity.set(address.city)

        addressAsStringTextBox = @$view.find('#address-as-string')
        addressAsStringTextBox.val("#{address.street}\n#{address.postalCode} #{address.city}")

        @onChange()

    create: (entityId) ->
        @ensureChangesAreConfirmedBefore( =>
            @clearContent()
            @data = @createNewProperty(entityId)
            @updateContent(@data)
            @moveToFirstTabAndFirstInput())

    saveOrUpdate: =>
        if not Validation.isStateValid(@validationState)
            for key, value of @stati.trackers
                if value.currentState is 'invalid'
                    translatedTabName = $(".nav.nav-tabs [href=##{key}]>span").text().trim()
                    notifications.error("#{@savePropertyErrorMessage} #{translatedTabName}")
        for tabName, tabState of @validationState
            for propertyName, isOk of tabState when not isOk
                if(propertyName.indexOf('grid_wrapper') isnt -1)
                    errorMessage = $('#'+propertyName).parents('[data-validation-error]').data('validation-error')
                else
                    errorMessage = $('#'+propertyName).data('validation-error')
                notifications.error(errorMessage)
                return

        @stati.changeStatus from: 'changed', to: 'working'

        @data = @binder.readControls()
        @data.propertyType.fkDataOwner = @entityId

        url = @saveOrUpdateAction
        postJson(url, @data)
            .done (result) =>
                @stati.changeStatus from: 'working', to: 'success'
                @applyChangesButton.prop 'disabled', true
                @resetChangesButton.prop 'disabled', true
                notifications.success @savePropertySuccessMessage
                @onSaveCallback(result)
            .fail (result) =>
                @stati.changeStatus from: 'working', to: 'error'
                notifications.error  @savePropertyErrorMessage

    reset: =>
        @binder.clearControls()
        @updateContent(@data)

    hasChanges: ->
        @hasAnyChanges()

    hasAnyChanges: ->
        return @stati.hasAnyChanges()

    dismissChanges: ->
        @reset()

    saveChanges: ->
        @saveOrUpdate()

    onChange: =>
        activeTab = $('.tab-content>.active').prop('id')
        @stati.setTabStatus activeTab, 'changed'

        @applyChangesButton.prop 'disabled', false
        @resetChangesButton.prop 'disabled', false

    clearContent: =>
        @data = {}
        @stati.changeStatus to: 'none'

        @binder.disableControls()
        @binder.clearControls()
        @editAddressButton.prop 'disabled', true

        @applyChangesButton.prop 'disabled', true
        @resetChangesButton.prop 'disabled', true

        @segmentGrid.editControls.add.$button.disable()

    clear: ->
        @ensureChangesAreConfirmedBefore(@clearContent)

    display: (propertyId) ->
        @ensureChangesAreConfirmedBefore( => @update(propertyId))

    askForConfirmation: (onConfirmFunc) ->
        @confirmationDialog.modal 'show'
        @confirmButton.off().on 'click', =>
            @applyButton.click()
            onConfirmFunc()
        @dismissButton.off().on 'click', ->
            onConfirmFunc()
        @cancelButton.off().on 'click', =>
            @cancelConfirmation()

    ensureChangesAreConfirmedBefore: (action) ->
        if @stati.hasAnyChanges()
            @askForConfirmation action
        else
            action()

module.exports = {PropertyDetailController}

module.exports.__esModule = true
module.exports.default = PropertyDetailController
