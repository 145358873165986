<template lang="html">
  <div class="real-estate">
    <tree-layout :loading="loading">
      <tree slot="tree" v-model="selectedTreeNode" :nodes="nodes" />
      <div v-if="translationsLoaded">
        <grid
          v-if="!isCreateLeaseContract" ref="grid"
          v-model="selectedContracts" :columns="columns"
          selection="single"
          :title="SR_RealEstate.LeaseContracts" paging
          :add-button="hasCurrentOrgUnit" :edit-button="hasCurrentContract"
          :create-button="hasCurrentContract"
          ajax-url="/RealEstate/Lease/GetContractListAjax" column-filtering
          :ajax-payload="ajaxPayload" @add="addContract"
          @edit="editLeaseContractRequest" @delete="deleteContract"
        />
        <div class="detail-body">
          <div v-if="hasCurrentContract && !isCreateLeaseContract">
            <bs-tabs v-model="activeTab" :tabs="allTabs">
              <span slot="common-header">
                <span>
                  {{ allTabs[0].title }}
                </span>
                <status-indicator :status="status.common" />
              </span>
              <lease-common slot="common" v-model="currentContract" />

              <span slot="areas-header">
                <span>
                  {{ allTabs[1].title }}
                </span>
                <status-indicator :status="status.areas" />
              </span>
              <lease-areas slot="areas" v-model="currentContract" />

              <span slot="finances-header">
                <span>
                  {{ allTabs[2].title }}
                </span>
                <status-indicator :status="status.finances" />
              </span>
              <lease-finances slot="finances" v-model="currentContract" />

              <span slot="terms-header">
                <span>
                  {{ allTabs[3].title }}
                </span>
                <status-indicator :status="status.terms" />
              </span>
              <lease-terms slot="terms" v-model="currentContract" />

              <span slot="amendments-header">
                <span>
                  {{ allTabs[4].title }}
                </span>
                <status-indicator :status="status.amendments" />
              </span>
              <lease-amendments slot="amendments" v-model="currentContract" />
            </bs-tabs>
            <div v-if="permissions.leaseContractCanEdit" class="row">
              <div class="col-sm-offset-8 col-sm-2">
                <button
                  slot="footer" type="button"
                  class="btn btn-success btn-block"
                  :disabled="!isEditChanged || !requiredInputsFilledOut" @click="editLeaseContractSuccessWithNotification"
                >
                  <IxRes :resource="SR_Common">
                    Save
                  </IxRes>
                </button>
              </div>
              <div class="col-sm-2">
                <button
                  slot="footer" type="button"
                  class="btn btn-default btn-block"
                  :disabled="!isEditChanged" @click="editLeaseContractFailure"
                >
                  <IxRes :resource="SR_Common">
                    Reset
                  </IxRes>
                </button>
              </div>
            </div>
          </div>

          <div v-if="isCreateLeaseContract">
            <h1>
              <IxRes :resource="SR_RealEstate">
                CreateNewLeaseContract
              </IxRes>
            </h1>
            <bs-tabs v-model="activeCreateTab" :tabs="allCreateTabs">
              <span slot="common-header">
                <span>
                  {{ allCreateTabs[0].title }}
                </span>
                <status-indicator :status="status.common" />
              </span>
              <lease-common slot="common" v-model="newContract" create-mode />

              <span slot="areas-header">
                <span>
                  {{ allCreateTabs[1].title }}
                </span>
                <status-indicator :status="status.areas" />
              </span>
              <lease-areas slot="areas" v-model="newContract" create-mode />

              <span slot="finances-header">
                <span>
                  {{ allCreateTabs[2].title }}
                </span>
                <status-indicator :status="status.finances" />
              </span>
              <lease-finances slot="finances" v-model="newContract" />

              <span slot="terms-header">
                <span>
                  {{ allCreateTabs[3].title }}
                </span>
                <status-indicator :status="status.terms" />
              </span>
              <lease-terms slot="terms" v-model="newContract" />
            </bs-tabs>
            <div class="row">
              <div class="col-sm-offset-8 col-sm-2">
                <button
                  slot="footer" type="button"
                  class="btn btn-success btn-block"
                  :disabled="!isEditChanged || !newContractRequiredInputsFilledOut" @click="createNewLeaseContractSuccessWithNotification"
                >
                  <IxRes :resource="SR_Common">
                    Create
                  </IxRes>
                </button>
              </div>
              <div class="col-sm-2">
                <button
                  slot="footer" type="button"
                  class="btn btn-default btn-block"
                  @click="createNewLeaseContractFailure"
                >
                  <IxRes :resource="SR_Common">
                    Cancel
                  </IxRes>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </tree-layout>
    <save-or-discard-modal
      :value="isSaveChangesModalOpen" @cancel="cancel"
      @no="discardAndNavigate"
      @yes="saveAndNavigate"
    />
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

import Vue from 'vue'

import BsTabs from 'src/vue/components/bootstrap/BsTabs'
import TreeLayout from 'src/vue/legacy-compat/TreeLayout'
import Tree from '@components/nav/Tree'
import Grid from 'src/vue/areas/real-estate/components/grid/RELegacyGrid.vue'
import LeaseAreas from './LeaseAreas'
import LeaseFinances from './LeaseFinances'
import LeaseTerms from './LeaseTerms'
import LeaseAmendments from './lease-amendments.vue'
import LeaseCommon from './leaseCommon'
import SaveOrDiscardModal from './save-or-discard-modal'
import statusIndicator from 'src/vue/areas/real-estate/components/status'

import makeTree from './makeTree'
import {findNode} from 'src/coffee/controls/tree/helpers'
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

import {notifications} from 'src/js/infrastructure'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    BsTabs,
    Tree,
    TreeLayout,
    Grid,
    LeaseCommon,
    LeaseAreas,
    LeaseFinances,
    LeaseTerms,
    SaveOrDiscardModal,
    LeaseAmendments,
    statusIndicator
  },
  mixins: [makeResourceMixin(resources)],
  props: ['value'],
  data () {
    return {
      translationsLoaded: false,
      loading: false,
      selectedOrganisationUnit: null,
      activeTab: 'common',
      activeCreateTab: 'common',
      potentialNavigationTarget: null,
      potentialNavigationTargetType: null
    }
  },

  computed: {
    ...mapGetters('realEstateContractLease/contracts', {
      permissions: 'currentPermissions',
      currentContract: 'currentContract',
      newContract: 'newContract',
      isCreateLeaseContract: 'isCreateLeaseContract',
      isEditLeaseContract: 'isEditLeaseContract',
      isEditChanged: 'isEditChanged',
      status: 'status',
      hasCurrentContract: 'hasCurrentContract'}),
    ...mapGetters('realEstateContractLease/navigation', {
      hasCurrentOrgUnit: 'hasCurrentOrgUnit',
      orgUnits: 'orgUnits',
      selectedOrgUnit: 'selectedOrgUnit'}),
    ...mapGetters('realEstateContractLease/save', {
      isSaveChangesModalOpen: 'isSaveChangesModalOpen'}),

    selectedTreeNode: {
      get () {
        let value = this.selectedOrgUnit

        if (value && !value.name && !value.template) { // value is not a orgUnit from tree
          const foundNode = findNode(this.nodes, x => x.id === value.id)

          if (foundNode && foundNode.entity) {
            value = foundNode.entity
          }
        }

        return value
      },
      set (value) {
        this.maybeNavigateToOrgUnit(value)
      }
    },

    selectedContracts: {
      get () {
        return this.hasCurrentContract ? [this.currentContract] : []
      },
      set (value) {
        const contract = value && value.length > 0 ? value[0] : null
        const contractId = contract && contract.uid ? contract.uid : null
        const currentContractId = this.hasCurrentContract && this.currentContract.uid ? this.currentContract.uid : null

        if (contractId !== currentContractId) {
          this.maybeNavigateToContract(contractId)
        }
      }
    },

    nodes () {
      return this.orgUnits.map(makeTree)
    },

    columns () {
      return [
        {typeName: 'String', propertyName: 'uid', invisible: true, isIdColumn: true},
        {localizedTitle: this.SR_ModelProperties['CertificateNumber'], typeName: 'String', propertyName: 'documentNumber'},
        {localizedTitle: this.SR_ModelProperties['TermFrom'], typeName: 'DateTime', propertyName: 'runtimeStart', dateTimeFormat: 'L'},
        {localizedTitle: this.SR_ModelProperties['TermTo'], typeName: 'DateTime', propertyName: 'runtimeEnd', dateTimeFormat: 'L'},
        {localizedTitle: this.SR_ModelProperties['Partner'], typeName: 'String', propertyName: 'leaser'},
        {localizedTitle: this.SR_ModelProperties['Area'], typeName: 'Float', propertyName: 'area', minDecimalDigits: 4, maxDecimalDigits: 4},
        {localizedTitle: this.SR_ModelProperties['LeaseObject'], typeName: 'Number', propertyName: 'businessObject'}]
    },

    allTabs () {
      return [
        {title: this.getLocalizedTitleByResourceName('Common'), value: 'common', disabled: false},
        {title: this.getLocalizedTitleByResourceName('Areas'), value: 'areas', disabled: false},
        {title: this.getLocalizedTitleByResourceName('Finances'), value: 'finances', disabled: false},
        {title: this.getLocalizedTitleByResourceName('Terms'), value: 'terms', disabled: false},
        {title: this.getLocalizedTitleByResourceName('Amendments'), value: 'amendments', disabled: false}
      ]
    },

    allCreateTabs () {
      return [
        {title: this.getLocalizedTitleByResourceName('Common'), value: 'common', disabled: false},
        {title: this.getLocalizedTitleByResourceName('Areas'), value: 'areas', disabled: false},
        {title: this.getLocalizedTitleByResourceName('Finances'), value: 'finances', disabled: false},
        {title: this.getLocalizedTitleByResourceName('Terms'), value: 'terms', disabled: false}
      ]
    },

    ajaxPayload () {
      return {
        Id: this.selectedOrgUnit ? this.selectedOrgUnit.id : null
      }
    },

    requiredInputsFilledOut () {
      let contract = this.selectedContracts[0]
      return contract.certificateNumber && contract.contractDate && contract.amendments[0].leaser[0] && contract.amendments[0].lessor[0]
    },

    newContractRequiredInputsFilledOut () {
      let contract = this.newContract
      return contract.certificateNumber && contract.contractDate && contract.amendments[0].leaser[0] && contract.amendments[0].lessor[0]
    }
  },

  methods: {
    ...mapActions('realEstateContractLease', [
      'initRequest']),
    ...mapActions('realEstateContractLease/contracts', [
      'selectContract',
      'deleteContract',
      'createNewLeaseContractRequest',
      'createNewLeaseContractSuccess',
      'createNewLeaseContractFailure',
      'editLeaseContractRequest',
      'editLeaseContractFailure',
      'editLeaseContractSuccess']),
    ...mapActions('realEstateContractLease/navigation', [
      'selectOrgUnit']),
    ...mapActions('realEstateContractLease/save', [
      'saveChangesRequest',
      'closeNavigationGuard',
      'saveChangesSuccess']),

    refreshGrid () {
      this.$refs.grid.reloadAjax()
    },

    getLocalizedTitleByResourceName (resourceName) {
      return this.SR_RealEstate[resourceName]
    },

    maybeNavigateToContract (target) {
      if (this.isEditChanged) {
        this.potentialNavigationTarget = target
        this.potentialNavigationTargetType = 'contract'
        this.saveChangesRequest()
      } else {
        this.selectContract(target)
      }
    },

    saveAndNavigate () {
      if (this.isCreateLeaseContract) {
        this.createNewLeaseContractSuccessWithNotification()
          .then(this.saveChangesSuccess)
          .then(this.navigateAway)
      } else {
        this.saveChangesSuccess()
          .then(this.navigateAway)
          .then(this.refreshGrid)
      }
    },

    navigateAway () {
      if (this.potentialNavigationTargetType === 'contract') {
        this.selectContract(null)
        Vue.nextTick()
          .then(() => this.selectContract(this.potentialNavigationTarget))
      } else if (this.potentialNavigationTargetType === 'newContract') {
        this.selectContract(null)
        Vue.nextTick()
          .then(this.createNewLeaseContractRequest)
      } else if (this.potentialNavigationTargetType === 'orgUnit') {
        this.selectOrgUnit(this.potentialNavigationTarget)
        this.selectContract(null)
      }
    },

    discardAndNavigate () {
      if (this.isCreateLeaseContract) {
        this.createNewLeaseContractFailure()
          .then(this.closeNavigationGuard)
          .then(this.navigateAway)
      } else {
        this.closeNavigationGuard()
          .then(this.navigateAway)
      }
    },

    maybeNavigateToOrgUnit (target) {
      if (this.isEditChanged) {
        this.potentialNavigationTarget = target
        this.potentialNavigationTargetType = 'orgUnit'
        this.saveChangesRequest()
      } else {
        if (this.isCreateLeaseContract) {
          this.createNewLeaseContractFailure()
        }

        this.selectOrgUnit(target)
      }
    },

    cancel () {
      this.closeNavigationGuard()
      // TODO: Reset Grid Selection
    },

    addContract () {
      this.activeCreateTab = 'common'
      if (!this.isEditChanged) {
        this.createNewLeaseContractRequest()
      } else {
        this.potentialNavigationTarget = null
        this.potentialNavigationTargetType = 'newContract'
        this.saveChangesRequest()
      }
    },

    createNewLeaseContractSuccessWithNotification () {
      this.createNewLeaseContractSuccess()
        .then(() => {
          notifications.success(this.SR_RealEstate.SaveLeaseContractSuccessMessage)
        })
        .then(this.refreshGrid).catch(() => {})
    },

    editLeaseContractSuccessWithNotification () {
      this.editLeaseContractSuccess()
        .then(() => {
          notifications.success(this.SR_RealEstate.SaveLeaseContractSuccessMessage)
        })
        .then(this.refreshGrid).catch(() => {})
    }
  },

  mounted () {
    this.selectContract(null)
    this.initRequest()
    this.activeTab = 'common'
    this.activeCreateTab = 'common'
  },

  created () {
    this.$store.dispatch('i18n/preloadTranslations', [
      'Common.SR_Common',
      'Areas.RealEstate.SR_RealEstate',
      'ModelProperties.SR_ModelProperties'
    ])
      .then(() => {
        this.translationsLoaded = true
      })
  }
}
</script>

<style lang="css">
</style>
