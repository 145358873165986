<template lang="html">
  <div>
    <div class="form">
      <vue-row>
        <vue-element width="6">
          <local-district-picker ref="firstInput" v-model="lp.localDistrict" :org-unit-id="tenantId" />
        </vue-element>
        <vue-element width="3">
          <vue-input v-model="lp.cadastralDistrict" label="Flur" />
        </vue-element>
        <vue-element width="3">
          <vue-input v-model="lp.number" label="Nummer" />
        </vue-element>
      </vue-row>
      <vue-row>
        <vue-element width="6">
          <business-object-picker v-model="lp.businessObject" :org-unit-id="tenantId" :fields="{value: 'value', label: 'Name'}" />
        </vue-element>
        <vue-element width="3">
          <formatted-input
            v-model="lp.size" label="Katastergröße"
            add-on-text="ha"
            :parser="parser" :formatter="formatter4d"
          />
        </vue-element>
        <vue-element width="3">
          <formatted-input
            v-model="lp.segmentSize" label="Summe Segmentgrößen"
            add-on-text="ha"
            read-only="true" :parser="parser"
            :formatter="formatter4d" tabindex="-1"
          />
        </vue-element>
      </vue-row>
      <vue-row>
        <vue-element width="5">
          <person-picker
            v-model="lp.owner" :org-unit-id="tenantId"
            type="Other"
            label="Eigentümer"
            :allow-empty="true"
            :disabled="false"
          />
        </vue-element>
        <vue-element width="5">
          <community-picker
            v-model="lp.landRegisterCommunity" :entity-id="tenantId"
            :local-district-id="(lp.localDistrict||{}).value"
            label="Grundbuchgemeinde"
          />
        </vue-element>
        <vue-element width="2">
          <vue-input v-model="lp.landRegister" label="Grundbuchseite" />
        </vue-element>
      </vue-row>
      <vue-row>
        <vue-element width="12">
          <vue-textarea v-model="lp.remark" label="Bemerkung" rows="3" />
        </vue-element>
      </vue-row>
    </div>
  </div>
</template>

<script>
import vueInput from 'src/vue/areas/real-estate/components/forms/vue-input'
import vueTextarea from 'src/vue/areas/real-estate/components/forms/vue-textarea'
import vueRow from 'src/vue/areas/real-estate/components/forms/vue-row'
import vueElement from 'src/vue/areas/real-estate/components/forms/vue-element'
import formattedInput from 'src/vue/areas/real-estate/components/forms/FormattedInput'

import businessObjectPicker from 'src/vue/areas/real-estate/components/object-picker/business-object-picker'
import personPicker from 'src/vue/areas/real-estate/components/object-picker/person-picker'
import localDistrictPicker from 'src/vue/areas/real-estate/components/object-picker/local-district-picker'
import communityPicker from 'src/vue/areas/real-estate/components/object-picker/community-picker'

import {parse, format} from 'src/js/i18n/conversion'

export default {
  components: {
    vueInput,
    vueRow,
    vueElement,
    vueTextarea,
    formattedInput,
    businessObjectPicker,
    personPicker,
    localDistrictPicker,
    communityPicker
  },
  data () {
    return {
      debug: false,
      parser: parse,
      formatter4d: value => format(value, 'f4'),
      lp: {
        localDistrict: null,
        cadastralDistrict: null,
        number: null,
        businessObject: null,
        size: null,
        segmentSize: null,
        owner: null,
        landRegisterCommunity: null,
        landRegister: null,
        remark: null

      }, // empty object to enable reactive wiring
      tenantId: ''
    }
  },
  methods: {
    toggleDebug () {
      this.debug = !this.debug
    },
    setOrgUnitId (value) {
      this.tenantId = value
    },
    focusFirstInput () {
      this.$refs.firstInput.focus()
    }
  }
}
</script>

<style lang="scss">
  .debugswitch:hover {
    -webkit-transition: color 7s;
    transition: color 7s;
    color: hsl(0, 0%, 89%);
  }

  .debugswitch {
    -webkit-transition: color 0.5s;
    transition: color 0.5s;
    color: #ffffff;
    cursor: default;
  }

</style>
