<template>
  <div class="form row">
    <div class="col-sm-12">
      <date-time-picker
        v-model="value.start" :label="SR_ModelProperties.Start"
        iso
        required
      />
    </div>
    <div class="col-sm-12">
      <date-time-picker
        v-model="value.end" :label="SR_ModelProperties.End"
        :min="endMin"
        iso required
      />
    </div>
    <div class="col-sm-6">
      <numeric-input v-model="value.leasePrice" :label="SR_ModelProperties.LeasePrice" required />
    </div>
    <div class="col-sm-6">
      <selectize
        v-model="value.currency" :label="SR_ModelProperties.Currency"
        :fields="{value: 'uid', text: 'symbol', label: 'name'}"
        :options="currencies" required
        :allow-empty="true"
        name="currency"
      />
    </div>
    <div class="col-sm-12">
      <text-input v-model="value.remark" :label="SR_ModelProperties.Remark" multiline />
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import TextInput from 'src/vue/components/forms/TextInput'
import NumericInput from 'src/vue/components/forms/NumericInput'
import Selectize from 'src/vue/areas/real-estate/components/forms/Selectize'
import DateTimePicker from 'src/vue/areas/real-estate/components/forms/DateTimePicker'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

import moment from 'moment'

const resources = {
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    TextInput,
    NumericInput,
    DateTimePicker,
    Selectize
  },

  mixins: [makeResourceMixin(resources)],

  props: ['value', 'name'],

  computed: {
    ...mapGetters('realEstateContractLease',
      [
        'currencies'
      ]),
    endMin: {
      get () {
        return this.value.start != null ? moment(this.value.start).utc() : null
      }
    }
  }
}
</script>
