<template>
  <div class="form">
    <div class="row">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <text-input v-model="value.communications.phone" :label="SR_ModelProperties.Phone" @input="onChange" />
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <text-input v-model="value.communications.phoneSecond" label="" @input="onChange" />
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <text-input v-model="value.communications.phoneThrid" label="" @input="onChange" />
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <text-input v-model="value.communications.eMail" :label="SR_ModelProperties.Email" @input="onChange" />
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <text-input v-model="value.communications.eMailSecond" label="" @input="onChange" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <text-input v-model="value.communications.fax" :label="SR_ModelProperties.Fax" @input="onChange" />
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <text-input v-model="value.communications.faxSecond" label="" @input="onChange" />
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <text-input v-model="value.communications.website" :label="SR_ModelProperties.WebSite" @input="onChange" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

import TextInput from 'src/vue/components/forms/TextInput'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

import FormPartMixin from 'src/vue/components/forms/FormPartMixin'
import ChangeDetectionMixin from 'src/vue/components/forms/ChangeDetectionMixin'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    TextInput
  },
  mixins: [FormPartMixin, ChangeDetectionMixin, makeResourceMixin(resources)],
  props: ['value', 'name'],
  methods: {
    ...mapActions('realEstateMasterDataContacts/contacts', [
      'setContactAsChanged']),
    onChange () {
      this.setContactAsChanged()
    }
  }
}
</script>
<style>
</style>
