<template lang="html">
  <div class="real-estate">
    <div v-if="translationsLoaded">
      <masterdata-ui
        :include-tree="true" :columns="columns"
        :load-items-action-url="loadItemsActionUrl" :create-item-action-url="createItemActionUrl"
        :update-item-action-url="updateItemActionUrl" :grid-title="SR_RealEstate.LoanNumber"
        :modal-title-add="SR_RealEstate.AddLoanNumber"
        :modal-title-edit="SR_RealEstate.EditLoanNumber" :setup-modal-content="setupModalContent"
        :build-object-for-request="buildObjectForRequest"
        @entityChanged="updateEntity"
      />
    </div>
  </div>
</template>

<script lang="js">
import masterdataUi from './masterdata-ui'
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'
import newGuid from 'src/js/helpers/new-guid'

const resources = {
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate'
}

export default {
  components: {
    masterdataUi
  },
  mixins: [makeResourceMixin(resources)],

  data () {
    return {
      loadItemsActionUrl: '/RealEstate/MasterDataLoanNumber/GetLoanNumbers',
      createItemActionUrl: '/RealEstate/MasterDataLoanNumber/Create',
      updateItemActionUrl: '/RealEstate/MasterDataLoanNumber/Update',
      entityId: null,
      translationsLoaded: false
    }
  },

  computed: {
    columns () {
      return [
        {
          localizedTitle: this.SR_RealEstate['LoanNumber'],
          typeName: 'String',
          propertyName: 'name'
        },
        {
          localizedTitle: 'UID',
          typeName: 'String',
          propertyName: 'uid',
          isIdColumn: true,
          invisible: true
        }
      ]
    }

  },

  methods: {
    setupModalContent (data = {uid: newGuid(), name: ''}) {
      return [
        {propertyName: 'uid', value: data.uid, label: '', required: false, editable: false},
        {propertyName: 'name', value: data.name, label: this.SR_RealEstate.LoanNumber, required: true, editable: true}
      ]
    },

    updateEntity (entityId) {
      this.entityId = entityId
    },

    buildObjectForRequest (data) {
      return {
        uid: data[0].value, // TODO: find better solution
        name: data[1].value,
        fkDataOwner: this.entityId
      }
    }

  },

  created () {
    this.$store.dispatch('i18n/preloadTranslations', [
      'Areas.RealEstate.SR_RealEstate'
    ])
      .then(() => {
        this.translationsLoaded = true
      })
  }
}
</script>

<style lang="scss" scoped>
</style>
