<template lang="html">
  <ObjectPicker
    :value="value"
    load-url="/RealEstate/MasterDataBusinessObject/GetBusinessObjectsSelectize"
    :label="label||'Objekt'"
    modal-title="Objekt erstellen"
    :open-function="populateModal"
    :confirm-function="createOnServer"
    :additional-query-params="{entityId: orgUnitId}"
    :fields="fields"
    @input="onInput"
  >
    <VueRow>
      <VueElement>
        <VueInput ref="input" v-model="modal.value" label="Objekt Name" />
      </VueElement>
    </VueRow>
  </ObjectPicker>
</template>

<script>

import {postJson, get} from 'src/coffee/infrastructure/ajax'

import VueInput from 'src/vue/areas/real-estate/components/forms/vue-input'
import VueRow from 'src/vue/areas/real-estate/components/forms/vue-row'
import VueElement from 'src/vue/areas/real-estate/components/forms/vue-element'
import ObjectPicker from 'src/vue/areas/real-estate/components/object-picker'
import newGuid from 'src/js/helpers/new-guid'

export default {
  components: {
    VueRow,
    VueElement,
    VueInput,
    ObjectPicker
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    label: String,
    orgUnitId: String,
    fields: Object
  },
  data () {
    return {
      modal: {
        value: ''
      }
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', value)
    },
    populateModal (input) {
      this.modal.value = input
      return this.$refs.input
    },
    createOnServer () {
      return new Promise((resolve) => {
        var uid = newGuid()
        postJson('/RealEstate/MasterDataBusinessObject/Create', {uid: uid, Name: this.modal.value, fkDataOwner: this.orgUnitId})
          .then(() => {
            get('/RealEstate/MasterDataBusinessObject/GetBusinessObjectById', {objectId: uid, entityId: this.orgUnitId})
              .then((bo) => {
                resolve({
                  label: '',
                  text: bo.name,
                  value: bo.uid
                })
              })
          })
      })
    }
  }
}
</script>

<style lang="css">
</style>
