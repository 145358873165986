# CoffeeScript

#Adds a confirmation dialog to the grid to allow extra care upon removing items
class GridExtender
    constructor: (grid) ->
        @grid = grid

    setupExtendedRemoveConfimatonDialog: (message) =>
        @removeConfimationDialog = $('#remove-confirmation-dialog').modal(show: false)
        @removeConfimationDialog.find('#dialog-text').empty().html(message)

        @removeConfimationDialogConfirmButton = @removeConfimationDialog.find('#confirm-button')
        @removeConfimationDialogDismissButton = @removeConfimationDialog.find('#dismiss-button')

        @removeConfimationDialogConfirmButton.off 'click'
                                            .on 'click', @handleConfirm


        @removeConfimationDialogDismissButton.off 'click'
                                            .on 'click', @handleDismiss

    handleConfirm: =>
        @grid.editor.remove('.selected', false)
                    .submit()

        @grid.table.rows().deselect()

        @removeConfimationDialog.modal('hide')


    handleDismiss: =>
        @removeConfimationDialog.modal('hide')

    addExtendedRemoveConfirmationDialog: (message) =>
        @setupExtendedRemoveConfimatonDialog(message)

        @deleteButton = @grid.editControls.delete.$button

        @deleteButton.off 'click'
                    .on 'click', @onDelete


    onDelete: (event) =>
        event.preventDefault()
        @removeConfimationDialog.modal('show')
        return true


module.exports = {GridExtender}

module.exports.__esModule = true
module.exports.default = GridExtender
