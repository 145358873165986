<!-- Copy of Tree.vue changed to bind directly to the node thus being again compatible to RenderJson on the multiroot tree -->

<template lang="html">
  <div class="vue-tree-wrapper">
    <div class="easytree-addon-data" :data-expand-text="mergedOptions.labels.expand" :data-collapse-text="mergedOptions.labels.collapse" />
    <div
      class="easytree fade" data-target="subcontent"
      :data-tree-id="mergedOptions.id"
      data-setup-control="tree" :data-keep-tree="mergedOptions.keep"
    />
  </div>
</template>

<script>
import setupTree from 'src/coffee/infrastructure/data-api/setup-functions/tree'
import {findNode} from 'src/coffee/controls/tree/helpers'

const defaultOptions = {
  labels: {
    expand: 'Expand',
    collapse: 'Collapse'
  },
  id: null,
  keep: true
}

export default {
  props: ['options', 'nodes', 'value'],
  data () {
    return {
      mergedOptions: defaultOptions,
      tree: null
    }
  },
  methods: {
    updateNodes (nodes) {
      this.tree.rebuildTree(nodes)
    },
    updateSelection () {
      if (!this.value) {
        return
      }

      const selectedNode = findNode(this.nodes, x => x.data.uid === this.value.uid)
      if (!selectedNode) {
        return
      }

      this.tree.activateNode(selectedNode.id)
    },
    onClick (event) {
      // this.$emit('input')
      if (event.target.tagName === 'A') {
        event.preventDefault()
      }

      let node = this.tree.getNode(event.currentTarget.id)

      this.$emit('input',
        {
          uid: node.data.uid,
          name: node.text
        })
    },
    deactivateAjax () {
      $(this.$el).find('.easytree a').attr('data-ajax', 'false')
    }
  },
  watch: {
    value () {
      this.updateSelection()
    },
    nodes (value) {
      if (value instanceof Function) {
        value().then(this.updateNodes)
      } else {
        this.updateNodes(value)
      }
    }
  },
  mounted () {
    this.mergedOptions = Object.assign({}, defaultOptions, this.options)

    const options = {
      data: this.nodes instanceof Function ? null : this.nodes,
      built: this.deactivateAjax,
      toggled: this.deactivateAjax
    }

    const parameters = {
      $element: $(this.$el.querySelector('.easytree')),
      $ctx: $(this.$el),
      options
    }
    setupTree(parameters)
      .then(tree => {
        this.tree = tree

        $(this.$el).on('click', '.easytree-node', this.onClick)

        if (this.nodes instanceof Function) {
          return this.nodes().then(this.updateNodes).then(this.updateSelection)
        } else {
          this.deactivateAjax()
          this.updateSelection()
        }
      })
  }
}
</script>

<style lang="scss" scoped>
.fullheight.vue-tree-wrapper .tree-wrapper .easytree {
  height: 100% !important;
  overflow-y: hidden;
}
</style>
