{noop} = require './noop'
{getCurrentLocale} = require 'src/js/i18n'

class Binder
    constructor: (@view, @onChangeCallback = noop, @onInvalidatedCallback = noop) ->
        @bindings = {}

    getIdentifiers: (predicate = (binding) -> true) =>
        identifiers = []
        for identifier, binding of @bindings
            if(predicate(binding))
                identifiers.push identifier
        return identifiers

    bindLink: (identifier, datapath) ->
        buildLink = (data, identifier) ->
            if data
                return "<a href='#{data}' id='#{identifier.slice(1)}' data-ajax='false' target='_blank'> <span class='glyphicon glyphicon-circle-arrow-right' aria-hidden='true'></span></a>"
            else
                return "<span id='#{identifier.slice(1)}'></span>"

        @bindings[identifier] = {
            type: 'link'
            datapath: datapath.split('.')
            isReadOnly: true
            setData: (data) =>
                @view.find(identifier).replaceWith(buildLink(data, identifier))
                @bindings[identifier].data = data
            clearData: =>
                @view.find(identifier).replaceWith(buildLink(false, identifier))
                @bindings[identifier].data = undefined
            getData: => @bindings[identifier].data
            enable: => @view.find(identifier).prop 'disabled', false
            disable: => @view.find(identifier).prop 'disabled', true
        }

    bindInput: (identifier, datapath, isReadOnly = false, formatfunc = ((val) -> val), parsefunc = ((val) -> val)) ->
        failsafeFormatter = (value) -> formatfunc(value) unless (value is undefined or value is null)
        failsafeParser = (value) -> parsefunc(value) unless (value is undefined or value is null)

        control = @view.find identifier
                    .on 'input', @onChangeCallback

        #for validation and correct numberformatting
        control.on 'blur', (event) =>
            $control = $(event.target)
            $control.val(failsafeFormatter(failsafeParser($control.val())))
            @onInvalidatedCallback($control)

        @bindings[identifier] = {
            type: 'input'
            control: control
            datapath: datapath.split('.')
            isReadOnly: isReadOnly
            setData: (data) -> control.val(failsafeFormatter(data))
            clearData: -> control.val('')
            getData: -> return failsafeParser(control.val())
            enable: -> control.prop 'disabled', false
            disable: -> control.prop 'disabled', true
        }

        return control

    bindCheckbox: (identifier, datapath) ->
        control = @view.find identifier
                    .on 'input', @onChangeCallback

        @bindings[identifier] = {
            type: 'input'
            control: control
            datapath: datapath.split('.')
            setData: (data) -> control.prop 'checked', data
            clearData: -> control.prop 'checked', false
            getData: -> return control .is (':checked')
            enable: -> control.prop 'disabled', false
            disable: -> control.prop 'disabled', true
        }

        return control

    bindDateControl: (identifier, datapath, isReadOnly = false) ->
        control = @view.find identifier
                .datetimepicker
                    format: 'L'
                    locale: getCurrentLocale()
                    useCurrent: false

        adjustDate = (dateToAdjust) -> moment(dateToAdjust).utc().add(dateToAdjust.utcOffset(), 'm')

        @bindings[identifier] = {
            type: 'date'
            control: control
            datapath: datapath.split('.')
            isReadOnly: isReadOnly
            setData: (value) =>
                control.off 'dp.change'
                control.data('DateTimePicker').date(moment.utc(value))
                control.on 'dp.change', (event) =>
                    return if adjustDate(event.date).isSame(event.oldDate)
                    @onChangeCallback()
                    @onInvalidatedCallback(control)
            clearData: ->
                control.off('dp.change').data('DateTimePicker').clear()
            getData: ->
                dateString = control.data('DateTimePicker').date()
                if dateString
                    return adjustDate(dateString).toISOString()
                else
                    return null

            enable: -> control.data('DateTimePicker').enable()
            disable: -> control.data('DateTimePicker').disable()
        }

        return control

    bindGrid: (identifier, datapath, isReadOnly = false) ->
        control = @view.find(identifier).staticgrid()[0]

        control.editor
            .on 'create', (event) => @onChangeCallback()
            .on 'edit', (event) => @onChangeCallback()
            .on 'remove', (event) => @onChangeCallback()

        @bindings[identifier] = {
            type: 'grid'
            control: control
            datapath: datapath.split('.')
            isReadOnly: isReadOnly
            setData: (data) ->
                control.table.clear()
                processedContent = []
                for item in data
                    processedContent.push(control.converter.processJSObject(item))
                control.table.rows.add(processedContent)
                control.table.draw()
            clearData: ->
                control.selectionExtension?.reset()
                control.selectionGridValidationExtension?.reset()
                control.table.clear()
                control.table.draw()
            getData: -> control.table.rows().data().toArray()
        }

        return control

    #bind to a combobox created using the default combobox setup
    bindCombobox: (identifier, datapath, isReadOnly = false) ->
        control = @view.find(identifier)[0].selectize
        control.on('change', (value) => @onChangeCallback())
        control.default = control.getValue()

        @bindings[identifier] = {
            type: 'select'
            control: control
            datapath: datapath.split('.')
            isReadOnly: isReadOnly
            setData: (data) ->
                if data is null or data is undefined
                    return
                if data is null
                    control.addItem('none', true)
                control.addOption(data)
                control.addItem(data, true)
            clearData: -> control.setValue(control.default, true)
            getData: -> if control.getValue() == 'none' then null else control.getValue()
            enable: -> control.enable()
            disable: -> control.disable()
        }

        return control

    bindAjaxSelectize: (identifier, datapath, loadfunction, extraoptions = {}, createFilter = ((input, control) -> true ), isReadOnly = false) ->
        defaultoptions = {
            valueField: 'uid'
            labelField: 'name'
            searchField: 'name'
            sortField: 'name'
            onChange: (value) =>
                @onChangeCallback()
                @onInvalidatedCallback($(identifier))

            createFilter: (input) => createFilter(input, @bindings[identifier].control)
            load: loadfunction
            preload: 'focus'
            allowEmptyOption: true
            selectOnTab: false
        }

        options = $.extend({}, defaultoptions, extraoptions)

        control = @view.find(identifier).selectize(options)[0].selectize  #ToDo: rename to select


        @bindings[identifier] = {
            type: 'selectize'
            control: control
            datapath: datapath.split('.')
            isReadOnly: isReadOnly
            setData: (data) ->
                if data is null or data is undefined
                    control.clear(true)
                    return
                control.addOption(data)
                control.addItem(data[options.valueField], true)
            clearData: -> control.clear(true)
            getData: -> control.options[control.getValue()]
            enable: -> control.enable()
            disable: -> control.disable()
        }

        return control

    bindHiddenValue: (identifier, datapath) ->
        container = {
            value: null,
            set: (val) ->
                this.value = val
                if this.change
                    this.change(val)
            change: @onChangeCallback
        }

        @bindings[identifier] = {
            type: 'hidden'
            valueContainer: container
            datapath: datapath.split('.')
            setData: (data) -> container.value = data
            clearData: -> container.value = undefined
            getData: -> return container.value
        }

        return container

    connect: (sourceIdentifier, targetIdentifier) ->
        @bindings[sourceIdentifier].control.change( =>
            @bindings[targetIdentifier].setData(@bindings[sourceIdentifier].getData())
            @onChangeCallback())

    remove: (identifier) ->
      delete @bindings[identifier]

    getDataValue: (data, datapath) ->
        obj = data
        for property in datapath[...-1]
            obj = obj[property]
            if obj is undefined
                obj = {}
        return obj[datapath[-1...][0]]

    enableControls: ->
        for identifier, binding of @bindings
            if not binding.isReadOnly and binding.enable isnt undefined
                binding.enable()

    disableControls: ->
        for identifier, binding of @bindings
            if binding.disable isnt undefined
                binding.disable()

    disableReadonlyControls: ->
        for identifier, binding of @bindings
            if binding.disable isnt undefined and binding.isReadOnly
                binding.disable()

    updateControls: (data) ->
        for identifier, binding of @bindings
            binding.setData(@getDataValue(data, binding.datapath))

    clearControls: ->
        for identifier, binding of @bindings
            binding.clearData()

    readControls: ->
        data = {}
        for identifier, binding of @bindings
            current = data
            previous = data
            property = binding.datapath.slice(-1)[0]
            path = binding.datapath.slice(0, -1)
            for element in path
                current = previous[element]
                if current is undefined
                    current = {}
                    previous[element] = current
                previous = current
            current[property] = binding.getData()
        return data

    readControl: (identifier) ->
        @bindings[identifier].getData()

    getControl: (identifier) ->
        @bindings[identifier].control


module.exports = {Binder}

module.exports.__esModule = true
module.exports.default = Binder
