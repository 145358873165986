<template lang="html">
  <div>
    <label v-if="label && showLabel" class="control-label" :for="id">
      {{ label }}
      <span v-if="!allowEmpty"> *</span>
      <a
        v-if="hasDescription" ref="info"
        :data-content="actualInfoTitle"
        data-placement="left" data-trigger="hover"
        class="info text-info"
      ><i class="fa fa-question" aria-hidden="true" /></a>
    </label>
    <VueSelectize
      ref="firstInput" :disabled="disabled"
      :options="options" :value="value"
      :create="create && openCreateModal" :allow-empty="allowEmpty"
      :fields="fields" @input="onInput"
      @type="onType"
    />
    <BsModal
      v-model="visible" :title="modalTitle"
      blocking
      @shown="focus" @hidden="disposeHandlers"
    >
      <div class="form" :disabled="internalDisabled">
        <slot />
      </div>
      <button
        slot="footer" class="btn btn-success"
        :disabled="internalDisabled"
        @click="confirmModal"
      >
        {{ label }} erstellen
      </button>
      <button
        slot="footer" class="btn btn-danger"
        :disabled="internalDisabled"
        @click="declineModal"
      >
        Abbrechen
      </button>
    </BsModal>
  </div>
</template>

<script>

import {get} from 'src/coffee/infrastructure/ajax'

import VueSelectize from 'src/vue/components/VueSelectize'
import BsModal from 'src/vue/components/bootstrap/BsModal'

let counter = 0

export default {
  components: {
    VueSelectize,
    BsModal
  },
  props: {
    name: String,
    value: {
      type: Object,
      default: null
    }, // value of the picker passed through to use v-model on implemented pickers, see e.g. business-object-picker
    loadUrl: { // load-url for the selectize
      type: String,
      required: true
    },
    label: String, // label for the selectize
    showLabel: {
      type: Boolean,
      default: true
    },
    modalTitle: String, // title for the creation modal
    confirmFunction: {
      // called upon confirmation of the modal - no parameters
      // it should do whatever needs doing - e.g. create the object on the server, twiddle thumbs....
      // and return a Promise resolving into a SelectizeItem, i.e. an item of the form
      // {
      //  text: 'Displaytext',
      //  value: 'ItemId',
      //  label: 'Itemlabel' //the small grey text next to the "text" - optional
      // }

      type: Function,
      required: true
    },
    openFunction: {
      // called upon the modal opening with the input from the selectize as parameter, this should populate
      // the fields of the modal (which are defined in the implementing picker) and return an element that should be focused
      // (this element needs a focus function otherwise it won't work)
      type: Function,
      required: true
    },
    additionalQueryParams: Object,
    allowEmpty: {
      default: true,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    create: {
      default: true,
      type: Boolean
    },
    fields: Object
  },
  data () {
    return {
      visible: false,
      selectizeCallback: undefined,
      focusObject: undefined,
      searchedData: this.value ? [this.value] : [],
      id: `object-picker-${counter++}`,
      internalDisabled: false
    }
  },
  computed: {
    hasDescription () {
      return !this.allowEmpty || this.ruleDescription
    },
    actualInfoTitle () {
      if (!this.allowEmpty) {
        return 'This is a required field'
      } else if (this.ruleDescription) {
        return this.ruleDescription
      }
      return ''
    },
    options () {
      return this.value ? Array.isArray(this.value) ? [...this.value, ...this.searchedData] : [this.value, ...this.searchedData] : this.searchedData
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', value)
    },
    onType: function (str) {
      let url = `${this.loadUrl}?query=${str}`
      let paramstring = ''
      for (const prop in this.additionalQueryParams) {
        paramstring += `&${prop}=${this.additionalQueryParams[prop]}`
      }
      url += `${paramstring}`
      get(url)
        .done((result) => {
          this.searchedData = result || []
        })
    },
    openCreateModal (input, callback) {
      this.selectizeCallback = callback

      // call outside function to initialize modal data
      this.focusObject = this.openFunction(input)

      this.internalDisabled = false
      this.visible = true
    },

    focus () {
      if (this.visible) {
        if (this.focusObject) {
          if (typeof (this.focusObject.focus) === 'function') {
            this.focusObject.focus()
          } else {
            alert(`WARNING:  ${this.focusObject.focus} is not a function.\nFocusObject: ${this.focusObject}`)
          }
        }
      } else {
        this.$refs.firstInput.focus()
      }
    },

    confirmModal () {
      this.internalDisabled = true
      this.confirmFunction()
        .then((result) => {
          this.selectizeCallback() // make selectize shut up
          this.selectizeCallback = undefined
          this.visible = false
          this.$emit('input', result)
        })

      // TODO: Errorhandling
    },
    declineModal () {
      this.internalDisabled = true
      this.selectizeCallback()
      this.selectizeCallback = undefined
      this.visible = false
    },
    disposeHandlers () {
      if (this.selectizeCallback) {
        this.selectizeCallback()
        this.selectizeCallback = undefined
      }
    }
  },
  watch: {
    value () {
      if (this.value) {
        this.searchedData.push(this.value)
      }
    },
    additionalQueryParams () {
      this.searchedData = []
    }
  },
  mounted () {
    if (this.value) {
      this.searchedData.push(this.value)
    }
  }
}
</script>

<style lang="css">
</style>
