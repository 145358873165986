import { render, staticRenderFns } from "./save-or-discard-modal.vue?vue&type=template&id=79b787e7&scoped=true&"
import script from "./save-or-discard-modal.vue?vue&type=script&lang=js&"
export * from "./save-or-discard-modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79b787e7",
  null
  
)

export default component.exports