<template>
  <vue-selectize
    ref="vueSelectize" :options="options"
    v-bind="$attrs" :value="value"
    :allow-empty="allowEmpty"
    @type="updateOptions" v-on="$listeners"
    @input="$emit('input', $event)"
  />
</template>

<script>
import vueSelectize from 'src/vue/components/VueSelectize.vue'
import {get} from 'src/coffee/infrastructure/ajax'
import {isEqual} from 'lodash'

export default {

  components: {
    vueSelectize
  },
  // this is a wrapper around vueSelectize.vue encapsulating ajax functionality
  // all original props from vueSelectize except options retain their usage and meaning
  // options is explicitly managed by this component
  props: {
    // for v-model binding since value is not in $attrs if bound with v-model
    value: [Object, Array],

    // The url to use when querying the server for options
    ajaxUrl: String,

    // additional parameters to use when querying the server
    additionalQueryParams: Object,

    // whether to emit an 'input':null event when additionalQueryParams changes
    resetOnDependencyChange: {
      type: Boolean,
      default: true
    },

    // whether to allow empty selection or not
    allowEmpty: Boolean,

    // whether to update the options from the server (using an empty input ('') in the query)
    // whenever additionalQueryParams changes
    preloadOnDependencyChange: Boolean
  },

  data () {
    return {
      searchedData: [],
      previousQueryParams: this.additionalQueryParams
    }
  },

  computed: {
    options () {
      return this.value ? Array.isArray(this.value) ? [...this.value, ...this.searchedData] : [this.value, ...this.searchedData] : this.searchedData
    }
  },

  methods: {
    updateOptions (input, openOnResult = true) {
      const data = {
        query: input,
        ...this.additionalQueryParams
      }

      get(this.ajaxUrl, data)
        .done(result => {
          this.searchedData = result || []

          if (openOnResult) {
            this.$refs.vueSelectize.selectize.open()
          }
        })
    },

    focus () {
      this.$refs.vueSelectize.focus()
    }
  },

  watch: {
    additionalQueryParams: {
      handler () {
        if (isEqual(this.additionalQueryParams, this.previousQueryParams)) {
          return
        }

        this.previousQueryParams = this.additionalQueryParams

        if (this.resetOnDependencyChange) {
          this.$emit('input', null)
        }
        if (this.preloadOnDependencyChange) {
          this.updateOptions('', false)
        } else {
          this.searchedData = []
        }
      },
      deep: true
    }
  }
}
</script>

<style>
</style>
