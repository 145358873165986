<template lang="html">
  <div class="real-estate">
    <div v-if="translationsLoaded">
      <masterdata-ui
        :columns="columns" :grid-title="SR_RealEstate.Usage"
        :modal-title-add="SR_RealEstate.AddUsageType" :modal-title-edit="SR_RealEstate.EditUsageType"
        :load-items-action-url="loadItemsActionUrl" :update-item-action-url="updateItemActionUrl"
        :create-item-action-url="createItemActionUrl"
        :build-object-for-request="buildUsageTypeObject" :setup-modal-content="setupModalContent"
      />
    </div>
  </div>
</template>

<script lang="js">
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'
import masterdataUi from './masterdata-ui'
import newGuid from 'src/js/helpers/new-guid'

const resources = {
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate'
}

export default {
  components: {
    masterdataUi
  },
  mixins: [makeResourceMixin(resources)],
  data () {
    return {
      loadItemsActionUrl: '/RealEstate/MasterDataUsageType/GetUsageTypes',
      updateItemActionUrl: '/RealEstate/MasterDataUsageType/Update',
      createItemActionUrl: '/RealEstate/MasterDataUsageType/Create',
      translationsLoaded: false
    }
  },
  computed: {
    columns () {
      return [
        {
          localizedTitle: this.SR_RealEstate['UsageTypeName'],
          typeName: 'String',
          propertyName: 'name'
        },
        {
          localizedTitle: this.SR_RealEstate['UsageTypeShortcut'],
          typeName: 'String',
          propertyName: 'shortcut'
        },
        {
          localizedTitle: 'UID',
          typeName: 'String',
          propertyName: 'uid',
          isIdColumn: true,
          invisible: true
        }
      ]
    }
  },
  methods: {
    setupModalContent (data = {uid: newGuid(), name: '', shortcut: ''}) {
      return [
        {propertyName: 'uid', value: data.uid, label: '', required: false, editable: false},
        {propertyName: 'name', value: data.name, label: this.SR_RealEstate.UsageTypeName, required: true, editable: true},
        {propertyName: 'shortcut', value: data.shortcut, label: this.SR_RealEstate.UsageTypeShortcut, required: true, editable: true}
      ]
    },
    buildUsageTypeObject (data) {
      return {
        uid: data[0].value, // TODO: find better solution
        name: data[1].value,
        shortcut: data[2].value
      }
    }
  },
  created () {
    this.$store.dispatch('i18n/preloadTranslations', [
      'Areas.RealEstate.SR_RealEstate'
    ])
      .then(() => {
        this.translationsLoaded = true
      })
  }
}
</script>

<style lang="scss" scoped>
</style>
