<template lang="html">
  <div class="form-group" :class="feedbackClasses">
    <label v-if="label" class="control-label" :for="id">
      {{ label }}
      <span v-if="required"> *</span>
      <a
        v-if="hasDescription" ref="info"
        :data-content="actualInfoTitle"
        data-placement="left" data-trigger="hover"
        class="info text-info"
      ><i class="fa fa-question" aria-hidden="true" /></a>
    </label>
    <VueDateTimePicker
      :id="id" :value="value"
      :iso="iso" :readonly="readonly"
      :min="min" :max="max"
      :aria-describedby="`${id}-sr-status`" :placeholder="actualPlaceholder"
      :tabindex="tabindex" :name="name"
      @input="onDatePickerInput"
    />
    <transition name="icon" mode="out-in">
      <span
        v-if="state" :key="icon"
        class="glyphicon form-control-feedback"
        :class="[icon ? `glyphicon-${icon}` : null]" aria-hidden="true"
      />
    </transition>
    <span v-if="state" :id="`${id}-status-sr`" class="sr-only">{{ srState }}</span>
    <transition name="message">
      <span v-if="message" :id="`${id}-status-message`" class="help-block">{{ message }}</span>
    </transition>
  </div>
</template>
<script>
import {debounce} from 'lodash'

import RuleMixin from 'src/vue/components/forms/RuleMixin'
import VueDateTimePicker from 'src/vue/components/forms/VueDateTimePicker'

let counter = 0

const iconLookup = {
  error: 'remove',
  warning: 'warning-sign',
  success: 'ok'
}

export default {
  components: {
    VueDateTimePicker
  },
  mixins: [RuleMixin],
  props: {
    value: null,
    iso: Boolean,
    label: String,
    min: null,
    max: null,
    name: String,
    placeholder: String, // placeholder has higher priority than default property of messages object
    required: Boolean,
    readonly: Boolean,
    tabindex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      id: `date-time-picker-${counter++}`,
      dirty: false
    }
  },
  computed: {
    hasDescription () {
      return this.required || this.ruleDescription
    },
    actualInfoTitle () {
      if (this.required) {
        return 'Dies ist ein Pflichtfeld.'
      } else if (this.ruleDescription) {
        return this.ruleDescription
      }
      return ''
    },
    actualPlaceholder () {
      return this.placeholder || this.ruleDescription
    },
    icon () {
      return iconLookup[this.state]
    },
    state () {
      // never validate without user entry
      if (!this.dirty) {
        return undefined
      }
      // required error has higher priority than other rules
      if (this.required && !this.value) {
        return 'error'
      }
      // test rule if possible
      if (this.ruleState !== undefined) {
        return this.ruleState
      }
      // required success has lower priority than other ruless
      if (this.required) {
        return 'success'
      }
      // neither required nor any rule
      return undefined
    },
    srState () {
      return this.state ? `(${this.state})` : null
    },
    message () {
      const combinedMessages = Object.assign({}, this.ruleMessages)

      return combinedMessages[this.state]
    },
    feedbackClasses () {
      const classes = this.state ? ['has-feedback'] : []

      if (this.state) {
        classes.push(`has-${this.state}`)
      }

      return classes
    }
  },
  methods: {
    onInput: debounce(function (event) {
      this.dirty = true
      this.$emit('input', event.target.value)
    }, 300),
    onDatePickerInput (event) {
      this.$emit('input', event)
    }
  },
  mounted () {
    $(this.$refs.info).popover()
  }
}
</script>

<style lang="scss" scoped>

.message-enter-active, .message-leave-active {
  transition: opacity 0.25s, max-height 0.25s;
}
.message-enter, .message-leave-to {
  opacity: 0;
  max-height: 0;
}

.message-enter-to, .message-leave {
  opacity: 1;
  max-height: 3rem;
}

.icon-enter-active, .icon-leave-active {
  transition: color 0.15s, opacity 0.15s, transform 0.15s;
}

.icon-enter, .icon-leave-to {
  opacity: 0;
  transform: scale(0.5);
}

label {
  transition: color 0.25s;
}

input {
  transition: border-color 0.25s, box-shadow 0.25s;
}

.form-control-feedback {
  top: 25px;
}

.form-group {
  position: relative;
}

a.info i {
  opacity: 0.5;
  transition: opacity 0.5s;

  &:hover {
    opacity: 1;
  }
}

</style>
