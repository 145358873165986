<template lang="html">
  <ObjectPicker
    ref="firstInput"
    :value="value"
    load-url="/RealEstate/LocalDistricts"
    :label="label||'Gemarkung'"
    modal-title="Gemarkung erfassen"
    :open-function="populateModal"
    :confirm-function="createOnServer"
    :create="create"
    :additional-query-params="{entityId: orgUnitId}"
    @input="onInput"
  >
    <VueRow>
      <VueElement width="6">
        <label class="control-label">Land</label>
        <VueSelectize
          ref="focusObject" v-model="country"
          :options="countries"
          :allow-empty="true"
        />
      </VueElement>
    </VueRow>
    <VueRow>
      <VueElement width="6">
        <label class="control-label">Bundesland</label>
        <SelectizeAjax
          v-model="region" ajax-url="/RealEstate/Region/Selectize"
          :additional-query-params="{country: country ? country.value : null}"
          :allow-empty="true"
        />
      </VueElement>
    </VueRow>
    <VueRow>
      <VueElement width="6">
        <label class="control-label">Landkreis</label>
        <SelectizeAjax
          v-model="district" ajax-url="/RealEstate/District"
          :additional-query-params="{region: region ? region.value : null, entityId: orgUnitId}"
          :allow-empty="true"
        />
      </VueElement>
    </VueRow>
    <VueRow>
      <VueElement width="6">
        <VueInput v-model="localDistrictName" label="Gemarkungsname" />
      </VueElement>
    </VueRow>
  </ObjectPicker>
</template>

<script>

import {postJson, get} from 'src/coffee/infrastructure/ajax'

import VueSelectize from 'src/vue/components/VueSelectize'
import SelectizeAjax from 'src/vue/areas/real-estate/components/forms/SelectizeAjaxWrapped'
import VueInput from 'src/vue/areas/real-estate/components/forms/vue-input'
import VueRow from 'src/vue/areas/real-estate/components/forms/vue-row'
import VueElement from 'src/vue/areas/real-estate/components/forms/vue-element'
import ObjectPicker from 'src/vue/areas/real-estate/components/object-picker'

export default {
  components: {
    VueRow,
    VueElement,
    VueInput,
    VueSelectize,
    ObjectPicker,
    SelectizeAjax
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    label: String,
    create: {
      type: Boolean,
      default: true
    },
    orgUnitId: String
  },
  data () {
    return {
      country: null,
      region: null,
      district: null,
      localDistrictName: '',
      countries: [],
      query: null
    }
  },
  methods: {
    onInput (value) {
      this.$emit('input', value)
    },
    populateModal (input) {
      this.region = null
      this.district = null
      this.localDistrictName = input
      return this.$refs.focusObject
    },
    createOnServer () {
      return new Promise((resolve) => {
        postJson('LocalDistricts/Create', {
          Name: this.localDistrictName,
          FKDistrict: this.district.value,
          FKDataOwner: this.orgUnitId
        })
          .then((id) => {
            get('LocalDistricts/Detail', {id: id})
              .then((ld) => {
                resolve({
                  text: ld.name,
                  label: `${ld.district}, ${ld.region}`,
                  value: ld.uid
                })
              })
          })
      })
    },
    focus () {
      this.$refs.firstInput.focus()
    }
  },
  watch: {
    country () {
      this.region = {}
    },
    region () {
      this.district = {}
    }
  },
  created () {
    get('/RealEstate/Country/GetSelectableCountries')
      .done((result) => {
        this.countries = result
      })
  }
}
</script>

<style lang="css">
</style>
