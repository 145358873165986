<template lang="html">
  <BsModal :title="title" :value="value" @input="$event ? confirm() : cancel()">
    <div class="content">
      <slot />
    </div>
    <template slot="footer">
      <div class="footer">
        <IxButton large @click="confirm">
          <slot name="confirm">
            <IxRes :resource="SR_Common">
              Yes
            </IxRes>
          </slot>
        </IxButton>
        <IxButton large @click="cancel">
          <slot name="cancel">
            <IxRes :resource="SR_Common">
              No
            </IxRes>
          </slot>
        </IxButton>
      </div>
    </template>
    <slot slot="title" name="title" />
  </BsModal>
</template>

<script>
import BsModal from 'src/vue/components/bootstrap/BsModal'
import IxButton from 'src/vue/components/IxButton'
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

const resources = {
  SR_Common: 'Common.SR_Common'
}
export default {
  components: {
    BsModal,
    IxButton
  },
  mixins: [makeResourceMixin(resources)],
  props: {
    title: String,
    value: Boolean
  },
  methods: {
    confirm () {
      this.$emit('input', false)
      this.$emit('confirm')

      if (this.resolve) {
        this.resolve()
        this.resolve = null
      }
    },
    cancel () {
      this.$emit('input', false)
      this.$emit('cancel')

      if (this.reject) {
        this.reject()
        this.reject = null
      }
    },
    show () {
      this.$emit('input', true)

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer {
  display: flex;
  justify-content: space-evenly;
}
</style>
