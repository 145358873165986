{get} = require 'src/coffee/infrastructure/ajax'
{makeReplaceHandler} = require 'src/coffee/helpers/async/fragment-loading'

GetHistoryFor = (id, action) ->
    if id is '00000000-0000-0000-0000-000000000000'
        ClearCurrentHistoryDisplay()
    else
        get("#{action}?id=#{id}")
        .then makeReplaceHandler($('#history-ajax-target'))

RegisterLoadEventOnTab = ($tab, id) ->
    $tab.off('shown.bs.tab')
    ClearCurrentHistoryDisplay()

    action = $('#history-actions').data('get-action')

    loadfunc = (event) ->
        $tab.off('shown.bs.tab')
        GetHistoryFor(id, action)
        return

    if $tab.parent().hasClass('active')
        loadfunc()
    else
        $tab.on('shown.bs.tab', loadfunc)

    return

ClearCurrentHistoryDisplay = ->
    $('#history-ajax-target').empty()

module.exports = {
    GetHistoryFor,
    RegisterLoadEventOnTab,
    ClearCurrentHistoryDisplay
}
module.exports.__esModule = true
