<template>
  <div class="form">
    <div class="row">
      <div class="col-sm-6">
        <selectize
          v-model="recipientData.paymentMode" :label="SR_ModelProperties.PaymentMode"
          :fields="{value: 'value', text: 'text'}"
          :options="paymentModes" required
          :allow-empty="true"
          name="paymentMode"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <person-picker
          v-model="recipientData.recipient" name="recipient"
          :org-unit-id="selectedOrgUnit.id"
          type="Other" :label="SR_ModelProperties.Recipient"
          :disabled="false"
          :allow-empty="true"
        />
      </div>
      <div class="col-sm-6">
        <text-input v-model="recipientData.transactionComment" :label="SR_ModelProperties.TransactionComment" name="transactionComment" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <selectize
          ref="bankDetailsSelectize" v-model="bankDetailsSelection"
          label="Bankverbindung"
          :options="bankDetailsSelectizeOptions" 
          :allow-empty="true"
          name="bankDetails"
        />
      </div>
    </div>
    <div class="row col-md-8" :style="{marginBottom: '20px', marginTop: '-1em'}">
      <a class="small" @click="showBankDetailInputs">
        <IxRes :resource="SR_RealEstate">AddBankDetails</IxRes>
      </a>
    </div>
    <transition name="fade">
      <div v-if="bankDetailInputsVisible" key="transitionDiv">
        <div class="row">
          <div class="col-sm-6">
            <text-input v-model="recipientData.iban" :label="SR_ModelProperties.IBAN" />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <text-input v-model="recipientData.bic" :label="SR_ModelProperties.BIC" @blur="loadBankName" />
          </div>
          <div class="col-sm-6">
            <text-input v-model="recipientData.bankName" :label="SR_RealEstate.Bank" :disabled="recipientData.bankNameAlreadyExists" />
          </div>
        </div>
      </div>
    </transition>
    <div class="row">
      <div class="col-sm-6">
        <numeric-input v-model="recipientData.share" :label="SR_ModelProperties.Share" />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <numeric-input v-model="recipientData.flatSum" :label="SR_ModelProperties.FlatSum" />
      </div>
      <div class="col-sm-6">
        <selectize
          v-model="recipientData.currency" :label="SR_ModelProperties.Currency"
          :fields="{value: 'uid', text: 'symbol', label: 'name'}"
          :options="currencies" required
          :allow-empty="true"
          name="currency"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <text-input v-model="recipientData.typeOfPayment" :label="SR_ModelProperties.TypeOfPayment" />
      </div>
    </div>
    <div class="col-md-offset-8" :style="{marginTop: '20px'}">
      <button
        type="button" class="btn btn-success"
        :disabled="!requiredInputsFilledOut"
        @click="save"
      >
        <IxRes :resource="SR_Common">
          Save
        </IxRes>
      </button>
      <button type="button" class="btn btn-default" @click="cancel">
        <IxRes :resource="SR_Common">
          Cancel
        </IxRes>
      </button>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import TextInput from 'src/vue/components/forms/TextInput'
import NumericInput from 'src/vue/components/forms/NumericInput'
import Selectize from 'src/vue/areas/real-estate/components/forms/Selectize'
import PersonPicker from 'src/vue/areas/real-estate/components/object-picker/person-picker.vue'
import {get} from 'src/coffee/infrastructure/ajax'
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'
import newGuid from 'src/js/helpers/new-guid'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    TextInput,
    NumericInput,
    Selectize,
    PersonPicker
  },
  mixins: [makeResourceMixin(resources)],
  props: {
    value: null
  },

  data () {
    return {
      bankDetailInputsVisible: false,
      bankDetails: [],
      bankDetailsSelectizeOptions: [],
      bankDetailsSelection: null,
      recipientData: {
        bankName: '',
        bankNameAlreadyExists: false,
        bic: null,
        currency: null,
        flatSum: null,
        iban: null,
        paymentMode: null,
        recipient: null,
        saveNewBankDetails: false,
        share: null,
        transactionComment: null,
        typeOfPayment: null
      }
    }
  },

  computed: {
    ...mapGetters('realEstateContractLease/finances', [
      'paymentModes'
    ]),
    ...mapGetters('realEstateContractLease/navigation', [
      'selectedOrgUnit'
    ]),
    ...mapGetters('realEstateContractLease',
      [
        'currencies'
      ]),

    requiredInputsFilledOut () {
      return this.recipientData.paymentMode && this.recipientData.recipient && this.recipientData.iban && this.recipientData.currency &&
        this.recipientData.typeOfPayment && this.shareAndFlatsumValid
    },

    shareAndFlatsumValid () { // at the moment, either share or flatSum has to be zero in order to be able to save the contract
      return this.recipientData.share != null && this.recipientData.flatSum != null &&
        ((this.recipientData.share === 0 && this.recipientData.flatSum > 0) || (this.recipientData.share > 0 && this.recipientData.flatSum === 0))
    }
  },

  methods: {

    showBankDetailInputs () {
      this.bankDetailInputsVisible = true
      this.bankDetailsSelection = null
      this.recipientData.saveNewBankDetails = true
      this.recipientData.bankNameAlreadyExists = false
      this.resetBankDetailInputs()
    },

    save () {
      this.$emit('input', this.recipientData)
      this.$emit('save')
      this.bankDetailInputsVisible = false
      this.bankDetailsSelection = null
    },

    cancel () {
      this.$emit('cancel')
      this.bankDetailInputsVisible = false
      this.bankDetailsSelection = null
      this.resetBankDetailInputs()
      this.resetBankDetails()
    },

    resetBankDetailInputs () {
      this.recipientData.iban = null
      this.recipientData.bic = null
      this.recipientData.bankName = null
    },

    resetBankDetails () {
      this.bankDetails = []
      this.bankDetailsSelectizeOptions = []
      this.resetBankDetailInputs()
    },

    mapBankDetailsSelectionToValue () {
      this.bankDetails.forEach((x) => {
        if (this.bankDetailsSelection.value === x.uid) {
          this.recipientData.iban = x.iban.trim()
          this.recipientData.bic = x.swiftBic ? x.swiftBic.trim() : ''
          this.recipientData.bankName = x.name ? x.name.trim() : ''
          this.recipientData.saveNewBankDetails = false
        }
      })
    },

    loadBankName () {
      this.recipientData.bankName = ''
      this.recipientData.bankNameAlreadyExists = false
      if (this.recipientData.bic) {
        get('/RealEstate/MasterDataBank/GetBankNameForBic', {entityId: this.selectedOrgUnit.id, bic: this.recipientData.bic})
          .done((result) => {
            if (result) {
              this.recipientData.bankName = result
              this.recipientData.bankNameAlreadyExists = true
            }
          })
      }
    }
  },
  watch: {

    value: {
      handler: function () {
        const additionalData = {
          bankName: '',
          bankNameAlreadyExists: false,
          currency: null,
          paymentMode: null
        }

        this.recipientData = Object.assign({}, additionalData, JSON.parse(JSON.stringify(this.value)))
      },
      deep: true
    },

    'recipientData.recipient': function (oldVal) {
      this.resetBankDetails()
      if (oldVal) {
        let ibanFromValue = this.value.iban ? this.value.iban.trim() : null
        let bicFromValue = this.value.bic ? this.value.bic.trim() : null

        get('/RealEstate/Contacts/GetBankDetailsForContact', {contactId: oldVal.value})
          .done((result) => {
            this.bankDetails = result
            result.forEach((x) => {
              x.iban = x.iban ? x.iban.trim() : ' '
              x.swiftBic = x.swiftBic ? x.swiftBic.trim() : ' '
              x.name = x.name ? x.name.trim() : ' '

              let currentItem = {value: x.uid, text: x.iban + ' - ' + x.name, label: x.swiftBic}
              this.bankDetailsSelectizeOptions.push(currentItem)

              if (ibanFromValue && bicFromValue && ibanFromValue === x.iban && bicFromValue === x.swiftBic) {
                this.bankDetailsSelection = currentItem
              }
            })

            if (!this.bankDetailsSelection && ibanFromValue && bicFromValue) {
              let bankDetailItemFromValue = {value: newGuid(), text: ibanFromValue, label: bicFromValue}
              this.bankDetailsSelectizeOptions = [bankDetailItemFromValue]
              this.bankDetailsSelection = bankDetailItemFromValue
            }
          })
      }
    },

    bankDetailsSelection () {
      if (this.bankDetailsSelection) {
        this.bankDetailInputsVisible = false
        this.mapBankDetailsSelectionToValue()
      } else {
        this.resetBankDetailInputs()
      }
    }
  }
}
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
