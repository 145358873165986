{getModal} = require 'src/coffee/controls/modals'
{LocalDistrictCreateController} = require 'src/coffee/areas/real-estate/local-district/local-district-create-controller'
{BusinessObjectCreateController} = require 'src/coffee/areas/real-estate/business-object/business-object-create-controller'
{CommunityCreateController} = require 'src/coffee/areas/real-estate/community/community-create-controller'

commands = {}

commands.CreateLocalDistrict = (commandArgs, mediator) ->
    getModal 'LocalDistricts/CreateView'
    .then ->
        $view = $('#create-local-district-view').modal show: true
        ctrl = new LocalDistrictCreateController $view, mediator
        ctrl.setName commandArgs.Name

commands.CreateBusinessObject = (commandArgs, mediator) ->
    getModal 'BusinessObject/CreateView'
    .then ->
        $view = $('#create-business-object-view').modal show: true
        ctrl = new BusinessObjectCreateController $view, mediator
        ctrl.setName commandArgs.Name
        ctrl.setEntityId commandArgs.entityId

commands.CreateCommunity = (commandArgs, mediator) ->
    getModal 'Community/CreateCommunityModal'
    .then ->
        $view = $('#create-community-view').modal show: true
        ctrl = new CommunityCreateController $view, mediator
        ctrl.setName commandArgs.Name
        ctrl.setEntityId commandArgs.entityId

module.exports = {
    commands
}
module.exports.__esModule = true
module.exports.default = commands
