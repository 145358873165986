{register} = require 'src/js/infrastructure/setup-registry'
{PropertyController} = require './property-controller'
{commands} = require '../land-parcel/commands'
{Mediator} = require '../utils/mediator'

ViewSetup = (ctx) ->
    mediator = new Mediator()
    mediator.register commands
    view = ctx.find '#property-view'
    ctrl = new PropertyController view, mediator
    return ctrl

register 'App.RealEstate.Property.ViewSetup', ViewSetup
