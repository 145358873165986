# CoffeeScript
{postJson} = require 'src/coffee/infrastructure/ajax'

class CreatePersonController
    constructor: (@options) ->
        @nameCtrl = @options.view.find '#notary-name'
        @nameCtrl.val(@options.name)
        @nameCtrl.focus()

        @doneBtn = @options.view.find '#done-btn'
        @doneBtn.on 'click', @create

        @cancelBtn = @options.view.find '#cancel-btn'
        @cancelBtn.on 'click', @cancel

        @options.view.on 'keyup', (event) =>
            return unless event.which is 13 #Enter
            @create()

    create: =>
        postJson '/RealEstate/Person/Create',
            Id: null
            fkDataOwner: @options.tenantId
            type: @options.type
            Name: @nameCtrl.val()
        .done @raiseCreated

    raiseCreated: (id) =>
        @options.onCreated
            uid: id
            name: @nameCtrl.val()

    cancel: =>
        @options.onCancel()


module.exports = {CreatePersonController}

module.exports.__esModule = true
module.exports.default = CreatePersonController
