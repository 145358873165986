{get} = require 'src/coffee/infrastructure/ajax'

{makeReplaceHandler} = require 'src/coffee/helpers/async/fragment-loading'

{getModal} = require 'src/coffee/controls/modals'

{LandParcelCreatorController} = require './land-parcel-creator-controller'

getSelectedItem = (box) ->
    box.options[box.getValue()]

class LandParcelSelectorController

    constructor: ($modal, @AssociatedEntities, @entityId) ->
        @$modal = $modal
        @submitHandlers = []

        @selectedLd = undefined
        @selectedCd = undefined
        @selectedLp = undefined
        @selectedLRE = undefined

        @multiSwitchPreventer = 0

        @setupModal $modal

    setupModal: ($modal) =>
        $actions = $modal.find '#land-parcel-add-actions'

        @ldAction = $actions.data 'local-district-action'
        @cdAction = $actions.data 'cadastral-district-action'
        @lpAction = $actions.data 'land-parcel-action'
        @lreAction = $actions.data 'lre-action'
        @creationModalAction = $actions.data 'modal-create-action'

        @ldBox = @setupLocalDistrictSelector $modal
        @cdBox = @setupCadastralDistrictSelector $modal
        @lpBox = @setupLandParcelSelector $modal
        @sizeBox = $modal.find('#contractualSize')[0]

        @submitButton = $modal.find '#submit'
        @submitButton.on 'click', @handleSubmit
        @submitButton.disable()

        @ldBox.focus()

    setupLocalDistrictSelector: ($modal) =>
        loader = (query, callback) =>
            $.get(@ldAction,  {query:query, entityId: @entityId})
            .done (result) -> callback result

        renderLocalDistrict = (data, escape) ->
            """
                <div>
                    <span>#{escape(data.text)}</span>
                    <small class='text-muted'>#{escape(data.label)}</small>
                </div>
            """
        select = $modal.find '#localDistrict'
        control = select
        .selectize
            valueField: 'value'
            searchField: 'text'
            selectOnTab: true
            closeAfterSelect: true
            load: loader
            onChange: @localDistrictChanged
            render:
                option: renderLocalDistrict
                item: renderLocalDistrict
        return control[0].selectize

    setupCadastralDistrictSelector: ($modal) =>
        select = $modal.find '#cadastralDistrict'
        control = select
        .selectize
            valueField: 'uid'
            labelField: 'number'
            searchField: 'number'
            selectOnTab: true
            closeAfterSelect: true
            onChange: @cadastralDistrictChanged
        c = control[0].selectize
        c.disable()
        return c

    setupLandParcelSelector: ($modal) =>
        select = $modal.find '#landParcelNumber'
        control = select
        .selectize
            valueField: 'uid'
            labelField: 'number'
            searchField: 'number'
            selectOnTab: true
            closeAfterSelect: true
            onChange: @landParcelChanged
            create: @switchToCreateModal
            createFilter: @createAllowed
        c = control[0].selectize
        c.disable()
        return c

    localDistrictChanged: (value) =>
        @selectedLd = value
        @updateBox @selectedLd, @cdAction, @cdBox

    cadastralDistrictChanged: (value) =>
        @selectedCd = value
        @updateBox @selectedCd, @lpAction, @lpBox

    landParcelChanged: (value) =>
        @selectedLp = value
        # @updateBox @selectedLp, @lreAction, @lreBox
        if not (value is undefined or value is '')
            @loadCurrentLRE()
            .then =>
                @sizeBox.value = $('#lre-data').data('lre-area')
                @sizeBox.focus()
                @submitButton.enable()
        else
            @submitButton.disable()
            $('#lreTarget').empty()

    updateBox: (parentId, action, box) =>
        box.clear()
        box.clearOptions()
        if parentId is undefined or parentId is ''
            box.disable()
        else
            @loadBoxContents parentId, action, box

    loadBoxContents: (parentId, action, box) ->
        fillBox = (contents) ->
            box.addOption(contents)
            box.refreshOptions(true)

        get(action, {parentId:parentId, entityId: @entityId})
        .done (result) -> fillBox result
        .then box.enable()

    loadCurrentLRE: =>
        get @lreAction + "?parentId=#{@selectedLp}"
        .then makeReplaceHandler($('#lreTarget'))

    switchToCreateModal: (input, callback) =>
        if @multiSwitchPreventer isnt 0
            return
        @multiSwitchPreventer = 1

        callback(false) #get Selectize out of the way, since we won't be using this one anymore

        @$modal.modal('hide')

        availableData =
            number: input
            cadastralDistrictNumber: getSelectedItem(@cdBox).number
            localDistrictName: getSelectedItem(@ldBox).text
            localDistrictUid: getSelectedItem(@ldBox).value
            associatedEntities: @AssociatedEntities

        getModal @creationModalAction
        .then ($modal) =>
            @creationModal = new LandParcelCreatorController($modal, @AssociatedEntities, @entityId)
            @creationModal.onSubmit(handler) for handler in @submitHandlers
            @creationModal.populate(availableData)

    createAllowed: (filtertext) =>
        for name, option of @lpBox.options
            return false if option.number is filtertext
        return true

    onSubmit: (func) =>
        @submitHandlers.push(func)

    handleSubmit: =>
        $lre =  $('#lre-data')

        parse = Globalize.numberParser()

        area = $lre.data 'lre-area'

        result =
            landParcelId: @selectedLp
            sizeByContract: parse @sizeBox.value
            currentSize: if typeof(area) is 'string' then parse(area) else area
            community: $lre.data 'lre-community'
            page: $lre.data 'lre-page'
            localDistrict: getSelectedItem(@ldBox).text
            cadastralDistrict: getSelectedItem(@cdBox).number
            number: getSelectedItem(@lpBox).number
            remark: ''
            price: 0.0
            currency: '346c8459-bfb3-4054-ad9c-e08cd17792fe' #Id for €
            uid: '00000000-0000-0000-0000-000000000000'

        returnItem =
            resultingRow: result
            reOpen: false

        @submit returnItem
        @$modal.modal('hide')

    submit: (result) =>
        for handler in @submitHandlers
            handler result


module.exports = {LandParcelSelectorController}

module.exports.__esModule = true
module.exports.default = LandParcelSelectorController
