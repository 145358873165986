<template lang="html">
  <div class="panel panel-default">
    <div class="panel-body">
      <div class="row">
        <div class="col-md-12">
          <contact-communication-form v-model="value" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactCommunicationForm from './contact-communication-form'
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    ContactCommunicationForm
  },
  mixins: [makeResourceMixin(resources)],
  props: {
    value: null,
    createMode: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    name: String
  }
}
</script>

<style lang="css">
</style>
