<template lang="html">
  <ObjectPicker
    :value="value"
    load-url="/RealEstate/Contacts/Selectize"
    :label="label"
    :modal-title="SR_RealEstate.CreatePerson"
    :open-function="populateModal"
    :confirm-function="createOnServer"
    :allow-empty="allowEmpty"
    :additional-query-params="{orgUnitId: orgUnitId, type: type}"
    :disabled="disabled"
    :show-label="showLabel"
    @input="onInput"
  >
    <div class="row">
      <VueElement width="6">
        <VueInput ref="surname" v-model="modal.surname" :label="SR_ModelProperties.Name" />
      </VueElement>
      <VueElement width="6">
        <VueInput v-model="modal.firstname" :label="SR_ModelProperties.NameAdditional" />
      </VueElement>
    </div>
    <div class="row">
      <VueElement width="12">
        <VueInput v-model="modal.street" :label="SR_ModelProperties.Street" />
      </VueElement>
    </div>
    <div class="row">
      <VueElement width="12">
        <VueInput v-model="modal.postofficeBox" :label="SR_ModelProperties.PostOfficeBox" />
      </VueElement>
    </div>
    <div class="row">
      <VueElement width="4">
        <VueInput v-model="modal.postalCode" :label="SR_ModelProperties.PostalCode" />
      </VueElement>

      <VueElement width="8">
        <VueInput v-model="modal.city" :label="SR_ModelProperties.City" />
      </VueElement>
    </div>
    <div class="row">
      <div class="col-xs-8 col-xs-offset-4">
        <VueInput v-model="modal.localCentre" :label="SR_ModelProperties.LocalCentre" />
      </div>
    </div>
  </ObjectPicker>
</template>

<script>

import {postJson} from 'src/coffee/infrastructure/ajax'

import VueInput from 'src/vue/areas/real-estate/components/forms/vue-input'
import VueElement from 'src/vue/areas/real-estate/components/forms/vue-element'
import ObjectPicker from 'src/vue/areas/real-estate/components/object-picker'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

const resources = {
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    VueElement,
    VueInput,
    ObjectPicker
  },
  mixins: [makeResourceMixin(resources)],
  props: {
    value: {default: null},
    label: null,
    showLabel: {
      type: Boolean,
      default: true
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: true
    },
    orgUnitId: null,
    type: null
  },
  data () {
    return {
      modal: {
        fkDataOwner: this.orgUnitId,
        firstname: '',
        surname: '',
        street: '',
        postOfficeBox: '',
        country: null,
        region: null,
        postalCode: '',
        city: '',
        localCentre: '',
        type: this.type
      }
    }
  },
  computed: {
  },
  methods: {
    onInput (value) {
      this.$emit('input', value)
    },
    populateModal (input) {
      this.modal.fkDataOwner = this.orgUnitId
      this.modal.surname = input
      this.modal.firstname = ''
      this.modal.street = ''
      this.modal.postOfficeBox = ''
      this.modal.country = null
      this.modal.region = null
      this.modal.postalCode = ''
      this.modal.city = ''
      this.modal.type = this.type
      return this.$refs.name
    },
    createOnServer () {
      var promise = new Promise((resolve) => {
        postJson('/RealEstate/Person/CreateFromPicker', this.modal)
          .then((item) => {
            resolve(item)
          })
      })

      return promise
    }
  }
}
</script>

<style lang="css">
</style>
