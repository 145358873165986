<template lang="html">
  <div class="real-estate">
    <div v-if="translationsLoaded">
      <masterdata-ui
        :columns="columns" :grid-title="SR_RealEstate.Countries"
        :modal-title-add="SR_RealEstate.AddCountry" :modal-title-edit="SR_RealEstate.EditCountry"
        :load-items-action-url="loadItemsActionUrl" :update-item-action-url="updateItemActionUrl"
        :create-item-action-url="createItemActionUrl"
        :build-object-for-request="buildCountryObject" :setup-modal-content="setupModalContent"
        @input="updateCountryOnInput"
      />
      <region-grid v-if="isItemSelected" :country-u-i-d="countryUID" />
    </div>
  </div>
</template>

<script lang="js">
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'
import newGuid from 'src/js/helpers/new-guid'
import masterdataUi from './masterdata-ui'
import regionGrid from './region-grid'

const resources = {
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate'
}

export default {
  components: {
    regionGrid,
    masterdataUi
  },
  mixins: [makeResourceMixin(resources)],

  data () {
    return {
      loadItemsActionUrl: '/RealEstate/Country/GetCountries',
      updateItemActionUrl: '/RealEstate/Country/Update',
      createItemActionUrl: '/RealEstate/Country/Create',
      translationsLoaded: false,
      isItemSelected: false,
      countryUID: null
    }
  },

  computed: {

    columns () {
      return [
        {
          localizedTitle: this.SR_RealEstate['Country'],
          typeName: 'String',
          propertyName: 'name'
        },
        {
          localizedTitle: this.SR_RealEstate['CountryShortcut'],
          typeName: 'String',
          propertyName: 'shortcut'
        },
        {
          localizedTitle: 'UID',
          typeName: 'String',
          propertyName: 'uid',
          isIdColumn: true,
          invisible: true
        }
      ]
    }
  },

  methods: {

    setupModalContent (data = {uid: newGuid(), name: '', shortcut: ''}) {
      return [
        {propertyName: 'uid', value: data.uid, label: '', required: false, editable: false},
        {propertyName: 'name', value: data.name, label: this.SR_RealEstate.Country, required: true, editable: true},
        {propertyName: 'shortcut', value: data.shortcut, label: this.SR_RealEstate.CountryShortcut, required: true, editable: true}
      ]
    },

    buildCountryObject (data) {
      return {
        uid: data[0].value, // TODO: find better solution
        name: data[1].value,
        shortcut: data[2].value
      }
    },

    updateCountryOnInput (country) {
      if (country) {
        this.isItemSelected = true
        this.countryUID = country.uid
      } else {
        this.isItemSelected = false
      }
    }
  },

  created () {
    this.$store.dispatch('i18n/preloadTranslations', [
      'Areas.RealEstate.SR_RealEstate'
    ])
      .then(() => {
        this.translationsLoaded = true
      })
  }
}
</script>

<style lang="scss">
</style>
