<template lang="html">
  <div class="panel panel-default">
    <div class="panel-body">
      <grid
        v-if="termColumnsTranslationsAreLoaded" v-model="selectedTerms"
        :data="terms" :columns="termColumns"
        selection="single"
        paging minimal
        :add-button="!readOnly && currentPermissions.leaseContractCanEdit" :edit-button="hasCurrentTerm && currentPermissions.leaseContractCanEdit"
        :delete-button="hasCurrentTerm && currentPermissions.leaseContractCanEdit"
        @add="createTermRequest" @delete="deleteTermRequest"
        @edit="editTermRequest"
      />
    </div>
    <bs-modal
      :title="SR_RealEstate.CreateTerm" :value="isEditTermModalOpen"
      blocking
      @hidden="createOrEditTermFailure"
    >
      <lease-term-form v-model="editTerm" />
      <button
        slot="footer" type="button"
        class="btn btn-success"
        :disabled="!requiredInputsFilledOut" @click="createOrEditTermSuccess"
      >
        <IxRes :resource="SR_Common">
          Save
        </IxRes>
      </button>
      <button
        slot="footer" type="button"
        class="btn btn-default"
        @click="createOrEditTermFailure"
      >
        <IxRes :resource="SR_Common">
          Cancel
        </IxRes>
      </button>
    </bs-modal>
    <bs-modal :title="SR_RealEstate.DeleteTermTitle" :value="isDeleteTermModalOpen" blocking>
      <p>
        <IxRes :resource="SR_RealEstate">
          DeleteTermQuestion
        </IxRes>
      </p>
      <button
        slot="footer" type="button"
        class="btn btn-success"
        @click="deleteTermFailure"
      >
        <IxRes :resource="SR_Common">
          No
        </IxRes>
      </button>
      <button
        slot="footer" type="button"
        class="btn btn-default"
        @click="deleteTermSuccess"
      >
        <IxRes :resource="SR_Common">
          Yes
        </IxRes>
      </button>
    </bs-modal>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

import Grid from 'src/vue/areas/real-estate/components/grid/RELegacyGrid.vue'
import BsModal from 'src/vue/components/bootstrap/BsModal'

import LeaseTermForm from './LeaseTermsForm'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    Grid,
    BsModal,
    LeaseTermForm
  },
  mixins: [makeResourceMixin(resources)],
  props: {
    value: null,
    readOnly: {
      type: Boolean,
      default: false
    },
    name: {
      Type: String,
      default: ''
    }
  },

  computed: {
    ...mapGetters('realEstateContractLease/contracts', [
      'currentPermissions']),
    ...mapGetters('realEstateContractLease/terms', [
      'editTerm',
      'isEditTermModalOpen',
      'isDeleteTermModalOpen',
      'currentTerm',
      'hasCurrentTerm',
      'currencies']),

    terms: {
      get () {
        return this.value.terms ? this.value.terms : []
      }
    },
    selectedTerms: {
      get () {
        return [this.currentTerm]
      },
      set (value) {
        const term = value && value.length > 0 ? value[0] : null
        this.selectTerm(term)
      }
    },
    termColumns () {
      return [
        {localizedTitle: this.getLocalizedTitleByResourceName('Start'), typeName: 'DateTime', dateTimeFormat: 'L', propertyName: 'start'},
        {localizedTitle: this.getLocalizedTitleByResourceName('End'), typeName: 'DateTime', dateTimeFormat: 'L', propertyName: 'end'},
        {localizedTitle: this.getLocalizedTitleByResourceName('Remark'), typeName: 'String', propertyName: 'remark'},
        {localizedTitle: this.getLocalizedTitleByResourceName('LeasePrice'),
          typeName: 'Float',
          propertyName: 'leasePrice',
          minDecimalDigits: 2,
          maxDecimalDigits: 2},
        {localizedTitle: this.getLocalizedTitleByResourceName('Currency'), typeName: 'String', propertyName: 'currency.symbol'}
      ]
    },
    termColumnsTranslationsAreLoaded () {
      return this.termColumns.every(c => !!c.localizedTitle)
    },
    requiredInputsFilledOut () {
      return this.editTerm.start && this.editTerm.end && this.editTerm.leasePrice != null && this.editTerm.currency
    }
  },
  methods: {
    ...mapActions('realEstateContractLease/terms', [
      'createTermRequest',
      'editTermRequest',
      'createOrEditTermSuccess',
      'createOrEditTermFailure',
      'deleteTermRequest',
      'deleteTermSuccess',
      'deleteTermFailure',
      'selectTerm']),

    getLocalizedTitleByResourceName (resourceName) {
      return this.SR_ModelProperties[resourceName] || resourceName
    }
  }
}
</script>

<style lang="css">
</style>
