<template lang="html">
  <div class="real-estate">
    <div v-if="translationsLoaded">
      <masterdata-ui
        :include-tree="true" :columns="columns"
        :load-items-action-url="loadItemsActionUrl" :create-item-action-url="createItemActionUrl"
        :update-item-action-url="updateItemActionUrl" :grid-title="SR_RealEstate.CommunityMD"
        :modal-title-add="SR_RealEstate.AddCommunity"
        :modal-title-edit="SR_RealEstate.EditCommunity" :setup-modal-content="setupModalContent"
        :build-object-for-request="buildObjectForRequest"
        @entityChanged="updateEntity"
      />
    </div>
  </div>
</template>

<script lang="js">
import masterdataUi from './masterdata-ui'
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'
import newGuid from 'src/js/helpers/new-guid'
import {get} from 'src/coffee/infrastructure/ajax'

const resources = {
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate'
}

export default {
  components: {
    masterdataUi
  },
  mixins: [makeResourceMixin(resources)],

  data () {
    return {
      loadItemsActionUrl: '/RealEstate/MasterDataCommunity/GetCommunitiesWithDistricts',
      createItemActionUrl: '/RealEstate/MasterDataCommunity/Create',
      updateItemActionUrl: '/RealEstate/MasterDataCommunity/Update',
      entityId: null,
      translationsLoaded: false,
      districts: []
    }
  },

  computed: {
    columns () {
      return [
        {
          localizedTitle: this.SR_RealEstate['CommunityMD'],
          typeName: 'String',
          propertyName: 'name'
        },
        {
          localizedTitle: this.SR_RealEstate['OfficialCodeCommunity'],
          typeName: 'String',
          propertyName: 'officialCode'
        },
        {
          localizedTitle: this.SR_RealEstate['District'],
          typeName: 'String',
          propertyName: 'districtName'
        },
        {
          localizedTitle: 'UID',
          typeName: 'String',
          propertyName: 'uid',
          isIdColumn: true,
          invisible: true
        }
      ]
    }

  },

  methods: {
    setupModalContent (data = {uid: newGuid(), district: '', name: '', officialCode: ''}) {
      return [
        {propertyName: 'uid', value: data.uid, label: '', required: false, editable: false},
        {propertyName: 'districtName', value: data.districtUid ? {value: data.districtUid, text: data.districtName} : null, label: this.SR_RealEstate.District, required: true, editable: true, dataType: 'selectize', selectizeOptions: this.districts},
        {propertyName: 'name', value: data.name, label: this.SR_RealEstate.CommunityMD, required: true, editable: true},
        {propertyName: 'officialCode', value: data.officialCode, label: this.SR_RealEstate.OfficialCodeCommunity, required: false, editable: true, maxLength: 8}
      ]
    },

    updateEntity (entityId) {
      this.entityId = entityId
      this.districts = []
      get('/RealEstate/MasterDataDistrict/GetDistricts', {entityId: this.entityId})
        .then((result) => {
          for (let district in result) {
            this.districts.push({value: result[district].uid, text: result[district].name, fkDataOwner: result[district].fkDataOwner})
          }
        })
    },

    buildObjectForRequest (data) {
      return {
        uid: data[0].value,
        fkDistrict: data[1].value.value,
        name: data[2].value,
        officialCode: data[3].value,
        fkDataOwner: this.entityId
      }
    }

  },

  created () {
    this.$store.dispatch('i18n/preloadTranslations', [
      'Areas.RealEstate.SR_RealEstate'
    ])
      .then(() => {
        this.translationsLoaded = true
      })
  }
}
</script>

<style lang="scss" scoped>
</style>
