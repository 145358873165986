<template lang="html">
  <div class="real-estate">
    <div v-if="translationsLoaded">
      <MasterdataUi
        :include-tree="true" :columns="columns"
        :load-items-action-url="loadItemsActionUrl" :create-item-action-url="createItemActionUrl"
        :update-item-action-url="updateItemActionUrl" :grid-title="SR_RealEstate.FunctionalZoningCategory"
        :modal-title-add="SR_RealEstate.AddFunctionalZoningCategory"
        :modal-title-edit="SR_RealEstate.EditFunctionalZoningCategory" :setup-modal-content="setupModalContent"
        :build-object-for-request="buildObjectForRequest"
        :global-and-tenant-items="true" @entityChanged="updateEntity"
      />
    </div>
  </div>
</template>

<script lang="js">
import {mapResources} from '@helpers/vuex'

import MasterdataUi from './masterdata-ui'
import newGuid from 'src/js/helpers/new-guid'

export default {
  components: {
    MasterdataUi
  },
  data () {
    return {
      loadItemsActionUrl: '/RealEstate/MasterDataFunctionalZoningCategory/GetFunctionalZoningCategories',
      createItemActionUrl: '/RealEstate/MasterDataFunctionalZoningCategory/Create',
      updateItemActionUrl: '/RealEstate/MasterDataFunctionalZoningCategory/Update',
      entityId: null,
      translationsLoaded: false
    }
  },
  computed: {
    columns () {
      return [
        {
          localizedTitle: this.SR_RealEstate['FunctionalZoningCategory'],
          typeName: 'String',
          propertyName: 'name'
        },
        {
          localizedTitle: this.SR_RealEstate['IsSystem'],
          typeName: 'Boolean',
          propertyName: 'isSystem',
          render: function (data) {
            return data + '( )'
          }
        },
        {
          localizedTitle: 'UID',
          typeName: 'String',
          propertyName: 'uid',
          isIdColumn: true,
          invisible: true
        },
        {
          localizedTitle: 'fkDataOwner',
          typeName: 'String',
          propertyName: 'fkDataOwner',
          isIdColumn: false,
          invisible: true
        }
      ]
    },
    ...mapResources([
      'Areas.RealEstate.SR_RealEstate'
    ])
  },
  methods: {
    setupModalContent (data = {uid: newGuid(), name: ''}) {
      return [
        {
          propertyName: 'uid',
          value: data.uid,
          label: '',
          required: false,
          editable: false
        }, {
          propertyName: 'name',
          value: data.name,
          label: this.SR_RealEstate.FunctionalZoningCategory,
          required: true,
          editable: true
        }
      ]
    },

    updateEntity (entityId) {
      this.entityId = entityId
    },

    buildObjectForRequest (data) {
      return {
        uid: data[0].value,
        name: data[1].value,
        fkDataOwner: data.fkDataOwner
      }
    }

  },
  created () {
    this.$store.dispatch('i18n/preloadTranslations', [
      'Areas.RealEstate.SR_RealEstate'
    ])
      .then(() => {
        this.translationsLoaded = true
      })
  }
}
</script>

<style lang="scss" scoped>
</style>
