<template lang="html">
  <div>
    <label v-if="label" :for="identifier" class="control-label">{{ label }}</label>
    <div v-if="!!addOnText" class="input-group">
      <input
        :id="identifier" ref="input"
        v-model="inputValue"
        class="form-control" :readonly="readOnly"
        :tabindex="tabindex" @blur="emit"
        @keyup.enter="emit"
      >
      <div class="input-group-addon">
        {{ addOnText }}
      </div>
    </div>
    <input
      v-else :id="identifier"
      ref="input"
      v-model="inputValue" class="form-control"
      :readonly="readOnly" :tabindex="tabindex"
      @blur="emit" @keyup.enter="emit"
    >
  </div>
</template>

<script>

let idCounter = 1

export default {
  props: ['label', 'value', 'addOnText', 'readOnly', 'formatter', 'parser', 'tabindex'],
  data () {
    return {
      identifier: `FormattedInput_${idCounter++}`,
      inputValue: null
    }
  },
  computed: {
    safeFormatter () {
      return this.formatter === undefined ? (x) => x : (x) => x === undefined ? x : this.formatter(x)
    },
    safeParser () {
      return this.parser === undefined ? (x) => x : (x) => x === undefined ? x : this.parser(x)
    }
  },
  methods: {
    emit ($event) {
      this.$emit('input', this.safeParser($event.target.value))
      this.reset() // necessary to also trigger formatting when the actual value didn't change. E.g: 3.00 => 3.0
    },
    reset () {
      this.inputValue = this.safeFormatter(this.value)
    },
    focus () {
      this.$refs.input.focus()
    }
  },
  watch: {
    value () {
      this.reset()
    }
  },
  created () {
    this.reset()
  } // necessary? Test!
}
</script>

<style lang="css">
</style>
