FollowsRegex = (re) ->
    (data) -> re.test(data)

HasValue = ->
    (data) -> !!data

HasValueSelectizeGrid = ->
    (data) -> data.uid != '00000000-0000-0000-0000-000000000000' and !!data.name

IsNumeric = ->
    (data) ->
        if not typeof(data) is 'number' or isNaN(data)
            return false
        return true

IsLink = -> FollowsRegex(/https?:\/\/.+/)

All = (rules...) ->
    (data) -> rules.every( (r) -> r(data) )

Any = (rules...) ->
    (data) -> rules.some( (r) -> r(data) )

Not = (rule) ->
    (data) -> not rule(data)

IsEmpty = ->
    Not(HasValue())

makeValidationRule = (validation, errorMsg) ->
    (data) ->
        new Promise((resolve, reject) ->
            if validation(data)
                resolve()
            else
                reject(errorMsg)
        )

module.exports = {
    makeValidationRule,
    Operators: {
        All, Any, Not
    }
    BaseRules: {
        FollowsRegex,
        HasValue,
        HasValueSelectizeGrid,
        IsEmpty,
        IsNumeric, # this one is bugged
        IsLink
    }
}

module.exports.__esModule = true
