<template lang="html">
  <div class="panel panel-default">
    <div class="panel-body">
      <grid
        v-if="amendmentColumnsTranslationsAreLoaded" v-model="selectedAmendment"
        :data="amendments" :columns="amendmentColumns"
        selection="single"
        paging minimal
        :add-button="!readOnly && currentPermissions.leaseContractCanEdit" :edit-button="hasCurrentAmendment && currentPermissions.leaseContractCanEdit"
        :delete-button="hasCurrentAmendment && amendments.length > 1 && currentPermissions.leaseContractCanEdit"
        @add="createAmendmentRequest" @delete="deleteAmendmentRequest"
        @edit="editAmendmentRequest"
      />
    </div>
    <bs-modal :title="SR_RealEstate.DeleteAmendmentTitle" :value="isDeleteAmendmentModalOpen" blocking>
      <p>
        <IxRes :resource="SR_RealEstate">
          DeleteAmendmentQuestion
        </IxRes>
      </p>
      <button
        slot="footer" type="button"
        class="btn btn-success"
        @click="deleteAmendmentFailure"
      >
        <IxRes :resource="SR_Common">
          No
        </IxRes>
      </button>
      <button
        slot="footer" type="button"
        class="btn btn-default"
        @click="deleteAmendmentSuccess"
      >
        <IxRes :resource="SR_Common">
          Yes
        </IxRes>
      </button>
    </bs-modal>
    <edit-amendment-modal />
  </div>
</template>
<script>

import {mapGetters, mapActions} from 'vuex'
import Grid from 'src/vue/areas/real-estate/components/grid/RELegacyGrid.vue'
import BsModal from 'src/vue/components/bootstrap/BsModal'
import EditAmendmentModal from './edit-amendment-modal'

import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_RealEstate: 'Areas.RealEstate.SR_RealEstate',
  SR_ModelProperties: 'ModelProperties.SR_ModelProperties'
}

export default {
  components: {
    Grid,
    BsModal,
    EditAmendmentModal
  },
  mixins: [makeResourceMixin(resources)],
  props: {
    value: null,
    readOnly: {
      type: Boolean,
      default: false
    },
    name: {
      Type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('realEstateContractLease/contracts', [
      'currentPermissions']),
    ...mapGetters('realEstateContractLease/amendments', [
      'isEditAmendmentModalOpen',
      'isDeleteAmendmentModalOpen',
      'currentAmendment',
      'hasCurrentAmendment']),
    amendments: {
      get () {
        return this.value.amendments ? [...this.value.amendments].sort((a, b) => new Date(b.validOn) - new Date(a.validOn)) : []
      }
    },
    selectedAmendment: {
      get () {
        return [this.currentAmendment]
      },
      set (value) {
        const selectedItem = value && value.length > 0 ? value[0] : null

        this.selectAmendment(selectedItem)
      }
    },
    amendmentColumns: {
      get () {
        return [
          {localizedTitle: this.getLocalizedTitleByResourceName('IsStart'), typeName: 'Boolean', propertyName: 'isStart'},
          {localizedTitle: this.getLocalizedTitleByResourceName('ValidOn'), typeName: 'DateTime', dateTimeFormat: 'L', propertyName: 'validOn'},
          {localizedTitle: this.getLocalizedTitleByResourceName('Lessor'), typeName: 'String', propertyName: 'lessorList'},
          {localizedTitle: this.getLocalizedTitleByResourceName('Leaser'), typeName: 'String', propertyName: 'leaserList'},
          {localizedTitle: this.getLocalizedTitleByResourceName('HasAreaChanges'), typeName: 'Boolean', propertyName: 'hasAreaChanges'},
          {localizedTitle: this.getLocalizedTitleByResourceName('IsExchangeAllowed'), typeName: 'Boolean', propertyName: 'isExchangeAllowed'},
          {localizedTitle: this.getLocalizedTitleByResourceName('IsWaterPaidByLeaser'), typeName: 'Boolean', propertyName: 'isWaterPaidByLeaser'},
          {localizedTitle: this.getLocalizedTitleByResourceName('IsTaxPaidByLeaser'), typeName: 'Boolean', propertyName: 'isTaxPaidByLeaser'}
        ]
      }
    },
    amendmentColumnsTranslationsAreLoaded () {
      return this.amendmentColumns.every(c => !!c.localizedTitle)
    }
  },
  methods: {
    ...mapActions('realEstateContractLease/amendments', [
      'createAmendmentRequest',
      'editAmendmentRequest',
      'createOrEditAmendmentSuccess',
      'createOrEditAmendmentFailure',
      'deleteAmendmentRequest',
      'deleteAmendmentSuccess',
      'deleteAmendmentFailure',
      'selectAmendment']),
    getLocalizedTitleByResourceName (resourceName) {
      return this.SR_ModelProperties[resourceName] ? this.SR_ModelProperties[resourceName] : `<<${resourceName}>>`
    }
  }
}
</script>

<style lang="css">
</style>
